/* --- CUSTOMER ITEM --- */
.customerItem .tabsNav a { 
	background: #F1F2FA; 
	border-radius: 5px;
	color: #5e626d; 
	display: inline-block; 
	margin-right: 5px;
	padding: 5px 20px; 
}
.customerItem .tabsNav a:hover { color: #54b6a9; }
.customerItem .tabsNav a.active { background: #54b6a9; color: #ffffff; }

.customerItem .tabsContent .tab { padding-top: 10px; }

.customerItem .addressBlock .add { margin-top: 20px; }
.customerItem .address { 
	border-top: 1px solid #e9eaee;
	margin-top: 10px;
	padding-top: 20px;
	padding-right: 30px; 
}
.customerItem .address:first-of-type { border: none; }
.customerItem .address .dadata { padding-right: 20px; }
.customerItem .address .comment { margin: 10px 0 5px; }
.customerItem .address .toggle { display: block; position: absolute; top: 2px; right: -8px; }
.customerItem .address .toggle i {
	background: url(../images/icons.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	width: 24px;
}
.customerItem .address .toggle.up i { background-position: -216px -128px; }
.customerItem .address .toggle.down i { background-position: -240px -128px; }
.customerItem .address .remove { position: absolute; top: 5px; right: -15px; }

.customerItem .stat .item { margin-bottom: 5px; }
.customerItem .stat h4 { margin: 0; font-size: 12px;  }
.customerItem .stat .number { font-size: 18px; font-weight: 700; color: #54b6a9; line-height: 25px; }
.customerItem .stat .number span { font-size: 13px; }


.customerItem .orderStat .graph .col { width: calc(100%/6 - 1px); }
.customerItem .orderStat .col .bar { min-height: 200px; margin: 0 25%; position: relative; }
.customerItem .orderStat .col .bar .fill { 
	border: 1px solid #ffffff; 
	position: absolute;
	bottom: 0;
	width: 100%;
}
.customerItem .orderStat .col .bar .fill .amount { 
	color: #FFFFFF;
	display: block;
	font-weight: 700;
	text-align: center;
	width: 100%;
}
.customerItem .orderStat .col .date {
	border-top: 1px solid #dddddd;
	color: #86878c;
	font-size: 11px;
	line-height: 30px;
	text-align: center;
	text-transform: capitalize;
}

.customerItem .brands { margin: 10px 0 20px; }
.customerItem .brands .item { border-top: 1px solid #dddddd; }
.customerItem .brands .chart > div { margin: auto; }
.customerItem .brands .chart text { color: #535663; }

.customerItem .orderHistory .table { display: table; width: 100%; font-size: 12px; }
.customerItem .orderHistory .order { display: table-row-group; box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); border-radius: 2px; }
.customerItem .orderHistory .order .tr { display: table-row; }
.customerItem .orderHistory .order .tr .td { display: table-cell; }
.customerItem .orderHistory .order .tr.head { background: #f5f6fb; }
.customerItem .orderHistory .order .tr.head .td { border-color: transparent; padding: 5px; line-height: 30px; display: table-cell; }
#OrdersPage .customerItem .orderHistory .order .td { display: table-cell; border-left: 1px solid #dddddd; padding: 2px 5px; vertical-align: top; border-bottom: none; }
.customerItem .orderHistory .order .td:first-child { border: none; }
.customerItem .orderHistory .order .td.products { width: 50%; }
.customerItem .orderHistory .order .td.status { width: 20%; }
.customerItem .orderHistory .order img { max-width: 90%; max-height: 40px; }
.customerItem .orderHistory .order h5 { text-transform: uppercase; color: #888888; font-size: 8px; line-height: 11px; }

.customerItem .orderHistory .order button {
	background: #3b96e3; /* Old browsers */
	background: -moz-linear-gradient(top, #3b96e3 0%, #2e86d0 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #3b96e3 0%,#2e86d0 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 ); /* IE6-9 */
	
	box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75);
	font: 14px/30px "Roboto", Arial, sans-serif;
}
.customerItem .orderHistory button:hover {
	background: #4da8f5; /* Old browsers */
	background: -moz-linear-gradient(top, #4da8f5 0%, #2e86d0 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #4da8f5 0%,#2e86d0 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); /* IE6-9 */
}

.customerItem .orderHistory button:disabled {
    background: #c9cace;
    cursor: default;
    box-shadow: none;
}

/* --- CUSTOMERS PAGE --- */
#CustomersPage .filter {margin-bottom: 10px; }
#CustomersPage .filter .col { padding-right: 20px; }
#CustomersPage .filter button { margin-top: 16px; width: 20%; }

#CustomersPage .table i.icon {
	background: url(../images/icons.svg) -672px -152px;
	display: block;
	height: 24px;
	margin-top: 5px;
	width: 24px;
}
#CustomersPage .table .icon.blacklist { background-position: -672px -152px; }
#CustomersPage .table .icon.common { background-position: -840px -152px; }

#CustomersPage .confirmBlock .buttons { float: none; }

#CustomerItem .section > .clearfix { min-height: auto; }
#CustomerItem .section .main .col { padding-right: 20px; }
#CustomerItem .section .main .col:last-child { margin-bottom: 20px; }

#CustomerItem .icon.common { background-position: -840px -152px; }



/* --- ORDERS PAGE --- */
/* #OrdersPage .listpage { padding-top: 90px; } */
#OrdersPage .head { margin: 0; }
#OrdersPage section > .head { display: block; }

#OrdersPage .head.fixed { 
	background: #f5f6fb;
	padding: 15px 20px 5px;
	position: fixed;
	top: 70px;
	right: 0;
	z-index: 2;
}

#OrdersPage .stat { font-size: 14px; margin: 0; }
#OrdersPage .stat span { display: inline-block; padding: 0 10px; border-left: 1px solid #d9dade; }
#OrdersPage .stat span span { font-size: 12px; color: #9a9a9a; padding: 0; }
#OrdersPage .stat span:first-of-type { border: none; padding-left: 0; }

#OrdersPage section > .head h2 { float: left; }
#OrdersPage section > .head button { float: right; } 


#OrdersPage .noSearch { margin-top: 20px; }

#OrdersPage .head .filter { float: right; margin: 2px 0; }
#OrdersPage .head .filter .nomargin {float: left; margin-right: 20px; }
#OrdersPage .head .filter .buttonGroup span,
#OrdersPage .head .filter .tags span { background: #FFFFFF; }
#OrdersPage .head .filter .buttonGroup span:hover,
#OrdersPage .head .filter .tags span:hover { background: #54b6a9; }
#OrdersPage .head .filter .buttonGroup span.active,
#OrdersPage .head .filter .tags span.active { background: #54b6a9; color: #FFFFFF; }


#OrdersPage .filter.fixed { background: #c1c3cc; margin: 5px 0; padding: 5px; }
#OrdersPage .filter .toggleFilter { top : 8px; right: 10px; left: auto; }
#OrdersPage .filter.fixed > div > .col { min-height: 32px; padding-right: 5px; }
#OrdersPage .filter .tags span { margin: 1px 0 0 5px; font-size: 12px; line-height: 30px; padding: 0 5px; box-sizing: border-box; }
#OrdersPage .filter .tags span:first-child { margin-left: 0; }

#OrdersPage .filter .col.payment { width: 10%; }
#OrdersPage .filter .col.filial { width: 13% ; }
#OrdersPage .filter .col.courier { width: 16%; }
#OrdersPage .filter .col.status { width: 13%; }

@media (max-width: 1280px) {
}
@media (max-width: 1030px) {
	#OrdersPage .stat { font-size: 12px; }
	#OrdersPage .head .filter .buttonGroup span { padding: 0 7px;}
}

#OrdersPage .filter .datePeriod {
	background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 5px 0 5px 5px;
	position: absolute;
	top: 42px;
    left: 0;
    z-index: 1;
}
#OrdersPage .filter .datePeriod .col { padding-right: 5px; }
#OrdersPage .filter .datePeriod .button { margin-top: 17px; text-align: center; padding: 0 10px;  }
#OrdersPage .filter .datePeriod .close { 
	background: none;
	box-shadow: none;
	position: absolute;
	top: 5px;
    right: 5px;
    transform: scale(0.8);
}
#OrdersPage .filter .datePeriod .close i {
	background: url(../images/icons.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#OrdersPage .filter .addDesktop input { background: #FFFFFF; }
#OrdersPage .filter .addDesktop label { color: #535353; }

#OrdersPage .SortHandle { top: 10px; left: 5px; }

/** --- ORDERS TABLE CSS --- */
.ordersTable.table { display: table; width: 100%; }
.ordersTable .tableBody { overflow: auto; }
.ordersTable .tableBody .SortableList { display: table; }
.ordersTable.table .tr h5 { text-transform: uppercase; font-size: 8px; color: #888888; line-height: 10px; }

.ordersTable .tag .time {
	border-radius: 50%;
	display: inline-block;
	height: 14px;
	margin-right: 5px;
	vertical-align: middle;
	width: 14px;
}
.ordersTable .tag .time.red { background: rgb(239, 139, 139); }
.ordersTable .tag .time.yellow { background: rgb(244, 233, 137); }
.ordersTable .tag .time.green { background: rgb(145, 249, 154); }
.ordersTable .tag .time.orange { background: rgb(242, 188, 139); }

.ordersTable .tr {
	border-radius: 2px;
	display: table-row-group;
	font-size: 12px;
	line-height: 19px;
	margin: 2px;
}
.ordersTable .tr .head { background: #ffffff; margin: 0; display: table-row; height: 43px; }
.ordersTable .tr .body { display: table-row; background: #FFFFFF;}
.ordersTable .tr .td {
	border-left: 1px solid #dddddd; 
	border-bottom: 6px solid #f5f6fb; 
	display: table-cell; 
	float: none; 
	padding: 3px 10px; 
	position: relative;
	vertical-align: top;
}
.ordersTable .tr .head .td {
	border-bottom: 1px solid #d9dade;
	border-left-color: transparent; 
	vertical-align: middle; 
	padding: 1px 10px 0;  
}
.ordersTable .tr .td:first-of-type { border-left: none; }

.ordersTable .tr .head .orderNumber b { border-bottom: 1px dotted #5e626d; cursor: pointer; }
.ordersTable .tr .head .orderNumber { min-width: calc(100% - 100px); text-overflow: clip; }

.ordersTable .tr .head .td > span:not(.tag) {
	line-height: 32px; 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
}

.ordersTable .tr .head .tag { white-space: nowrap; overflow: hidden; margin-bottom: 0; vertical-align: middle; }
.ordersTable .tr .head .tag.alert { color: #ad0f49; font-weight: bold; }

.ordersTable.table .tr button {
	background: #3b96e3;
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%);
	margin: 0;
	padding: 0 10px;
}
.ordersTable.table .tr button.red {
	background: #e73535;
	background: linear-gradient(to bottom, #e73535 0%,#e13131 100%);
	font-size: 10px !important;
}
#OrdersPage:not(.mobile) .table .tr button { width: auto; line-height: 25px; font-size: 12px; }
.ordersTable .tr button:disabled { background: #c9cace; cursor: default; box-shadow: none; }

.ordersTable .tr .td .col { padding-right: 0; }

.ordersTable .tr button.icon i { filter: brightness(0) invert(1); }

.ordersTable .tr .head span + button.button { float: right; margin-top: 4px; margin-right: 0; }
.ordersTable .tr .head .form-row { margin: 0; }

.ordersTable .tr .head h5 { color: #535663; line-height: 12px; font-size: 8px; }

.ordersTable .tr .products { width: 23%; }
.ordersTable .tr .products .ready { font-weight: 700; color: #54b6a9; }
.ordersTable .tr .head .products .col { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.ordersTable .tr .body .products .col { line-height: 17px; margin-bottom: 2px; }
.ordersTable .tr .products .toggle { color: #5e626d; border-bottom: 1px dotted #5e626d; }
.ordersTable .tr .products .price span { font-size: 10px; opacity: 0.7; }
.ordersTable .tr .products .mod > .col:first-child { padding-left: 20px; color: #888888; }
.ordersTable .tr .products .mod > .col:first-child::before { content: "+" ; position: absolute; left: 9px; }

.ordersTable .tr .amount { width: 5%; min-width: 75px; }
.ordersTable .tr .amount p span { font-size: 10px; opacity: 0.7; }

.ordersTable .tr .filial { width: 8%; white-space: nowrap; max-width: 150px; min-width: 150px; }
.ordersTable .tr .filial .reciving { float: left; }
.ordersTable .tr .filial .reference { float: right;}
.ordersTable .tr .filial .reference .tag { margin-right: 0; }
.ordersTable .tr .filial p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 15px;
	margin-bottom: 2px;
	max-width: 120px;
}
.ordersTable .tr .filial img { max-width: 90px; max-height: 30px; }

.ordersTable .tr:last-child .payment .form-row .select__menu-list { max-height : 80px; }
.ordersTable .tr .payment { min-width: 110px; }
.ordersTable .tr .payment button { float: none; margin-top: 5px; }

.ordersTable .tr .payment .tag { padding: 1px 4px; vertical-align: top; }
.ordersTable .tr .payment button.qr { margin-top: 0; padding: 0 2px; }
.ordersTable .tr .payment button.qr i { background: url(../images/icons.svg) -984px -128px; }

.ordersTable .tr .time.td { min-width: 310px; }

.ordersTable .tr .head .time .couriers { float: right; padding-top: 4px; text-align: right; }
.ordersTable .tr .head .time .couriers .icon { margin: 0 0 0 5px; }
.ordersTable .tr .head .time .couriers .icon.info {
	background: #f2f3f9;
	border-radius: 50%;
	display: inline-block;
	transform: scale(0.8);
}
.ordersTable .tr .head .time .couriers .icon.info i { 
	background-position: -984px -152px; 
	filter: brightness(0) invert(0.6);
}
.ordersTable .tr .head .time .couriers .icon.info:hover,
.ordersTable .tr .head .time .couriers .icon.info:active,
.ordersTable .tr .head .time .couriers .icon.info.active {
	background: #54B6A9;
}
.ordersTable .tr .head .time .couriers .icon.info:hover i,
.ordersTable .tr .head .time .couriers .icon.info:active i,
.ordersTable .tr .head .time .couriers .icon.info.active i {
	filter: brightness(0) invert(1);
}

.ordersTable .tr .head .time .couriers .form-row,
.ordersTable .tr .head .time .couriers .tag { display: inline-block; margin-right: 0; }
.ordersTable .tr .head .time .couriers .form-row { width: calc(100% - 35px); }
.ordersTable .tr .head .time .couriers .tag { max-width: calc(100% - 35px); text-overflow: ellipsis; }

.ordersTable .tr .customer { text-align: center; vertical-align: middle; width: 13%; min-width: 150px; max-width: 150px; }
.ordersTable .tr .customer .form-row {text-align: left; }

.ordersTable .tr:last-child .customer .form-row .select__menu-list { max-height : 120px; }
.ordersTable .tr .customer h4 {
	font-size: 13px;
	margin-bottom: 0; 
	text-transform: none; 
	white-space: nowrap;
	overflow: hidden;
	max-width: 150px;
}
.ordersTable .tr .customer h4 a { text-overflow: ellipsis; overflow: hidden; max-width: 100%; }
.ordersTable .tr .customer h5 { font-size: 11px; margin-bottom: 5px; }
.ordersTable .tr .customer .tag { margin: 0; }
.ordersTable .tr .customer i {
	background: url(../images/icons.svg) -576px -152px;
	display: inline-block;
	height: 24px;
	margin-right: 7px;
	width: 24px;
	vertical-align: middle;
}
.ordersTable .tr .customer a i { background-position: -624px -152px; }
.ordersTable .tr .customer a:hover i { background-position: -648px -152px; }
.ordersTable .tr .td.remove { vertical-align: middle; width: 40px; position: relative; }

.ordersTable .tr .td.remove .priority {
	display: block;
	height: 12px;
	margin: 0 -10px;
	padding: 3px;
	position: absolute;
	left: 50%;
	top: 50%;
	text-align: center;
}
.ordersTable .tr .td.remove .priority i {
	border-color: transparent;
	border-width: 8px 6px;
	border-style: solid;
	display: block;
	height: 0;
}
.ordersTable .tr .td.remove .priority.up { margin-top: -14px; }
.ordersTable .tr .td.remove .priority.up i { border-bottom-color: #535663; border-top: none; }
.ordersTable .tr .td.remove .priority.up:hover i { border-bottom-color: #54b6a9; }

.ordersTable .tr .td.remove .priority.down i { border-top-color: #535663; border-bottom: none; }
.ordersTable .tr .td.remove .priority.down:hover i { border-top-color: #54b6a9; }



.ordersTable .tr a.remove { background: transparent; padding: 0; }
.ordersTable .tr a.remove i { margin: 0; }

.ordersTable .orderActionsList {
	background : #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
	padding: 2px 5px 5px;
	position: absolute;
	top: 45px;
	z-index: 2;
}
.ordersTable .orderActionsList::after {
	border: 8px transparent solid;
	border-bottom-color: #FFFFFF;
	content: "";
	position: absolute;
	top: -16px;
	left: 14px;
}
.ordersTable .orderActionsList.phones { right: 0; }
.ordersTable .orderActionsList.phones::after { left: auto; right: 16px; }
.ordersTable .orderActionsList span {
	background : #f2f3f9;
	border-radius: 2px;
	color: #535663;
	display: block;
	line-height: 25px;
	margin-top: 3px; 
	padding: 0 10px;
	white-space: nowrap;
	min-width: 70px;
}
.ordersTable .orderActionsList.printFormats span { cursor: pointer; }
.ordersTable .orderActionsList.printFormats span:hover { color: #54b6a9; }

.ordersTable .orderActionsList.phones span {
	display: inline-block;
	line-height: 24px;
	width: auto;
	vertical-align: middle;
}
.ordersTable .orderActionsList.phones .icon { margin: 0; vertical-align: middle; }

@media (max-width: 1030px) {
	.ordersTable .tr .td { padding: 3px; }
	.ordersTable .tr select { font-size: 12px; }
	.ordersTable .tr .payment { min-width: 80px; }
	.ordersTable .tr .head .td { padding: 1px 5px 0; }
	.ordersTable .tr .head .tag { padding: 0 5px; }
	.ordersTable .tr .products { max-width: 240px; }
	.ordersTable .tr .filial .reference { margin: 0; }
	.ordersTable .tr .filial { max-width: 115px; min-width: 115px; }
	.ordersTable .tr .description { max-width: 230px; }
	.ordersTable .tr .customer { min-width: 120px; }
}

/* --- ORDERS PAGE – MOBILE ---*/
#OrdersPage.mobile .listpage { padding: 0; }

#OrdersPage.mobile .stat { font-size: 11px; margin: 0 0 10px; width: 100%; float: none; line-height: 25px; }

#OrdersPage.mobile .addFilter button {
	float: left;
	font-size: 14px;
	padding: 0 10px;
}
#OrdersPage.mobile .addFilter .time { margin: 0 40px 0 110px; }
#OrdersPage.mobile .addFilter .tags span { background: #FFFFFF; line-height: 35px; margin-right: 5px; font-size: 12px; }

#OrdersPage.mobile .addFilter .date {
	background: #FFFFFF;
	box-shadow: none;
	float: right;
	padding: 5px;
	height: 35px;
}
#OrdersPage.mobile .addFilter .date i { display: block; margin: 0; }
#OrdersPage.mobile .addFilter .dates {
	background: #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
	padding: 10px;
	position: absolute;
	left: 1%;
	width: 98%;
	z-index: 5;
}
#OrdersPage.mobile .addFilter .dates::before {
	content: "";
	border: 5px solid #FFFFFF;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	right: 10px;
}
#OrdersPage.mobile .addFilter .dates .buttonGroup span { padding: 0 10px;}
#OrdersPage.mobile .addFilter .dates .datePeriod { margin-top: 30px; }
#OrdersPage.mobile .addFilter .dates .datePeriod .button { float: right; margin-top: -83px; }

#OrdersPage.mobile .filter { background: #c1c3cc; border-radius: 5px; padding: 5px 40px 5px 10px; margin-bottom: 5px; }
#OrdersPage.mobile .filter .nomargin,
#OrdersPage.mobile .filter .addDesktop .col
{ width: 100%; float:none; }
#OrdersPage.mobile .filter .nomargin { padding: 0; margin: 5px 0; }

#OrdersPage.mobile .filter .col.payment { width: 100%; }

#OrdersPage.mobile .filter .tags span { margin: 0 1% 0 0; text-align: center; width: 49%; }
#OrdersPage.mobile .filter .datePeriod { box-shadow: none; position: relative; top: 0; }


#OrdersPage.mobile .table { display: block; }
#OrdersPage.mobile .tableBody .SortableList { display: block; }

#OrdersPage.mobile .table .tr { box-shadow: 0 1px 5px rgba(0,0,0,0.13); display: block; margin: 0 0 10px; }
#OrdersPage.mobile .table .tr .head { border-radius: 2px 2px 0 0; display: block;  height: auto; }
#OrdersPage.mobile .table .tr .body { display: block; background: #FFFFFF;}

#OrdersPage.mobile .table .tr .td { 
	display: block; 
	float: left; 
	padding: 3px 10px; 
	border-bottom: none;
}

#OrdersPage.mobile .table .tr .head .td { border: none; }

#OrdersPage.mobile .table .tr .head .products { width: 100%;  }
#OrdersPage.mobile .table .tr .head .orderNumber { width: 60%; vertical-align: middle; min-width: auto; }
#OrdersPage.mobile .table .tr .head .status { width: 40%; float: right; }
#OrdersPage.mobile .table .tr .head .time { min-width: auto; padding-top: 0; }

#OrdersPage.mobile .table .tr .head .option {
    float: right;
    margin-top: 10px;
	width: auto;
    padding: 0 15px;
}
#OrdersPage.mobile .table .tr .head .optionMenu {
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.5);
	border-radius: 4px;
	position: absolute;
	top: 45px;
	left: 5%;
	width: 90%;
	z-index: 2;
}
#OrdersPage.mobile .table .tr .head .optionMenu:before {
	content: "";
	border: 5px solid #ffffff;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	right: 18px;

}
#OrdersPage.mobile .table .tr .head .optionMenu .form-row { margin-top: 20px; }
#OrdersPage.mobile .table .tr .head .optionMenu .row { padding: 5px 15px; } 
#OrdersPage.mobile .table .tr .optionMenu button,
#OrdersPage.mobile .table .tr .optionMenu .button { float: left; line-height: 31px; }

#OrdersPage.mobile .table .tr .row { border-bottom: 1px solid #dddddd; }

#OrdersPage.mobile .table .tr .products { width: 65%; }
#OrdersPage.mobile .table .tr .amount { width: 35%; }
#OrdersPage.mobile .table .tr .amount .pay .tag { 
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 85%;
}
#OrdersPage.mobile .table .tr .filial { width: 35%; }
#OrdersPage.mobile .table .tr .customer { width: 100%; text-align: left; }
#OrdersPage.mobile .table .tr .customer > * { display: inline-block; vertical-align: middle; }
#OrdersPage.mobile .table .tr .customer h4 { height: 26px; max-width: 40%; }
#OrdersPage.mobile .table .tr .customer h5 { margin: 0 10px; line-height: 26px; }
#OrdersPage.mobile .table .tr .customer p { float: right; }

#OrdersPage.mobile .table .tr .payment { padding: 0; }
#OrdersPage.mobile .table .tr .payment button,
#OrdersPage.mobile .table .tr .payment p { width: 35%; margin: 20px 0 0 5%; line-height: 31px; float: left; }

#OrdersPage.mobile .table .buttons { padding: 5px 10px; }
#OrdersPage.mobile .table .buttons button { line-height: 40px; float: left; }
#OrdersPage.mobile .table .buttons .remove { height: 40px; text-align: center; }
#OrdersPage.mobile .table .buttons .remove i { transform: scale(1.5); margin-top: 3px; }

/* #OrdersPage .table .tr .head .history {
	overflow: visible;
	padding: 10px;
	position: absolute;
	width: 340px;
} */
/* #OrdersPage .table .tr .head .history::before {
	border: 5px solid transparent;
	border-bottom-color: #ffffff;
	content: "";
	position: absolute;
	top: -10px;
	left: 30px;
} */
/* #OrdersPage .table .tr .head .history p { vertical-align: middle; margin-bottom: 5px; font-size: 12px; }
#OrdersPage .table .tr .head .history i {
	display: inline-block;
	border-radius: 50%;
	height: 15px;
	margin-right: 10px;
	width: 15px;
	vertical-align: middle; 
}
#OrdersPage .table .tr .head .history .user { margin-top: 10px; }
#OrdersPage .table .tr .head .history .user p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .table .tr .head .history .user p:first-child { padding-left: 25px; } */


/* #OrdersPage .table .tr .head .td > span:not(.tag) { 
	line-height: 32px; 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
} */

/* #OrdersPage .table .tr .head .tag { white-space: nowrap; overflow: hidden; margin-bottom: 0; } */
/* #OrdersPage .table .tr button {
	background: #3b96e3;
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%);
	line-height: 30px;
	margin: 0;
	width: 90px;
}
#OrdersPage .table .tr button:disabled { background: #c9cace; cursor: default; box-shadow: none; }
#OrdersPage .table .tr .head span + button { margin-left: 10px; }
#OrdersPage .table .tr .head .form-row { margin: 0; }

#OrdersPage .table .tr .head h5 { color: #535663; line-height: 12px; font-size: 8px; } */

/* #OrdersPage.mobile .table .tr .products { width: 100%; } */
/* #OrdersPage .table .tr .products .ready { font-weight: 700; color: #54b6a9; } */
/* #OrdersPage .table .tr .products .col { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; } */
/* #OrdersPage .table .tr .products .toggle { color: #5e626d; border-bottom: 1px dotted #5e626d; } */

/* #OrdersPage .table .tr .filial { width: 8%; }
#OrdersPage .table .tr .filial p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .table .tr .filial img { max-width: 90px; max-height: 40px; }

#OrdersPage .table .tr:last-child .payment .form-row .select__menu-list { max-height : 80px; }
#OrdersPage .table .tr .payment { min-width: 110px; }
#OrdersPage .table .tr .payment button { float: none; margin-top: 5px; }

#OrdersPage .table .tr .time.td { min-width: 310px; }
#OrdersPage .table .tr .head .time .select { float: right; margin-top: 4px; }




*/


/* --- ORDERS PAGE - CUSTOMER --- */

#OrdersPage .customerItem  > .clearfix:first-of-type > h4 { padding-bottom: 10px; }

#OrdersPage .customerItem .mainInfo > .col:first-child {padding-right: 20px;}
#OrdersPage .customerItem .main .title,
#OrdersPage .customerItem .main h5 { font-size: 11px; color: #888888; text-transform: uppercase; }
#OrdersPage .customerItem .main p { line-height: 25px; }

#OrdersPage .customerItem .stat { background: #f5f6fb; border-radius: 5px; padding: 10px 10px 5px; }
#OrdersPage .customerItem .stat h4 { margin: 0; line-height: 20px; }
#OrdersPage .customerItem .stat .number span { border: none; padding: 0; }

#OrdersPage .customerItem .orderHistory .order .tr { display: table-row; }

#OrdersPage .window.qrcode { min-height: 500px; }
#OrdersPage .window.qrcode img { margin-top: 20px; max-width: 100%; }

/* --- ORDERS PAGE - CONTENT --- */
#OrdersPage .window.content h3, #OrdersPage .window.content h4 { text-transform: none; }
#OrdersPage .window.content h3 { font-size: 15px; line-height: 35px; }
#OrdersPage .window.content h4 { font-size: 14px; margin-bottom: 8px; position: relative; }

#OrdersPage .window.content .toggleContent:hover,
#OrdersPage .window.content .toggleContent:active,
#OrdersPage .window.content .open .toggleContent { color: #54b6a9; }
#OrdersPage .window.content .toggleContent i {
	background: url(../images/icons.svg) -240px -128px;
	position: absolute;
	left: -24px;
}
#OrdersPage .window.content .open > h4 > .toggleContent i { transform: rotate(-90deg); }

#OrdersPage .window.content .article { padding-left: 25px; }
#OrdersPage .window.content .body { padding: 15px 0; }
#OrdersPage .window.content ul { margin: 10px 30px; list-style: disc;}
#OrdersPage .window.content ol { margin: 10px 30px; list-style: decimal; }

/* --- ORDERS PAGE - BILL --- */
#OrdersPage .bill { padding: 0; }

/* #OrdersPage .bill .close { top: 10px; right: 10px; } */

#OrdersPage .bill .print { padding: 20px; }
#OrdersPage .bill .print .checkGroupLabel { width: 100%; }
#OrdersPage .bill .print .button { width: 100%; text-align: center; margin-top: 20px; }

#OrdersPage .bill .billTemplate { background: #f5f6fb; padding: 20px;}

/* --- ORDERS PAGE - PROCESSING --- */
#OrdersCookPage .head { vertical-align: middle; justify-content: start; gap: 30px; }
#OrdersCookPage .head h2 { vertical-align: middle; }

#OrdersCookPage .filter { display: inline-block; vertical-align: middle; margin-top: -15px; width: 50%; }
#OrdersCookPage .filter .col { padding-right: 20px; }

#OrdersCookPage .list { margin-top: 10px; overflow: auto; }
#OrdersCookPage .list .column { padding-right: 20px; }
#OrdersCookPage .list.tablet .column { padding-right: 10px; }

#OrdersCookPage .list .item {
	background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
	overflow: hidden;
    position: relative;
}
#OrdersCookPage .list .item h5 { text-transform: uppercase; font-size: 10px; }
#OrdersCookPage .list .item button { float: none; width: 100%; padding: 0; }
#OrdersCookPage .list .item .head .tag { margin: 0; }

#OrdersCookPage .list .item .head {
	background: #e8e9eb;
	border-radius: 4px 4px 0 0;
	color: #5e626d;
	margin: 0;
}
#OrdersCookPage .list .item .head.red { background: rgb(239, 139, 139); }
#OrdersCookPage .list .item .head.yellow { background: rgb(244, 233, 137); }
#OrdersCookPage .list .item .head.green { background: rgb(145, 249, 154); }
#OrdersCookPage .list .item .head.orange { background: rgb(242, 188, 139); }

#OrdersCookPage .list .item .head .orderInfo { padding: 5px 10px; }
#OrdersCookPage .list .item .head .orderInfo > div:first-child { margin-bottom: 3px; }
#OrdersCookPage .list .item .head .orderInfo .orderNumber,
#OrdersCookPage .list .item .head .orderInfo .orderType { display: inline-block; width: 45px; line-height: 24px; }
#OrdersCookPage .list .item .head .orderInfo .orderType i { background: url(../images/icons.svg); }
#OrdersCookPage .list .item .head .orderInfo .orderType.restaurant i { background-position: -864px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.pickup i { background-position: -888px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.delivery i { background-position: -912px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.home i { background-position: -24px -200px; filter: brightness(0) invert(0.45);}

#OrdersCookPage .list .item .head .time {
	font-size: 22px;
	line-height: 63px;
	text-align: right;
	padding: 0 10px;
}

#OrdersCookPage .list .item .head .reference { border-left: 3px solid #f2f3f9; padding: 9px 10px; }
#OrdersCookPage .list.tablet .item .head .reference { padding: 9px 5px; }

#OrdersCookPage .list .item .decription { padding: 5px 10px; } 
#OrdersCookPage .list .item .decription > .col { min-height: 1px; }
#OrdersCookPage .list .item .decription img { max-width: 100%; max-height: 30px; }
#OrdersCookPage .list .item .decription .brand { float: right; }

#OrdersCookPage .list .item .body { border-left: 10px solid #d5d9f2; }

#OrdersCookPage .list .item .products { display: table; width: 100%; }
#OrdersCookPage .list .item .product { display: table-row-group; }
#OrdersCookPage .list .item .product .row { display: table-row; }
#OrdersCookPage .list .item .product .td { 
	display: table-cell; 
	border-bottom: 1px solid #d9dade;
	font-size: 14px;
	line-height: 30px;
	vertical-align: top;
}
#OrdersCookPage .list .item .product .td.count { text-align: center; width: 35px; }
#OrdersCookPage .list .item .product .td.name { padding: 0 5px; }
#OrdersCookPage .list .item .product .td.name .mods { color: #888888; }
#OrdersCookPage .list .item .product .td.workshops {
	text-align: center; width: 35px; vertical-align: text-top;
}
#OrdersCookPage .list .item .product .td.workshops i {
	background: url(../images/icons.svg);
	display: inline-block;
	height: 24px;
	vertical-align: text-top;
	width: 24px;
}
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_1 { background-position: -144px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_2 { background-position: -168px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_3 { background-position: -192px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_4 { background-position: -216px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_5 { background-position: -240px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_6 { background-position: -264px -176px; }

#OrdersCookPage .list .item .product .td.buttons { width: 80px; text-align: center; }
#OrdersCookPage .list .item .product .td.cancel { width: 30px; }
#OrdersCookPage .list .item .product .td.cancel i { margin: 0; }

#OrdersCookPage .list .item > button { border-radius: 0 0 5px 5px; }

#OrdersCookPage .list .item .product.inProcess { font-weight: bold; }
#OrdersCookPage .list .item .product.ready { font-weight: bold; color: #54B6A9; }

#OrdersCookPage .list .item.active .product .td { border: 3px solid #FFFFFF; line-height: 35px; }
#OrdersCookPage .list .item.active .product .td.count { 
	background: #3A95E2; 
	border-radius: 5px 0 0 5px;
	color: #FFFFFF; 
	font-size: 24px;
}
#OrdersCookPage .list .item.active .product .td.count.more { background: #1962A0; }
#OrdersCookPage .list .item.active .product.ready .td.count { background: #54B6A9;}
#OrdersCookPage .list .item.active .product .td.name { 
	background-color: #f5f6fb; 
	color: #454A5A;
	font-weight: bold;
	line-height: 21px;
	padding: 7px 5px;
}

#OrdersCookPage .list .item.active .product .td.name a { display: block; color: #454A5A; }
#OrdersCookPage .list .item.active .product .td.workshops { background-color: #f5f6fb; }

#OrdersCookPage .list .item.active .product .mods .td { border-top-width: 0; }
#OrdersCookPage .list .item.active .product .mods .count { background: none; }
#OrdersCookPage .list .item.active .product .mods .td.name { padding-top: 0; border-right-color: #f5f6fb; }
#OrdersCookPage .list .item.active .product .mods .td.name p { color: #757575; }
#OrdersCookPage .list .item.active .product .mods .td.workshops { padding-top: 21px; border-left-color: #f5f6fb; }
#OrdersCookPage .list .item.active .product .mods .td.workshops p {
	color: #757575;
	line-height: 21px;
	font-weight: bold;
}

#OrdersCookPage .window.large { position: absolute;}

/* --- CHANGE FROM BLOCK --- */
.changeBlock h4 { margin: 0; }
.changeBlock .main p { line-height: 30px; }
.changeBlock .calc .col { padding-right: 10px; }
.changeBlock .calc .col.preset { padding: 0; }
.changeBlock .calc a {
	background: #f5f6fb;
	border-radius: 4px;
	color: #535663;
	display: block;
	line-height: 28px;
	text-align: center;
	margin: 2px 0;
}
.changeBlock .calc .col.preset a { background: #54b6a9; color: #ffffff; }
.changeBlock .calc a.clear { background: #e83075; color: #ffffff; }

/* --- NEW ORDER PAGE --- */
#OrderItem .section .order { display: table; padding: 0; }
#OrderItem .section .order > div { display: table-row; }
#OrderItem .section .order > div > .col { display: table-cell; float: none; background: #f8f9fb; padding: 0; }
#OrderItem .section .order > div > .col.active { background: #FFFFFF; }

#OrderItem h5 { text-transform: uppercase; font-size: 11px; white-space: nowrap; }
#OrderItem .comment { margin-top: 0; }

#OrderItem .order .tab { color: #5e626d; line-height: 35px; text-align: center; }
#OrderItem .order .tab.active { background: #54b6a9; color: #FFFFFF; }

#OrderItem .order .tabBlock { border-top: 1px solid #e8e9eb; padding: 15px 20px 0; }
#OrderItem .order .active .tabBlock { border-top-color: #FFFFFF; }

@media (max-width: 1280px) {
	#OrderItem .order .tabBlock { padding: 10px 15px 0; }
}

#OrderItem .orderBlock { padding-left: 15px; border-right: 1px solid #dddddd; padding-right: 0; }
#OrderItem .orderBlock:first-of-type { padding: 0 20px 0 0; position: relative; }
#OrderItem .orderBlock:last-of-type { border: none; }

#OrderItem .orderBlock .brands .checkGroupLabel { width: 50%; }
#OrderItem .orderBlock .toggleBrands { font-size: 11px; line-height: 17px; position: absolute; top: -20px; right: 10px; }

#OrderItem .customerDescription textarea { height: 210px; }
#OrderItem .customerDescription p { margin: 5px 0; }
#OrderItem .customerDescription p i.icon {
	background: url(../images/icons.svg) -624px -152px;
	display: inline-block;
	height: 24px;
	width: 24px;
	margin-right: 5px;
	position: static;
	vertical-align: middle;
}
#OrderItem .customerDescription p i.icon.birth { background-position: -960px -128px; }
#OrderItem .customerDescription p i.icon.bl { background-position: -672px -152px; }

#OrderItem .orderBlock .customerInfo { position: absolute; top: 22px; right: -4px; }
#OrderItem .orderBlock .customerInfo i {
	background: url(../images/icons.svg) -624px -152px;
	display: block;
	height: 24px;
	width: 24px;
}
#OrderItem .orderBlock .customer .icon { position: absolute; top: 22px; }
#OrderItem .orderBlock .icon i {
	background: url(../images/icons.svg) -624px -152px;
	display: block;
	height: 24px;
	width: 24px;
}
#OrderItem .orderBlock .customerInfo { right: -8px; }
#OrderItem .orderBlock .customerInfo i { background-position: -624px -152px; }
#OrderItem .orderBlock .customerInfo:hover i { background-position: -648px -152px; }

#OrderItem .orderBlock .changeCustomerIcon { right: 18px; }
#OrderItem .orderBlock .changeCustomerIcon i {
	background: url(../images/icons.svg);
	background-position: -1010px -127px;
}
#OrderItem .orderBlock .changeCustomerIcon:hover i { background-position: -1010px -151px; }

#OrderItem .orderBlock .customerStat { right: 30px;}
#OrderItem .orderBlock .customerStat i {
	background-color: #757575;
	background-position: -960px -152px;
	border-radius: 50%;
}
#OrderItem .orderBlock .customerStat.active i { background-color: #54b6a9; }

#OrderItem .orderBlock .addressBlock {
	background: #f5f6fb;
    margin: 10px -20px 30px;
    padding: 10px 20px 5px;
}
#OrderItem .orderBlock .location { margin-bottom: 30px; }

#OrderItem .orderBlock .location .orderType span { padding-left: 10px; padding-right: 10px; }
#OrderItem .orderBlock .location .orderType span.home i { background-position: -24px -200px; }
#OrderItem .orderBlock .location .orderType span.home.active i { filter: brightness(0) invert(1); }

#OrderItem .address { margin: 20px 0 10px; }
#OrderItem .address .col { padding-right: 10px;}
#OrderItem .address .dadata { padding-right: 20px; }
#OrderItem .address .comment { margin: 10px 0 5px; }
#OrderItem .address .toggle { display: block; position: absolute; top: 2px; right: -8px; }
#OrderItem .address .toggle i {
	background: url(../images/icons.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	width: 24px;
}
#OrderItem .address .toggle.up i { background-position: -216px -128px; }
#OrderItem .address .toggle.down i { background-position: -240px -128px; }

#OrderItem .orderBlock .toDate { padding-left: 30px; }
#OrderItem .orderBlock .toDate i.date { left: -30px; }
#OrderItem .orderBlock .toDate label { left: 43px; }

#OrderItem .orderBlock .aggregators { margin-bottom: 10px; }
#OrderItem .orderBlock .aggregators .checkGroupLabel { width: 50%; }
#OrderItem .orderBlock .aggregators .toggleBrands { top: -20px; right: 0; }

#OrderItem .orderBlock .homeorder { margin: 10px 0 30px; }

#OrderItem .order .products .col { padding-right: 10px; }
#OrderItem .order .products .item { border-top: 1px solid #dddddd; padding: 5px 0; }
#OrderItem .order .products .item .form-row { margin: 0; }
#OrderItem .order .products .item .col { padding-right: 10px; }
#OrderItem .order .products .item > span { line-height: 22px; min-height: 32px; padding: 4px 0; }
#OrderItem .order .products .item .started { color : #54b6a9; font-weight: 700; }
#OrderItem .order .products .item .alert { color : #ad0f49; font-weight: 700; }
#OrderItem .order .products .item .mod { color: #888888; padding-left: 20px; }
#OrderItem .order .products .item .mod::before { content: "+"; position: absolute; left: 6px; }

#OrderItem .order .products .item .mods {
	color: #888888;
	display: block;
	font-size: 11px;
	line-height: 12px;
	margin-top: 5px;
}
#OrderItem .order .products .item .mods i {
	background: #888888;
	border-radius: 2px;
	color: #FFFFFF;
	display: inline-block;
	font-weight: bold;
	font-style: normal;
	height: 12px;
	margin-right: 5px;
	text-align: center;
	width: 12px;
}
#OrderItem .order .products .item .mods:hover { color: #54B6A9; }
#OrderItem .order .products .item .mods:hover i { background: #54B6A9; }

#OrderItem .order .total { padding-right: 0; }
#OrderItem .order .total h5 { line-height: 32px; text-align: right; }
#OrderItem .order .total span { line-height: 32px; padding-left: 13px; padding-right: 0; }
#OrderItem .order .total .form-row { margin: 0; padding-right: 0; }

#OrderItem .order .payment .paymentMethod .checkGroupLabel { margin: 5px 0; }

#OrderItem .menu { border-left: 1px solid #e8e9eb; overflow: auto; padding: 0; }

#OrderItem .section .menu .table { display: table; height: 100%; width: 100%; }
#OrderItem .section .menu .table .row { display: table-row; height: 100%; }
#OrderItem .section .menu .table .row > .col { display: table-cell; float: none; padding: 0;}

#OrderItem .menu .searchBlock.clearfix { padding: 5px; }
/* #OrderItem .menu .searchBlock .form-row { margin-top: 10px; } */
#OrderItem .menu .searchBlock .searchInput .form-input { padding-left: 35px; }

#OrderItem .menu .searchBlock .search {
	background: url(../images/icons.svg) no-repeat -96px -152px;
	top: 4px;
	left: 5px;
}
#OrderItem .menu .searchBlock .icon i {
    background: url(../images/icons.svg) no-repeat -360px -128px;
    display: block;
    height: 24px;
    width: 24px;
}
#OrderItem .menu .searchBlock .toggleFilter { left: auto; right: 20px; background: none; }
#OrderItem .menu .searchBlock .toggleFilter i { background-position: -360px -128px; }
#OrderItem .menu .searchBlock .toggleFilter.active i { background-position: -385px -128px; }

#OrderItem .menu .searchBlock .toggleProducts { position: absolute; right: 0; top: 5px; }
#OrderItem .menu .searchBlock .toggleProducts i { 
	background-position: -216px -152px; 
	filter: brightness(0) invert(0.4);
}
#OrderItem .menu .searchBlock .toggleProducts { background: none; }
#OrderItem .menu .searchBlock .toggleProducts.active i { filter: none; }

#OrderItem .menu .item {
	background: #f8f9fb;
	color: #5e626d;
	padding: 0 10px;
	position: relative;
	z-index: 0;
}
#OrderItem .menu .item.active { background: #54b6a9 !important; color: #FFFFFF; }
#OrderItem .menu .item.disabled { cursor: default; color: #9a9a9a; }
#OrderItem .menu .item.disabled.active { color: #dddddd; }

#OrderItem .menu .sections { background: #f8f9fb; vertical-align: top; width: 130px; }
#OrderItem .menu .sections .item {
	border-top: 1px solid #e9eaee;
	box-sizing: border-box;
	display: block;
	line-height: 22px;
	padding: 8px 10px;
}

#OrderItem .menu .sections .item:first-of-type { border-top: none; }
#OrderItem .menu .sections .item.active { border: none; }


#OrderItem .menu .products { padding: 5px; }
#OrderItem .menu .products .item { margin: 5px; padding: 0; border-radius: 5px; width: 110px; height: 72px; border: 0; }
#OrderItem .menu .products .item a { 
	border-radius: 5px; 
	box-sizing: border-box;
	color: #5e626d;
	display: block; 
	padding: 3px 10px 5px; 
	width: 100%;
	height: 100%;
}
#OrderItem .menu .products .item a.active { background: #54b6a9 !important; color: #FFFFFF; }
#OrderItem .menu .products .item a.disabled { cursor: default; color: #9a9a9a; }
#OrderItem .menu .products .item a.disabled.active { color: #dddddd; }

#OrderItem .menu .products .item span { display: block; text-align: center; overflow: hidden; text-overflow: ellipsis; }
#OrderItem .menu .products .item .name { line-height: 17px; font-size: 12px; height: 34px; }
#OrderItem .menu .products .item .price,
#OrderItem .menu .products .item .title { font-weight: bold; line-height: 24px; }

#OrderItem .menu .products .productList { min-height: 613px; padding-bottom: 0; }

/* ----- MODIFICATORS BLOCK ----- */
#OrderItem .window.modificators {
	padding: 5px;
	min-width: 250px;
	max-width: 350px;
	max-height: 400px;
	overflow: auto;
}
#OrderItem .window.modificators::before {
	border: 5px solid transparent;
	border-bottom-color: #FFFFFF;
	content: "";
	position: absolute;
	top: -10px;
	left: 10px;
}
#OrderItem .window.modificators .item {
	background: #F5F6FB;
	border-radius: 2px;
	color: #5E626D;
	display: block;
	margin-top: 5px;
	padding: 2px 10px;
}
#OrderItem .window.modificators .item:first-child { margin: 0; }
#OrderItem .window.modificators .item:hover { color: #54b6a9; }
#OrderItem .window.modificators .item .price { text-align: right;}

/** ----- NEW ORDER MOBILE ----- */
.mobile #OrderItem .head {
	position: fixed;
	padding: 10px 5px;
	top: 50px;
	width: 100%;
}
.mobile #OrderItem .section { margin-top: 77px; }

.mobile #OrderItem .order .tabBlock > h4 { padding: 5px 10px; }
.mobile #OrderItem .orderBlock { padding: 0 10px;}

.mobile #OrderItem .customer .customerDescription {
	background: #f5f6fb;
	margin: 10px -10px;
    padding: 5px 10px 10px;
}

.mobile #OrderItem .menu .search .toggleFilter { right: 35px; }

.mobile #OrderItem .section .menu .table,
.mobile #OrderItem .section .menu .table .row { display: block; }

.mobile #OrderItem .menu .products { border-bottom: 1px solid #dddddd; margin-top: 10px; }
.mobile #OrderItem .menu .products .productList { height: 270px; min-height: auto; overflow: auto; width: 100%; }

.mobile #OrderItem .menu .products .productContainer { display: flex; flex-wrap: wrap; flex-direction: column; height: 250px; }
.mobile #OrderItem .menu .products .item { width: 147px; height: 72px; }

.mobile #OrderItem .clearfix .titles { padding-left: 10px; padding-top: 10px; }
.mobile #OrderItem h5 { padding-left: 10px; }

.mobile #OrderItem .clearfix.form-row.col.col50 { padding-left: 10px; }
.mobile #OrderItem .clearfix.form-row.col.col50>label { padding-left: 10px; }

.mobile #OrderItem .clearfix.form-row.paymentMethod { padding-left: 10px; }
.mobile #OrderItem .clearfix.form-row.paymentMethod>label { padding-left: 10px; }

.mobile #OrderItem .clearfix .block p { padding-left: 10px; }

.mobile #OrderItem .order .products .item > span:nth-child(1) { line-height: 22px; min-height: 32px; padding: 4px 10px; }
.mobile #OrderItem .order div.clearfix.block.products { background-color: #F5F6FB; padding-bottom: 10px; }

/* OrderMobileTabs */

.orderMobileTabs {
	position: fixed;
	top: 100px;
	left: 0px;
	z-index: 9999;
	width: 100vw;
	display: flex;
	justify-content: center;
}

.orderMobileTabs a {
	width: 49%;
	height: 32px;
	border-radius: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #5E626D;
	background-color: white;
}

.orderMobileTabs a:nth-child(1) {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.orderMobileTabs a:nth-child(2) {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.activeLink {
	color: white !important;
	background-color: #54B6A9 !important;
}

/** ------ CONFIRM BLOCK ------ */
#OrdersPage .confirmBlock .buttons { float: none; }
#OrdersPage .confirmBlock button { width: 140px; float: none; }
#OrdersPage .confirmBlock .checkGroupLabel { width: 100%; max-width: none; }

/* ----- PAYMENT BLOCK ----- */
#OrdersPage .confirmBlock.paymentconfirm .close { right: 0; top: 0; }

#OrdersPage .confirmBlock.paymentconfirm .button { width: auto; }
#OrdersPage .confirmBlock.paymentconfirm .button.icon i { background: url(../images/icons.svg); filter: brightness(0) invert(1); }
#OrdersPage .confirmBlock.paymentconfirm .button.icon_1 i { background-position: 0 -176px; }
#OrdersPage .confirmBlock.paymentconfirm .button.icon_2 i { background-position: -24px -176px; }
#OrdersPage .confirmBlock.paymentconfirm .buttons .right { float: right; }

#OrdersPage .confirmBlock.paymentconfirm .buttons .separated {
	border-top: 1px solid #dddddd;
    margin-top: 10px;
	padding-top: 30px;
}

#OrdersPage .confirmBlock.paymentconfirm .loading {
	background: rgba(255, 255, 255, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 5;
}
#OrdersPage .confirmBlock.paymentconfirm .loading img { margin: -10px -80px; position: absolute; top: 50%; left: 50%; }

#OrdersPage.mobile .confirmBlock { margin-left: 0; width: 98%; left: 1%; top: 190px; }

/* ----- ORDER HISTORY BLOCK ----- */
#OrdersPage .window.history {
	overflow: visible;
	padding: 10px;
	position: absolute;
	width: 340px;
}
#OrdersPage .window.history::before {
	border: 5px solid transparent;
	border-bottom-color: #ffffff;
	content: "";
	position: absolute;
	top: -10px;
	left: 20px;
}
#OrdersPage .window.history p {
	font-size: 12px; 
	margin-bottom: 5px; 
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle; 
	white-space: nowrap;
}
#OrdersPage .window.history i {
	display: inline-block;
	border-radius: 50%;
	height: 15px;
	margin-right: 10px;
	width: 15px;
	vertical-align: middle; 
}
#OrdersPage .window.history .user { margin-top: 10px; }
#OrdersPage .window.history .user p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .window.history .user p:first-child { padding-left: 25px; }

/* ----- PAYMENT BLOCK ----- */

/** ------- DELIVERY PAGE ------- */
#DeliveryOrdersPage .head h2 { float: left; }
#DeliveryOrdersPage .head form { float: left; }
#DeliveryOrdersPage.mobile .head form { float: right; }

#DeliveryOrdersPage .deliveryList .item {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}
#DeliveryOrdersPage .item .head {
	border-bottom: 1px solid #d5d9f2;
	font-size: 15px;
	margin: 0;
	padding: 7px 15px;
}
#DeliveryOrdersPage .item .head .amount { text-align: right; }
#DeliveryOrdersPage .item .head .tag { margin: 22px 5px 0 0; font-size: 12px;}
#DeliveryOrdersPage .item .head .times {float: right; }
#DeliveryOrdersPage .item .head .times .tag { margin-top: 0;}

#DeliveryOrdersPage .item .head .times .tag i {
	border-radius: 50%;
	display: inline-block;
	height: 16px;
	margin-right: 5px;
	vertical-align: middle;
	width: 16px;
}
#DeliveryOrdersPage .item .head .times .tag i.red { background: rgb(239, 139, 139); }
#DeliveryOrdersPage .item .head .times .tag i.yellow { background: rgb(244, 233, 137); }
#DeliveryOrdersPage .item .head .times .tag i.green { background: rgb(145, 249, 154); }
#DeliveryOrdersPage .item .head .times .tag i.orange { background: rgb(242, 188, 139); }

#DeliveryOrdersPage .item .body { padding: 5px 15px 5px; }
#DeliveryOrdersPage .item .body p { margin-top: 7px; }

#DeliveryOrdersPage .item .body .filial { color: #86878c; }
#DeliveryOrdersPage .item .body .address { font-size: 15px; line-height: 24px; }

#DeliveryOrdersPage.mobile .item .block { margin-top: 10px; }
#DeliveryOrdersPage .item .block:last-child { padding-bottom: 0;}

#DeliveryOrdersPage .address { position: relative; width: 85% }
#DeliveryOrdersPage .geo {
	background: none;
	box-shadow: none;
	height: 24px;
	line-height: 24px;
	padding: 0;
	position: absolute;
	top: 0;
	right: -40px;
}

#DeliveryItem .head .phone { text-align: center; }
#DeliveryItem .head .close { float: right; text-align: center; }

#DeliveryItem .order {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}
#DeliveryItem .order .products {
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
	padding: 10px 15px;
}
#DeliveryItem .order .products .product { border-top: 1px solid #dddddd; }
#DeliveryItem .order .products .product:first-of-type { border-top: none; }

#DeliveryItem .paymentInfo .tag { margin-top: 5px; }


#DeliveryItem .buttons .button { text-align: center; }
#DeliveryItem .buttons .button.main { line-height: 50px; }

#DeliveryItem .button.icon:not(.geo) i { filter: brightness(0) invert(1); }
#DeliveryItem .button.icon.qr i { margin: 0; }

#DeliveryItem .buttons .button.pay.icon i { background: url(../images/icons.svg); }
#DeliveryItem .buttons .button.pay.icon_1 i { background-position: 0 -176px; }
#DeliveryItem .buttons .button.pay.icon_2 i { background-position: -24px -176px; }

#DeliveryItem .buttons .adds {
	border-top: 1px solid #c1c3cc;
	border-bottom: 1px solid #dddddd;
	margin: 25px 0 0;
	padding: 20px 0 10px;
}
#DeliveryItem .buttons .adds p { line-height: 35px; margin-left: 3%; font-size: 12px; }
#DeliveryItem .buttons .payment { font-weight: bold; padding: 0 0 10px; }

#DeliveryItem .buttons .button.gray { float: right; }

#DeliveryItem .loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba(255,255,255,0.5);
}
#DeliveryItem .loading img { position: absolute; top: 50%; left: 50%; margin: -10px -80px; }

#DeliveryItem .confirmBlock.calc p { margin-bottom: 0; }

#DeliveryOrdersPage.mobile .confirmBlock.calc {
	width: 98%;
	left: 1%;
	margin: -180px 0 0;
	min-width: auto;
}

#DeliveryOrdersPage.mobile .changeBlock .calc a { line-height: 45px; }

#DeliveryItem .window.qrcode { min-height: 500px; }
#DeliveryItem .window.qrcode img { margin-top: 20px; max-width: 100%; }

#DeliveryOrdersPage.mobile .window.qrcode {
	width: 90%;
	left: 5%;
	margin: 0;
	top: 60px;
	min-height: 450px;
}


/*-----------ChangeCustomerBlock--------------*/
#OrdersPage .confirmBlock.changeCustomer .button.gray { float: right; }