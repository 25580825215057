/**
 * Inputs and Validation
 */

:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--text: #5e626d;
	--green10: #54b6a91A;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
}

input, select, textarea {
	background: transparent;
	/* border: 1px solid #DDDDDD; */
	border: 1px solid var(--border-gray);
	border-radius: 5px;
	box-sizing: border-box;
	color: var(--text);
	font: 14px/28px "Roboto_Light", Arial, Helvetica, sans-serif;
	padding: 0 13px;
	vertical-align: middle;
	transition: all 0.2s ease;
}



.dark input {
	background: rgba(215,225,255,0.1); color: #FFFFFF; border-color: transparent;
}
.dark .selectize .select__control, 
.dark .selectize .select__control:hover, 
.dark .selectize .select__control--is-focused {
	background: rgba(215,225,255,0.1); color: #FFFFFF; border-color: transparent;
}
.dark .selectize.single .select__value-container > div {
	color: #FFFFFF;
}

input:not([type="radio"]), input:not([type="checkbox"]) { width: 100%; }
textarea {
	font: 13px/18px "Roboto_Light", Arial, Helvetica, sans-serif;
	padding: 5px 13px;
}

input:focus, select:focus, textarea:focus { outline: none; }

input:disabled, select:disabled, textarea:disabled { opacity: 0.5; background-color: #EEEEEE; }
input:read-only, select:read-only, textarea:read-only { opacity: 0.5; background-color: #EEEEEE; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] { -moz-appearance: textfield; }

textarea {
	overflow-y: auto;
	resize: none;
	width: 100%;
}

@media (max-width: 980px) {
	input, select, textarea { 
		line-height: 40px;
	}
}

form .form-row, .form .form-row { margin-top: 20px; }
form .form-row.hidden { margin: 0; }

form .form-row.hidden .form-input { min-height: auto; }

form .buttons { margin-top: 20px; float: right; }

form .buttons button { width: 100%; }

form .period .form-row { float: left; margin: 0 1% 0 0; width: 49%; }

form .period .checkGroupLabel { margin-right: 5px; width: auto !important; }

form .form-row.invalid label { color: #db004f; }

form .form-row.invalid .form-input input { border-color: #db004f; }

form .form-input input { width: 100%; display: block; }

form .form-input input[type="radio"], form .form-input input[type="checkbox"] {
	display: inline-block;
	margin: 0 7px 0 1px;
	width: auto;
}

form .form-input select, 
.form .form-input select { 
	background: #FFFFFF; 
	height: 30px; 
	opacity: 1; 
	width: 100%; 
	font-size: 13px; 
	padding-left: 7px;
}
form .form-input select:disabled,
.form .form-input select:disabled { opacity: 0.5;  }

form .form-input input.upper { text-transform: uppercase; }

form label, .form label {
	color: #9699a7;
	display: inline-block;
	line-height: 28px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: absolute;
	left: 13px;
	font-size: 14px;
	transition: all 0.2s ease;
	max-width: 80%;
	z-index: 0;
}



form label.focused, .form label.focused {
	font-size: 11px;
	margin-left: -10px;
	transform: translateY(-24px);
	max-width: 95%;
}

@media (max-width: 980px) {
	form label, .form label { 
		line-height: 40px;
	}
	form label.focused, .form label.focused {
		transform: translateY(-30px);
	}
}

form label.checkGroupLabel {
	display: inline-block;
	vertical-align: middle;
	color: #5e626d;
	font-size: 12px;
	position: relative;
	left: 0;
	width: 49%;
}

form label.checkGroupLabel span {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	max-width: 78%;
}

form label.checkGroupLabel input:disabled+span { color: #9fa6af; }

form label.checkGroupLabel .help {
	border: 1px solid #d2d4d7;
	border-radius: 50%;
	color: #888;
	font-size: 13px;
	font-style: normal;
	font-weight: 900;
	height: 20px;
	line-height: 20px;
	text-align: center;
	top: 5px;
	right: 0;
	width: 20px;
	z-index: 1;
}

form .form-input { display: block; min-height: 32px; position: relative; }

form .form-input i, .form .form-input i {
	display: block;
	height: 24px;
	position: absolute;
	top: 2px;
	right: 5px;
	width: 24px;
}

form .form-input i.date, .form .form-input i.date {
	background: url(../images/icons.svg) -288px -128px;
	z-index: 0;
}
/* 
form .form-input i.login {
	background: url(../images/icons.svg) 0 0;
}

form .form-input i.pass {
	background: url(../images/icons.svg) -40px 0;
} */

form .selectize, .form .selectize { white-space: nowrap; }

form .selectize .select__control, form .selectize .select__control:hover, form .selectize .select__control--is-focused, .form .selectize .select__control, .form .selectize .select__control:hover, .form .selectize .select__control--is-focused {
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	box-shadow: none;
	min-height: 32px;
}

form .selectize .select__value-container, .form .selectize .select__value-container { padding: 0 8px; }
form .selectize .select__indicators .select__indicator, .form .selectize .select__indicators .select__indicator { padding: 5px 6px; }
form .selectize.single .select__control, .form .selectize.single .select__control { height: 30px; }
form .selectize.single .select__value-container, .form .selectize.single .select__value-container { height: 30px; }

@media (max-width: 980px) {
	form .selectize.single .select__control, .form .selectize.single .select__control { height: 40px; }
	form .selectize.single .select__value-container, .form .selectize.single .select__value-container { height: 40px; }
}

form .selectize .select__menu, 
.form .selectize .select__menu {
	top: 100%;
	background-color: hsl(0, 0%, 100%);
	border-radius: 4px;
	box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
	margin-bottom: 8px;
	margin-top: 0px;
	position: absolute;
	/* width: 110%; */
	min-width: 100%;
	max-width: 170%;
	width: auto;
	z-index: 2;
	box-sizing: border-box;
	font-size: 12px;
}

form .selectize .select__menu-list, .form .selectize .select__menu-list {
	max-height: 300px;
	overflow-y: auto;
	padding-bottom: 4px;
	padding-top: 4px;
	position: relative;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
}
@media (max-width: 980px) {
	form .selectize .select__menu-list, .form .selectize .select__menu-list { max-height: 180px;}
}

form .selectize .select__option, .form .selectize .select__option {
	background-color: transparent;
	color: inherit;
	cursor: default;
	display: block;
	font-size: inherit;
	padding: 0 55px 0 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
	position: relative;
}

form .selectize .select__option:active, form .selectize .select__option:hover, .form .selectize .select__option:active, .form .selectize .select__option:hover {
	background-color: #B2D4FF;
}

form .selectize .select__option span, .form .selectize .select__option span {
	width: 100%;
	display: inline-block;
	line-height: 32px;
}

form .selectize .select__option a {
	display: none;
	color: #db004f;
	font-size: 12px;
	position: absolute;
	top: 8px;
	right: 5px;
}

form .selectize .select__option:hover a { display: inline-block; }

form .selectize input { border: none; border-radius: 0; padding: 0; }

form .selectize .icon.status {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	top: 6px;
}


/* form .form-input.periodTime span { display: inline-block; margin: 0 10px; vertical-align: middle; } */

/* form .form-input.periodTime span:first-child { margin-left: 0; } */
form .form-input.periodTime input { display: inline-block; width: 60px; padding: 0 10px; }

form .form-input.periodTime .type { display: flex; justify-content: left; margin-bottom: 5px; gap: 10px; }
form .form-input.periodTime .type a { font-size: 10px; line-height: 17px; color: #5e626d; }
form .form-input.periodTime .type a.active { color:#54b6a9; cursor: default; }
form .form-input.periodTime .type span {
	background: #b6b7c0;
	border: 2px solid #b6b7c0;
	border-radius:11px;
	display: flex;
	width: 30px;
	justify-content: left;
}
form .form-input.periodTime .type span.exact { justify-content: right ;}
form .form-input.periodTime .type span b {
	display: inline-flex;
	width: 14px;
	height: 14px;
	background: #FFFFFF;
	border-radius: 50%;
}

form .form-input.periodTime .set {
	display: flex;
	align-items: center;
	gap: 8px;
}

/* --- DatePicker --- */
.page .react-datepicker {
	border: none;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	font-family: "Roboto", Arial, sans-serif;
}

.page .react-datepicker.withTime { width: 282px; }

.page .react-datepicker-popper { z-index: 3; }

.page .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .page .react-datepicker__year-read-view--down-arrow::before, .page .react-datepicker__month-read-view--down-arrow::before, .page .react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: transparent;
}

.page .react-datepicker-wrapper, .react-datepicker__input-container { width: 100%; }

.page .react-datepicker-wrapper input {
	background: #FFF;
	position: relative;
	z-index: 1;
}

.page .react-datepicker-wrapper input:disabled { opacity: 0.5; background-color: #EEEEEE; }

.page .react-datepicker__navigation {
	background: none;
	width: 10px;
	box-shadow: none;
	border-radius: 0;
}

.page .react-datepicker__header { background: #f5f6fb; border: none; }

.page .react-datepicker__current-month, 
.page .react-datepicker-time__header { font-weight: normal; text-transform: uppercase; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { height: 20px !important; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled { display: none; }
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) { left: 175px; }

.page .react-datepicker__close-icon {
	background: transparent url(../images/icons.svg) -168px -128px;
	box-shadow: none;
	height: 24px;
	opacity: 0.4;
	top: 4px;
	width: 24px;
	z-index: 1;
}

.page .react-datepicker__close-icon:hover { opacity: 0.6; }
.page .react-datepicker__close-icon:after { display: none; }
.page input:disabled+.react-datepicker__close-icon { display: none; }

.page .react-datepicker__year-dropdown, 
.page .react-datepicker__month-dropdown, 
.page .react-datepicker__month-year-dropdown {
	background: #f5f6fb;
	border: none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.page .react-datepicker__month-dropdown { left: 5%; }
.page .react-datepicker__year-dropdown { left: 50%; }

.page .react-datepicker__year-option:hover, 
.page .react-datepicker__month-option:hover, 
.page .react-datepicker__month-year-option:hover {
	background: #e6e7ec;
}

.page .react-datepicker__month-dropdown .react-datepicker__navigation,
.page .react-datepicker__year-dropdown .react-datepicker__navigation { width: 0; }


form .slider { margin-top: 6px; }

form .slider .rc-slider-rail {
	height: 6px;
	background: var(--dark-gray);
}

form .slider .rc-slider-track {
	height: 6px;
}

form .slider .rc-slider-handle, form .slider .rc-slider-handle:active {
	background: var(--green);
	border: none;
	box-shadow: none;
	margin-top: -4px;
	outline: none;
}

form .colorPreset span {
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	height: 20px;
	margin: 7px 7px 0 0;
	overflow: hidden;
	width: 20px;
	transition: all 0.2s ease;
}

form .colorPreset span:hover {
	transform: scale(1.2);
}

form .colorPreset span.active {
	border: 1px solid #5e626d;
	transform: scale(1.2);
}

form .colorPreset span.disabled {
	cursor: default;
	opacity: 0.5;
}

form .colorPreset span.disabled:hover {
	transform: scale(1);
}

form .iconPreset span {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	height: 30px;
	margin: 0 5px 5px 0;
	overflow: hidden;
	padding: 3px;
	width: 30px;
	transition: all 0.2s ease;
}

form .iconPreset span:hover {
	transform: scale(1.2);
}

form .iconPreset span.active {
	border: 1px solid #5e626d;
	transform: scale(1.2);
	padding: 2px;
}

form .iconPreset span.disabled {
	cursor: default;
	opacity: 0.5;
}

form .iconPreset span.disabled:hover {
	transform: scale(1);
}

form .iconPreset span i {
	background: url(../images/icons.svg);
	display: block;
	height: 24px;
	width: 24px;
	position: static;
}

form .iconPreset span.icon_1 i {
	background-position: -144px -176px;
}

form .iconPreset span.icon_2 i {
	background-position: -168px -176px;
}

form .iconPreset span.icon_3 i {
	background-position: -192px -176px;
}

form .iconPreset span.icon_4 i {
	background-position: -216px -176px;
}

form .iconPreset span.icon_5 i {
	background-position: -240px -176px;
}

form .iconPreset span.icon_6 i {
	background-position: -264px -176px;
}

.textEditor button {
	box-shadow: none;
}

.textEditor .DraftEditor-editorContainer {
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	font-size: 13px;
}

.textEditor .DraftEditor-editorContainer h5 {
	font-size: 15px;
	text-transform: uppercase;
}

.textEditor .DraftEditor-editorContainer h6 {
	font-size: 13px;
	text-transform: uppercase;
	font-weight: normal;
}

.gallery {
	margin-bottom: 15px;
}

.gallery .buttons .button {
	margin: 0 !important;
}

/* .buttonDropdown { float: right; width: 22%; } */

.buttonDropdown .btn-group { position: relative; }

.buttonDropdown .btn {
	/* background: #0cd6e5;  */
	border-radius: 5px 0 0 5px;
	float: left;
	padding: 0 10px;
	text-align: left;
	width: 86%;
}

/* #page .buttonDropdown .btn:hover { background: #1de7f6; } */

.buttonDropdown .dropdown-toggle {
	border-radius: 0 5px 5px 0;
	display: block;
	float: right;
	/* height: 30px; */
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: -1000px;
	width: 13%;
}

.buttonDropdown .dropdown-toggle:after {
	border-style: solid;
	border-width: 5px 5px;
	border-color: transparent;
	border-top-color: #ffffff;
	content: '';
	margin: -2px -5px;
	position: absolute;
	top: 50%;
	right: 50%;
}

.buttonDropdown .dropdown-menu {
	background: #FFFFFF;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	position: absolute;
	width: 100%;
	z-index: 2;
	transform: translate3d(500%, 0px, 0);
}

.buttonDropdown .dropdown-menu .dropdown-item {
	background: transparent;
	box-shadow: none;
	color: #5e626d;
	display: block;
	line-height: 28px;
	text-align: left;
	padding: 0 10px;
	text-transform: none;
	width: 100%;
}

.buttonDropdown .dropdown-menu .dropdown-item:hover {
	padding-left: 15px;
}

form .fileUploader {
	margin-top: 20px;
}

form .fileContainer {
	background: #d2d4d7;
	border-radius: 10px;
	box-shadow: none;
	display: block;
	height: 150px;
	margin: 0 auto 0;
	overflow: hidden;
	padding: 0;
}

form .fileContainer .buttonContainer {
	position: absolute;
	margin: -55px 0 0;
	top: 50%;
	left: 0;
	text-align: center;
	width: 100%;
	z-index: 2;
}

form .fileContainer.file .buttonContainer {
	display: inline-block;
	margin: 0 4% 0 0;
	position: relative;
	width: 30%;
	vertical-align: middle;
}

form .fileContainer button.chooseFileButton {
	background: none;
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 5px;
	float: none;
	padding: 0 25px;
	position: static;
	text-transform: uppercase;
	transition: none;
}

form .fileContainer button i {
	background: url("../images/icons.svg") no-repeat -48px -128px;
	display: block;
	height: 24px;
	margin: 0 auto;
	width: 24px;
	transform: scale(1.5);
}

form .fileContainer button.chooseFileButton:hover {
	background: none;
	color: #54b6a9;
}

form .fileContainer button.chooseFileButton:hover i {
	background-position: -480px -128px;
}

form .fileContainer .deleteImage {
	background: url("../images/icons.svg") no-repeat -120px -128px;
	border-radius: 0;
	height: 24px;
	overflow: hidden;
	text-indent: -100px;
	top: 5px;
	right: 5px;
	width: 24px;
	z-index: 3;
}

form .fileContainer .deleteImage:hover {
	background-position: -144px -128px;
}

form .fileContainer .uploadPictureContainer {
	background: none;
	border: none;
	box-shadow: none;
	color: #555555;
	margin: 0;
	padding: 0;
	width: 100%;
}

form .fileContainer .uploadPictureContainer img.uploadPicture {
	transform: scale(1.4);
	transition: all linear 0.2s;
}

form .fileContainer .uploadPictureContainer video {
	width: 120%;
}

form .fileContainer:hover .uploadPictureContainer img, form .fileContainer:hover .uploadPictureContainer video {
	filter: brightness(50%);
}

form .fileContainer.file .uploadPicturesWrapper {
	display: inline-block;
	width: 65%;
	vertical-align: middle;
}

form .fileContainer .errorsContainer {
	color: #e13e3e;
	font-size: 11px;
	line-height: 130%;
	margin: 10px 0;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
}

.form .tags span, form .tags span {
	background: #f5f6fb;
	border-radius: 4px;
	;
	color: #5e626d;
	cursor: pointer;
	display: inline-block;
	line-height: 25px;
	margin: 0 0 7px 3px;
	padding: 0 7px;
	white-space: nowrap;
	transition: 0.2s all linear;
}

.form .tags span:hover, form .tags span:hover {
	background: #54b6a9;
}

.form .tags span.active, form .tags span.active {
	background: #54b6a9;
	color: #FFFFFF;
}

.form .tags span.disabled, form .tags span.disabled, .form .tags span.disabled:hover, form .tags span.disabled:hover {
	color: #888888;
	background: #f5f6fb;
	cursor: default;
}
/* --- EDITABLE TAGS GROUP --- */
.form .edittags span, form .edittags span {
	display: inline-block;
	margin: 0 3% 7px 0;
	position: relative;
	width: 22%;
}
.form .edittags span i, form .edittags span i { right: 2px; }

/* --- BUTTONS RADIO GROUP --- */
form .buttonGroup, .form .buttonGroup { margin-bottom: 5px; }

form .buttonGroup span, .form .buttonGroup span {
	background: #f5f6fb;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	color: #5e626d;
	cursor: pointer;
	display: block;
	float: left;
	line-height: 32px;
	margin: 0 1px;
	padding: 0 12px;
	white-space: nowrap;
	transition: 0.2s all linear;
}
form .buttonGroup span.icon, .form .buttonGroup span.icon { padding-top: 4px; padding-bottom: 4px; }
form .buttonGroup span.icon i, .form .buttonGroup span.icon i { position: static; }

form .buttonGroup span:first-child, .form .buttonGroup span:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

form .buttonGroup span:last-child, .form .buttonGroup span:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

form .buttonGroup span:hover, .form .buttonGroup span:hover,
form .buttonGroup span:active, .form .buttonGroup span:active { background: #54b6a9; }

/* .mobile form .buttonGroup span:hover, .mobile .form .buttonGroup span:hover { background: #f5f6fb; } */

form .buttonGroup span.active, .form .buttonGroup span.active {
	background: #54b6a9;
	color: #FFFFFF;
}

form .buttonGroup span.disabled, .form .buttonGroup span.disabled {
	background: #e5e6eb;
	box-shadow: none;
	color: #7e828d;
	cursor: default;
	text-shadow: 0 1px 0 #FFFFFF;
}

form .buttonGroup span.disabled.active, .form .buttonGroup span.disabled.active {
	background: #9ac1bc;
	color: #f5f6fb;
	text-shadow: none;
}

/* --- NUMBER SPINNER INPUT --- */
form .spinner > * {
	border: 1px solid #DDDDDD;
	border-left: none;
	color: #7e828d;
	float: left;
	line-height: 30px;
	padding: 0 8px;
	text-align: center;
}

form .spinner a { font-weight: bold; width: 10px; }

form .spinner a:hover { background: #f5f6fb; }

form .spinner a:first-child { border-radius: 4px 0 0 4px; border-left: 1px solid #DDDDDD; }
form .spinner a:last-child { border-radius: 0 4px 4px 0; }
form .spinner a.disabled { cursor: default; background: #f7f7f7; }

form .spinner .form-row { margin: 0; padding: 0; width: 40%; }
form .spinner .form-row .form-input { min-height: 30px; }
form .spinner .form-row input {
	border: none;
	text-align: center;
	padding: 0 8px;
	border-radius: 0;
	line-height: 30px;
}

/* --- DADATA --- */
form .dadata .react-dadata__input { font-size: 14px; height: 30px; border-color: #DDDDDD; }
form .dadata .react-dadata__input:focus { box-shadow: none; }
form .dadata .react-dadata__suggestions button {
	background: #ffffff;
	box-shadow: none;
	color: #5e626d;
	font-size: 13px;
	line-height: 22px;
}
form .dadata  .react-dadata__suggestions button:hover { background: #f5f6fb; }
form .dadata .react-dadata--highlighted { color: #54b6a9; }

/* --- AUTOCOMPLETE --- */
form .form-input.autocomplete { position: relative; }
form .form-input.autocomplete .menu {
	background: #FFFFFF;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	position: absolute;
	padding: 5px 0;
	overflow: auto;
	max-height: 150px;
	width: 100%;
	z-index: 3;
}
form .form-input.autocomplete .menu a { color: #555555; display: block; padding: 0 5px; line-height: 20px }
form .form-input.autocomplete .menu a:hover { background: #f5f6fb; }

/* --- DATEPRESET --- */
form .dates { display: flex; justify-content: space-between; gap: 20px; }

form .datepreset { 
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	border-radius: 15px;
	overflow: hidden;
	
}
form .datepreset a {
	background: var(--dark-gray);
	color: var(--white);
	text-align: center;
	line-height: 30px;
	width: 25%;
}
form .datepreset a.active { background: var(--green); }

/* --- MONTH FILTER --- */
.monthsFilter { text-align: center; text-transform: capitalize; }
.monthsFilter a { color: #54b6a9; line-height: 35px; display: inline-block; }
.monthsFilter span { font-size: 18px; display: inline-block; margin: 0 15px; line-height: 35px; }

/* --- CHECK FILTER --- */
.form-input .checkFilter { display: flex; justify-content: left; margin-bottom: 5px; }
.form-input .checkFilter a { font-size: 11px; line-height: 18px; color: var(--text); }

.form-input .checkFilter span {
	background: var(--dark-gray);
	border: 2px solid var(--dark-gray);
	border-radius:11px;
	display: flex;
	width: 30px;
	justify-content: left;
	margin-right: 5px;
}
.form-input .checkFilter span b {
	display: inline-flex;
	width: 14px;
	height: 14px;
	background: #FFFFFF;
	border-radius: 50%;
}
.form-input .checkFilter.active span { justify-content: right; background: var(--green); border-color: var(--green);}
.form-input .checkFilter.active a { color:var(--green); }

@media (max-width: 980px) {
	.form-input .checkFilter a { font-size: 12px; line-height: 26px; }
	.form-input .checkFilter span { border-radius: 13px; width: 50px;}
	.form-input .checkFilter span b { height: 24px; width: 24px; }
}