#CatalogPage .productsNav > button { width: 100%; }

#CatalogPage .productsNav .brand { margin-bottom: 20px; padding-bottom: 5px; }
#CatalogPage .productsNav .brand > .add { margin-top: 20px; }

#CatalogPage .productsNav h5 {
	font-weight: bold;
	color: #54B6A9;
	font-size: 13px;
	line-height: 30px;
	padding: 0 15px;
	text-transform: uppercase;
}

#CatalogPage .productsNav .sectionItem { border-top: 1px solid #dddddd;}
#CatalogPage .productsNav .SortableList .SortableList .sectionItem { padding-left: 50px; }
#CatalogPage .productsNav .SortableList .SortableList .sectionItem .SortHandle { left: 25px; }
#CatalogPage .productsNav .SortableList .SortableList .sectionItem .edit { right: 27px; }

#CatalogPage .productsNav a:first-of-type { padding-top: 0; border-radius: 0; }
#CatalogPage .productsNav a:first-of-type i { top: 3px; }
#CatalogPage .productsNav a i.edit { right : 51px; }

#CatalogPage .productsNav form { padding: 10px; }
#CatalogPage .productsNav form .checkGroupLabel { width: 100%; }
#CatalogPage .productsNav form .buttons { float: none; }
#CatalogPage .productsNav form .buttons button { float: left; width: 70%; }

#CatalogPage .table .titles { margin: 0 2px; padding-left: 33px; }
#CatalogPage .table .titles span { padding: 0 5px; }
#CatalogPage .table i.hidden {
	background: url(../images/icons.svg) -168px -152px; 
	display: inline-block;
	filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
	vertical-align: middle;
}
#CatalogPage .table .col { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
#CatalogPage .table .photo {
	background-position: center;
	background-size: cover; 
	border-radius: 2px;
	height: 40px;
	margin: 3px 0;
}


#CatalogItem .section { margin-top: 115px; }
#CatalogItem .fileContainer { height: 110px; }
#CatalogItem textarea { height: 110px; }
#CatalogItem h5 { color: #9f9f9f; text-transform: uppercase; font-size: 10px; }
#CatalogItem .productInfo { margin: 10px; }

#CatalogItem .optionsBlock .remove { position: absolute; right: -10px; top: 23px; }
#CatalogItem .optionsBlock .group { position: relative; }
#CatalogItem .optionsBlock .group .remove { right: 30px; }
#CatalogItem .optionsBlock .productInfo { margin: 0; }