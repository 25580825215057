:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--green10: #54b6a91A;
	--text: #5e626d;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
}

.page .ReactTable  { border: none; }

.page .ReactTable .rt-thead.-header {
    box-shadow: none;
    font: 12px/20px "Roboto", Arial, sans-serif;
    color: var(--textGray);
}
.page .ReactTable .rt-thead .rt-tr {
    background: none;
    /* box-shadow: none; */
    padding-left: 10px;
    text-align: left;
}
.page .ReactTable .rt-tbody .rt-tr-group { border: none; } 
.page .ReactTable .rt-tr {
    background: var(--white);
    border-radius: 2px;
    /* box-shadow: 0 1px 5px rgba(0,0,0,0.13); */
    margin: 1px 2px 1px 2px;
}

.page .ReactTable .rt-thead .rt-th, 
.page .ReactTable .rt-thead .rt-td,
.page .ReactTable .rt-tbody .rt-td {
    border: none;
    padding: 5px 7px;
}
.page .ReactTable .rt-tbody .rt-tr { border-left: 3px solid transparent; padding-left: 10px; }

.page .ReactTable .rt-tbody .rt-td { line-height: 30px; }

.page .ReactTable .rt-thead .rt-th.-sort-desc, 
.page .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(121,195,179,0.6);
}

.page .ReactTable .rt-thead .rt-th.-sort-asc, 
.page .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(121,195,179,0.6);
}

.page .ReactTable.listTable .rt-tbody .rt-tr:hover { border-left: 3px solid var(--green); }
.page .ReactTable.reportTable .rt-tbody .rt-tr.expired { border-left: 3px solid var(--red); background: var(--red10) }
.page .ReactTable.reportTable .rt-tbody .rt-tr.notSend { border-left: 3px solid #f4bf3b; background: #fffbe9 }
.page .ReactTable .rt-tbody .rt-tr.alert { border-left: 3px solid var(--red); background: var(--red10) }

.page .ReactTable.reportTable .rt-tbody .rt-tr.payment { border-left: 3px solid var(--green); color: #aaaaaa; }
.page .ReactTable.reportTable .rt-tbody .rt-tr.notPayment { border-left: 3px solid #f4bf3b; background: #fffbe9; }

.page .ReactTable .rt-tbody .rt-tr.section { border-left: 3px solid #afb1bb; background: #e4e5ea; }

.page .ReactTable .rt-thead.-filters .rt-th { border: none; padding: 2px;}
.page .ReactTable .rt-thead.-filters input, .page .ReactTable .rt-thead.-filters select { 
    background: transparent;
    line-height: 18px; 
}
