/* ----- PRODUCTS NAVIGATION ----- */
.productsNav { position: relative; }
.productsNav .container { 
    background: #f8f9fb;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}
.productsNav a, .sectionItem {
	border-top: 1px solid #d9dade;
	color: #5e626d;
	display: block;
	line-height: 30px;
	padding: 0 15px 0 30px;
	position: relative;
	text-transform: none;
}
.productsNav a:first-of-type { border: none; padding-top: 5px; border-radius: 3px 3px 0 0; }
.productsNav a:hover, .productsNav a:active { color: #54b6a9; }
.productsNav a.active { background: #54b6a9; color: #FFFFFF; border: none; }
.productsNav a.active + a { border: none; }

.productsNav a:not(.button) i { position: absolute; top: 3px; right: 3px; }
.productsNav a:first-of-type i { top: 8px; }

.productsNav a i.hidden { 
	background-position: -168px -152px; 
	filter: brightness(0) invert(0.5);
	cursor: default; 
	right: 15px; 
}
.productsNav a.active i.hidden { filter: brightness(0) invert(1); }

.productsNav a i.edit,
.productsNav a i.remove,
.productsNav a i.add { cursor: pointer; display: none; right: 0; transition: background-color linear 0.2s; }

.productsNav a i.edit { background-position: -96px -128px; filter: brightness(0) invert(0.5); right: 27px; }
.productsNav a i.edit:hover, .productsNav a i.edit:active { filter: none; }
.productsNav a.active i.edit { filter: brightness(0) invert(1); }
.productsNav a.active i.edit:hover, .productsNav a.active i.edit:active { opacity: 0.7; }
.productsNav a i.add { background-position: -480px -128px; filter: brightness(0) invert(0.5); right: 27px; }
.productsNav a i.add:hover, .productsNav a i.add:active { filter: none; }
.productsNav a.active i.add { filter: brightness(0) invert(1); }
.productsNav a.active i.add:hover, .productsNav a.active i.add:active { opacity: 0.7; }

.productsNav a i.remove { background-position: -120px -128px; }
.productsNav a i.remove:hover, .productsNav a i.remove:active { opacity: 0.6; }

.productsNav a:hover i.edit, .productsNav a:active i.edit, 
.productsNav a:hover i.remove, .productsNav a:active i.remove,
.productsNav a:hover i.add, .productsNav a:active i.add { display: block; }
.productsNav a:hover i.hidden, .productsNav a:active i.hidden { display: none; }

.productsNav a.add { padding-left: 5px; }
.productsNav a.removed {
	border: none; 
	padding: 15px; 
	position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}
.productsNav a.add i,
.productsNav a.removed i {
	background: url(../images/icons.svg) -384px -152px;
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	vertical-align: middle;
	position: static;
	width: 24px;
}
.productsNav a.removed:hover i,
.productsNav a.removed:active i { background-position: -408px -152px; }
.productsNav a.removed.active i { background-position: -432px -152px; }

.productsNav a.add i { background: url(../images/icons.svg) -456px -128px; }
.productsNav a.add:hover i,
.productsNav a.add:active i { background: url(../images/icons.svg) -480px -128px; }

/** ----- PRODUCTS PAGE ----- */
#ProductPage .section { margin-top: 15px; }
/* #ProductPage .section { margin-top: 90px; } */

#ProductPage .productsNav .container { padding-bottom: 60px; }

#ProductPage .productsNav form { border-top: 1px solid #d9dade; margin: 0; padding: 15px; }

#ProductPage .productsNav form input { background: #FFFFFF; }
#ProductPage .productsNav form label { z-index: 1;}
#ProductPage .productsNav form .checkGroupLabel { width: 100%; }
/* #ProductPage .productsNav form .nomargin { margin: 0; } */
#ProductPage .productsNav form .error { margin-top: 10px; }

#ProductPage .productsNav form button { 
	line-height: 32px; 
	/* display: inline-block;  */
	float: left; 
	margin: 10px 0 0 0; 
}
#ProductPage .productsNav form .close { 
	background: none;
	box-shadow: none;
	line-height: 32px; 
	float: right; 
	padding: 2px;
	margin-top: 7px;
}
#ProductPage .productsNav form .close i { position: static; }

#ProductPage .SortHandle, .sectionItem .SortHandle { left: 5px; opacity: 0.5; transform: scale(0.7); }

#ProductPage .products { padding-left: 20px; }

#ProductPage .products .table .color {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	vertical-align: middle;
	width: 15px;
}

#ProductPage .products .table .ws {
	background: url(../images/icons.svg);
	display: inline-block;
	height: 24px;
	margin-left: 3px;
	width: 24px;
}
#ProductPage .products .table .ws:first-child { margin-left: 0; }
#ProductPage .products .table .ws.ws_1 { background-position: -144px -176px; }
#ProductPage .products .table .ws.ws_2 { background-position: -168px -176px; }
#ProductPage .products .table .ws.ws_3 { background-position: -192px -176px; }
#ProductPage .products .table .ws.ws_4 { background-position: -216px -176px; }
#ProductPage .products .table .ws.ws_5 { background-position: -240px -176px; }
#ProductPage .products .table .ws.ws_6 { background-position: -264px -176px; }

/* -- RAW ITEM --*/
#RawItem .section { margin-top: 110px; }

#RawItem textarea { height: 120px; }

#RawItem .block h4 { margin-bottom: 0; }
#RawItem .gray h4 { margin-bottom: 10px; }
#RawItem .gray h5 { text-transform: uppercase; margin-bottom: 5px; }

#RawItem .gray .item { border-top: 1px solid #dddddd; }
#RawItem .gray .item span { 
	line-height: 25px; 
	min-height: 25px;
	white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis; 
}

#RawItem .costs .item { padding: 5px 0;}
#RawItem .costs .item .form-row { margin: 0; }
#RawItem .costs .item span { line-height: 32px; min-height: 32px; }

#RawItem .gray .toggle { display: block; float: right; background: transparent; box-shadow: none; }
#RawItem .gray .toggle i {
    background: url(../images/icons.svg) no-repeat -218px -128px;
    display: block;
    height: 24px;
    width: 24px;
}
#RawItem .gray .toggle.open i { background-position: -218px -128px; }
#RawItem .gray .toggle.close i { background-position: -242px -128px; }

#RawItem .gray .provider { 
	background: transparent; 
	box-shadow: none; 
	display: block; 
	position: absolute; 
	top : 7px;
	right: 0;
}
#RawItem .gray .provider i {
    background: url(../images/icons.svg) no-repeat -648px -128px;
    display: block;
    height: 24px;
    width: 24px;
}

/* -- PRODUCT ITEM --*/
#ProductItem .section { margin-top: 90px; }

#ProductItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: -15px;
}
#ProductItem .remove i { margin: 0; }
#ProductItem .add { line-height: 32px; margin-top: 15px; }
#ProductPage .nomargin { margin: 0; }

#ProductItem .form-row .icon.hidden {
	background: url(../images/icons.svg) -168px -152px;
	filter: brightness(0) invert(0.6);
	position: absolute;
	top: 4px;
	right: 15px;
}


#ProductItem .row { border-top: 1px solid #dddddd; }
#ProductItem .row span { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
#ProductItem .row.titles span,
#ProductItem .row span.title { text-transform: uppercase; font-size: 11px; }
#ProductItem .row:first-of-type { border: none; }

#ProductItem .description textarea { height: 150px; }

#ProductItem .expdate { padding-bottom: 0; }


#ProductItem .finance .prices span { line-height: 32px; height: 32px; }
#ProductItem .finance .titles { margin-top: 10px; }
#ProductItem .finance .city { padding: 5px 0; }
#ProductItem .finance .city .col { padding-right: 10px; }
#ProductItem .finance .city .form-row { margin: 0; }
#ProductItem .finance .alert input { color: #e83075; }

#ProductItem .technique h4 { margin: 0; }
#ProductItem .technique .tech { padding-bottom: 15px; }
#ProductItem .technique textarea { height: 98px; }

#ProductItem .composition .group { margin-bottom: 15px; }
#ProductItem .composition .group .product .col { padding-right: 10px; }
#ProductItem .composition .group .product > span { line-height: 30px; margin-top: 20px; }
#ProductItem .composition .buttonDropdown { width: 47%; }
#ProductItem .composition .buttonDropdown button { line-height: 32px; }

#ProductItem .CPFC .titles { margin-top: 20px; }
#ProductItem .CPFC span { line-height: 21px; height: 21px;}

#ProductItem .video .col { position: relative; }
#ProductItem .video .item { margin-bottom: 20px; }
#ProductItem .video .item .form-row { margin: 0; }
#ProductItem .video .fileUploader { margin: 0; width: 90%; }
#ProductItem .video .remove { top: -5px; right: 15px;}
#ProductItem .video .add { margin: 0; }
#ProductItem .video .deleteImage { display: none; }

/** ------- MENU PAGE ------- */
#MenuPage .head .searchProducts { float: right; }

#MenuPage > .col { padding-right: 20px; }
#MenuPage > .col .container { box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); border-radius: 5px; }

#MenuPage .productsNav.sec { width: 17%; }
#MenuPage .productsNav.prod { width: 23%; }
#MenuPage .productsNav .container { overflow: auto; }
#MenuPage .productsNav a { font-size: 12px; line-height: 22px; padding: 4px 15px; }
#MenuPage .productsNav .type_2 + .type_3 { margin-top: 20px; border: none; }

#MenuPage .MenuProductItem { padding: 0; }
#MenuPage .MenuProductItem .container { background: #FFFFFF; overflow: auto; }

.MenuProductItem .container h4 { margin-bottom: 10px; }
.MenuProductItem .container h5 { text-transform: uppercase; font-weight: bold; margin-bottom: 5px; }
.MenuProductItem .container .row { border-top: 1px solid #d9dade; }
	
.MenuProductItem .main > .col { border-left: 1px solid #d9dade; padding: 15px; }
.MenuProductItem .main .col:first-child { border: none; }

.MenuProductItem .container .photo {
	background-position: center;
	background-size: cover; 
	height: 190px;
	margin: -10px -15px -10px 0;
	position: relative;
}
@media (max-width: 1030px) {
	.MenuProductItem .container .photo { height: 110px; }
}
.MenuProductItem .container .photo .zoom { position: absolute; top: 5px; right: 5px; }
.MenuProductItem .container .photo .zoom i { filter: invert(1);}
.MenuProductItem .container .main .photo {
	background-image: url(../images/menu_dummy.svg);
	background-color: #535663;
	margin: -15px -15px 10px;
}

.MenuProductItem .container form .form-row { margin-top: 5px;}

.MenuProductItem .container .tech .row { padding: 10px 15px; }

.MenuProductItem .container .composition .row .col,
.MenuProductItem .container .composition .titles span { line-height: 24px; padding: 3px 10px 3px 0; }
.MenuProductItem .container .composition .titles { border: none; }
.MenuProductItem .container .composition .titles span { 
    color: #888888;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.MenuProductItem .container .composition .row .count { font-weight: bold; }
.MenuProductItem .container .composition .row a { color: #54b6a9; }
.MenuProductItem .container .composition .row a:hover { text-decoration: underline; }

.MenuProductItem .container .video { border-top: 1px solid #d9dade; margin-top: 15px; padding-top: 15px; }
.MenuProductItem .container .video video { max-width: 100%; }

.window.menuPhoto .photo {
	background-position: center;
	background-size: cover; 
	height: 100%;
	/* margin: -10px -15px -10px 0; */
	position: relative;
}

/* --- tablet --- */
.tablet#MenuPage .productsNav a { font-size: 15px; }
.tablet .MenuProductItem .container h4 { font-size: 20px; }
.tablet .MenuProductItem .container h5 { font-size: 16px; }
.tablet .MenuProductItem .container .composition .row span,
.tablet .MenuProductItem .container .composition .row a,
.tablet .MenuProductItem .container p { font-size: 16px; font-weight: bold; }
