@font-face { font-family: "Roboto_Medium"; src: url(./fonts/Roboto-Medium.ttf); }
@font-face { font-family: "Roboto"; src: url(./fonts/Roboto-Regular.ttf); }

:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--green10: #54b6a91A;
	--dark-green: #2c7982;
	--text: #5e626d;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
	--blue: #3b96e3;
}

*:not(select) { border: none; margin: 0; padding: 0; outline: none; }
article, aside, canvas, figure, figure img, figcaption, hgroup, footer, header, nav, section, audio, video { display: block; }
ul, ol { list-style: none; }
a { cursor: pointer; text-decoration: none; transition: all 0.2s linear; }

html, body { background: var(--main-light-gray); color: var(--text); font: 13px/21px "Roboto", Arial, sans-serif; }
body { overflow-x: hidden; }

@media print {
	html, body { background: #FFFFFF; color: #000000; }  
}

h2 { font: normal 18px/28px "Roboto", Arial, sans-serif; }
h3 { font: normal 16px/30px "Roboto", Arial, sans-serif; }
h4 { font: normal 15px/25px "Roboto", Arial, sans-serif; }
h5 { font: normal 13px/25px "Roboto", Arial, sans-serif; }
h6 { font: normal 12px/21px "Roboto", Arial, sans-serif; }
h1,h2,h3,h4, h6 { text-transform: uppercase; }
h2,h3,h4,h5 { color: var(--text); }
h1 a, h2 a, h3 a, h4 a, h5 a { color: inherit; font: inherit; text-transform: inherit; display: inline-block; }
@media (max-width: 1030px) {
	h2 { font: normal 16px/26px "Roboto", Arial, sans-serif; }
}

p.error { color: var(--red); }
p.success { color: var(--green); }

.comment {
	font-size: 11px;
	color: #888888;
	line-height: 16px;
	margin: -8px 0 8px;
}

.clearfix { box-sizing: border-box; display: block; position: relative; }
.clearfix:after {
	clear: both;
	content: ' ';
	display: block;
	font-size: 0;
	line-height: 0;
	visibility: hidden;
	width: 0;
	height: 0;
}

svg { width: 20px; fill: var(--text); }
svg * { fill: var(--text); }
a:hover svg * { fill: var(--green); }
button svg *, .button svg * { fill: var(--white); }
.remove:not(button):hover svg * { fill: var(--red);}

a.iconSvg { display: block; }
a.iconSvg.active svg * { fill: var(--green); }

.button.iconSvg { display: flex; gap: 10px; align-items: center; }
.button.iconSvg svg * { fill: var(--white);}

.col { box-sizing: border-box; display: block; float: left; padding-right: 20px; }
@media (max-width: 1280px) {
	.col { padding-right: 15px; }
}
@media (max-width: 1030px) {
	.col { padding-right: 10px; }
}
.col.col5 { width: 5%; }
.col.col10 { width: 10%; }
.col.col15 { width: 15%; }
.col.col20 { width: 20%; }
.col.col25 { width: 25%; }
.col.col30 { width: 30%; }
.col.col35 { width: 35%; }
.col.col40 { width: 40%; }
.col.col45 { width: 45%; }
.col.col50 { width: 50%; }
.col.col55 { width: 55%; }
.col.col60 { width: 60%; }
.col.col65 { width: 65%; }
.col.col70 { width: 70%; }
.col.col75 { width: 75%; }
.col.col80 { width: 80%; }
.col.col85 { width: 85%; }
.col.col90 { width: 90%; }
.col.col95 { width: 95%; }
.col.col100 { width: 100%; }

.col.col1_1 { width: 100%; }
.col.col1_2 { width: 50%; }
.col.col1_3 { width: 33.3%; }
.col.col1_4 { width: 25%; }
.col.col1_5 { width: 20%; }
.col.col2_3 { width: 66.6%; }
.col.col3_4 { width: 75%; }

button, .button {
	background: var(--blue); 
	/* background: -moz-linear-gradient(top, #3b96e3 0%, #2e86d0 100%); 
	background: -webkit-linear-gradient(top, #3b96e3 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 );
	 */
	border: none;
	border-radius: 5px;
	box-sizing: border-box;
	/* box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75); */
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font: 14px/35px "Roboto", Arial, sans-serif;
	padding: 0 25px;
	vertical-align: middle;
	transition: all 0.2s ease;
	width: auto;
}
@media (max-width: 980px) {
	button, .button { padding: 0 15px; line-height: 40px; }
}
.button:hover, button:hover,
.button:active, button:active {
	background: #4da8f5; /* Old browsers */
	/* background: -moz-linear-gradient(top, #4da8f5 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #4da8f5 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); */
}

button.disabled, .button.disabled, button.disabled:hover, .button.disabled:hover,
button.disabled.red, .button.disabled.red, button.disabled.red:hover, .button.disabled.red:hover,
button.disabled.green, .button.disabled.green, button.disabled.green:hover, .button.disabled.green:hover,
button.disabled.blue, .button.disabled.blue, button.disabled.blue:hover, .button.disabled.blue:hover,
button:disabled, .button:disabled, button:disabled:hover, .button:disabled:hover,
button.red:disabled, .button.red:disabled, button.red:disabled:hover, .button.red:disabled:hover,
button.green:disabled, .button.green:disabled, button.green:disabled:hover, .button.green:disabled:hover,
button.blue:disabled, .button.blue:disabled, button.blue:disabled:hover, .button.blue:disabled:hover {
	color: #fdfdfd;
	background: #c9cace;
	cursor: default;
	box-shadow: none;
}

button.blue, .button.blue {
	background: var(--blue); /* Old browsers */
	/* background: -moz-linear-gradient(top, #3b96e3 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #3b96e3 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 );  */

	/* box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75); */
	color: #ffffff;
}
button.blue:hover, .button.blue:hover,
button.blue:active, .button.blue:active {
	background: #4da8f5; /* Old browsers */
	/* background: -moz-linear-gradient(top, #4da8f5 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #4da8f5 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); */
}

/* .dark button.blue, .dark .button.blue {
	box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15); 
} */

button.green, .button.green {
	background: var(--green); /* Old browsers */
	/* background: -moz-linear-gradient(top, #54b6a9 0%, #35a289 100%); 
	background: -webkit-linear-gradient(top, #54b6a9 0%,#35a289 100%);
	background: linear-gradient(to bottom, #54b6a9 0%,#35a289 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54b6a9', endColorstr='#35a289',GradientType=0 );

	box-shadow: 1px 2px 10px rgba(40, 130, 109, 0.75); */
}
button.green:hover, .button.green:hover,
button.green:active, .button.green:active {
	background: #64cdbf; /* Old browsers */
	/* background: -moz-linear-gradient(top, #64cdbf 0%, #35a289 100%); 
	background: -webkit-linear-gradient(top, #64cdbf 0%,#35a289 100%);
	background: linear-gradient(to bottom, #64cdbf 0%,#35a289 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64cdbf', endColorstr='#35a289',GradientType=0 ); */
}

button.red, .button.red { 
	background: #e73535;
	/* background: -moz-linear-gradient(top, #e73535 0%, #e13131 100%); 
	background: -webkit-linear-gradient(top, #e73535 0%,#e13131 100%); 
	background: linear-gradient(to bottom, #e73535 0%,#e13131 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e73535', endColorstr='#e13131',GradientType=0 );
	box-shadow: 1px 2px 10px rgba(225, 49, 49, 0.5); */
}
button.red:hover, .button.red:hover,
button.red:active, .button.red:active { 
	background: #f94747;
	/* background: -moz-linear-gradient(top, #f94747 0%, #e13131 100%); 
	background: -webkit-linear-gradient(top, #f94747 0%,#e13131 100%);
	background: linear-gradient(to bottom, #f94747 0%,#e13131 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f94747', endColorstr='#e13131',GradientType=0 ); 
	box-shadow: 1px 2px 10px rgba(225, 49, 49, 0.5); */
}

button.gray, button.gray:hover, button.gray:active,
.button.gray, .button.gray:hover, .button.gray:active{
	background: #d5d9f2 !important;
	/* background: -moz-linear-gradient(top, #d5d9f2 0%, #c0c6e1 100%) !important; */
	/* background: -webkit-linear-gradient(top, #d5d9f2 0%,#c0c6e1 100%) !important; */
	/* background: linear-gradient(to bottom, #d5d9f2 0%,#c0c6e1 100%) !important; */
	/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d9f2', endColorstr='#c0c6e1',GradientType=0 ) !important; */
	/* box-shadow: 1px 2px 10px rgba(231, 233, 243, 0.75) !important; */
	color: #636b91;
}
a.close:hover, a.close:active { color: #2e86d0; }

button i, .button i, .icon i {
	background: url(images/icons.svg);
	display: inline-block;
	height: 24px;
	margin: 0 7px 0 -7px;
	width: 24px;
	vertical-align: middle;
}
.icon i { margin: 0; }

button.loading, .button.loading { cursor: default; }
button.loading img, .button.loading img { height: 8px; }

a.close, a.close:hover, a.close:active {
	background: #d5d9f2; /* Old browsers */
	background: -moz-linear-gradient(top, #d5d9f2 0%, #c0c6e1 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #d5d9f2 0%,#c0c6e1 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #d5d9f2 0%,#c0c6e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d9f2', endColorstr='#c0c6e1',GradientType=0 ); /* IE6-9 */

	box-shadow: 1px 2px 10px rgba(231, 233, 243, 0.75);
	color: #636b91;
}
a.close:hover, a.close:active { color: #2e86d0; }

a.dotted { color: #5e626d; border-bottom: 1px dotted #5e626d; }
a.dotted:hover { color: var(--green); border-bottom-color: var(--green); }

button.remove, .button.remove { box-shadow: none; line-height: 32px; background: none; padding: 0; }

.icon i { background: url(images/icons.svg); }

.icon.edit i { background-position: -96px -128px; filter: brightness(0) invert(0.5); }
.icon:hover.edit i, .icon:active.edit i { filter: none; }

.icon.remove i,
	.button.remove i, button.remove i { background-position: -120px -128px; margin: 0; }
.icon:hover.remove i, .icon:active.remove i,
	.button:hover.remove i, .button:active.remove i { opacity: 0.6; }

.icon.copy i { background-position: -24px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.copy i, .icon:active.copy i { filter: none; }

.icon.deactivation i { background-position: -168px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.deactivation i, .icon:active.deactivation i { filter: none; }
.icon.activation i { background-position: -144px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.activation i, .icon:active.activation i { filter: none; }

.icon.process i { background-position: -504px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.process i, .icon:active.process i { filter: none; }

.icon.view i { background-position: -144px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.view i, .icon:active.view i { filter: none; }

.icon.send i { background-position: -528px -128px; filter: brightness(0) invert(0.5); }
.icon:hover.send i, .icon:active.send i { filter: none; }

.icon.zoom i { background-position: 0 -152px; }
.icon.zoom:hover i, .icon.zoom:active i { opacity: 0.7; }

.icon.activate i, .icon.active { background-position: -48px -152px; }
.icon:hover.activate i, .icon:active.activate i { filter: brightness(0.9); }

.icon.pay i { background-position: -552px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.pay i, .icon:active.pay i { filter: none; }

.icon.download i { background-position: -624px -128px; filter: brightness(0) invert(0.5); }
.icon:hover.download i, .icon:active.download i { filter: none; }

.icon.take i { background-position: -576px -128px; filter: brightness(0) invert(0.5); }
.icon:hover.take i, .icon:active.take i { filter: none; }

.icon.print i { background-position: -360px -152px; filter: brightness(0) invert(0.5); }
.icon:hover.print i, .icon:active.print i { filter: none; }

.icon.toggle i { background-position: -912px -152px; filter: brightness(0) invert(0.5); transform: rotate(90deg); }
.icon:hover.toggle i, .icon:active.toggle i { filter: none; }
.icon.toggle.open i { transform: rotate(-90deg); }
.icon:hover.toggle.open i, .icon:active.toggle.open i { filter: none; }

.icon.number i { background-image: none; }


button.geo i, .button.geo i { background: url(images/icons.svg) -816px -152px; }
button.calc i, .button.calc i { background: url(images/icons.svg) -792px -152px; }
button.close i, .button.close i, .icon.close i { 
	background-position: -120px -128px; filter: brightness(0) invert(0.5);
}
button.close:hover i, button.close:active i, 
.button.close:hover i, .button.close:active i,
.icon.close:hover i, .icon.close:active i { opacity: 0.8; }



button.add i, .button.add i { background: url(images/icons.svg) -48px -128px; }
.icon.add i { background: url(images/icons.svg) -458px -128px; }
.blue.icon.add i { filter: brightness(0) invert(1); }


/* button.activate i, .button.activate i, .icon.active { background: url(images/icons.svg) -48px -152px; } */


button.image i, .button.image i { background: url(images/icons.svg) -216px -152px; }


/* button.send i, .button.send i { background: url(images/icons.svg) -504px -128px; } */



button.bl i, .button.bl i { background: url(images/icons.svg) -696px -152px; }
button.minus i, .button.minus i { background: url(images/icons.svg) -720px -152px; }
button.phone i, .button.phone i { background: url(images/icons.svg) -744px -152px; }
button.option i, .button.option i { background: url(images/icons.svg) -768px -152px; }

/* button.close i, .button.close i, .icon.close i { background: url(images/icons.svg) -168px -128px; } */
button.date i, .button.date i, .icon.date i { background: url(images/icons.svg) -288px -128px; }
button.qr i, .button.qr i { background: url(images/icons.svg) -984px -128px; }


/* button:hover.edit i, .button:hover.edit i,
button:active.edit i, .button:active.edit i,
.icon:hover.edit i, .icon:active.edit i { background: url(images/icons.svg) -96px -128px; } */


/* button:hover.activate i, .button:hover.activate i,
button:active.activate i, .button:active.activate i,
.icon:hover.activate i, .icon:active.activate i { background: url(images/icons.svg) -72px -152px; } */




/* button:hover.pay i, .button:hover.pay i,
button:active.pay i, .button:active.pay i { background: url(images/icons.svg) -552px -152px; } */
button:hover.view i, .button:hover.view i,
button:active.view i, .button:active.view i { background: url(images/icons.svg) -144px -152px; }

button.date:hover i, .button.date:hover i, .icon.date:hover i,
button.date.active i, .button.date.active i, .icon.date.active i,
button.date:active i, .button.date:active i, .icon.date:active i { background: url(images/icons.svg) -840px -128px; }


/* -- tags -- */
.tag {
	background: #f5f6fb;
	border-radius: 3px;
	box-sizing: border-box;
	display: inline-block;
	line-height: 24px;
	margin: 0 5px 3px 0;
	padding: 0 10px;
}

.tag.green { background: #54b6a9; color: #FFFFFF; }
.tag.red { background: #ef8b8b; color: #ffffff; }
.tag.yellow { background: #f4e989; }
.tag.orange { background: rgb(242, 188, 139); }

.nav a { 
	cursor: pointer;
	display: block;
	text-transform: uppercase;
	font: 12px/21px "Roboto_Medium", Arial, sans-serif;
}

/* --- Login Page --- */
.backPage {
	background: #343844;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	min-height: 350px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#loginPage {
	width: 280px;
	display: flex;
	flex-direction: column;
	gap: 30px;
}
/* #loginPage > * { display: inline-flex;align-self: stretch;} */
#loginPage > img { max-width: 270px; align-self: center; }
#loginPage form {
	display: flex;
	flex-direction: column;
}
#loginPage form > * {align-self: stretch;}
/* #loginPage form input { line-height: 30px; } */
#loginPage form .pass input { color: #535663; }
/* #loginPage form label { line-height: 30px; } */
#loginPage form button { margin-top: 20px; }

#loginPage .error { margin-top: 15px; }
#loginPage a { color: var(--green); display: inline-block; margin-top: 20px; text-align: center; }
#loginPage p:not(.error) {color: var(--textGray); text-align: center; margin-bottom: 5px; align-self: center; display: inline-block; font-size: 16px; }
#loginPage p:not(.error) a { margin: 0; }

#loginPage.register { width: 400px; }
/* #loginPage.register > img { width: 70%; align-self: center; } */

@media (max-width: 980px) {
	#loginPage, #loginPage.register {
		width: 90%;
	}
}

/* #loginPage .steps {
	background: #9699a7;
	height: 1px;
	margin: 30px 0 15px;
	position: relative;
}

#loginPage .steps a {
	display: block;
	position: absolute;
	top: -22px;
	right: 25%;
	height: 26px;
	width: 26px;
	font-weight: bold;
	text-align: center;
	border-radius: 50%;
	background: #9699a7;
	color: #343844;
	line-height: 25px;
}
#loginPage .steps a.active { background: #35a289; cursor: default; }
#loginPage .steps a.checked { background: #35a289; color: #FFFFFF; }
#loginPage .steps a.disabled { color: #68696c; cursor: default;}

#loginPage .steps a:first-child { left: 25%; } */




/* --- Sidebar --- */
aside {
	background: #343844;
	box-sizing: border-box;
	width: 260px;
	/* position: absolute; */
	position: fixed;
	z-index: 1;
}
aside.close { width: 50px; }

@media (max-width: 1050px) {
	aside.open { box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); z-index: 6; }
	aside.close { box-shadow: none; }
}

aside .logo { background: #454a5a; padding: 10px 20px; }
aside .logo a { display: block; }
aside .logo img { display: block; max-height: 50px; margin: auto; }

aside.close .logo {
	background: #6bc9aa; /* Old browsers */
	background: -moz-linear-gradient(top, #6bc9aa 0%, #257782 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #6bc9aa 0%,#257782 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #6bc9aa 0%,#257782 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bc9aa', endColorstr='#257782',GradientType=0 ); /* IE6-9 */

	padding: 20px 10px;
}
aside.close .logo img { width: 100%; }

aside .toggle {
	background: #424859;
	border-radius: 3px;
	display: block;
	margin: -28px -6px;
	padding: 12px 4px;
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 2;
}
aside .toggle:hover, aside .toggle:active { background: #5b6276; }
aside .toggle i {
	background: #868c9c;
	border-radius: 50%;
	display: block;
	height: 4px;
	margin-top: 5px;
	width: 4px;
}
aside .toggle i:first-child { margin: 0; }

aside .shift { color: #adb0bc; padding: 15px; }
aside .shift p { color: #f5f6fb; line-height: 20px; margin: 20px 0; }

aside .shift .dropdown {
	background: #454a5a;
	border-radius: 5px;
	line-height: 32px;
	margin-bottom: 5px;
	padding: 0 10px;
	position: relative;
}

aside .shift button {
	background: transparent;
	box-shadow: none;
	color: #adb0bc;
	cursor: default;
	font-size: 13px;
	line-height: 35px;
	padding: 0;
	text-align: left;
	text-transform: none;
	width: 100%;
}
aside .shift .dropdown button span { color: #FFFFFF; margin-left: 5px; }
aside .shift .dropdown .active { color: #54b6a9 !important; }

aside .shift .dropdown .dropdown-toggle {
	background: transparent;
	cursor: pointer;
	height: 24px;
	padding: 1px;
	position: absolute;
	top: 4px;
	right: 6px;
	width: 24px;
}
aside .shift .dropdown .dropdown-toggle span {
	background: url(images/icons.svg) 0 -128px;
	display: block;
	height: 24px;
	width: 24px;
	text-indent: -150px;
	overflow: hidden;
}
aside .shift .dropdown .dropdown-toggle:hover span,
aside .shift .dropdown .dropdown-toggle:active span { background: url(images/icons.svg) -24px -128px; }

aside .shift .dropdown .dropdown-menu {
	background: #454a5a;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: none;
	padding: 5px 10px;
	position: absolute;
	top: 29px !important;
	left: 0;
	width: 100%;
	transform: none !important;
	z-index: 1;
}
aside .shift .dropdown .dropdown-menu.show { display: block; }
aside .shift .dropdown .dropdown-menu button { cursor: pointer; }
aside .shift .dropdown .dropdown-menu button:hover,
aside .shift .dropdown .dropdown-menu button:active { color: #FFFFFF; }

aside .shiftToggle { padding: 0 15px 10px; }
aside .shiftToggle p { color: #f5f6fb; margin-bottom: 10px; }
aside .shiftToggle .shiftb {
	background: #54b6a9;
	box-shadow: none;
	font-size: 13px;
    margin-right: 10px;
	padding: 0 10px;
	width: auto;
}
aside .shiftToggle .shiftb:hover,
aside .shiftToggle .shiftb:active { background: #35a289; }

aside .shiftToggle .shiftb.finish { background: #e73535; }
aside .shiftToggle .shiftb.finish:hover,
aside .shiftToggle .shiftb.finish:active { background: #c01d1d; }
aside .shiftToggle .shiftb i { background: url(./images/icons.svg) -288px -224px; margin: 0; }

aside .shiftToggle .date { display: inline-block; }

aside.close .shiftToggle .shiftb { margin: 0 -10px; padding: 0 8px; }


aside .menu { overflow: auto; }
aside .nav a { color: #adb0bc; line-height: 24px; padding: 12px; position: relative; }
aside .nav a:hover, aside .nav a:active, aside .nav a.active { color: #ffffff; }
aside .nav a i {
	display: inline-block;
	height: 24px;
	margin-right: 10px;
	vertical-align: middle;
	width: 24px;
}

aside .nav a.CheckList i 	{ background: url(images/icons.svg) 0 -80px; }
aside .nav a.CourierTaxes i 	{ background: url(images/icons.svg) -24px -80px; }
aside .nav a.Staff i 		{ background: url(images/icons.svg) -48px -80px; }
aside .nav a.StaffFood i { background: url(images/icons.svg) -72px -80px; }
aside .nav a.Movement i 	{ background: url(images/icons.svg) -96px -80px; }
aside .nav a.StopList i 	{ background: url(images/icons.svg) -120px -80px; }
aside .nav a.Documents i 	{ background: url(images/icons.svg) -144px -80px; }
aside .nav a.Reports i 		{ background: url(images/icons.svg) -168px -80px; }
aside .nav a.FAQ i 			{ background: url(images/icons.svg) -192px -80px; }
aside .nav a.Algorithms i 	{ background: url(images/icons.svg) -216px -80px; }

aside .nav .dropdown-toggle::after {
	border: 5px solid transparent;
	border-top-color: #adb0bc;
	content: "";
	position: absolute;
	top: 21px;
	right: 20px;
}
aside .nav .show .dropdown-toggle::after {
	border: 5px solid transparent;
	border-bottom-color: #adb0bc;
	top: 16px;
}

aside .nav .dropdown-menu { padding: 0 0 0 50px; display: none; }
aside .nav .dropdown-menu.show { display: block; position: static !important; transform: none !important; }
aside .nav .dropdown-menu a { padding: 3px 12px; text-transform: none; }
aside .nav .dropdown-menu .subGroup { margin-bottom: 5px; }

aside.close .nav a { padding: 12px 13px; }
aside.close .nav a span { display: none; }

/* --- Header --- */
header {
	background: #ffffff;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
	/* margin-left: 260px; */
	height: 70px;
	position: fixed;
	left: 50px;
	right: 0;
	z-index: 3;
}

header .menu {
	box-sizing: border-box;
	float: left; 
	height: 70px;
	padding: 0 0 0 10px;
	position: relative;
}
header .menu .arrow {
	background: #FdFeFf;
	box-sizing: border-box;
	display: block;
	height: 70px;
	text-align: center;
	padding: 33px 0;
	position: absolute;
	top: 0;
	width: 20px;
	z-index: 5;
}
header .menu .arrow i {
	border: 10px solid transparent;
	display: block;
	height: 0;
	width: 0;
}
header .menu .arrow.prev { border-right: 1px solid #d9dade; left: 0; }
header .menu .arrow.prev i { border-right-color: #d9dade; }

header .menu .arrow.prev:hover,
header .menu .arrow.prev:active { border-right-color: #54b6a9; }
header .menu .arrow.prev:hover i,
header .menu .arrow.prev:active i { border-right-color: #54b6a9; }

header .menu .arrow.next { border-left: 1px solid #d9dade; right: 0; }
header .menu .arrow.next i { border-left-color: #d9dade; }

header .menu .arrow.next:hover,
header .menu .arrow.next:active { border-left: 1px solid #54b6a9; right: 0; }
header .menu .arrow.next:hover i,
header .menu .arrow.next:active i { border-left-color: #54b6a9; }

header .nav { width: max-content; }

header .scrollable .nav { position: relative; transition: left 0.2s linear; }

header .nav .nav-item { display: inline-block; position: relative; }
header .nav a, header .nav .title {
	box-sizing: border-box;
	color: #68696c;
	font-size: 10px;
	font-family: "Roboto_Medium", Arial, Helvetica, sans-serif;
	text-align: center;
	padding: 5px 11px;
}
header .nav a i { 
	background: url(images/icons.svg) 0 0; 
	filter: brightness(0) invert(0.4);
	display: block; 
	height: 35px; 
	margin: 0 auto 4px; 
	width: 35px; 
}
header .nav a.Orders i 		{ background-position: 0 0; }
header .nav a.CallCentre i 	{ background-position: -35px 0; }
header .nav a.Production i 	{ background-position: -70px 0; }
header .nav a.Staff i 		{ background-position: -105px 0; }
header .nav a.Directory i 	{ background-position: -140px 0; }
header .nav a.Store i 		{ background-position: -175px 0; }
header .nav a.Money i 		{ background-position: -210px 0; }
header .nav a.Management i 	{ background-position: -245px 0; }
header .nav a.Statistics i 	{ background-position: -280px 0; }
header .nav a.Settings i 	{ background-position: -315px 0; }
header .nav a.Site i 		{ background-position: -351px 0; }

header .nav a:hover i, header .nav a:active i, header .nav a.active i { filter: none; }
header .nav a:hover, header .nav a.active { color: #54b6a9; }

header .dropdown-menu { position: absolute; display: none; z-index: 5; min-width: 150px; text-align: left; }
header .dropdown-menu.show { display: block; transform: translate3d(0,70px,0) !important; }

header .dropdown-menu .covermenu {
	background: #FFFFFF;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
	margin-top: 3px;
	padding: 5px 0;
	position: relative;
}
header .dropdown-menu .covermenu.cols { column-count: 2; column-rule: 1px solid #f1f2f5; }
header .dropdown-menu .covermenu:after {
	border: 7px solid transparent;
    border-bottom: 5px solid #FFF;
    content: "";
    margin-left: -5px;
    position: absolute;
    top: -12px;
    left: 40px;
}

header .dropdown-menu .covermenu .subGroup {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}
header .dropdown-menu button { display: block; }
header .dropdown-menu a, header .dropdown-menu .title {
	color: #54b6a9;
	font-size: 12px;
	float: none;
	padding: 3px 15px;
	text-transform: none;
	text-align: left;
	white-space: nowrap;
}
header .dropdown-menu a:hover, header .dropdown-menu a:active, header .dropdown-menu a.active { color: #267469; }
header .dropdown-menu a.subitem {
	color: #6d6d6d;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	padding-left: 30px;
}
header .dropdown-menu a.subitem:hover, header .dropdown-menu a.subitem:active { color: #252525; }

header .adds { 
	background: #ffffff;
	float: right; 
	max-height: 70px;
	position: relative;
	z-index: 1;
}
header .adds > div { border-left: 1px solid #d9dade; float: left; }

header .location {
	line-height: 70px;
	height: 100%;
	position: relative;
}
header .location .dropdown { padding: 0 20px; position: relative; }

header .location button {
	background: transparent;
	box-shadow: none;
	color: #68696c;
	cursor: default;
	font-size: 12px;
	padding: 0;
	text-align: left;
	text-transform: none;
	width: 100%;
}

header .location .dropdown .dropdown-menu {
	background: #FFFFFF;
	border-radius: 0 0 3px 3px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: none;
	padding: 5px 10px;
	position: absolute;
	top: 70px !important;
	left: 0 !important;
	width: 100%;
	min-width: auto;
	transform: none !important;
	z-index: 1;
}
header .location .dropdown .dropdown-menu.show { display: block; }
header .location .dropdown .dropdown-menu button { cursor: pointer; line-height: 25px; }
header .location .dropdown .dropdown-menu button:hover,
header .location .dropdown .dropdown-menu button:active { color: #343844; }


header .location span { display: inline-block; white-space: nowrap; }
header .location i {
	background: url(images/icons.svg) -192px -128px;
	display: inline-block;
	margin-right: 5px;
	height: 24px;
	vertical-align: middle;
	width: 24px;
}

header .adds .user { 
	box-sizing: border-box; 
	float: right; 
	padding: 5px 55px 5px 20px; 
	position: relative;
	z-index: 1;
}
header .user .photo {
	background: #d9dade;
	background-position: center;
	background-size: cover; 
	border-radius: 50%;
	display: inline-block;
	height: 60px;
	margin-right: 10px;
	vertical-align: middle;
	width: 60px;
}

header .user .userName { display: inline-block; vertical-align: middle; max-width: 100%; overflow: hidden; }
/* header .user .userName a { color: #535663; } */
header .user .userName p { font-size: 11px; }
header .user .userName a {
	color: #343844;
	font: 11px/23px "Roboto_Medium", Arial, sans-serif;
	text-transform: uppercase;
	white-space: nowrap;
}

header .logout {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 24px;
	z-index: 2;
}
header .logout i { 
	background: url(images/icons.svg) -312px -152px;
	display: block;
	filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
}
header .logout:hover i,
header .logout:active i { filter: none; }

@media (max-width: 1500px) {
	header .user .photo { display: none; }
	header .adds .user { padding: 14px 55px 14px 20px; }
}

@media (max-width: 1420px) {
	header .user .position { display: none; }
	header .adds { width: 150px; }
	header .adds .user { padding: 7px 30px 2px 15px; width: 100%; }
	header .location { line-height: 38px; }
	header .location .dropdown { padding: 0 15px; }
	header .adds > div { float: none; }
}

@media (max-width: 1270px) {
	/* header .adds { display: none; } */
}

/* --- COMMON PAGE --- */
.page { position: relative; min-height: 300px; height: 100%; }
main { margin-left: 260px; padding: 85px 20px 20px; position: relative; z-index: 0; }
@media (max-width: 1280px) {
	main { padding: 85px 15px 15px; }
}
@media (max-width: 1030px) {
	main { padding: 85px 10px 10px; }
}

main .page .head { margin-bottom: 10px; position: relative; display: flex; justify-content: space-between; align-items: center; }
/* main .page h2 { display: inline-block; line-height: 35px; margin-right: 40px; vertical-align: middle; }
main .page button { float: right; } */

main .page .head .toggleActive, main .page .head .toggleTask {
	border-bottom: 1px dotted #5b6276;
	color: #5b6276;
	display: inline-block;
	font-size: 12px;
	line-height: 17px;
	margin-left: 35px;
	margin-top: -3px;
	text-transform: none;
	vertical-align: middle;
}
main .page .head .toggleActive:hover, main .page .head .toggleTask:active {
	border-color: var(--green);
	color: var(--green);
}

main .page .toggleFilter { position: absolute; top: 5px; left: 230px; }
main .page .toggleFilter i {
	background: url(images/icons.svg) no-repeat -360px -128px;
	display: block;
	height: 24px;
	width: 24px;
}
main .page .toggleFilter:hover i,
main .page .toggleFilter.active i,
main .page .toggleFilter:active i, #CheckListReport .toggleFilter.active i { background-position-x: -384px; }


main .page .filters .form-row {
	float: left;
	margin-bottom: 10px;
	padding-right: 20px;
	width: 33.3%;
}
main .page .filters .checkGroupLabel { width: 100%; }
main .page .filters button { line-height: 32px; }
main .page .head .toggle {
	color: #71747e;
	display: block;
	position: absolute;
	top: 12px;
	right: 0;
}
main .page .head .toggle i {
	background: url(images/icons.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 10px;
	vertical-align: middle;
	width: 24px;
}
main .page .close .head .toggle i { background-position: -240px -128px; }

main .page .table button {
	background: transparent;
	box-shadow: none;
	float: none;
	line-height: 24px;
	padding: 0;
	text-align: center;
}
main .page .table button i { margin: 0; }
main .page .table .notactive { color :#c8c9cd; }

/* main .page .item .head { margin-bottom: 10px; } */
/* --- item page --- */
main .page .item.flex-page {

}


main .page .item form > .head {
	position: fixed;
    top: 70px;
    z-index: 4;
    right: 0;
    left: 260px;
    background: #f5f6fb;
	padding: 15px 20px 10px;
	margin: 0;
}

@media (max-width: 1280px) {
	main .page .item form > .head { padding: 15px 15px 10px; }
}
@media (max-width: 1030px) {
	main .page .item form > .head { padding: 15px 10px 10px; }
}

main.tablet .page .item form > .head { top :0; }



main .page .item .head .button, 
main .page .item .head button { margin-right: 10px; float: none; }
main .page .item .head button { width: 120px; }
main .page .item .head .error, main .page .item .head .success { display: inline-block; }

main .page .item .section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	padding: 15px 20px;
	margin-top: 55px;
}
main .page .item .section .gray { background: #f8f9fb; }
main .page .item .section .gray input { background: #ffffff; }
main .page .item .section .gray input:disabled { background: #EEEEEE; }
main .page .item .section .gray label { z-index: 1; }

/* main .page .item .section */

main .page .item .backblock { margin-top: 40px; }
main .page .item .backblock + .section { margin-top: 15px; }
/* main .page .item .backblock .col { margin: 0; padding-right: 20px; } */
main .page .item .backblock input { background: #FFFFFF; }
main .page .item .backblock input:disabled { background: #EEEEEE; }
main .page .item .backblock label { z-index: 1; }

main .page .item .backblock.fixed { 
	background: #f5f6fb;
	margin: 0;
	padding: 0 20px 10px;
	position: fixed;
	top: 130px;
	right: 0;
	z-index: 3;
}
@media (max-width: 1280px) {
	main .page .item .backblock.fixed { padding: 0 15px 10px; }
}
@media (max-width: 1030px) {
	main .page .item .backblock.fixed { padding: 0 10px 10px; }
}


@media (max-width: 1280px) {
	main .page .item form > .head { padding: 15px 15px 10px; }
}

main .page .item h4 { margin-bottom: 15px; }
main .page .block { margin-top: 15px; border-top: 1px solid #dddddd; padding-top: 10px; }
main .page .block:first-of-type { margin-top: 0; border: none; padding-top: 0; }
main .page .block:last-child { padding-bottom: 20px; }
/* main .page .block .col { padding-right: 20px; } */

main .page .item h5 { line-height: 21px; font-size: 11px; }

main .page .item.two-cols .section > .clearfix { overflow: hidden; min-height: 100%; }
main .page .item.two-cols .section > .clearfix > .col { padding: 15px 20px 99999px; margin-bottom: -99999px; }

main .page .item .section { padding: 0; overflow: auto; height: auto !important; }
main .page .item .section > .col { padding: 15px 20px; }
main .page .item .section .checkGroupLabel { width: 100%; max-width: 100%; }

main .page .item .rights .checkAll .form-row { margin-top: 0; text-align: right; padding: 0; }
main .page .item .rights .toggle { position: absolute; top: 10px; right: 0; }
main .page .item .rights .block:first-of-type .toggle { top: 0; }
main .page .item .rights h5 { text-transform: uppercase; }

main .page .item .dashboards .head h4 { float: left; }
main .page .item .dashboards .head .checkAll { float: right; margin-top: -5px; }
main .page .item .dashboards .head .checkAll .form-row { margin: 0; }

main .page .item .dashboards .Sidebar { border-right: 1px solid #dddddd; }
main .page .item .dashboards .Widget { padding-left: 20px; }

main .page .item .dashboards .row { margin-bottom: 15px; }
main .page .item .dashboards .row .col { margin: 0; }
main .page .item .dashboards .row .toggleRow { 
	color: #787c86; 
	display: block;
	float: right; 
	font-size: 12px;
	line-height: 24px;
	margin-bottom: 10px; 
}
main .page .item .dashboards .row .toggleRow i { 
	background: url(images/icons.svg) no-repeat;
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	vertical-align: middle;
	width: 24px;
}
main .page .item .dashboards .row.open .toggleRow i { background-position: -216px -128px; }
main .page .item .dashboards .row.close { margin: 0; }
main .page .item .dashboards .row.close .toggleRow i { background-position: -240px -128px; }

main .page .item .dashboards .row.close .checkGroupLabel { display: none; }

main .page .section .titles span { 
	color: #9f9f9f;
	text-transform: uppercase; 
	font-size: 10px;     
	white-space: nowrap;
    text-overflow: ellipsis;
	overflow: hidden; 
}

/* --- ROLES PAGE --- */
#RoleItem .mainInfo { display: flex; align-items: flex-end; }


#RolesPage .confirmBlock .form-row { margin-bottom: 20px;}

#RolesPage .backblock .button { margin-top: 19px; line-height: 32px; }

#RoleItem .section label:not(.checkGroupLabel) { color: #5e626d; font-size: 13px; text-transform: uppercase; }

/* --- USERS PAGE --- */
#UsersPage .table .button.shift {
	padding: 0 25px;
	line-height: 30px;
}
#UsersPage .table .button.green { background: #54b6a9; }
#UsersPage .table .button.red { background: #e73535; }
#UsersPage .table .button.yellow { 
	background: #f2bc8b;
	background: -moz-linear-gradient(top, #f2bc8b 0%, #e28b50 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f2bc8b 0%,#e28b50 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f2bc8b 0%,#e28b50 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2bc8b', endColorstr='#e28b50',GradientType=0 ); /* IE6-9 */
}

#UsersPage .courierShift .checkGroupLabel { width: 100%; max-width: 100%; }
#UsersPage .courierShift .buttons { float: none; }
#UsersPage .courierShift .error { margin: 10px 0; font-size: 13px; }

#UserItem .main > div { display: flex; justify-content: space-between; }
#UserItem .main .form-row { margin-right: 20px; flex-grow: 1; }
#UserItem .main .reset {justify-content: right; margin-bottom: 10px;}
#UserItem .main .tips { flex-direction: column;}

#UserItem .roles { padding: 0; }
#UserItem .roles .checkGroupLabel { width: 50%; }
#UserItem .roles .form-row + .form-row { margin-top: 40px; }
#UserItem .roles .comment { margin: 10px 0 5px; }

#UserItem .filials .head{ margin: 0; }
#UserItem .filials .head h4 { float: left; }
#UserItem .filials .head .checkAll { float: right; margin-top: -5px; }
#UserItem .filials .head .form-row { margin: 0; width: auto; }
#UserItem .filials .head + .block { border: none; }
#UserItem .filials .block { display: flex; flex-flow: wrap; }
#UserItem .filials .block .head { width: 100%; }

#UserItem .filials h5 { color: #9699a7; }
#UserItem .filials .cassa { margin-left: 20px; }

#UserItem .filials .filial { margin-bottom: 20px; }

#UserItem .dashboards { border-top: 1px solid #dddddd; }

#UserItem .section .dashboards label:not(.checkGroupLabel) { color: #5e626d; font-size: 13px; text-transform: uppercase; }

/* --- FILIALS PAGE --- */
#FilialItem h4 { margin: 0; }
#FilialItem .section {
	display: flex;
	flex-direction: column;
}
#FilialItem .section .mainInfo {
	display: flex;
	flex-direction: row;
}
#FilialItem .section .mainInfo > div { padding: 20px 15px 15px; width: 60%;}
#FilialItem .section .mainInfo .workdays { 
	/* border-radius: 0 3px 3px 0;  */
	width: 40%;
}
#FilialItem .section .mainInfo .workdays .comment { margin: 15px 0 35px;}
/* #FilialItem .workdays h4 { margin: 22px 0 20px; } */
#FilialItem .workdays .shift { width: 35%; }
#FilialItem .workdays .head {
	display: flex;
    justify-content: space-between;
    margin: 15px 0 5px;
}
#FilialItem .workdays .head h5 { flex-grow: 1; }
#FilialItem .workdays .head a { margin: 0 10px; font-size: 10px; }

/* #FilialItem .workdays label { z-index: 1; } */

#FilialItem .workdays .day {
	display: flex;
	flex-wrap: wrap;
	justify-content: right;
	 margin-top: 5px;
}
#FilialItem .workdays .day .form-row { width: 60%;  margin: 0; }
#FilialItem .workdays .day .form-row:first-child { width: 40%; }
#FilialItem .workdays .day a { font-size: 10px; margin-bottom: 10px; }


/* #FilialItem.two-cols .section > .clearfix { min-height: auto; } */

#FilialItem .poses { border-top: 1px solid #dddddd; margin: 0; padding: 20px; }
#FilialItem .poses .pos { border-top: 1px solid #dddddd; padding: 10px 0; } 
#FilialItem .poses .pos:first-of-type { border: none; } 

#FilialItem .pos .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: 20px;
}
#FilialItem .pos .remove i { margin: 0; }

#FilialItem .add { margin-top: 25px; }



/* --- STOCKS PAGE --- */
#StockItem .section { padding: 20px; }
#StockItem .section .col { padding-right: 10px; }

#StockItem .section .toggle {
	background: none;
	box-shadow: none;
	color: #5e626d;
	display: block;
	float: right;
    text-transform: none;
    font-size: 12px;
}
#StockItem .section .toggle i {
	background: url(images/icons.svg) no-repeat -218px -128px;
	display: inline-block;
	height: 24px;
	margin-left: 5px;
	vertical-align: middle;
	width: 24px;
}
#StockItem .section .toggle.close i { background-position: -240px -128px; }

#StockItem .section .title { background: #f5f6fb; margin: 5px -20px; padding: 5px 20px; }
#StockItem .section .title .remove {
	background: transparent;
	color: #5e626d;
	font-size: 12px;
	padding: 0;
	position: absolute;
	top: 25px;
	right: 20px;
}

#StockItem .section .item { padding-left: 20px; }
#StockItem .section .group .add { margin: 10px 0 10px 20px; }

#StockItem .semimanufactures .col { padding-right: 10px; }
#StockItem .semimanufactures .remove { right: 10px; }
#StockItem .semimanufactures .checkGroupLabel { width: auto;  margin-right: 20px; }

#StockItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: 20px;
}
#StockItem .remove i { margin: 0; }

#StockItem .section .add { margin-top: 20px; line-height: 32px; }

/* --- PROVIDERS PAGE --- */
#ProviderItem .backblock .suggest { margin-top: -2px; }
/* #ProviderItem .dadata .react-dadata { width: 90%; } */
#ProviderItem .dadata a.close {
	background: none;
	box-shadow: none;
	padding: 0;
	position: absolute;
	top: -4px;
	right: 0;
}
#ProviderItem .dadata a.close i { background: url(images/icons.svg) -168px -128px; }

#ProviderItem .requisites .dadata .react-dadata__suggestions {
	max-height: 250px;
}

#ProviderItem .day .form-row { float: left; margin-top: 5px; width: 70%; }
#ProviderItem .day .form-row:first-child { width: 30%; }

#ProviderItem .contacts .contact { margin-top: 40px; position: relative; }
#ProviderItem .contacts .contact:first-of-type { margin-top: 30px; }
#ProviderItem .contacts .contact .form-input { width: 90%; }

#ProviderItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: -5px;
	right: 0;
}
#ProviderItem .remove i { margin: 0; }

#ProviderItem .add { margin-top: 20px; line-height: 32px; }

#ProviderItem .products .form-row { padding-right: 10px; }
#ProviderItem .products .remove { right: -16px; top: 17px; }
#ProviderItem .products .select__menu-list { max-height: 100px; overflow: auto; }
#ProviderItem .products .product:last-of-type .select__menu-list { max-height: 70px; }

#ProviderItem .workdays .periodTime input { width: 70px; }

#ProviderItem .history .order { border-top: 1px solid #dddddd; padding: 5px 0; }
#ProviderItem .history .order:first-of-type { border: none; }
#ProviderItem .history .order .col { 
	overflow: hidden;
	padding-right: 10px; 
	text-overflow: ellipsis;
	white-space: nowrap;
}

#ProviderItem .history .order i {
	background: #dddddd;
	border-radius: 50%;
	display: inline-block;
	height: 8px;
	margin-right: 8px;
	width: 8px;
}

#ProviderItem .history .order i.success { background: #54b6a9; }
#ProviderItem .history .order i.ready { background: #f4bf3b; }

#ProviderItem .history .toggle { margin-top: 10px; display: inline-block; float: right; }





/* --- CHECK LIST MANAGE PAGE --- */
#CheckListManagePage > section > h2 { margin-top: 30px; }
#CheckListManagePage .head .buttons { display: flex; gap: 20px; }

#CheckListItem form {display: flex; flex-direction: column; gap: 20px; }
#CheckListItem .section { padding: 15px 20px; display: flex; flex-direction: column; margin-top: 0; }
@media (max-width: 1030px) {
	#CheckListItem .section { padding: 10px 15px; }
	#CheckListManagePage .head { flex-direction: column; align-items: start; }
}
@media (max-width: 700px) {
	#CheckListItem .section { padding: 5px 10px; }
}

#CheckListItem .section.main {
	flex-direction: row;
	gap: 20px;
	margin-top: 55px;
}
#CheckListItem .section.main > div { width: 40%;}
#CheckListItem .section.main > div:first-child { width: 60%;}

#CheckListItem .button.iconSvg { width: max-content; }

#CheckListItem .grouptask { display: flex; flex-direction: column; gap: 10px; padding: 0; }
#CheckListItem .grouptitle { 
	background: var(--main-light-gray); 
	padding: 10px 20px; 
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#CheckListItem .grouptitle > *:first-child { width: 65%; }

#CheckListItem .grouptitle input { background: #ffffff; }
#CheckListItem .grouptitle h4 { margin: 0; }

#CheckListItem .grouptask .button.iconSvg { margin: 0 20px 20px; }

#CheckListItem .tasks { 
	display: grid;
	grid-template-columns: 35% auto 20% 25px;
	grid-column-gap: 2%;
    grid-row-gap: 25px;
	padding: 0 20px;
}
#CheckListItem .tasks.simple { grid-template-columns: 95% 3%; }
#CheckListItem .tasks h5 { text-transform: uppercase; }
#CheckListItem .tasks textarea { height: 90px; }

#CheckListItem .tasks .periodSettins { display: flex; gap: 20px;}
#CheckListItem .tasks .periodSettins > div { width: 50%; display: flex; flex-direction: column; gap: 10px;}

#CheckListItem .tasks .actions a { display: block; margin-bottom: 10px;}
#CheckListItem .tasks .week .checkGroupLabel { width: auto; margin-right: 5px; }

/* --- CHECK LIST EXECUTION PAGE --- */
#CheckListPage .taskStat > div { display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px; }
#CheckListPage .taskStat .stat {
	/* margin : 20px 0; */
	height: 20px;
	border-radius: 10px;
	background: var(--dark-gray);
	width: 500px;
	display: flex;
	position: relative;
	overflow: hidden;
}
#CheckListPage .taskStat .stat > div { position: relative; }
#CheckListPage .taskStat .stat .done { background: var(--green); }
#CheckListPage .taskStat .stat .expired { background: var(--red); }

#CheckListPage .taskStat .stat span { font-size: 10px; position: absolute; right: 6px; line-height: 20px; color: var(--white); }

#CheckListPage.mobile .taskStat > div:not(:first-child) { flex-direction: column; align-items: flex-start; }
#CheckListPage.mobile .taskStat .stat { width: 100%; margin-bottom: 5px; }

#CheckListPage .taskStat .filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 10px;
	width: 45%;
}
#CheckListPage .taskStat .filter > div { width: 45%; margin: 0; }

#CheckListPage .tasksList {
	margin-top: 20px;
	display: grid;
	grid-template-columns : 62% 18% 18%;
	grid-column-gap: 1%;
	grid-row-gap: 5px;
}
#CheckListPage .tasksList .title { display: inline-grid; grid-column-end: span 3; }
#CheckListPage .tasksList .name,
#CheckListPage .tasksList .time {
	background: #ffffff;
	border-radius: 3px;
	line-height: 35px;
	min-height: 35px;
	padding: 0 20px;
}

#CheckListPage .tasksList .name { 
	border-left: 3px solid var(--dark-gray); 
	display: flex;
	justify-content: space-between;
}
#CheckListPage .tasksList .name .iconSvg { height: 24px; margin-top: 5px; position: relative; }
#CheckListPage .tasksList .name.rejected .iconSvg::after {
	background: var(--red);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	content: "";
	position: absolute;
	right: 0;
}
#CheckListPage .tasksList .name i { margin-right: 5px; }
#CheckListPage .tasksList .name i svg { width: 16px; height: 16px; }
#CheckListPage .tasksList .time { text-align: center; } 

#CheckListPage .tasksList .name.done { border-left: 3px solid var(--green); background: var(--green10); }
#CheckListPage .tasksList .name.done i svg * { fill: var(--green); }
#CheckListPage .tasksList .name.expired,
#CheckListPage .tasksList .name.rejected { border-left: 3px solid var(--red); background: var(--red10) }
#CheckListPage .tasksList .name.rejected i svg * { fill: var(--red); }
#CheckListPage .tasksList .name.disabled { background: var(--dark-gray10); }
#CheckListPage .tasksList .name.oncheck { background: var(--yellow10); border-left: 3px solid var(--yellow); }
#CheckListPage .tasksList .name.oncheck i svg * { fill: var(--yellow); }

#CheckListPage .tasksList .history {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	width: 62%;
	background: var(--white);
	padding: 10px 20px;
	box-sizing: border-box;
	flex-direction: column;
	gap: 10px;
}
#CheckListPage .tasksList .history h5 {
	text-transform: uppercase;
	font-size: 11px;
}
#CheckListPage .tasksList .history > div { 
	width: 70%;
	display: flex;
    flex-direction: column;
    gap: 5px;
}
#CheckListPage .tasksList .history .author { 
	font-size: 10px;
    color: var(--textGray);
    line-height: 14px;
}
#CheckListPage .tasksList .history .message {
	box-sizing: border-box;
	padding: 5px;
	background: var(--main-light-gray);
	border-radius: 10px;
}
#CheckListPage .tasksList .history .message.green { background: var(--green10);}
#CheckListPage .tasksList .history .message.red { background: var(--red10);}
#CheckListPage .tasksList .history .right { justify-self: right;}
#CheckListPage .tasksList .history .right .author { text-align: right;}
#CheckListPage .tasksList .history .message.photo {
	display: grid;
	gap: 20px;
}
#CheckListPage .tasksList .history .message.photo img { max-height: 40px;}

#CheckListPage #TaskListPhotoExecusion {
	width: 600px;
	max-height: 70vh;
	overflow: auto;
	position: relative;
}
#CheckListPage #TaskListPhotoExecusion form {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
#CheckListPage #TaskListPhotoExecusion p { margin: 0; }
#CheckListPage #TaskListPhotoExecusion .photos { display: flex; gap: 20px; }
#CheckListPage #TaskListPhotoExecusion .photos > div { width: 30%; }
#CheckListPage #TaskListPhotoExecusion .buttons { margin: 0;}

#TaskPhotoExecusion { width: fit-content; max-width: 58%; padding: 10px; }
#TaskPhotoExecusion img { max-height: 70vh; max-width: 100%; }
#TaskPhotoExecusion a {
	color: var(--white);
	display: block;
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	width: 100px;
	right: -120px;
}


#CheckListPage.mobile .tasksList { grid-template-columns : 79% 20%; }
#CheckListPage.mobile .tasksList .title { grid-column-end: span 2; }
#CheckListPage.mobile .tasksList button svg { vertical-align: text-bottom; }

#CheckListPage .confirmBlock .checklists { display: flex; flex-direction: column; }
#CheckListPage .confirmBlock .checklists button { float: none; margin: 10px 0; }

@media (max-width: 1030px) {
	#CheckListPage .tasksList .name,
	#CheckListPage .tasksList .time { padding: 0 10px;}
}

@media (max-width: 980px) {
	#CheckListPage .taskStat .stat { height: 30px; border-radius: 15px;}
	#CheckListPage .taskStat .stat span { line-height: 30px;}
}



/* --- CHECK LIST EXECUTE WIDGET --- */

#TaskListWidget .stat {
	margin : 10px 0;
	height: 12px;
	border-radius: 6px;
	background: var(--dark-gray);
	display: flex;
	overflow: hidden;
}
#TaskListWidget .stat .done { background: var(--green); }
#TaskListWidget .stat .expired { background: var(--red); }

#TaskListWidget .list {
	display: grid;
	grid-template-columns: 77% 20%;
	row-gap: 5px;
	column-gap: 3%;
}
#TaskListWidget .list > * { line-height: 18px; padding: 3px 0;}
#TaskListWidget .list button svg { vertical-align: text-bottom; }
#TaskListWidget .list button.red { background-color: var(--red);}

#TaskListWidget .comment { margin-top: 20px; }

@media (max-width: 980px) {
	#TaskListWidget .stat { margin: 15px 0 30px; }
	#TaskListWidget .list { row-gap: 10px; }
	#TaskListWidget .list .name { line-height: 40px;}
}

#CheckListWidget {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
#DashboardPage #CheckListWidget h4 { margin: 0; }
#CheckListWidget .button { text-align: center; }

#CheckListReportWidget { display: flex; flex-direction: column; gap: 15px; }
#DashboardPage #CheckListReportWidget h4 { margin: 0; }
#CheckListReportWidget .tasksStat {
	height: 100px;
	overflow: hidden;
	position: relative;
}
#CheckListReportWidget .pie {
	border-radius: 50%;
	background: conic-gradient(var(--green) 0.00% 13%, var(--white) 13% 13.4%, var(--yellow) 13.4% 23%, var(--white) 23% 23.4%, var(--red) 23.4% );
	height: 200px;
	margin: auto;
	transform: rotate(-90deg);
	position: relative;
	width: 200px;
}
#CheckListReportWidget .pie::after {
	background: var(--white);
	border-radius: 50%;
	content: "";
	position: absolute;
	width: 90px;
	height: 90px;
	top: 55px;
	left: 55px;
}
#CheckListReportWidget .tasksStat > a { position: absolute; top: 0; left: 0; }
#CheckListReportWidget .tasksStat > a:hover + .legend { display: block;}
#CheckListReportWidget .tasksStat .legend {
	background: var(--white);
	border-radius: 4px;
	box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.3);
	font-size: 11px;
	position: absolute;
	top: 0;
	left: 35px;
	padding: 5px 10px;
	display: none;
}
#CheckListReportWidget .tasksStat .legend::after {
	background: var(--white);
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	top: 7px;
	left: -5px;
	transform: rotate(45deg);
}

#CheckListReportWidget .userStat { display: flex; justify-content: space-between; gap: 20px; }
#CheckListReportWidget .userStat > * {
	display: flex;
	gap: 5px;
	width: 50%;
	overflow: hidden;
	font-size: 12px;
	line-height: 16px;
}

#CheckListReportWidget .userStat span.icon {
	background-color: var(--red);
	background-size: cover;
	background-position: center;
	border: 2px solid var(--red);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 31px;
	width: 31px;
	min-width: 31px;
}
#CheckListReportWidget .userStat > div > div > span { display: block; overflow: hidden; white-space: nowrap; }

#CheckListReportWidget .userStat .title {
	color: var(--textGray);
	font-size: 8px;
	line-height: 13px;
	text-transform: uppercase;
}
#CheckListReportWidget .userStat .count { font-weight: 900; font-size: 15px; }

#CheckListReportWidget .userStat .icon svg * { fill: var(--white); }

#CheckListReportWidget .userStat .bestEmployee span.icon { background-color: var(--green); border-color: var(--green); }
#CheckListReportWidget .userStat .worthEmployee span.icon svg { transform: rotate(180deg);}

#CheckListReportWidget > img { height: 110px; }

/* --- CHECK LIST REPORT PAGE --- */
#CheckListReport { display: flex; flex-direction: column; gap: 10px; }

#CheckListReport .head { flex-direction: column; align-items: normal; margin: 0; }
#CheckListReport .head > div:first-child {display: flex; justify-content: space-between; width: 100%; }
#CheckListReport .head .filter { padding: 5px 10px; margin-right: 15px; border-radius: 5px 5px 0 0; }
#CheckListReport .head .filter.active { background: var(--white); }

#CheckListReport .head .filterBlock {
	background: var(--white);
	border-radius: 10px;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 20px;
}
#CheckListReport .head .filterBlock > div:first-child { flex-grow: 1; }
#CheckListReport .head .filterBlock .lists { display: flex; justify-content: space-between; align-items: flex-start; gap: 20px; }
#CheckListReport .head .filterBlock .lists > * { width: 25%;}
#CheckListReport .head .filterBlock > button { float: none; margin-top: 16px; }

#CheckListReport .statBlock .statistic { display: flex; justify-content: space-between; gap: 10px; }
#CheckListReport .statBlock .statistic > * {
	background: var(--white);
	border-radius: 10px;
	box-sizing: border-box;
	display: flex;
	padding: 15px;
	width: 33%;
	position: relative;
}

#CheckListReport .statBlock .statistic span.icon {
	background-color: var(--dark-gray);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-right: 15px;
	width: 40px;
}
#CheckListReport .statBlock .statistic span.icon svg { width: 24px; }
#CheckListReport .statBlock .statistic span.icon.photo {
	background-size: cover;
	background-position: center;
	border: 2px solid var(--red);
	height: 48px;
	width: 48px;
}

#CheckListReport .statBlock .statistic .title {
	color: var(--textGray);
	font-size: 9px;
	line-height: 16px;
	text-transform: uppercase;
	display: block;
}
#CheckListReport .statBlock .statistic .count { display: block; font-weight: 900; font-size: 20px; }

#CheckListReport .statBlock .statistic .done .icon { background-color: var(--green10); }
#CheckListReport .statBlock .statistic .done .icon svg * { fill: var(--green); }
#CheckListReport .statBlock .statistic .expired .icon { background-color: var(--yellow10); }
#CheckListReport .statBlock .statistic .expired .icon svg * { fill: var(--yellow); }
#CheckListReport .statBlock .statistic .failed .icon { background-color: var(--red10); }
#CheckListReport .statBlock .statistic .failed .icon svg * { fill: var(--red); }

#CheckListReport .statBlock .statistic.rate {
	margin-top: 10px;
}
#CheckListReport .statBlock .statistic.rate > * {
	align-items: center;
	width: 25%;
} 
#CheckListReport .statBlock .statistic.rate span.icon {border-radius: 50%;}
#CheckListReport .statBlock .statistic.rate span.icon { background-color: var(--red); }
#CheckListReport .statBlock .statistic.rate span.icon svg * { fill: var(--white); }

#CheckListReport .statBlock .statistic .bestEmployee span.icon { background-color: var(--green); border-color: var(--green); }
#CheckListReport .statBlock .statistic .worthEmployee span.icon svg { transform: rotate(180deg);}

#CheckListReport .statBlock .statistic a.iconSvg {
	position: absolute;
	top: 5px;
	right: 10px;
}
#CheckListReport .statBlock .statistic a.iconSvg svg { width: 15px; }

@media (max-width: 1030px) {
	#CheckListReport .statBlock .statistic > * { padding: 10px; }
	#CheckListReport .statBlock .statistic .icon svg { width: 20px; } 
	#CheckListReport .statBlock .statistic .icon {
		height: 35px;
		margin-right: 5px;
		width: 35px;
	}
	#CheckListReport .statBlock .statistic .title { font-size: 8px; }
	#CheckListReport .statBlock .statistic .count { font-size: 16px; }
}

#CheckListReport .groups {
	display: grid;
	grid-template-columns: 9% 18% 18% 15% 13% 23% 4%;
	grid-row-gap: 2px;
}
#CheckListReport h5 { font-size: 12px; color: var(--textGray); padding: 0 7px; }
#CheckListReport .groups > h5:first-child { padding-left: 18px; }
#CheckListReport .groups > span { background: var(--white); padding: 8px 7px; }
#CheckListReport .groups > span.date { border-radius: 2px 0 0 2px; padding-left: 18px; }
#CheckListReport .groups > span.actions { border-radius: 0 2px 2px 0; }

#CheckListReport .groups > span.actions a { width: 20px; height: 20px; }
#CheckListReport .groups > span.actions a.active svg { transform: rotate(90deg); }

#CheckListReport .groups .stat {
	height: 20px;
	border-radius: 10px;
	background: var(--red);
	display: flex;
	position: relative;
	overflow: hidden;
}
#CheckListReport .groups .stat > div { position: relative; }
#CheckListReport .groups .stat .done { background: var(--green); }
#CheckListReport .groups .stat .expired { background: var(--yellow); }
#CheckListReport .groups .stat span { font-size: 10px; position: absolute; right: 6px; line-height: 20px; color: var(--white); font-weight: bold; }

@media (max-width: 1030px) {
	#CheckListReport .groups { grid-template-columns: 21% 22% 22% 35% }
	#CheckListReport .groups > h5:first-child { padding-left: 8px; }
	#CheckListReport .groups > span { line-height: 18px; }
	#CheckListReport .groups > span.date { padding-left: 8px; }
}

#CheckListReport .groups.control {
	grid-template-columns: 110px 10% 18% 25% auto 11% 40px;
}
#CheckListReport .groups.control .comment {
	display: block;
	line-height: 12px;
	margin: 5px 0 0;
}
#CheckListReport .groups.control .photos {
	display: flex;
	gap: 10px;
}
#CheckListReport .groups.control .photos img { max-height: 50px; cursor: pointer; }
#CheckListReport .groups.control .actions {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
#CheckListReport .groups.control .actions a { width: auto; height: auto; }

#CheckListReport .window.large { position: fixed;}
#CheckListReport .window .stat {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 5% 39% 14% 14% 14% 14%;
	grid-row-gap: 4px;
}
#CheckListReport .window .stat > span { padding: 0 7px; }
#CheckListReport .window .stat .comment { display: block; margin: -2px 0 5px;}

#CheckListReport .groups .tasks {
	background: var(--white); 
	grid-column-end: span 7;
	padding: 5px 25px 10px 40px;
	display: grid;
	grid-template-columns: auto 14% 13% 15% 15% 25px;
}
#CheckListReport .groups .tasks > span,
#CheckListReport .groups .tasks > div { padding: 8px 7px; }
#CheckListReport .groups .tasks span.icon { text-align: center; }
#CheckListReport .groups .tasks span svg { width: 16px; height: 16px; }
#CheckListReport .groups .tasks .icon svg *,
#CheckListReport .groups .tasks .intime svg * { fill: var(--green); }
#CheckListReport .groups .tasks .expired svg * { fill: var(--yellow); }
#CheckListReport .groups .tasks .done { display: flex; align-items: flex-start; gap: 10px; }
#CheckListReport .groups .tasks .photo { display: flex; gap: 20px; }
#CheckListReport .groups .tasks .photo img { max-height: 50px;}

#CheckListReport .groups .tasks .history {
	grid-column-start: 1;
	grid-column-end: 7;
	display: grid;
	width: 75%;
	gap: 10px;
}
#CheckListReport .groups .tasks .history h5 { text-transform: uppercase; font-size: 11px; margin: 0; }
#CheckListReport .groups .tasks .history > div { 
	width: 70%;
	display: flex;
    flex-direction: column;
    gap: 5px;
}
#CheckListReport .groups .tasks .history .author { 
	font-size: 10px;
    color: var(--textGray);
    line-height: 14px;
}
#CheckListReport .groups .tasks .history .message {
	box-sizing: border-box;
	padding: 5px;
	background: var(--main-light-gray);
	border-radius: 10px;
}
#CheckListReport .groups .tasks .history .message.green { background: var(--green10);}
#CheckListReport .groups .tasks .history .message.red { background: var(--red10);}
#CheckListReport .groups .tasks .history .right { justify-self: right;}
#CheckListReport .groups .tasks .history .right .author { text-align: right;}
/* #CheckListReport .groups .tasks .history .message.photo {
	display: flex;
	gap: 20px;
} */

#ReviewTaskWindow textarea { height: 120px; }

/* --- SHIFT REPORT PAGE --- */
#ShiftReport .head { margin: 0; }
#ShiftReport .listpage { margin-top: 10px; }

#ShiftReport .toggleFilter { left: 450px; }
#ShiftReport.mobile .toggleFilter { right: 10px; left: auto; }
#ShiftReport.mobile .filter .buttons { float: none; margin-top: 10px; }
/* #ShiftReport .filter .col { padding-right: 20px; } */

#ShiftReport .table span .comment { display: block; }

/* --- CASSA PAGE --- */
#CassaPage .head h2 { float: left; }
#CassaPage .head .openCassa { float: left; }
#CassaPage .head .openCassa button { float: left; margin-right: 20px; min-width: 100px; }
#CassaPage .head .openCassa form { float: left; }
#CassaPage .head .openCassa p { float: left; line-height: 34px; }
#CassaPage .head .openCassa p.alert { color: #ee1566; }

#CassaPage .head .cassaTransactions { float: right; }
#CassaPage .head .cassaTransactions button { float: none; margin-left: 20px; }

#CassaPage.mobile .head { margin: 0; }

#CassaPage .confirmBlock.transaction .col { padding-right: 10px; }
#CassaPage .confirmBlock.transaction .checkGroupLabel { width: 100%; }
#CassaPage .confirmBlock.transaction .checkGroupLabel span { display: inline; }

#CassaPage.mobile .confirmBlock { width: 98%; left: 1%; margin-left: 0; }
#CassaPage.mobile .confirmBlock h4 { margin-bottom: 20px; }
#CassaPage.mobile .confirmBlock .close {
	background: none;
	box-shadow: none;
	position: absolute;
	right: 10px;
	top: 10px;
}
#CassaPage.mobile .confirmBlock .close i {
	background: url(images/icons.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#CassaPage .filter .col { padding-right: 10px; }
#CassaPage .filter > button { margin-top: 18px; padding: 0 20px; }
#CassaPage .filter .checkGroupLabel { width: 100%; }
#CassaPage .filter .checkGroupLabel span { max-width: none; display: inline; }

#CassaPage.mobile .filter { margin-bottom: 10px; }
#CassaPage.mobile .filter button { margin-top: 10px; float: left; }
#CassaPage.mobile .filter .showOrders { margin-top: 10px; }

#CassaPage .stat {
	display: table;
	font-size: 12px;
	margin: 10px 0 5px;
	width: 100%;
	vertical-align: top;
}
#CassaPage .stat .row { display: table-row; }
#CassaPage .stat .row .td { 
	background: #e6e6ec; 
	border: 2px solid #f5f6fb; 
	border-radius: 5px;
	box-sizing: border-box;
	display: table-cell;
	padding: 2px 10px;
	vertical-align: middle;
}
#CassaPage .stat .row .td.dates { width: 15%; }
#CassaPage .stat .row .td.summary { width: 45%; }
#CassaPage .stat .row .td.couriers { width: 15%; position: relative; }
#CassaPage .stat .row .td h5 { color: #888888; font-size: 11px; line-height: 15px; }

#CassaPage .stat .row .td.summary p,
#CassaPage .stat .row .td.couriers p { font-size: 14px; font-weight: bold; } 
#CassaPage .stat .row .td.summary .col { border-left: 1px solid #c8cbe0; padding-left: 10px; }
#CassaPage .stat .row .td.summary .col:first-of-type { border-left: none; padding: 0; }
#CassaPage .stat .row.titles .td {
	background: transparent;
	border-top: none;
	border-bottom: none;
	color: #9699a7;
	font-size: 10px;
	line-height: 13px;
	text-transform: uppercase;
}
#CassaPage .stat .row .orders .titles { margin-top: 15px; }
#CassaPage .stat .row .orders .titles h5 { line-height: 21px; }
#CassaPage .stat .row .orders .col { padding-right: 0; }

#CassaPage .stat .row .td.couriers .details {
	background: #e6e6ec;
	border-radius: 5px;
	box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.2);
	display: none;
	padding: 10px;
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 2;
}
#CassaPage .stat .row .td.couriers:hover .details { display: block; }
#CassaPage .stat .row .td.couriers .details p { font-weight: normal; font-size: 12px; }

#CassaPage.mobile .stat { display: block; }
#CassaPage.mobile .stat .row { display: block; }
#CassaPage.mobile .stat .row .td { display: block; width: 100% !important; }
#CassaPage.mobile .stat .row .td.summary { padding: 7px 10px; }
#CassaPage.mobile .stat .row .td.summary .col {padding-left: 5px; }
#CassaPage.mobile .stat .row .td.summary p { font-size: 11px; font-weight: bold; }


#CassaPage .table .subcomponent { background: #fafbfe; padding: 0 5px 5px 35px; }
#CassaPage .table .days { background: #e4e5ea; }
#CassaPage .table .neg { border-left: 3px solid #e73535; color: #e73535 }
#CassaPage .table .pos { border-left: 3px solid #54b6a9; color: #54b6a9; }
#CassaPage .table .add { color: #999999; }

#CassaPage .table .no-transaction .rt-expandable { pointer-events: none; }
#CassaPage .table .no-transaction .rt-expander { display: none; }

#CassaPage .table .block span { display: block; line-height: 19px; }
#CassaPage .table .block span:last-child { color: #888888; font-size: 11px; line-height: 16px;}

/* --- CONTENT PAGE --- */
#ContentPage .head .searchBlock { float: right; }
#ContentPage .head .searchBlock input { padding-left: 35px; width: 100%; }
#ContentPage .head .searchBlock label { left: 35px; }
#ContentPage .head .searchBlock .search {
	background: url(images/icons.svg) no-repeat -96px -152px;
	display: block;
	height: 24px;
	position: absolute;
	top: 4px;
	left: 5px;
	width: 24px;
}

#ContentPage section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	overflow: auto;
	padding: 15px 20px 15px 0;
}

#ContentPage section .head { margin: 10px 0; }
#ContentPage section .head:first-of-type { margin-top: 0; }

#ContentPage section > .button.add { margin: 20px 35px; }
#ContentPage section > p { margin: 20px 35px; }

#ContentPage h3, #ContentPage h4, .SortableHelper h4 { text-transform: none; }

#ContentPage h3 { font-size: 15px; line-height: 28px; padding-right: 25px; }
#ContentPage h4 { font-size: 14px; }

#ContentPage h3 a { position: relative; }
#ContentPage h3 a:hover, #ContentPage h3 a:active, #ContentPage .open h3 a,
#ContentPage h4 a:hover, #ContentPage h4 a:active, #ContentPage .openContent h4 a { color: #54b6a9; }

#ContentPage .toggleIcon {
	background: url(images/icons.svg) -240px -128px;
	display: block;
	height: 24px;
	position: absolute;
	left: -24px;
	width: 24px;
}
#ContentPage h3 .toggleIcon { top: 4px; }
#ContentPage .openContent h4 .toggleIcon,
#ContentPage .open h3 .toggleIcon { background-position: -264px -128px; }

#ContentPage .article.edit { margin: 30px 0; }

#ContentPage .body { padding: 15px 0 25px; }
/* @media print { */
	/* #ContentPage .body > .clearfix { padding: 15px 25px 25px; } */
/* } */

#ContentPage ul { margin: 10px 30px; list-style: disc;}
#ContentPage ol { margin: 10px 30px; list-style: decimal; }

#ContentPage .button { line-height: 34px; margin: 20px 0; }

#ContentPage .group { margin-top: 10px; z-index: 1; padding-left: 35px; }
#ContentPage .group:first-child { margin-top: 0; }

#ContentPage .group > .head button { float: left; line-height: 29px; margin: 20px 0 0 20px; }
#ContentPage .group > .head .SortHandle { left: -27px; top: 2px; }
#ContentPage .group .button { margin: 0 0 0 20px; }

#ContentPage .News .group { padding-left: 10px; }

#ContentPage .icons { position: absolute; top: 0; right: 0; }
#ContentPage .icons .icon { display: inline-block; box-shadow: none; transition: none; cursor: pointer; }
/* #ContentPage .icons .icon i { display: block; height: 24px; width: 24px; } */
#ContentPage .icons .icon.close { background: none; }


#ContentPage .group .head .icons { top: 3px; }
#ContentPage .group .head .icons .close { margin-top: 20px; }
#ContentPage .article .icons .icon { margin-left: 10px; }


#ContentPage .article, .SortableHelper .article { margin-bottom: 0px; padding-left: 25px; width: 100%; }

#ContentPage .article.notactive, .SortableHelper .article.notactive, 
#ContentPage .article.notactive h4, .SortableHelper .article.notactive h4,
#ContentPage .article.notactive h5, .SortableHelper .article.notactive h5 { color: #adadad; }

#ContentPage .article h4, .SortableHelper .article h4 { margin-bottom: 8px; position: relative; }

#ContentPage .article.notactive .comment, .SortableHelper .article.notactive .comment { color: #bdbdbd; }
#ContentPage .article .head .icons .edit, .SortableHelper .article .head .icons .edit { top: 0; }

#ContentPage .article h4 .form-row {
	font-size: 13px;
	text-transform: none;
	padding-right: 30px;
}

#ContentPage .article .buttons { float: none; }
#ContentPage .article button { width: auto; float: left; }
#ContentPage .article .error { margin-left: 150px; line-height: 45px; }

#ContentPage.mobile .group { padding-left: 5px; }
#ContentPage.mobile .group > .head button {line-height: 35px; margin: 10px 0 0; }

#ContentPage.mobile .article h4 { padding-right: 25px; }
#ContentPage.mobile .article .icons .icon { display: block; margin-bottom: 5px; }

/* #ContentPage .article a:not(.button).icon.edit { right: 120px; } */



/* --- DIRECTORIES PAGE --- */
#Directory .addNew {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2) inset;
	padding: 12px 20px;
	margin-bottom: 10px;
}
#Directory .addNew form .form-row { padding-right: 20px; }

#Directory .addNew button { width: auto; float: left; }
#Directory .addNew a.cancel { background: none; box-shadow: none; }
#Directory .addNew a.cancel i { background: url(images/icons.svg) -168px -128px; }

#Directory .table i.icon {
	background: url(images/icons.svg);
	display: block;
	height: 24px;
	width: 24px;
}
#Directory .table i.icon.icon_1 { background-position: -144px -176px; }
#Directory .table i.icon.icon_2 { background-position: -168px -176px; }
#Directory .table i.icon.icon_3 { background-position: -192px -176px; }
#Directory .table i.icon.icon_4 { background-position: -216px -176px; }
#Directory .table i.icon.icon_5 { background-position: -240px -176px; }
#Directory .table i.icon.icon_6 { background-position: -264px -176px; }

#InventoriesItem.addNew form .buttons,
#InventoriesItem.addNew .sections .button { margin-top: 17px; }
#InventoriesItem.addNew .sections { margin-top: 50px; }

#InventoriesItem.addNew .error { margin-top: 15px; }

/* --- TRANSFER SHIFT REPORTS PAGE --- */
#UserReport:not(.mobile) .head h2 { float: left; margin: 0; }
#UserReport .head button { margin-left: 20px; }

#UserReport .error { line-height: 35px; margin: 0 360px 0 180px; }

#UserReport section {
	background: #FFFFFF;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	overflow: auto;
}
#UserReport:not(.mobile) section > .clearfix { overflow: hidden; padding-bottom: 20px; }
#UserReport section > .clearfix > .col { padding: 15px 20px 99999px; margin-bottom: -99999px; }
#UserReport section > .clearfix > .col.form { padding-right: 0; }

#UserReport.mobile .tabs { margin: 0; padding: 0; }
#UserReport .tabs a {
	line-height: 32px;
	color: #5E626D;
	float: left;
	text-align: center;
	width: 50%;
}
#UserReport .tabs a:first-child { border-top-left-radius: 5px; }
#UserReport .tabs a:last-child { border-top-right-radius: 5px; }

#UserReport.mobile .form, #UserReport.mobile .reports { padding: 5px 0 5px 5px; }


#UserReport form .form-row textarea { height: 100px; text-transform: capitalize; }
#UserReport form label span { color: #e73535; }

#UserReport form h4 { margin: 25px 20px 10px 0; border-top: 1px solid #d9dade; padding-top: 10px; }
#UserReport form .group:first-of-type h4 { margin-top: 0; border-top: none; padding-top: 0; }

#UserReport .reports { background: #f8f9fb; }
#UserReport .reports h4 { margin-bottom: 10px; }

#UserReport .reports .report { margin-bottom: 10px; padding-left: 30px; }

#UserReport .reports h6 { margin-top: 7px; }

@media (max-width: 1030px) {
	#UserReport .reports h4 { font-size: 12px; }
	#UserReport .reports h6 { font-size: 9px; line-height: 14px; }
}

#UserReport .reports .field p {
	font-size: 12px;
	line-height: 19px;
	color: #9699a7;
}
#UserReport .reports .field p.user { color: #535663; }

/* ----- SHIFT TRANSFERS PAGE ----- */
#ShiftTransferReport .head .toggleFilter { left: 320px; }

/* #ShiftTransferReport .filter .col { padding-right: 20px; } */
#ShiftTransferReport .filter .checkGroupLabel { width: 100%; max-width: 100%; }
#ShiftTransferReport.mobile .filter .buttons { float: none; margin-top: 10px; }

#ShiftTransferReport .table span .comment {display: block; }


#ShiftTransferReport .subRowTable { 
	background: #f9faff; 
	border-radius: 3px; 
	box-shadow: 0 1px 3px rgba(0,0,0,0.1) inset;
	padding : 10px 40px 10px 100px; 
}
#ShiftTransferReport.mobile .subRowTable { padding : 10px 20px;  }

#ShiftTransferReport .subRowTable h6 { text-transform: none; }
#ShiftTransferReport .subRowTable p { font-size: 12px; line-height: 19px; color: #9699a7; min-height: 19px; }

#ShiftTransferReport .subRowTable .row { border-top: 1px solid #dddddd; }
#ShiftTransferReport .subRowTable .row:first-of-type { border-top: none; }

#ShiftTransferReport .icon.check {
	border: 3px #adadad solid;
	border-radius: 2px;
	box-sizing: border-box;
	display: block;
	height: 16px;
	margin: 8px 0 0;
	width: 16px;
}
#ShiftTransferReport .icon.check:hover,
#ShiftTransferReport .icon.check:active { border-color: #35a289; }
#ShiftTransferReport .icon.check.active { border: #35a289 solid 8px; cursor: default; }
#ShiftTransferReport .icon i { background: none; }

/* --- REQUESTS PAGE --- */
#RequestItem.tablet .head { top: 0; }

#RequestItem .section { padding: 15px 20px 20px; }
#RequestItem .section .titles { width: 96%; }

#RequestItem .search { float: right; }

#RequestItem .templateProducts .product { padding-left: 20px; }
#RequestItem .templateProducts .product .checkGroupLabel { color: #787c86; }

#RequestItem h5 { text-transform: uppercase; font-size: 12px; color: #444444; margin: 20px 0 10px; }
#RequestItem .item { border-top: 1px solid #dddddd; padding: 5px 0; width: 96%; }
#RequestItem .item > span { line-height: 32px; min-height: 32px; }
#RequestItem .item span .date { display: inline-block; font-size: 10px; color: #888888; }

#RequestItem .remove { position: absolute; top: 10px; right: -20px; }

/* --- SHOPLISTS PAGE --- */
#ShopListItem .item { border-top: 1px solid #dddddd; padding: 10px 0; }
#ShopListItem .item:first-of-type { border: none;  }

#ShopListItem .amount .item { border: none; }
#ShopListItem .amount span { display: block; }
#ShopListItem .amount .min { color: #e83075; }

#ShopListItem .products .item,
#ShopListItem .products .titles { width: 99%; }
#ShopListItem .products .titles span { text-transform: uppercase; font-size: 11px; }
#ShopListItem .products .item .col { padding-right: 10px; }
#ShopListItem .products .item .form-row { margin: 0; }
#ShopListItem .products .min input { color: #e83075; }

#ShopListItem .contacts h4 { margin: 0; }
#ShopListItem .contacts h5 { text-transform: uppercase; color: #444444; margin-bottom: 5px; }

#ShopListItem .workdays .active { color: #54b6a9; }

#ShopListItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 9px;
	right: -20px;
}
#ShopListItem .remove i { margin: 0; }

/* --- STOCK PROCESS COMMON */
#page .nomargin { margin: 0; }

#page .item .processStockStat { display: table; width: 100%; }
#page .item .processStockStat .tr { display: table-row; width: 100%; }
#page .item .processStockStat .td { display: table-cell; float: none; }

#page .item .processStockStat .col { padding: 0 10px; border-left: 1px solid #d9dade; }
#page .item .processStockStat .col:first-child { border-left: none; padding-left: 0; }
#page .item .processStockStat h5 { margin-bottom: 10px; line-height: 24px; height: 24px; font-size: 12px; text-transform: uppercase; }
#page .item .processStockStat h5 i {
	background: url(./images/icons.svg) -696px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 6px;
	vertical-align: middle;
	width: 24px;
}
#page .item .processStockStat .col.create h5 i { background-position: -696px -128px; }
#page .item .processStockStat .col.send h5 i   { background-position: -720px -128px; }
#page .item .processStockStat .col.accept h5 i { background-position: -744px -128px; }
#page .item .processStockStat .col.from h5 i { background-position: -768px -128px; }
#page .item .processStockStat .col.move h5 i   { background-position: -792px -128px; }
#page .item .processStockStat .col.to h5 i { background-position: -816px -128px; }
#page .item .processStockStat .col.payment h5 i { background-position: -552px -152px; }

#page .item .processStockStat .row { font-size: 12px; padding-left: 30px; line-height: 25px; }
#page .item .processStockStat .row .col { border: none; padding: 0 20px 0 0; }
#page .item .processStockStat .row .title { 
	color: #86878c; 
	font-size: 11px; 
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase; 
	white-space: nowrap; 

}

/* --- INVOICES PAGE --- */
#InvoicePage .toggleFilter { left: 150px; }
#InvoicePage span.icon {
	background: url(images/icons.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	vertical-align: bottom;
}
#InvoicePage .icon.type_1 { background-position: 0 -176px; }
#InvoicePage .icon.type_2 { background-position: -48px -176px; }
#InvoicePage .icon.type_3 { background-position: -24px -176px; }

#InvoiceItem .head .button.green { text-align: center; width: 225px; }

#InvoiceItem.two-cols .section > .clearfix { min-height: auto; }

#InvoiceItem .backblock { margin-top: 50px; }
#InvoiceItem .backblock .form-row { margin: 0 !important; }
#InvoiceItem .main:not(.backblock) { padding: 15px 20px 0; }
#InvoiceItem .processStockStat .main.col { padding: 20px 0 20px 20px !important; }
#InvoiceItem .main .col { padding-right: 20px; }
#InvoiceItem .main .form-row:last-child { margin-bottom: 20px; }

#InvoiceItem .payment { padding: 15px 20px 20px;}
#InvoiceItem .main .form-row:not(.full_select) .select__menu-list { max-height: 85px; }
#InvoiceItem .main h5 { text-transform: uppercase; font-size: 11px; }

#InvoiceItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#InvoiceItem .item:first-of-type { border: none;  }

#InvoiceItem .titles { width: 99%; }

#InvoiceItem .invoiceList { padding: 20px; border-top: 1px solid #dddddd; }
#InvoiceItem .invoiceList .col { padding-right: 10px; margin: 0; }

#InvoiceItem .item span.col { line-height: 30px; min-height: 30px; }

#InvoiceItem .summary { border-top: 2px solid #dddddd; margin-top: 15px; padding-top: 25px; width: 99%; }
#InvoiceItem .summary h4 { text-align: right; margin-top: 23px; }

#InvoiceItem .pay { margin: 20px 0; }

#InvoiceItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#InvoiceItem .remove i { margin: 0; }

#InvoiceConfirm p.icon {
	font-size: 13px;
	line-height: 24px;
	margin-bottom: 10px;
	padding-left: 20px;
}
#InvoiceConfirm p.icon i { margin-right: 10px; }
#InvoiceConfirm .buttons { margin-top: 25px; }

#InvoiceConfirm p.icon.stock i { background-position: -648px -128px; }
#InvoiceConfirm p.icon.provider i { background-position: -792px -128px; }
#InvoiceConfirm p.icon.amount i { background-position: -552px -152px; }

/* --- PAYMENTS PAGE --- */
#PaymentPage .head h2 { float: left; }

#PaymentPage .head .monthsFilter { float: left; width: 60%; }
/* #PaymentPage .head .monthsFilter a { color: #54b6a9; line-height: 35px; display: inline-block; } */
/* #PaymentPage .head .monthsFilter span { font-size: 18px; display: inline-block; margin: 0 15px; line-height: 35px; } */

#PaymentPage .loader { text-align: center; padding-right: 130px; }

#PaymentPage .table { display: table; border-spacing: 2px; width: 100%; }
#PaymentPage .table .titles, #PaymentPage .summary { display: table-row; }
#PaymentPage .table .dates { display: table-row-group; }
#PaymentPage .table .titles .col, #PaymentPage .summary .sum { display: table-cell; float: none; }

#PaymentPage .weekday,
#PaymentPage .titles span {
	color: #9f9f9f;
	text-transform: uppercase; 
	font-size: 10px;     
	white-space: nowrap;
    text-overflow: ellipsis;
	overflow: hidden; 
	
}

#PaymentPage .titles span { padding-left: 10px; }
#PaymentPage .weekday { width: 25px; text-align: center;  }

#PaymentPage .line { display: table-row; }
#PaymentPage .day {
	background: #FFFFFF;
	box-sizing: border-box;
	border-radius: 4px;
	min-height: 50px;
	padding: 5px 10px;
	display: table-cell;
	float: none;
}
#PaymentPage .day:not(.disabled) { box-shadow: 0 1px 2px rgba(0,0,0,0.1); }
#PaymentPage .day.today { background: rgba(53, 162, 137, 0.15); }
#PaymentPage .day.disabled { background: transparent; }

#PaymentPage .day h5 { font-size: 11px; line-height: 21px; color: #888888; }

#PaymentPage .day .provider { font-size: 12px; border-top: 1px solid #dddddd;}
#PaymentPage .day .provider span { 
	line-height: 24px; 
	vertical-align: middle; 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis;
	padding-right: 0px;
}

#PaymentPage i.icon {
	background: url(images/icons.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	transform: scale(0.7);
	vertical-align: bottom;
}
#PaymentPage i.icon.type_1 { background-position: 0 -176px; }
#PaymentPage i.icon.type_2 { background-position: -48px -176px; }
#PaymentPage i.icon.type_3 { background-position: -24px -176px; }
#PaymentPage i.icon.type_1_3 { background-position: -72px -176px; }
#PaymentPage i.icon.type_1_2 { background-position: -96px -176px; }
#PaymentPage i.icon.type_2_3 { background-position: -120px -176px; }

#PaymentPage .pay { color: #54b6a9; }
#PaymentPage .notPay { color: #f4bf3b; }
#PaymentPage .alert { color: #e73535; }

#PaymentPage .summary .sum { min-height: 1px; margin-right: 2px; }
#PaymentPage .summary .icon { float: left; }
#PaymentPage .summary span { display: block; font-size: 11px; white-space: nowrap; }
#PaymentPage .summary .payment { color: #54b6a9; }

#PaymentPage .popup {
	background: #FFFFFF;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); 
	margin: 0 -180px;
	padding: 15px 20px;
	position: fixed;
	left: 50%;
	top: 90px;
	width: 360px;
	max-height: 350px;
	overflow: auto;
}
#PaymentPage .popup .close {
	background: none;
	box-shadow: none;
	position: absolute;
	right: 1px;
	top: 1px;
	transform: scale(0.7);
}
#PaymentPage .popup .close i {
	background: url(images/icons.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#PaymentPage .popup h5 { font-size: 11px; line-height: 21px; color: #888888; text-transform: uppercase;  }
#PaymentPage .popup h5 .date { text-align: right; }
#PaymentPage .popup h5 .deadline { color: #e83075 }

#PaymentPage .popup .type { border-top: 1px solid #dddddd; margin-top: 5px; font-size: 12px; }
#PaymentPage .popup .type h5 { line-height: 24px; margin: 5px 0; }

#PaymentPage .popup .type .payment { border-bottom: 1px solid #ececec; }
#PaymentPage .popup .type .payment span { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 25px; }
#PaymentPage .popup .type .payment span.comment { color: #888888; }
#PaymentPage .popup .type .payment .icon {
	background: none;
	box-shadow: none;
	line-height: 24px;
	padding: 0;
	position: absolute;;
}
#PaymentPage .popup .type .payment .icon i { margin: 0; }

#PaymentPage .popup .type .summary { padding: 0; margin-bottom: 10px; }

/* --- WRITE OFF REQUESTS PAGE --- */
#WriteOffRequestItem .section { padding: 15px 20px 20px; }

#WriteOffRequestItem h5 { text-transform: uppercase; font-size: 11px; }

#WriteOffRequestItem .titles { width: 99%; }
#WriteOffRequestItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#WriteOffRequestItem .item .form-row { margin: 0; }
#WriteOffRequestItem .item > span { line-height: 21px; min-height: 21px;}

#WriteOffRequestItem .add { margin-top: 20px; line-height: 32px; }
#WriteOffRequestItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#WriteOffRequestItem .remove i { margin: 0; }

#WriteOffRequestItem .new { line-height: 30px; color: #e73535; }

#WriteOffRequestItem .head .button.green { text-align: center; width: 225px; }



/* --- TRANSFERS PAGE --- */
#TransfersPage .toggleFilter { left: 165px;}
#TransferItem .section { padding: 20px; }
#TransferItem .col { padding-right: 10px; }
#TransferItem .backblock { margin-top: 60px; }

#TransferItem h5 { text-transform: uppercase; font-size: 11px; } 

#TransferItem .titles { width: 99%; }
#TransferItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#TransferItem .item .form-row { margin: 0; }

#TransferItem .item .alert input { color: #e83075; }
#TransferItem .item span.col { line-height: 32px; min-height: 32px; }

#TransferItem .add { margin-top: 20px; line-height: 32px; }
#TransferItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#TransferItem .remove i { margin: 0; }

#TransferItem .head .button.accept {
	background: #54b6a9; /* Old browsers */
	background: -moz-linear-gradient(top, #54b6a9 0%, #35a289 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #54b6a9 0%,#35a289 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #54b6a9 0%,#35a289 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54b6a9', endColorstr='#35a289',GradientType=0 ); /* IE6-9 */

	box-shadow: 1px 2px 10px rgba(40, 130, 109, 0.6);
	text-align: center;
	width: 225px;
}
#TransferItem .head .button.accept:hover,
#TransferItem .head .button.accept:active {
	background: #64cdbf; /* Old browsers */
	background: -moz-linear-gradient(top, #64cdbf 0%, #35a289 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #64cdbf 0%,#35a289 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #64cdbf 0%,#35a289 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64cdbf', endColorstr='#35a289',GradientType=0 ); /* IE6-9 */
}

#TransferItem .select__menu-list { max-height: 150px; overflow: auto; }

/* --- REVISION PAGE --- */
#AuditPage .toggleFilter { left: 100px; }
#AuditPage span.plus { color: #54b6a9; }
#AuditPage span.minus { color: #ad0f49; }

#AuditItem .section { padding: 20px; }
#AuditItem.tablet .head { top: 0; }

/* #AuditItem .head { float: left; } */
#AuditItem .productSearch { 
    position: fixed;
    right: 20px;
    top: 67px;
    z-index: 4;
}
#AuditItem.tablet .productSearch { top: 3px; }

#AuditItem .template .start { margin-top : 20px; }
#AuditItem .template .col { padding-right: 20px; }
#AuditItem .template .templateProducts { margin-top: 20px; }

#AuditItem .template .checkAll { margin: 0;  }
#AuditItem .template .checkAll + .form-row { margin: 0 0 0 20px; }

#AuditItem h5 { text-transform: uppercase; font-size: 11px; }
#AuditItem .block:not(.audit):not(.summary) h5 { color: #BBBBBB; margin-bottom: 5px;}
#AuditItem .titles span { color: #9f9f9f; }

#AuditItem .titles { width: 99%; }
#AuditItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#AuditItem .item .form-row { margin: 0; }
#AuditItem .item span { line-height: 32px; min-height: 32px; }
#AuditItem .plus { color: #54b6a9; }
#AuditItem .minus { color: #ad0f49; }
#AuditItem .item span.date { color: #888888; font-size: 12px; font-style: italic; }

#AuditItem .head .button.green { text-align: center; width: 225px; }
#AuditItem .summary h5 { text-align: right; }

/* --- LEFTOVERS PAGE --- */
#ActualStockPage h2 { float: left; } 
#ActualStockPage form .form-row { margin: 0; } 
#ActualStockPage form .col { padding-right: 20px;}

#ActualStockPage h2 a, #ActualStockPage h2 span {
	color: inherit;
	font-size: 12px;
	line-height: 18px;
	text-transform: none;
}
#ActualStockPage h2 span { margin-left: 20px; }
#ActualStockPage .head .sort { border-bottom: 1px dotted #5e626d; margin-left: 10px; }
#ActualStockPage h2 .sort:hover, #ActualStockPage h2 .sort:active, #ActualStockPage h2 .sort.active { border-color: #35a289; color: #35a289; }

#ActualStockPage .section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	margin-top: 15px;
	padding: 15px 20px;
	overflow: auto;
}

#ActualStockPage h5 { text-transform: uppercase; font-size: 11px; color: #BBBBBB; margin-bottom: 5px;}
#ActualStockPage h5 .pos { color: #35a289; }
#ActualStockPage h5 .neg { color: #ad0f49; }

#ActualStockPage .item { border-top: 1px solid #dddddd; padding: 7px 0; }
#ActualStockPage .item span { line-height: 22px; min-height: 22px; }
#ActualStockPage .item span.date { color: #888888; font-size: 12px; font-style: italic; }

/* --- RELEASE PAGE --- */
#ReleasePage .item { margin: 5px 0; }
#ReleasePage .item .col { margin-left: 5px; text-align: center; }
#ReleasePage .item .col:first-child { margin: 0; }

#ReleasePage .item span {
	background: #ffffff;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
	color: #71747e;
	line-height: 35px;
	padding: 0 20px;
}

#ReleasePage .item .name { border-left: 3px solid transparent; text-align: left; }
#ReleasePage .item.done .name { border-left: 3px solid #79b853; }
#ReleasePage .item.expired .name { border-left: 3px solid #e73535; background: #f8f1e7 }

#ReleasePage .item .form-row { margin-top: 0; }
#ReleasePage .item input { line-height: 33px; }
#ReleasePage .item button { float: left; }

#ReleasePage .item.done .action { background: #79b853; color: #ffffff; }

#ReleasePage .item .error { padding: 40px 20px 10px; }

/* --- INVENTARIZATION PAGE --- */
#InventorizationPage .toggleFilter { left: 190px; }
#InventorizationPage span.plus { color: #54b6a9; }
#InventorizationPage span.minus { color: #ad0f49; }

#InventarizationItem .backblock { margin-top: 25px; }
#InventarizationItem .section { padding: 20px; }

#InventarizationItem .item { border-top: 1px solid #dddddd; padding: 7px 0; }

#InventarizationItem .item,
#InventarizationItem .titles { width: 99%; }
#InventarizationItem .item .form-row { margin: 0; }
#InventarizationItem .item span.col { line-height: 32px; min-height: 32px; }

#InventarizationItem .add { margin-top: 20px; line-height: 32px; }
#InventarizationItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 4px;
	right: -15px;
}
#InventarizationItem .remove i { margin: 0; }

/* --- STATISTIC PAGE --- */
#StatPage .head { margin: 0; }
#StatPage .head h2 { float: left; }
#StatPage .head .toggleFilter { float: left; position: static; margin-top: 5px; }
#StatPage .head .button { float: right; }
#StatPage .head .error { display: inline-block; }

/* #StatPage form { margin-top : -10px; } */
#StatPage form .filter .col { padding-right: 10px; }
#StatPage form .filter .preset a {
	padding: 0;
	margin: 20px 0 0;
	background: #c8cad4;
	color: #FFFFFF;
	text-align: center;
	line-height: 30px;
}
#StatPage form .filter .preset a:first-child { border-radius: 4px 0 0 4px; }
#StatPage form .filter .preset a:last-child { border-radius: 0 4px 4px 0; }
#StatPage form .filter .preset a.active { background: #54b6a9; }
#StatPage form .filter .checkGroupLabel { width: 100%; }
#StatPage form .filter .nomargin { margin: 0; }
#StatPage form .buttons { margin-top: 17px; }
#StatPage form .buttonDropdown .dropdown-menu.show { transform: translate3d(0, 35px, 0px) !important; }

#StatPage form.PurchaseSummary .filter .buttonGroup { text-transform: capitalize; }

#StatPage form .filter .select__value-container { max-height: 32px; overflow: auto; }

#StatPage form.EmployeesStat .buttons { margin-top: 72px;}

#StatPage .loader { text-align: center; margin: 70px 0;}

#StatPage .table .block span { display: block; line-height: 19px; }
#StatPage .table .block span:last-child { color: #888888; font-size: 11px; line-height: 16px;}

#StatPage .table span.plus { color: #54b6a9; }
#StatPage .table span.minus { color: #ad0f49; }

#StatPage .table .section .rt-expandable { pointer-events: none; }
#StatPage .table .section .rt-expander { display: none; }
#StatPage .subRowTable { 
	background: #f9faff; 
	border-radius: 3px; 
	box-shadow: 0 1px 3px rgba(0,0,0,0.1) inset;
	padding : 10px 40px 10px 100px; 
}
#StatPage .subRowTable .col { padding-right: 20px; }

#StatPage .subRowTable .row { border-top: 1px solid #dddddd; }
#StatPage .subRowTable .row:first-of-type { border-top: none; }

#ImportItem .head .button { float: none; }
#ImportItem .comment { margin: 0 0 5px; }
#ImportItem .main .col { padding-right: 20px; }
#ImportItem .errors { color: #c01d1d; }

#ImportItem .item { border-top: 1px solid #dddddd; padding: 7px 0; }
#ImportItem .item .form-row { margin: 0; }
#ImportItem .item span.col { line-height: 32px; min-height: 32px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

#ImportItem .add { margin-top: 20px; line-height: 32px; }
#ImportItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 4px;
	right: -15px;
}
#ImportItem .remove i { margin: 0; }

#ImportItem form .fileContainer.file .buttonContainer { top: 2%; left: 30%; }

/* --- MERCHANT SETTINGS --- */
#MerchantPage .tabs {
	margin-top: 55px;
	display: flex;
}
#MerchantPage .tabs a {
	background: #FFFFFFA1;
	display: inline-block;
	color: #5e626d;
	line-height: 30px;
	padding: 0 15px;
	margin-left: 5px;
	border-radius: 5px 5px 0 0;
	text-transform: uppercase;
	font-size: 12px;
}
#MerchantPage .tabs a:first-child { margin-left: 15px; }
#MerchantPage .tabs a:hover { background: #FFFFFF; z-index: 1;}
#MerchantPage .tabs a.active {
	background: #FFFFFF;
	color: #54b6a9;
	z-index: 1;
}

#MerchantPage .flexSection {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px;
}
/* #MerchantPage .flexSection > div { padding: 15px; width: 35%; } */
#MerchantPage .flexSection .main { display: flex; gap: 20px; margin-bottom: 20px; }
#MerchantPage .flexSection .main .commonSettings { flex-grow: 1; }

#MerchantPage .type { margin-top: 10px; }

#MerchantPage .flexSection .main .paymentInfo {
	border: 1px solid var(--green);
    border-radius: 10px;
    margin-top: 20px;
    padding: 15px;
    position: relative;
	width: 40%;
	box-sizing: border-box;
}
#MerchantPage .flexSection .main .paymentInfo h5 {
	position: absolute;
    top: -11px;
    background: #fff;
    padding: 0 10px;
	color: #54b6a9;
}
#MerchantPage .flexSection .main .paymentInfo .support { margin-top: 15px;}


@media (max-width: 980px) {
	#MerchantPage .flexSection .main { flex-direction: column; }
	#MerchantPage .flexSection .main .paymentInfo { width: 100%; }
}



#MerchantPage .flexSection .entitiesList {
	display: grid;
	row-gap: 20px;
	column-gap: 2%;
	grid-template-columns: 22% 22% 22% 22% 5%;
	margin-bottom: 20px;
}
#MerchantPage .flexSection .entitiesList .iconSvg { margin-top: 20px;}


#MerchantPage.mobile .section { padding: 10px; }


#MerchantPage .checkGroupLabel { width: 100%; max-width: 100%; }

#MerchantPage .ip .comment { margin-top: 5px; }
#MerchantPage .ip textarea { height: 70px; }

#MerchantPage .list .add { margin-top: 15px; }

#MerchantPage .list .item { position: relative; }
#MerchantPage.mobile .list .item { padding: 0; margin-top: 15px; }

#MerchantPage .list .remove { position: absolute; top: 22px; right: 16px; }

#MerchantPage .list .fileContainer { height: 125px; }
#MerchantPage .list .small .fileContainer { height: 85px; }

#MerchantPage .blockedMerchant {
	/* background: #FFFFFF; */
	/* border-radius: 3px; */
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px 20px;
	margin-top: 0;
}

#MerchantPage .blockedMerchant img { max-width: 320px; }

/* --- ORDER STATUS PAGE --- */
#OrderStatusPage .table .titles {
    color: #71747e;
	font: 12px/26px "Roboto", Arial, sans-serif;
	padding-left: 33px;
}

#OrderStatusPage .SortHandle, .orderSortItem .SortHandle { left: 0;  top: 10px; }

#OrderStatusPage .table .titles span { padding: 0 5px; }
#OrderStatusPage .table .tableBody { overflow: auto; }
.orderSortItem {
	background: #FFFFFF;
	border-left: 3px solid transparent;
	border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.13);
	margin: 2px;
    padding-left: 30px;
}
.orderSortItem span { line-height: 45px !important; padding: 0 5px; height: 45px; }
.orderSortItem.notactive { color: #868c9c; }

.orderSortItem span i.icon {
	border-radius: 50%;
	display: inline-block;
	height: 18px;
	width: 18px;
}
.orderSortItem .icon { display: inline-block;  height: 24px; position: static; vertical-align: middle; }


#StatusItem .flexible { display: inline-block; margin-top: 20px; }

#StatusItem .main .comment { margin: 0; }

#StatusItem h5 { text-transform: uppercase; }
#StatusItem .flextime .time { width: calc(85%/7 - 1px); }
#StatusItem .flextime .titles { margin-top: 20px; }
#StatusItem .flextime .item { border-top: 1px solid #dddddd; padding: 3px 0; }
#StatusItem .flextime .item span { line-height: 30px; min-height: 30px; }

/* --- BILLTEMPLATES PAGE --- */
#BillTemplateItem .template,
#BillTemplateItem.mobile .sample { padding: 10px; }
#BillTemplateItem .fields .item { position: relative; }
#BillTemplateItem .fields .add { margin: 20px 0; }
#BillTemplateItem .fields .item .remove { position: absolute; top: 12px; right: 10px; }

#BillTemplateItem.mobile .printFormat .checkGroupLabel { width: 49%; }

#BillTemplateItem .billItem { box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); margin: 20px 0; }

.billItem { background: #FFFFFF; box-sizing: border-box; }

#BillTapeItem { width: 270px; padding: 3%; }
#BillPaperItem { width: 100%; padding: 20px 4%; }

#BillTapeItem .head .brand { text-align: center; border-bottom: 1px solid #adadad; margin-bottom: 10px; }
#BillPaperItem .head .brand { text-align: left; }

.billItem .head .brand img { max-width: 100%; margin-bottom: 15px; }

#BillTapeItem .head .orderNumber { margin-top: 15px; }
#BillPaperItem .head .orderNumber { text-align: center; }
#BillPaperItem .head .order { text-align: right; }

.billItem .head .fields p { margin-top: 15px; }

.billItem .info { margin: 15px 0; }
.billItem .info .item { border-top: 1px solid #dddddd; }
.billItem .info .item:first-child { border: none; }
.billItem .info .item b, .billItem .info .item span { min-height: 21px; }

.billItem .products { margin: 15px 0; display: table; width: 100%; border-collapse: collapse; }
.billItem .products .trGroup { display: table-row-group; }
.billItem .products .tr { display: table-row; }
.billItem .products .td { display: table-cell; }

#BillTapeItem .products .td { padding: 1px 5px; }
#BillPaperItem .products .td { border: 1px solid #adadad; padding: 1px 10px; }

.billItem .products .titles { font-weight: bold; }

#BillTapeItem .products .product .td { border-top: 1px solid #eeeeee; }
#BillTapeItem .products .titles + .product .td { border-top: 1px solid #adadad; }

.billItem .products .count, .billItem .products .price { text-align: center; }
.billItem .products .amount { text-align: right; width: 40%; }

#BillTapeItem .products .amounts { border-top: 1px solid #adadad; border-bottom: 1px solid #adadad;	}
#BillPaperItem .products .amounts { font-weight: bold;}
.billItem .products .summary { font-weight: bold; }

.billItem .comments { margin: 15px 0; text-align: center; }

.billItem .customer .description { margin-top: 10px; }

.billItem .composition h5 { 
	border-top: 1px solid #adadad; 
	border-bottom: 1px solid #adadad; 
	font-size: 12px;
	font-weight: bold;
	margin: 20px 0 10px;
}
.billItem .composition .item {
	line-height: 120%;
	border-bottom: 1px solid #dddddd;
	margin-top: 5px;
	padding-bottom: 5px;
}

/* --- PAYOUT PAGE --- */
#PayoutPage .head .filter { display: inline-block; vertical-align: middle; width: 60%; }
#PayoutPage .head .filter .col { padding-right: 20px; }

#PayoutItem .head { left: 0 !important; }
#PayoutItem .section { margin-top: 0; }

#PayoutItem .stat .item { border-top: 1px solid #dddddd; }
#PayoutItem .stat .item span { min-height: 21px; }
#PayoutItem .stat form { margin-bottom: 10px; }

#PayoutItem .payments h4 { float: left; line-height: 35px; margin: 0; }
#PayoutItem .payments button { margin-left: 10px; }

#PayoutItem span.icon {
	background: url(images/icons.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	vertical-align: bottom;
}
#PayoutItem .icon.type_1 { background-position: 0 -176px; }
#PayoutItem .icon.type_2 { background-position: -48px -176px; }
#PayoutItem .icon.type_3 { background-position: -24px -176px; }

#PayoutPage .confirmBlock.payout { width: 480px; }
#PayoutPage .confirmBlock.payout .col { padding-right: 20px; }
#PayoutPage .confirmBlock.payout .buttons { float: none; }
#PayoutPage .confirmBlock.payout .buttons button { float: left; width: 140px; }

#PayoutPage .confirmBlock.payout .checkGroupLabel { width: 100%; max-width: 100%; }
#PayoutPage .confirmBlock.payout .checkGroupLabel span { display: inline; max-width: none; } 

#PayoutPage .confirmBlock.payout.payment { margin-top: 0; top: 100px; }
/** ----- STOP LIST PAGE ----- */
#StopListPage h5 { color: #71747e; text-transform: uppercase; }

#StopListPage .addBlock { margin-top: 20px; }
#StopListPage button { float: left; }
#StopListPage .addBlock form button, #StopListPage .addBlock form .button { margin: 18px 20px 0 0; }

#StopListPage .item { margin: 5px 0; }
#StopListPage .item .col { margin-right: 5px; }

#StopListPage .item span {
	background: #ffffff;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
	line-height: 35px;
	padding: 0 20px;
}
#StopListPage .item .name { border-left: 3px solid #e73535; }
#StopListPage .item span i { color: #9699a7; font-size: 12px; }
#StopListPage .item .remove { padding: 5px; height: 24px; }



/* --- BONUS PAGE --- */
#BonusRulePage .table .titles {
    color: #71747e;
	font: 12px/26px "Roboto", Arial, sans-serif;
	padding-left: 33px;
}
#BonusRulePage .table .titles span { padding: 0 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }

#BonusRulePage .SortHandle, .orderSortItem .SortHandle { left: 0;  top: 10px; }

#BonusRulePage .table .tableBody { overflow: auto; }
.orderSortItem {
	background: #FFFFFF;
	border-left: 3px solid transparent;
	border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.13);
	margin: 2px;
    padding-left: 30px;
}
.orderSortItem span { line-height: 45px !important; padding: 0 5px; height: 45px; }
.orderSortItem .col.edit { min-width: 35px; }
.orderSortItem.notactive { color: #868c9c; }

.orderSortItem span i.icon {
	border-radius: 50%;
	display: inline-block;
	height: 18px;
	width: 18px;
	vertical-align: middle;
}

#RuleItem .section > .clearfix > .col.col70 { padding-top: 25px; }

#RuleItem h4 { margin-bottom: 5px; }
#RuleItem textarea { height: 100px; }
#RuleItem .date label { left: 45px; }
#RuleItem .date .form-input { padding-left: 30px; }
#RuleItem .date .form-input i { left: 0; }
#RuleItem .item .comment { margin-top: 20px; padding: 0; }
#RuleItem .item .week .checkGroupLabel { width: 33%; }

#RuleItem .item .product { position: relative; }
#RuleItem .item .add { margin-top: 20px; }
#RuleItem .item .remove { background: none; padding: 0; position: absolute; top: 17px; right: 10px; }
#RuleItem .item .remove i { margin: 0; }

/* --- PROMOCODES PAGE --- */
#PromocodePage .upper { text-transform: uppercase;}

#PromocodeItem h4 { margin-bottom: 5px; }
#PromocodeItem textarea { height: 100px; }

#PromocodeItem .generatePromo { padding: 0; }
#PromocodeItem .generatePromo .dotted { margin-top: 22px; display: inline-block; }
#PromocodeItem .generatePromo .col { padding-right: 10px; }
#PromocodeItem .generatePromo .button { margin-top: 17px; float: right; width: 50%; }

#PromocodeItem .promocode input { text-transform: uppercase; }

#PromocodeItem .date label { left: 45px; }
#PromocodeItem .date .form-input { padding-left: 30px; }
#PromocodeItem .date .form-input i { left: 0; }
#PromocodeItem .item .comment { margin-top: 20px; padding: 0; }
#PromocodeItem .item .week .checkGroupLabel { width: 33%; }

#PromocodeItem .item .product { position: relative; }
#PromocodeItem .item .add { margin-top: 20px; }
#PromocodeItem .item .remove { background: none; padding: 0; position: absolute; top: 17px; right: 10px; }
#PromocodeItem .item .remove i { margin: 0; }

#CustomerItem .orderStat .titles { border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd; margin-top: 20px;  }

/** --- ACCOUNT PAGE --- */
#AccountPage section {
	display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    gap: 20px;
	justify-content: space-between;
}

@media (max-width: 1280px) {
	#AccountPage section { gap: 15px; }
}

#AccountPage h2 {width: 100%; }
#AccountPage h4 { margin: 0; }
#AccountPage h5 { text-transform: uppercase; }

#AccountPage .section { padding: 20px; display: flex; flex-direction: column; margin: 0; position: relative; }

#AccountPage .userinfo { align-items: center; gap: 15px; width: 40%; }
#AccountPage .userinfo .photo {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: var(--dark-gray);
	background-size: cover;
	background-position: center;
	text-align: center;
    line-height: 200px;
    font-weight: bold;
    color: var(--main-light-gray);
    font-size: 18px;
}
#AccountPage .userinfo .edit { position: absolute; top: 10px; right: 10px; }
#AccountPage .userinfo h5 { margin-top: 10px; }

#AccountPage .checklist { width: 50%; gap: 20px; }
#AccountPage .checklist .rate {
	background: var(--dark-gray);
	background: linear-gradient(90deg, var(--red) 0%, var(--yellow) 50%, var(--green) 100%);
	height: 50px;
	border-radius: 25px;
	position: relative;
}
#AccountPage .checklist .rate > div {
	background-color: var(--dark-gray);
	height: 44px;
	width: 44px;
	border-radius: 25px;
	border: 3px solid var(--text);
	text-align: center;
	line-height: 44px;
	position: absolute;
	top: 0;
	color: var(--white);
    font-weight: bold;
    font-size: 18px;
	text-transform: uppercase;
	background-position: center;
	background-size: cover;
}
#AccountPage .checklist .rate > div.last { left: 0; border-color: var(--red); }
#AccountPage .checklist .rate > div.middle { border-color: var(--yellow); }
#AccountPage .checklist .rate > div.first { right: 0; border-color: var(--green); }

#AccountPage .checklist > p { font-size: 16px; padding: 0 10%; text-align: center; margin-bottom: 30px; }
#AccountPage .checklist > img { max-width: 350px; align-self: center;}

#AccountPage .checklist .table { display: grid; grid-template-columns: 30% 30% 20% 20%; }
#AccountPage .checklist .table span { border-top: 1px solid var(--border-gray); line-height: 32px; }


#AccountPage .tips { padding: 0; border-radius: 10px; width: 30%;}
#AccountPage .tips > div { padding: 15px 20px; position: relative; }

#AccountPage .tips .code { display: flex; justify-content: space-between; }
#AccountPage .tips .code a { display: inline-flex; margin-top: 20px; }

#AccountPage .tips .card a svg *,
#AccountPage .tips .status a svg * { fill: var(--main-light-gray); }
#AccountPage .tips .card a:hover svg *,
#AccountPage .tips .status a:hover svg * { fill: var(--white); }

#AccountPage .tips .card {
	background: var(--green);
	border-radius: 10px 10px 0 0;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

#AccountPage .tips .card .amount { font-size: 25px; color: var(--white); display: flex; justify-content: space-between; }

#AccountPage .tips .card .amountLimit {
	background: var(--dark-green);
	height: 5px;
	position: relative;
	margin-top: 10px;
	border-radius: 3px;
}
#AccountPage .tips .card .amountLimit p {
	position: absolute;
	top: -20px;
	color: var(--main-light-gray);
	font-size: 10px;
	text-transform: uppercase;
}
#AccountPage .card .amountLimit p:last-child { text-align: right; right: 0; }
#AccountPage .card .amountLimit .balance { background: var(--white); border-radius: 5px; height: 5px; }

#AccountPage .card .cardNumber { display: flex; flex-wrap: wrap; position: relative; width: 90%; justify-content: space-between; gap: 20px; }
#AccountPage .card .cardNumber > div { width: 45%; margin: 0; }
#AccountPage .card .cardNumber > div:first-child { width: 100%; }
#AccountPage .card .cardNumber a { position: absolute; top: 0; right: -30px; }
#AccountPage .card .cardNumber input:read-only { opacity: 1; background: rgba(215,225,255,0.1); }


#AccountPage .tips .status { 
	background: var(--dark-green);
	color: var(--main-light-gray);
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding-top: 25px;
	display: flex;
}
#AccountPage .tips .status::after {
	content: "";
	height: 10px;
	width: 100%;
	background: var(--green);
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#AccountPage .tips .info button { float: none; width: 100%; margin-top: 20px; }

#AccountPage .transactions { width: 64%;}
#AccountPage .transactions .head { display: flex; justify-content: center; gap: 10px; align-items: stretch; }
#AccountPage .transactions .head a:first-child svg { transform: rotate(180deg); }
#AccountPage .transactions .table { display: grid; grid-template-columns: 30% 30% 40%; }
#AccountPage .transactions .table span { border-top: 1px solid var(--border-gray); line-height: 32px; }
#AccountPage .transactions .pos { color: var(--green); }
#AccountPage .transactions .neg { color: var(--red); }

@media (max-width: 980px) {
	#AccountPage .section { width: 100%; }
	#AccountPage .userinfo .button { width: 100%; text-align: center;}
}

/* #AccountPage.mobile .section { width: 100%; } */

#AccountPage .window h5 { text-align: left; margin-bottom: 50px; }
#AccountPage .window iframe { width: 100%; min-height: 450px; }

#AccountPage #ConfirmPhone .comment { margin-bottom: 0; }
.mobile#AccountPage #ConfirmPhone { bottom: 10px; }
.mobile#AccountPage #ConfirmPhone iframe { min-height: 380px; }

#AccountPage #PayOut .form-row { width: 50%; margin-bottom: 15px; }
#AccountPage #PayOut button { float: none; margin-top: 30px; }

.mobile#AccountPage #PayOut { bottom: 10px; }
.mobile#AccountPage #PayOut iframe { min-height: 380px; }

/** --- TIPS PRINT PAGE --- */
#TipsPrintPage a { cursor: pointer; }
#TipsPrintPage .filter { margin-bottom: 20px; }
#TipsPrintPage .filter a { color: #54b6a9; margin-top: 20px; line-height: 30px; display: inline-block; }
#TipsPrintPage .filter a:hover { text-decoration: underline; }

#TipsPrintPage .item {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	height: 266px;
	margin-bottom: 20px;
	padding: 10px 15px;
	position: relative;
}

#TipsPrintPage .item img { max-width: 100%; max-height: 200px; }

#TipsPrintPage .item h5 { text-transform: uppercase; margin-bottom: 10px; }
#TipsPrintPage .item p { margin: 5px 0 10px; }
#TipsPrintPage .item a { margin-top: 10px; padding: 0 5px; }

/** --- DASHBOARD PAGE --- */
/* #DashboardPage { margin-right: -20px; } */
/* @media (max-width: 1280px) {
	#DashboardPage { margin-right: -15px; }
}
@media (max-width: 1030px) {
	#DashboardPage { margin-right: -10px; }
} */
#DashboardPage section {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}



#DashboardPage .widgetBlock { margin-bottom: 20px; }
#DashboardPage .widget {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	/* height: 230px; */
	/* min-height: 170px; */
	padding: 10px 15px 15px;
	position: relative;
	box-sizing: border-box;
	width: 31.5%;
}
#DashboardPage .widget h4 { margin-bottom: 10px; }
#DashboardPage .widget .openPage { position: absolute; top: 10px; right: 10px; }
#DashboardPage .widget .openPage i {
	background: url(images/icons.svg) -336px -128px;
	display: block;
	filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
}
#DashboardPage .widget .openPage:hover i,
#DashboardPage .widget .openPage:active i { filter: none; }

@media (max-width: 980px) {
	#DashboardPage { margin-right: 0; }
	#DashboardPage .widget { height: auto; }
}

/* -- DASHBOARD TASKS -- */
#DashboardPage .widget .task { margin-bottom: 5px; line-height: 28px; }
#DashboardPage .widget .item { border-top: 1px solid #dddddd; line-height: 28px; }
#DashboardPage .widget .task .name i,
#DashboardPage .widget .item i {
	background: #dddddd;
	border-radius: 50%;
	display: inline-block;
	height: 8px;
	margin-right: 10px;
	width: 8px;
}
#DashboardPage .widget .task:first-of-type,
#DashboardPage .widget .item:first-of-type { border: none; }

#DashboardPage .widget .task.expired,
#DashboardPage .widget .item.expired { color: #ee1566; }
#DashboardPage .widget .task.expired .name i,
#DashboardPage .widget .titemask.expired i { background: #ee1566; }

#DashboardPage .widget .task > .col:not(.button),
#DashboardPage .widget .item > .col { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

#DashboardPage .widget .task button { line-height: 28px; padding: 0 5px; }
#DashboardPage .widget .task button i { filter: brightness(0) invert(1); }

/* -- DASHBOARD NEWS -- */
#DashboardPage #NewsWidget .news a { color: #54b6a9; display: block; text-transform: uppercase; }
#DashboardPage #NewsWidget .news a:hover,
#DashboardPage #NewsWidget .news a:active { color: #35a289; }
#DashboardPage #NewsWidget .news p { margin: 0 0 10px; }


.back {
	position: fixed;
    background: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
}
.window {
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.5);
	padding: 20px;
	/* position: fixed; */
	/* top: 50%; */
	/* left: 50%; */
}

.window .close { 
	background: none;
	box-shadow: none;
	padding: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2;
}
.window .close:hover, .window .close:active { background: none; box-shadow: none; opacity: 0.7; }
.window .close i {
	background: url(images/icons.svg) -120px -128px;
	filter: brightness(0) invert(0.4);
	display: block;
	height: 24px;
	margin: 0;
	width: 24px;
}

.window.large { 
	/* top: 80px;  */
	/* bottom: 30px;  */
	overflow: auto; 
	top: 90px;
    height: 80vh;
}
.window.large.customerItem {
	/* max-height: 80vh;
	margin-top: 80px;
	width: 76%; */
	top: 90px;
    height: 80vh;
}
.window.medium { 
	/* top: 80px;  */
	/* width: 500px;  */
	margin: 0 -250px; 
}




/* .mobile .window, .mobile .window.large, .mobile .window.medium { width: 94%; left: 3%; margin: 0; } */

.tablet.window.large, .tablet.window.medium { top: 30px; }

.window .windowHeader {
	background: #FFFFFF;
	padding: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.window .windowContainer { padding-top: 40px; }

.window .breadcrumbs { margin-bottom: 10px; color: #adadad; }
.window .breadcrumbs a { margin-right: 20px; color: #787c86; position: relative; font-size: 14px; font-weight: bold; }
.window .breadcrumbs a:hover { text-decoration: underline; }
.window .breadcrumbs a::after { content: ">"; color: #999; position: absolute; right: -14px; }



.confirmBlock { width: 400px; }
@media (max-width: 980px) {
	.confirmBlock { width: 96%; }
}


.confirmBlock p { font-size: 15px; margin-bottom: 25px; }
.confirmBlock p p { margin-bottom: 5px; }
.confirmBlock p.comment { font-size: 11px; }
.confirmBlock .buttons { float: none; }
.confirmBlock .buttons button { width: 140px; float: left;}
.confirmBlock .buttons .gray { float: right; }

.confirmBlock.checkShift ul { margin : 20px 0; }
.confirmBlock.checkShift ul li { margin: 5px 0; }
.confirmBlock.checkShift ul li.alert { color: #ee1566; }
.confirmBlock.checkShift ul li i {
	background: url(./images/icons.svg) -120px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 7px;
	vertical-align: middle;
	width: 24px;
}
.confirmBlock.checkShift ul li.complete i { background-position: -48px -152px; }

.confirmBlock.checkShift .buttons { float: none; }
.confirmBlock.checkShift .buttons button { float: left; width: 140px; }

.galleryBlock {
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
	height: 200px;
	margin: -100px 0;
	overflow: hidden;
	position: fixed;
    top: 50%;
    left: 20%;
    width: 60%;
}
.galleryBlock .head, .galleryBlock .buttons {
	background: #FFFFFF;
	box-sizing: border-box;
	padding: 10px 20px;
	position: absolute !important;
	left: 0;
	width: 100%;
	z-index: 1;
}
.galleryBlock .head { border-bottom: 1px solid #dddddd; top: 0; }
.galleryBlock .head h4 { margin: 0 !important; }
.galleryBlock .buttons { border-top: 1px solid #dddddd; bottom: 0; }
.galleryBlock .buttons .close { float: right; }

.galleryBlock .body { height: 200px; overflow: auto; }

.galleryBlock .files { padding: 60px 20px 65px; }
.galleryBlock .fileItem {
	box-sizing: border-box;
	height: 100px;
	float: left;
	padding: 10px;
	width: 25%;
}
.galleryBlock .fileItem img {
    cursor: pointer;
    background: #eee;
	min-width: 100%;
    min-height: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.galleryBlock .fileItem img.active { box-shadow: 0 0 5px #3b96e3; }

/* --- SORTABLE --- */
.SortableList {
	height: auto;
	list-style: none;
	outline: none;
	overflow: auto;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 0;
	
	-webkit-overflow-scrolling: touch;
}
.SortableItem {
	background-color: #FFF;
	box-sizing: border-box;
	
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.SortableHelper {
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
	background-color: rgba(255, 255, 255, 0.8);
	cursor: row-resize;
	z-index: 20;
}
.SortHandle {
	background: url(images/icons.svg) 0 -128px !important;
	display: block;
	height: 24px;
	position: absolute;
	top: 0;
	left: -24px;
	width: 24px;
}

.pagination { margin-top: 20px; display: flex; justify-content: space-between; align-items: center; }
.pagination a { line-height: 30px; display: inline-flex; color: var(--text); align-items: center; }
.pagination a:hover { color: var(--green); }
.pagination .prev svg { transform: rotate(180deg); }

.pagination.numbers { text-align: center; vertical-align: top; }
.pagination.numbers .icon {
	display: inline-block;
	background: #ffffff;
	color: #757575;
	border-radius: 4px;
	font-weight: bold;
	height: 24px;
	line-height: 24px;
	margin: 0 5px; 
	min-width: 24px;
	vertical-align: top;
}
.pagination.numbers .icon i {
	display: block;
	font-style: normal;
	height: 24px;
	min-width: 24px;
}
.pagination.numbers .icon:not(.number) i { background: url(./images/icons.svg) 0 0; filter: brightness(0) invert(0.6); }
.pagination.numbers .icon.start i { background-position: -864px -152px; }
.pagination.numbers .icon.prev i { background-position: -888px -152px; }
.pagination.numbers .icon.next i { background-position: -912px -152px; }
.pagination.numbers .icon.end i { background-position: -936px -152px; }
.pagination.numbers .icon:hover i { filter: none; }

.pagination.numbers .icon:hover { color: #54b6a9; }
.pagination.numbers .icon.active,
.pagination.numbers .icon.hover { background: #54b6a9; color: #ffffff; cursor: default; }

/* --- ERROR MESSAGES --- */
#errorMessage {
	background: rgba(0, 0, 0, 0.45);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

#errorMessage .message {
	background: var(--white);
	border-radius: 5px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	padding: 20px;
	width: 360px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
	cursor: default;
}
#errorMessage .message a {
	color: var(--white);
	display: block;
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	width: 100px;
	right: -120px;
}

#errorMessage .message.error, #errorMessage .message.error h4 { color: var(--red);}
#errorMessage .message.success, #errorMessage .message.success h4 { color: var(--green);}

/* --- PUSH MESSAGES --- */
#pushMessage {
	position: fixed;
	top: 20px;
	right: 10px;
	width: 300px;
	z-index: 10;
}
#pushMessage .message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	box-sizing: border-box;
	color: #d9dade;
	padding: 12px 20px 15px;
	position: relative;
	width: 100%;
	margin-bottom: 15px;
}
#pushMessage .message h5 { color: #FFFFFF; }
#pushMessage .message .close {
	background: none;
	box-shadow: none;
	position: absolute;
	top: 7px;
	right: 7px;
}
#pushMessage .message .close i {
	background: url(images/icons.svg) -168px -128px;
	filter: brightness(0) invert(1);
	display: block;
	height: 24px;
	width: 24px;
}
#pushMessage .message .close:hover,
#pushMessage .message .close:active { opacity: 0.7; }