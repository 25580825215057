@media (max-width: 750px) {
	main { padding: 60px 5px 5px;}
}
/* ----- ASIDE & HEADER ----- */
aside.tablet { width: 50px; top: 0; bottom: 0; }
aside.mobile { width: 100%; top: 0; left: 0; height: 50px; }

aside.tablet .logo,
aside.mobile .logo {
	background: #6bc9aa; /* Old browsers */
	background: -moz-linear-gradient(top, #6bc9aa 0%, #257782 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #6bc9aa 0%,#257782 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #6bc9aa 0%,#257782 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bc9aa', endColorstr='#257782',GradientType=0 ); /* IE6-9 */

	padding: 20px 10px;
}
aside.tablet .logo img, aside.mobile .logo img { width: 100%; }
aside.mobile .logo { width: 26px; padding: 12px; float: left; }

aside.tablet .shift p:first-child,
aside.mobile .shift p:first-child { margin-top: 0; text-transform: uppercase; text-align: center; }

aside a.menu { display: block; padding: 14px; text-align: center; }
aside.mobile a.menu { width: 50px; float: left; height: 50px; box-sizing: border-box; padding: 13px; }
aside a.menu i {
	background: url(../images/icons.svg) 0 -200px;
	display: block;
	height: 24px;
	width: 24px;
}
aside a.menu.navigation i { background-position: 0 -200px; }
aside a.menu.navigation.active i { filter: brightness(0) invert(0.9); }

aside a.menu.shiftsettings i { background-position: -24px -200px; }
aside a.menu.shiftsettings.active i { filter: brightness(0) invert(0.9); }

/* aside a.menu.shiftsettings.active i { background-position: -24px -224px; } */

aside a.menu.orders i { background-position: -48px -200px; }
aside a.menu.orders.active i { filter: brightness(0) invert(0.9); }

aside.tablet a.menu.logout { position: absolute; bottom: 0; }
aside.mobile a.menu.logout { position: absolute; right: 0; top: 0; }
aside a.menu.logout i { background-position: -312px -200px; }
aside a.menu.logout:active i { background-position: -312px -224px; }

aside.tablet a.menu.account { position: absolute; bottom: 50px; }
aside.mobile a.menu.account { position: absolute; right: 50px; top: 0; }
aside a.menu.account i { background-position: -336px -200px; }
aside a.menu.account:active i { background-position: -336px -224px; }

aside .tabletMenu {
	background: #343844;
	border: 1px solid #474c5a;
	position: absolute;
	bottom: 0;
	z-index: 6;
}
aside.tablet .tabletMenu {
	box-shadow: 3px 0 4px rgba(0, 0, 0, 0.5); 
	top: 0;
	left: 50px;
	width: 230px;
}
aside.mobile .tabletMenu {
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5); 
	height: 370px;
	top: 50px;
	left: 0;
	width: 100%;
}

aside.tablet .shift .dropdown { margin-bottom: 15px; line-height: 40px; }
aside.tablet .shift .dropdown:last-of-type { margin: 0; }
aside.tablet .shift button { line-height: 40px; }
aside.tablet .shift .dropdown .dropdown-toggle { top: 7px; right: 14px; transform: scale(1.2); }
aside.tablet .shiftToggle .shiftb { width: 100%; }
aside.tablet .shiftToggle .date { display: block; margin-top: 10px; text-align: center; }

aside.mobile .shift button { font-size: 15px; }
aside.mobile .shift .dropdown { margin-bottom: 15px; line-height: 50px; }
aside.mobile .shift .dropdown .dropdown-toggle { top: 10px; right: 20px; transform: scale(1.5); }
aside.mobile .shift .dropdown .dropdown-menu { top: 48px !important }
aside.mobile .shift .dropdown:last-of-type { margin: 0; }
aside.mobile .shiftToggle .shiftb { width: 100%; line-height: 50px; font-size: 15px; }
aside.mobile .shiftToggle .date { display: block; margin-top: 10px; text-align: center; }

aside.tablet .menu a i,
aside.mobile .menu a i { background: url(../images/icons.svg); }
aside.tablet .menu a.Orders i,
aside.mobile .menu a.Orders i 		{ background-position:   -48px -200px; }
aside.tablet .menu a.CallCentre i,
aside.mobile .menu a.CallCentre i 	{ background-position:   -72px -200px; }
aside.tablet .menu a.Production i,
aside.mobile .menu a.Production i 	{ background-position:   -96px -200px; }
aside.tablet .menu a.Staff i,
aside.mobile .menu a.Staff i 		{ background-position:   -120px -200px; }
aside.tablet .menu a.Directory i,
aside.mobile .menu a.Directory i 	{ background-position:   -144px -200px; }
aside.tablet .menu a.Store i,
aside.mobile .menu a.Store i 		{ background-position:   -168px -200px; }
aside.tablet .menu a.Money i,
aside.mobile .menu a.Money i 		{ background-position:   -192px -200px; }
aside.tablet .menu a.Management i,
aside.mobile .menu a.Management i 	{ background-position:   -216px -200px; }
aside.tablet .menu a.Statistics i,
aside.mobile .menu a.Statistics i 	{ background-position:   -240px -200px; }
aside.tablet .menu a.Settings i,
aside.mobile .menu a.Settings i 	{ background-position:   -264px -200px; }

aside.tablet .menu a.Orders:active i, 		aside.tablet .menu a.Orders.active i,
aside.mobile .menu a.Orders:active i, 		aside.mobile .menu a.Orders.active i 	 { background-position:   -48px -224px; }
aside.tablet .menu a.CallCentre:active i, 	aside.tablet .menu a.CallCentre.active i,
aside.mobile .menu a.CallCentre:active i, 	aside.mobile .menu a.CallCentre.active i { background-position:   -72px -224px; }
aside.tablet .menu a.Production:active i, 	aside.tablet .menu a.Production.active i,
aside.mobile .menu a.Production:active i, 	aside.mobile .menu a.Production.active i { background-position:   -96px -224px; }
aside.tablet .menu a.Staff:active i, 		aside.tablet .menu a.Staff.active i,
aside.mobile .menu a.Staff:active i, 		aside.mobile .menu a.Staff.active i 	 { background-position:   -120px -224px; }
aside.tablet .menu a.Directory:active i, 	aside.tablet .menu a.Directory.active i,
aside.mobile .menu a.Directory:active i, 	aside.mobile .menu a.Directory.active i  { background-position:   -144px -224px; }
aside.tablet .menu a.Store:active i, 		aside.tablet .menu a.Store.active i,
aside.mobile .menu a.Store:active i, 		aside.mobile .menu a.Store.active i 	 { background-position:   -168px -224px; }
aside.tablet .menu a.Money:active i, 		aside.tablet .menu a.Money.active i,
aside.mobile .menu a.Money:active i, 		aside.mobile .menu a.Money.active i 	 { background-position:   -192px -224px; }
aside.tablet .menu a.Management:active i, 	aside.tablet .menu a.Management.active i,
aside.mobile .menu a.Management:active i, 	aside.mobile .menu a.Management.active i { background-position:   -216px -224px; }
aside.tablet .menu a.Statistics:active i, 	aside.tablet .menu a.Statistics.active i,
aside.mobile .menu a.Statistics:active i, 	aside.mobile .menu a.Statistics.active i { background-position:   -240px -224px; }
aside.tablet .menu a.Settings:active i, 	aside.tablet .menu a.Settings.active i,
aside.mobile .menu a.Settings:active i, 	aside.mobile .menu a.Settings.active i 	 { background-position:   -264px -224px; }

/* --- COMMON PAGES --- */
main .page .head.mobile h2 { margin-right: 20px;}
main .page .head.mobile .toggleActive, main .page .head.mobile .toggleTask { display: block; margin: 5px 0 0; }
main .page .item form > .head.mobile { position: relative; top: 0; padding: 0; left: 0 !important; }

main .page .item form > .head.mobile + .section { margin-top: 15px; }
main .page .item form > .head.mobile + .backblock { margin-top: 25px; }

/* --- ROLE PAGE --- */
#RoleItem .departmentBlock.mobile { margin: 0 }

/* --- FILIALS PAGE --- */
#FilialItem .pos.mobile > .col { padding-right: 0;} 
#FilialItem .pos.mobile .remove { top: 27px; right: -10px; }

/* --- RELEASE PAGE --- */x
#ReleasePage.tablet .item { margin: 10px 0; }
#ReleasePage.tablet .item span,
#ReleasePage.tablet .item input,
#ReleasePage.tablet .item button { line-height: 42px; }

/* --- CHECK LIST EXECUTION PAGE --- */
#CheckListPage.tablet .task { margin: 10px 0; }
#CheckListPage.tablet .task .col,
#CheckListPage.tablet .task .col button { line-height: 42px; }
