body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face { font-family: "Roboto_Medium"; src: url(/static/media/Roboto-Medium.99fc0816.ttf); }
@font-face { font-family: "Roboto"; src: url(/static/media/Roboto-Regular.54a91b06.ttf); }

:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--green10: #54b6a91A;
	--dark-green: #2c7982;
	--text: #5e626d;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
	--blue: #3b96e3;
}

*:not(select) { border: none; margin: 0; padding: 0; outline: none; }
article, aside, canvas, figure, figure img, figcaption, hgroup, footer, header, nav, section, audio, video { display: block; }
ul, ol { list-style: none; }
a { cursor: pointer; text-decoration: none; transition: all 0.2s linear; }

html, body { background: #f5f6fb; background: var(--main-light-gray); color: #5e626d; color: var(--text); font: 13px/21px "Roboto", Arial, sans-serif; }
body { overflow-x: hidden; }

@media print {
	html, body { background: #FFFFFF; color: #000000; }  
}

h2 { font: normal 18px/28px "Roboto", Arial, sans-serif; }
h3 { font: normal 16px/30px "Roboto", Arial, sans-serif; }
h4 { font: normal 15px/25px "Roboto", Arial, sans-serif; }
h5 { font: normal 13px/25px "Roboto", Arial, sans-serif; }
h6 { font: normal 12px/21px "Roboto", Arial, sans-serif; }
h1,h2,h3,h4, h6 { text-transform: uppercase; }
h2,h3,h4,h5 { color: #5e626d; color: var(--text); }
h1 a, h2 a, h3 a, h4 a, h5 a { color: inherit; font: inherit; text-transform: inherit; display: inline-block; }
@media (max-width: 1030px) {
	h2 { font: normal 16px/26px "Roboto", Arial, sans-serif; }
}

p.error { color: #ff2b65; color: var(--red); }
p.success { color: #54b6a9; color: var(--green); }

.comment {
	font-size: 11px;
	color: #888888;
	line-height: 16px;
	margin: -8px 0 8px;
}

.clearfix { box-sizing: border-box; display: block; position: relative; }
.clearfix:after {
	clear: both;
	content: ' ';
	display: block;
	font-size: 0;
	line-height: 0;
	visibility: hidden;
	width: 0;
	height: 0;
}

svg { width: 20px; fill: #5e626d; fill: var(--text); }
svg * { fill: #5e626d; fill: var(--text); }
a:hover svg * { fill: #54b6a9; fill: var(--green); }
button svg *, .button svg * { fill: #FFFFFF; fill: var(--white); }
.remove:not(button):hover svg * { fill: #ff2b65; fill: var(--red);}

a.iconSvg { display: block; }
a.iconSvg.active svg * { fill: #54b6a9; fill: var(--green); }

.button.iconSvg { display: -webkit-flex; display: flex; grid-gap: 10px; gap: 10px; -webkit-align-items: center; align-items: center; }
.button.iconSvg svg * { fill: #FFFFFF; fill: var(--white);}

.col { box-sizing: border-box; display: block; float: left; padding-right: 20px; }
@media (max-width: 1280px) {
	.col { padding-right: 15px; }
}
@media (max-width: 1030px) {
	.col { padding-right: 10px; }
}
.col.col5 { width: 5%; }
.col.col10 { width: 10%; }
.col.col15 { width: 15%; }
.col.col20 { width: 20%; }
.col.col25 { width: 25%; }
.col.col30 { width: 30%; }
.col.col35 { width: 35%; }
.col.col40 { width: 40%; }
.col.col45 { width: 45%; }
.col.col50 { width: 50%; }
.col.col55 { width: 55%; }
.col.col60 { width: 60%; }
.col.col65 { width: 65%; }
.col.col70 { width: 70%; }
.col.col75 { width: 75%; }
.col.col80 { width: 80%; }
.col.col85 { width: 85%; }
.col.col90 { width: 90%; }
.col.col95 { width: 95%; }
.col.col100 { width: 100%; }

.col.col1_1 { width: 100%; }
.col.col1_2 { width: 50%; }
.col.col1_3 { width: 33.3%; }
.col.col1_4 { width: 25%; }
.col.col1_5 { width: 20%; }
.col.col2_3 { width: 66.6%; }
.col.col3_4 { width: 75%; }

button, .button {
	background: #3b96e3;
	background: var(--blue); 
	/* background: -moz-linear-gradient(top, #3b96e3 0%, #2e86d0 100%); 
	background: -webkit-linear-gradient(top, #3b96e3 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 );
	 */
	border: none;
	border-radius: 5px;
	box-sizing: border-box;
	/* box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75); */
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font: 14px/35px "Roboto", Arial, sans-serif;
	padding: 0 25px;
	vertical-align: middle;
	transition: all 0.2s ease;
	width: auto;
}
@media (max-width: 980px) {
	button, .button { padding: 0 15px; line-height: 40px; }
}
.button:hover, button:hover,
.button:active, button:active {
	background: #4da8f5; /* Old browsers */
	/* background: -moz-linear-gradient(top, #4da8f5 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #4da8f5 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); */
}

button.disabled, .button.disabled, button.disabled:hover, .button.disabled:hover,
button.disabled.red, .button.disabled.red, button.disabled.red:hover, .button.disabled.red:hover,
button.disabled.green, .button.disabled.green, button.disabled.green:hover, .button.disabled.green:hover,
button.disabled.blue, .button.disabled.blue, button.disabled.blue:hover, .button.disabled.blue:hover,
button:disabled, .button:disabled, button:disabled:hover, .button:disabled:hover,
button.red:disabled, .button.red:disabled, button.red:disabled:hover, .button.red:disabled:hover,
button.green:disabled, .button.green:disabled, button.green:disabled:hover, .button.green:disabled:hover,
button.blue:disabled, .button.blue:disabled, button.blue:disabled:hover, .button.blue:disabled:hover {
	color: #fdfdfd;
	background: #c9cace;
	cursor: default;
	box-shadow: none;
}

button.blue, .button.blue {
	background: #3b96e3;
	background: var(--blue); /* Old browsers */
	/* background: -moz-linear-gradient(top, #3b96e3 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #3b96e3 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 );  */

	/* box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75); */
	color: #ffffff;
}
button.blue:hover, .button.blue:hover,
button.blue:active, .button.blue:active {
	background: #4da8f5; /* Old browsers */
	/* background: -moz-linear-gradient(top, #4da8f5 0%, #2e86d0 100%);
	background: -webkit-linear-gradient(top, #4da8f5 0%,#2e86d0 100%);
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); */
}

/* .dark button.blue, .dark .button.blue {
	box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15); 
} */

button.green, .button.green {
	background: #54b6a9;
	background: var(--green); /* Old browsers */
	/* background: -moz-linear-gradient(top, #54b6a9 0%, #35a289 100%); 
	background: -webkit-linear-gradient(top, #54b6a9 0%,#35a289 100%);
	background: linear-gradient(to bottom, #54b6a9 0%,#35a289 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54b6a9', endColorstr='#35a289',GradientType=0 );

	box-shadow: 1px 2px 10px rgba(40, 130, 109, 0.75); */
}
button.green:hover, .button.green:hover,
button.green:active, .button.green:active {
	background: #64cdbf; /* Old browsers */
	/* background: -moz-linear-gradient(top, #64cdbf 0%, #35a289 100%); 
	background: -webkit-linear-gradient(top, #64cdbf 0%,#35a289 100%);
	background: linear-gradient(to bottom, #64cdbf 0%,#35a289 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64cdbf', endColorstr='#35a289',GradientType=0 ); */
}

button.red, .button.red { 
	background: #e73535;
	/* background: -moz-linear-gradient(top, #e73535 0%, #e13131 100%); 
	background: -webkit-linear-gradient(top, #e73535 0%,#e13131 100%); 
	background: linear-gradient(to bottom, #e73535 0%,#e13131 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e73535', endColorstr='#e13131',GradientType=0 );
	box-shadow: 1px 2px 10px rgba(225, 49, 49, 0.5); */
}
button.red:hover, .button.red:hover,
button.red:active, .button.red:active { 
	background: #f94747;
	/* background: -moz-linear-gradient(top, #f94747 0%, #e13131 100%); 
	background: -webkit-linear-gradient(top, #f94747 0%,#e13131 100%);
	background: linear-gradient(to bottom, #f94747 0%,#e13131 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f94747', endColorstr='#e13131',GradientType=0 ); 
	box-shadow: 1px 2px 10px rgba(225, 49, 49, 0.5); */
}

button.gray, button.gray:hover, button.gray:active,
.button.gray, .button.gray:hover, .button.gray:active{
	background: #d5d9f2 !important;
	/* background: -moz-linear-gradient(top, #d5d9f2 0%, #c0c6e1 100%) !important; */
	/* background: -webkit-linear-gradient(top, #d5d9f2 0%,#c0c6e1 100%) !important; */
	/* background: linear-gradient(to bottom, #d5d9f2 0%,#c0c6e1 100%) !important; */
	/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d9f2', endColorstr='#c0c6e1',GradientType=0 ) !important; */
	/* box-shadow: 1px 2px 10px rgba(231, 233, 243, 0.75) !important; */
	color: #636b91;
}
a.close:hover, a.close:active { color: #2e86d0; }

button i, .button i, .icon i {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	margin: 0 7px 0 -7px;
	width: 24px;
	vertical-align: middle;
}
.icon i { margin: 0; }

button.loading, .button.loading { cursor: default; }
button.loading img, .button.loading img { height: 8px; }

a.close, a.close:hover, a.close:active {
	background: #d5d9f2; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #d5d9f2 0%,#c0c6e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d9f2', endColorstr='#c0c6e1',GradientType=0 ); /* IE6-9 */

	box-shadow: 1px 2px 10px rgba(231, 233, 243, 0.75);
	color: #636b91;
}
a.close:hover, a.close:active { color: #2e86d0; }

a.dotted { color: #5e626d; border-bottom: 1px dotted #5e626d; }
a.dotted:hover { color: #54b6a9; color: var(--green); border-bottom-color: #54b6a9; border-bottom-color: var(--green); }

button.remove, .button.remove { box-shadow: none; line-height: 32px; background: none; padding: 0; }

.icon i { background: url(/static/media/icons.9c0007f3.svg); }

.icon.edit i { background-position: -96px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.edit i, .icon:active.edit i { -webkit-filter: none; filter: none; }

.icon.remove i,
	.button.remove i, button.remove i { background-position: -120px -128px; margin: 0; }
.icon:hover.remove i, .icon:active.remove i,
	.button:hover.remove i, .button:active.remove i { opacity: 0.6; }

.icon.copy i { background-position: -24px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.copy i, .icon:active.copy i { -webkit-filter: none; filter: none; }

.icon.deactivation i { background-position: -168px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.deactivation i, .icon:active.deactivation i { -webkit-filter: none; filter: none; }
.icon.activation i { background-position: -144px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.activation i, .icon:active.activation i { -webkit-filter: none; filter: none; }

.icon.process i { background-position: -504px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.process i, .icon:active.process i { -webkit-filter: none; filter: none; }

.icon.view i { background-position: -144px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.view i, .icon:active.view i { -webkit-filter: none; filter: none; }

.icon.send i { background-position: -528px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.send i, .icon:active.send i { -webkit-filter: none; filter: none; }

.icon.zoom i { background-position: 0 -152px; }
.icon.zoom:hover i, .icon.zoom:active i { opacity: 0.7; }

.icon.activate i, .icon.active { background-position: -48px -152px; }
.icon:hover.activate i, .icon:active.activate i { -webkit-filter: brightness(0.9); filter: brightness(0.9); }

.icon.pay i { background-position: -552px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.pay i, .icon:active.pay i { -webkit-filter: none; filter: none; }

.icon.download i { background-position: -624px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.download i, .icon:active.download i { -webkit-filter: none; filter: none; }

.icon.take i { background-position: -576px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.take i, .icon:active.take i { -webkit-filter: none; filter: none; }

.icon.print i { background-position: -360px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); }
.icon:hover.print i, .icon:active.print i { -webkit-filter: none; filter: none; }

.icon.toggle i { background-position: -912px -152px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); -webkit-transform: rotate(90deg); transform: rotate(90deg); }
.icon:hover.toggle i, .icon:active.toggle i { -webkit-filter: none; filter: none; }
.icon.toggle.open i { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }
.icon:hover.toggle.open i, .icon:active.toggle.open i { -webkit-filter: none; filter: none; }

.icon.number i { background-image: none; }


button.geo i, .button.geo i { background: url(/static/media/icons.9c0007f3.svg) -816px -152px; }
button.calc i, .button.calc i { background: url(/static/media/icons.9c0007f3.svg) -792px -152px; }
button.close i, .button.close i, .icon.close i { 
	background-position: -120px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5);
}
button.close:hover i, button.close:active i, 
.button.close:hover i, .button.close:active i,
.icon.close:hover i, .icon.close:active i { opacity: 0.8; }



button.add i, .button.add i { background: url(/static/media/icons.9c0007f3.svg) -48px -128px; }
.icon.add i { background: url(/static/media/icons.9c0007f3.svg) -458px -128px; }
.blue.icon.add i { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }


/* button.activate i, .button.activate i, .icon.active { background: url(images/icons.svg) -48px -152px; } */


button.image i, .button.image i { background: url(/static/media/icons.9c0007f3.svg) -216px -152px; }


/* button.send i, .button.send i { background: url(images/icons.svg) -504px -128px; } */



button.bl i, .button.bl i { background: url(/static/media/icons.9c0007f3.svg) -696px -152px; }
button.minus i, .button.minus i { background: url(/static/media/icons.9c0007f3.svg) -720px -152px; }
button.phone i, .button.phone i { background: url(/static/media/icons.9c0007f3.svg) -744px -152px; }
button.option i, .button.option i { background: url(/static/media/icons.9c0007f3.svg) -768px -152px; }

/* button.close i, .button.close i, .icon.close i { background: url(images/icons.svg) -168px -128px; } */
button.date i, .button.date i, .icon.date i { background: url(/static/media/icons.9c0007f3.svg) -288px -128px; }
button.qr i, .button.qr i { background: url(/static/media/icons.9c0007f3.svg) -984px -128px; }


/* button:hover.edit i, .button:hover.edit i,
button:active.edit i, .button:active.edit i,
.icon:hover.edit i, .icon:active.edit i { background: url(images/icons.svg) -96px -128px; } */


/* button:hover.activate i, .button:hover.activate i,
button:active.activate i, .button:active.activate i,
.icon:hover.activate i, .icon:active.activate i { background: url(images/icons.svg) -72px -152px; } */




/* button:hover.pay i, .button:hover.pay i,
button:active.pay i, .button:active.pay i { background: url(images/icons.svg) -552px -152px; } */
button:hover.view i, .button:hover.view i,
button:active.view i, .button:active.view i { background: url(/static/media/icons.9c0007f3.svg) -144px -152px; }

button.date:hover i, .button.date:hover i, .icon.date:hover i,
button.date.active i, .button.date.active i, .icon.date.active i,
button.date:active i, .button.date:active i, .icon.date:active i { background: url(/static/media/icons.9c0007f3.svg) -840px -128px; }


/* -- tags -- */
.tag {
	background: #f5f6fb;
	border-radius: 3px;
	box-sizing: border-box;
	display: inline-block;
	line-height: 24px;
	margin: 0 5px 3px 0;
	padding: 0 10px;
}

.tag.green { background: #54b6a9; color: #FFFFFF; }
.tag.red { background: #ef8b8b; color: #ffffff; }
.tag.yellow { background: #f4e989; }
.tag.orange { background: rgb(242, 188, 139); }

.nav a { 
	cursor: pointer;
	display: block;
	text-transform: uppercase;
	font: 12px/21px "Roboto_Medium", Arial, sans-serif;
}

/* --- Login Page --- */
.backPage {
	background: #343844;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	min-height: 350px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

#loginPage {
	width: 280px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 30px;
	gap: 30px;
}
/* #loginPage > * { display: inline-flex;align-self: stretch;} */
#loginPage > img { max-width: 270px; -webkit-align-self: center; align-self: center; }
#loginPage form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
#loginPage form > * {-webkit-align-self: stretch;align-self: stretch;}
/* #loginPage form input { line-height: 30px; } */
#loginPage form .pass input { color: #535663; }
/* #loginPage form label { line-height: 30px; } */
#loginPage form button { margin-top: 20px; }

#loginPage .error { margin-top: 15px; }
#loginPage a { color: #54b6a9; color: var(--green); display: inline-block; margin-top: 20px; text-align: center; }
#loginPage p:not(.error) {color: #9799a2;color: var(--textGray); text-align: center; margin-bottom: 5px; -webkit-align-self: center; align-self: center; display: inline-block; font-size: 16px; }
#loginPage p:not(.error) a { margin: 0; }

#loginPage.register { width: 400px; }
/* #loginPage.register > img { width: 70%; align-self: center; } */

@media (max-width: 980px) {
	#loginPage, #loginPage.register {
		width: 90%;
	}
}

/* #loginPage .steps {
	background: #9699a7;
	height: 1px;
	margin: 30px 0 15px;
	position: relative;
}

#loginPage .steps a {
	display: block;
	position: absolute;
	top: -22px;
	right: 25%;
	height: 26px;
	width: 26px;
	font-weight: bold;
	text-align: center;
	border-radius: 50%;
	background: #9699a7;
	color: #343844;
	line-height: 25px;
}
#loginPage .steps a.active { background: #35a289; cursor: default; }
#loginPage .steps a.checked { background: #35a289; color: #FFFFFF; }
#loginPage .steps a.disabled { color: #68696c; cursor: default;}

#loginPage .steps a:first-child { left: 25%; } */




/* --- Sidebar --- */
aside {
	background: #343844;
	box-sizing: border-box;
	width: 260px;
	/* position: absolute; */
	position: fixed;
	z-index: 1;
}
aside.close { width: 50px; }

@media (max-width: 1050px) {
	aside.open { box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); z-index: 6; }
	aside.close { box-shadow: none; }
}

aside .logo { background: #454a5a; padding: 10px 20px; }
aside .logo a { display: block; }
aside .logo img { display: block; max-height: 50px; margin: auto; }

aside.close .logo {
	background: #6bc9aa; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #6bc9aa 0%,#257782 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bc9aa', endColorstr='#257782',GradientType=0 ); /* IE6-9 */

	padding: 20px 10px;
}
aside.close .logo img { width: 100%; }

aside .toggle {
	background: #424859;
	border-radius: 3px;
	display: block;
	margin: -28px -6px;
	padding: 12px 4px;
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 2;
}
aside .toggle:hover, aside .toggle:active { background: #5b6276; }
aside .toggle i {
	background: #868c9c;
	border-radius: 50%;
	display: block;
	height: 4px;
	margin-top: 5px;
	width: 4px;
}
aside .toggle i:first-child { margin: 0; }

aside .shift { color: #adb0bc; padding: 15px; }
aside .shift p { color: #f5f6fb; line-height: 20px; margin: 20px 0; }

aside .shift .dropdown {
	background: #454a5a;
	border-radius: 5px;
	line-height: 32px;
	margin-bottom: 5px;
	padding: 0 10px;
	position: relative;
}

aside .shift button {
	background: transparent;
	box-shadow: none;
	color: #adb0bc;
	cursor: default;
	font-size: 13px;
	line-height: 35px;
	padding: 0;
	text-align: left;
	text-transform: none;
	width: 100%;
}
aside .shift .dropdown button span { color: #FFFFFF; margin-left: 5px; }
aside .shift .dropdown .active { color: #54b6a9 !important; }

aside .shift .dropdown .dropdown-toggle {
	background: transparent;
	cursor: pointer;
	height: 24px;
	padding: 1px;
	position: absolute;
	top: 4px;
	right: 6px;
	width: 24px;
}
aside .shift .dropdown .dropdown-toggle span {
	background: url(/static/media/icons.9c0007f3.svg) 0 -128px;
	display: block;
	height: 24px;
	width: 24px;
	text-indent: -150px;
	overflow: hidden;
}
aside .shift .dropdown .dropdown-toggle:hover span,
aside .shift .dropdown .dropdown-toggle:active span { background: url(/static/media/icons.9c0007f3.svg) -24px -128px; }

aside .shift .dropdown .dropdown-menu {
	background: #454a5a;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: none;
	padding: 5px 10px;
	position: absolute;
	top: 29px !important;
	left: 0;
	width: 100%;
	-webkit-transform: none !important;
	        transform: none !important;
	z-index: 1;
}
aside .shift .dropdown .dropdown-menu.show { display: block; }
aside .shift .dropdown .dropdown-menu button { cursor: pointer; }
aside .shift .dropdown .dropdown-menu button:hover,
aside .shift .dropdown .dropdown-menu button:active { color: #FFFFFF; }

aside .shiftToggle { padding: 0 15px 10px; }
aside .shiftToggle p { color: #f5f6fb; margin-bottom: 10px; }
aside .shiftToggle .shiftb {
	background: #54b6a9;
	box-shadow: none;
	font-size: 13px;
    margin-right: 10px;
	padding: 0 10px;
	width: auto;
}
aside .shiftToggle .shiftb:hover,
aside .shiftToggle .shiftb:active { background: #35a289; }

aside .shiftToggle .shiftb.finish { background: #e73535; }
aside .shiftToggle .shiftb.finish:hover,
aside .shiftToggle .shiftb.finish:active { background: #c01d1d; }
aside .shiftToggle .shiftb i { background: url(/static/media/icons.9c0007f3.svg) -288px -224px; margin: 0; }

aside .shiftToggle .date { display: inline-block; }

aside.close .shiftToggle .shiftb { margin: 0 -10px; padding: 0 8px; }


aside .menu { overflow: auto; }
aside .nav a { color: #adb0bc; line-height: 24px; padding: 12px; position: relative; }
aside .nav a:hover, aside .nav a:active, aside .nav a.active { color: #ffffff; }
aside .nav a i {
	display: inline-block;
	height: 24px;
	margin-right: 10px;
	vertical-align: middle;
	width: 24px;
}

aside .nav a.CheckList i 	{ background: url(/static/media/icons.9c0007f3.svg) 0 -80px; }
aside .nav a.CourierTaxes i 	{ background: url(/static/media/icons.9c0007f3.svg) -24px -80px; }
aside .nav a.Staff i 		{ background: url(/static/media/icons.9c0007f3.svg) -48px -80px; }
aside .nav a.StaffFood i { background: url(/static/media/icons.9c0007f3.svg) -72px -80px; }
aside .nav a.Movement i 	{ background: url(/static/media/icons.9c0007f3.svg) -96px -80px; }
aside .nav a.StopList i 	{ background: url(/static/media/icons.9c0007f3.svg) -120px -80px; }
aside .nav a.Documents i 	{ background: url(/static/media/icons.9c0007f3.svg) -144px -80px; }
aside .nav a.Reports i 		{ background: url(/static/media/icons.9c0007f3.svg) -168px -80px; }
aside .nav a.FAQ i 			{ background: url(/static/media/icons.9c0007f3.svg) -192px -80px; }
aside .nav a.Algorithms i 	{ background: url(/static/media/icons.9c0007f3.svg) -216px -80px; }

aside .nav .dropdown-toggle::after {
	border: 5px solid transparent;
	border-top-color: #adb0bc;
	content: "";
	position: absolute;
	top: 21px;
	right: 20px;
}
aside .nav .show .dropdown-toggle::after {
	border: 5px solid transparent;
	border-bottom-color: #adb0bc;
	top: 16px;
}

aside .nav .dropdown-menu { padding: 0 0 0 50px; display: none; }
aside .nav .dropdown-menu.show { display: block; position: static !important; -webkit-transform: none !important; transform: none !important; }
aside .nav .dropdown-menu a { padding: 3px 12px; text-transform: none; }
aside .nav .dropdown-menu .subGroup { margin-bottom: 5px; }

aside.close .nav a { padding: 12px 13px; }
aside.close .nav a span { display: none; }

/* --- Header --- */
header {
	background: #ffffff;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
	/* margin-left: 260px; */
	height: 70px;
	position: fixed;
	left: 50px;
	right: 0;
	z-index: 3;
}

header .menu {
	box-sizing: border-box;
	float: left; 
	height: 70px;
	padding: 0 0 0 10px;
	position: relative;
}
header .menu .arrow {
	background: #FdFeFf;
	box-sizing: border-box;
	display: block;
	height: 70px;
	text-align: center;
	padding: 33px 0;
	position: absolute;
	top: 0;
	width: 20px;
	z-index: 5;
}
header .menu .arrow i {
	border: 10px solid transparent;
	display: block;
	height: 0;
	width: 0;
}
header .menu .arrow.prev { border-right: 1px solid #d9dade; left: 0; }
header .menu .arrow.prev i { border-right-color: #d9dade; }

header .menu .arrow.prev:hover,
header .menu .arrow.prev:active { border-right-color: #54b6a9; }
header .menu .arrow.prev:hover i,
header .menu .arrow.prev:active i { border-right-color: #54b6a9; }

header .menu .arrow.next { border-left: 1px solid #d9dade; right: 0; }
header .menu .arrow.next i { border-left-color: #d9dade; }

header .menu .arrow.next:hover,
header .menu .arrow.next:active { border-left: 1px solid #54b6a9; right: 0; }
header .menu .arrow.next:hover i,
header .menu .arrow.next:active i { border-left-color: #54b6a9; }

header .nav { width: -webkit-max-content; width: -moz-max-content; width: max-content; }

header .scrollable .nav { position: relative; transition: left 0.2s linear; }

header .nav .nav-item { display: inline-block; position: relative; }
header .nav a, header .nav .title {
	box-sizing: border-box;
	color: #68696c;
	font-size: 10px;
	font-family: "Roboto_Medium", Arial, Helvetica, sans-serif;
	text-align: center;
	padding: 5px 11px;
}
header .nav a i { 
	background: url(/static/media/icons.9c0007f3.svg) 0 0; 
	-webkit-filter: brightness(0) invert(0.4); 
	        filter: brightness(0) invert(0.4);
	display: block; 
	height: 35px; 
	margin: 0 auto 4px; 
	width: 35px; 
}
header .nav a.Orders i 		{ background-position: 0 0; }
header .nav a.CallCentre i 	{ background-position: -35px 0; }
header .nav a.Production i 	{ background-position: -70px 0; }
header .nav a.Staff i 		{ background-position: -105px 0; }
header .nav a.Directory i 	{ background-position: -140px 0; }
header .nav a.Store i 		{ background-position: -175px 0; }
header .nav a.Money i 		{ background-position: -210px 0; }
header .nav a.Management i 	{ background-position: -245px 0; }
header .nav a.Statistics i 	{ background-position: -280px 0; }
header .nav a.Settings i 	{ background-position: -315px 0; }
header .nav a.Site i 		{ background-position: -351px 0; }

header .nav a:hover i, header .nav a:active i, header .nav a.active i { -webkit-filter: none; filter: none; }
header .nav a:hover, header .nav a.active { color: #54b6a9; }

header .dropdown-menu { position: absolute; display: none; z-index: 5; min-width: 150px; text-align: left; }
header .dropdown-menu.show { display: block; -webkit-transform: translate3d(0,70px,0) !important; transform: translate3d(0,70px,0) !important; }

header .dropdown-menu .covermenu {
	background: #FFFFFF;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
	margin-top: 3px;
	padding: 5px 0;
	position: relative;
}
header .dropdown-menu .covermenu.cols { -webkit-column-count: 2; column-count: 2; -webkit-column-rule: 1px solid #f1f2f5; column-rule: 1px solid #f1f2f5; }
header .dropdown-menu .covermenu:after {
	border: 7px solid transparent;
    border-bottom: 5px solid #FFF;
    content: "";
    margin-left: -5px;
    position: absolute;
    top: -12px;
    left: 40px;
}

header .dropdown-menu .covermenu .subGroup {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}
header .dropdown-menu button { display: block; }
header .dropdown-menu a, header .dropdown-menu .title {
	color: #54b6a9;
	font-size: 12px;
	float: none;
	padding: 3px 15px;
	text-transform: none;
	text-align: left;
	white-space: nowrap;
}
header .dropdown-menu a:hover, header .dropdown-menu a:active, header .dropdown-menu a.active { color: #267469; }
header .dropdown-menu a.subitem {
	color: #6d6d6d;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	padding-left: 30px;
}
header .dropdown-menu a.subitem:hover, header .dropdown-menu a.subitem:active { color: #252525; }

header .adds { 
	background: #ffffff;
	float: right; 
	max-height: 70px;
	position: relative;
	z-index: 1;
}
header .adds > div { border-left: 1px solid #d9dade; float: left; }

header .location {
	line-height: 70px;
	height: 100%;
	position: relative;
}
header .location .dropdown { padding: 0 20px; position: relative; }

header .location button {
	background: transparent;
	box-shadow: none;
	color: #68696c;
	cursor: default;
	font-size: 12px;
	padding: 0;
	text-align: left;
	text-transform: none;
	width: 100%;
}

header .location .dropdown .dropdown-menu {
	background: #FFFFFF;
	border-radius: 0 0 3px 3px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: none;
	padding: 5px 10px;
	position: absolute;
	top: 70px !important;
	left: 0 !important;
	width: 100%;
	min-width: auto;
	-webkit-transform: none !important;
	        transform: none !important;
	z-index: 1;
}
header .location .dropdown .dropdown-menu.show { display: block; }
header .location .dropdown .dropdown-menu button { cursor: pointer; line-height: 25px; }
header .location .dropdown .dropdown-menu button:hover,
header .location .dropdown .dropdown-menu button:active { color: #343844; }


header .location span { display: inline-block; white-space: nowrap; }
header .location i {
	background: url(/static/media/icons.9c0007f3.svg) -192px -128px;
	display: inline-block;
	margin-right: 5px;
	height: 24px;
	vertical-align: middle;
	width: 24px;
}

header .adds .user { 
	box-sizing: border-box; 
	float: right; 
	padding: 5px 55px 5px 20px; 
	position: relative;
	z-index: 1;
}
header .user .photo {
	background: #d9dade;
	background-position: center;
	background-size: cover; 
	border-radius: 50%;
	display: inline-block;
	height: 60px;
	margin-right: 10px;
	vertical-align: middle;
	width: 60px;
}

header .user .userName { display: inline-block; vertical-align: middle; max-width: 100%; overflow: hidden; }
/* header .user .userName a { color: #535663; } */
header .user .userName p { font-size: 11px; }
header .user .userName a {
	color: #343844;
	font: 11px/23px "Roboto_Medium", Arial, sans-serif;
	text-transform: uppercase;
	white-space: nowrap;
}

header .logout {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 24px;
	z-index: 2;
}
header .logout i { 
	background: url(/static/media/icons.9c0007f3.svg) -312px -152px;
	display: block;
	-webkit-filter: brightness(0) invert(0.5);
	        filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
}
header .logout:hover i,
header .logout:active i { -webkit-filter: none; filter: none; }

@media (max-width: 1500px) {
	header .user .photo { display: none; }
	header .adds .user { padding: 14px 55px 14px 20px; }
}

@media (max-width: 1420px) {
	header .user .position { display: none; }
	header .adds { width: 150px; }
	header .adds .user { padding: 7px 30px 2px 15px; width: 100%; }
	header .location { line-height: 38px; }
	header .location .dropdown { padding: 0 15px; }
	header .adds > div { float: none; }
}

@media (max-width: 1270px) {
	/* header .adds { display: none; } */
}

/* --- COMMON PAGE --- */
.page { position: relative; min-height: 300px; height: 100%; }
main { margin-left: 260px; padding: 85px 20px 20px; position: relative; z-index: 0; }
@media (max-width: 1280px) {
	main { padding: 85px 15px 15px; }
}
@media (max-width: 1030px) {
	main { padding: 85px 10px 10px; }
}

main .page .head { margin-bottom: 10px; position: relative; display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: center; align-items: center; }
/* main .page h2 { display: inline-block; line-height: 35px; margin-right: 40px; vertical-align: middle; }
main .page button { float: right; } */

main .page .head .toggleActive, main .page .head .toggleTask {
	border-bottom: 1px dotted #5b6276;
	color: #5b6276;
	display: inline-block;
	font-size: 12px;
	line-height: 17px;
	margin-left: 35px;
	margin-top: -3px;
	text-transform: none;
	vertical-align: middle;
}
main .page .head .toggleActive:hover, main .page .head .toggleTask:active {
	border-color: #54b6a9;
	border-color: var(--green);
	color: #54b6a9;
	color: var(--green);
}

main .page .toggleFilter { position: absolute; top: 5px; left: 230px; }
main .page .toggleFilter i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -360px -128px;
	display: block;
	height: 24px;
	width: 24px;
}
main .page .toggleFilter:hover i,
main .page .toggleFilter.active i,
main .page .toggleFilter:active i, #CheckListReport .toggleFilter.active i { background-position-x: -384px; }


main .page .filters .form-row {
	float: left;
	margin-bottom: 10px;
	padding-right: 20px;
	width: 33.3%;
}
main .page .filters .checkGroupLabel { width: 100%; }
main .page .filters button { line-height: 32px; }
main .page .head .toggle {
	color: #71747e;
	display: block;
	position: absolute;
	top: 12px;
	right: 0;
}
main .page .head .toggle i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 10px;
	vertical-align: middle;
	width: 24px;
}
main .page .close .head .toggle i { background-position: -240px -128px; }

main .page .table button {
	background: transparent;
	box-shadow: none;
	float: none;
	line-height: 24px;
	padding: 0;
	text-align: center;
}
main .page .table button i { margin: 0; }
main .page .table .notactive { color :#c8c9cd; }

/* main .page .item .head { margin-bottom: 10px; } */
/* --- item page --- */
main .page .item.flex-page {

}


main .page .item form > .head {
	position: fixed;
    top: 70px;
    z-index: 4;
    right: 0;
    left: 260px;
    background: #f5f6fb;
	padding: 15px 20px 10px;
	margin: 0;
}

@media (max-width: 1280px) {
	main .page .item form > .head { padding: 15px 15px 10px; }
}
@media (max-width: 1030px) {
	main .page .item form > .head { padding: 15px 10px 10px; }
}

main.tablet .page .item form > .head { top :0; }



main .page .item .head .button, 
main .page .item .head button { margin-right: 10px; float: none; }
main .page .item .head button { width: 120px; }
main .page .item .head .error, main .page .item .head .success { display: inline-block; }

main .page .item .section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	padding: 15px 20px;
	margin-top: 55px;
}
main .page .item .section .gray { background: #f8f9fb; }
main .page .item .section .gray input { background: #ffffff; }
main .page .item .section .gray input:disabled { background: #EEEEEE; }
main .page .item .section .gray label { z-index: 1; }

/* main .page .item .section */

main .page .item .backblock { margin-top: 40px; }
main .page .item .backblock + .section { margin-top: 15px; }
/* main .page .item .backblock .col { margin: 0; padding-right: 20px; } */
main .page .item .backblock input { background: #FFFFFF; }
main .page .item .backblock input:disabled { background: #EEEEEE; }
main .page .item .backblock label { z-index: 1; }

main .page .item .backblock.fixed { 
	background: #f5f6fb;
	margin: 0;
	padding: 0 20px 10px;
	position: fixed;
	top: 130px;
	right: 0;
	z-index: 3;
}
@media (max-width: 1280px) {
	main .page .item .backblock.fixed { padding: 0 15px 10px; }
}
@media (max-width: 1030px) {
	main .page .item .backblock.fixed { padding: 0 10px 10px; }
}


@media (max-width: 1280px) {
	main .page .item form > .head { padding: 15px 15px 10px; }
}

main .page .item h4 { margin-bottom: 15px; }
main .page .block { margin-top: 15px; border-top: 1px solid #dddddd; padding-top: 10px; }
main .page .block:first-of-type { margin-top: 0; border: none; padding-top: 0; }
main .page .block:last-child { padding-bottom: 20px; }
/* main .page .block .col { padding-right: 20px; } */

main .page .item h5 { line-height: 21px; font-size: 11px; }

main .page .item.two-cols .section > .clearfix { overflow: hidden; min-height: 100%; }
main .page .item.two-cols .section > .clearfix > .col { padding: 15px 20px 99999px; margin-bottom: -99999px; }

main .page .item .section { padding: 0; overflow: auto; height: auto !important; }
main .page .item .section > .col { padding: 15px 20px; }
main .page .item .section .checkGroupLabel { width: 100%; max-width: 100%; }

main .page .item .rights .checkAll .form-row { margin-top: 0; text-align: right; padding: 0; }
main .page .item .rights .toggle { position: absolute; top: 10px; right: 0; }
main .page .item .rights .block:first-of-type .toggle { top: 0; }
main .page .item .rights h5 { text-transform: uppercase; }

main .page .item .dashboards .head h4 { float: left; }
main .page .item .dashboards .head .checkAll { float: right; margin-top: -5px; }
main .page .item .dashboards .head .checkAll .form-row { margin: 0; }

main .page .item .dashboards .Sidebar { border-right: 1px solid #dddddd; }
main .page .item .dashboards .Widget { padding-left: 20px; }

main .page .item .dashboards .row { margin-bottom: 15px; }
main .page .item .dashboards .row .col { margin: 0; }
main .page .item .dashboards .row .toggleRow { 
	color: #787c86; 
	display: block;
	float: right; 
	font-size: 12px;
	line-height: 24px;
	margin-bottom: 10px; 
}
main .page .item .dashboards .row .toggleRow i { 
	background: url(/static/media/icons.9c0007f3.svg) no-repeat;
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	vertical-align: middle;
	width: 24px;
}
main .page .item .dashboards .row.open .toggleRow i { background-position: -216px -128px; }
main .page .item .dashboards .row.close { margin: 0; }
main .page .item .dashboards .row.close .toggleRow i { background-position: -240px -128px; }

main .page .item .dashboards .row.close .checkGroupLabel { display: none; }

main .page .section .titles span { 
	color: #9f9f9f;
	text-transform: uppercase; 
	font-size: 10px;     
	white-space: nowrap;
    text-overflow: ellipsis;
	overflow: hidden; 
}

/* --- ROLES PAGE --- */
#RoleItem .mainInfo { display: -webkit-flex; display: flex; -webkit-align-items: flex-end; align-items: flex-end; }


#RolesPage .confirmBlock .form-row { margin-bottom: 20px;}

#RolesPage .backblock .button { margin-top: 19px; line-height: 32px; }

#RoleItem .section label:not(.checkGroupLabel) { color: #5e626d; font-size: 13px; text-transform: uppercase; }

/* --- USERS PAGE --- */
#UsersPage .table .button.shift {
	padding: 0 25px;
	line-height: 30px;
}
#UsersPage .table .button.green { background: #54b6a9; }
#UsersPage .table .button.red { background: #e73535; }
#UsersPage .table .button.yellow { 
	background: #f2bc8b; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f2bc8b 0%,#e28b50 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2bc8b', endColorstr='#e28b50',GradientType=0 ); /* IE6-9 */
}

#UsersPage .courierShift .checkGroupLabel { width: 100%; max-width: 100%; }
#UsersPage .courierShift .buttons { float: none; }
#UsersPage .courierShift .error { margin: 10px 0; font-size: 13px; }

#UserItem .main > div { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; }
#UserItem .main .form-row { margin-right: 20px; -webkit-flex-grow: 1; flex-grow: 1; }
#UserItem .main .reset {-webkit-justify-content: right;justify-content: right; margin-bottom: 10px;}
#UserItem .main .tips { -webkit-flex-direction: column; flex-direction: column;}

#UserItem .roles { padding: 0; }
#UserItem .roles .checkGroupLabel { width: 50%; }
#UserItem .roles .form-row + .form-row { margin-top: 40px; }
#UserItem .roles .comment { margin: 10px 0 5px; }

#UserItem .filials .head{ margin: 0; }
#UserItem .filials .head h4 { float: left; }
#UserItem .filials .head .checkAll { float: right; margin-top: -5px; }
#UserItem .filials .head .form-row { margin: 0; width: auto; }
#UserItem .filials .head + .block { border: none; }
#UserItem .filials .block { display: -webkit-flex; display: flex; -webkit-flex-flow: wrap; flex-flow: wrap; }
#UserItem .filials .block .head { width: 100%; }

#UserItem .filials h5 { color: #9699a7; }
#UserItem .filials .cassa { margin-left: 20px; }

#UserItem .filials .filial { margin-bottom: 20px; }

#UserItem .dashboards { border-top: 1px solid #dddddd; }

#UserItem .section .dashboards label:not(.checkGroupLabel) { color: #5e626d; font-size: 13px; text-transform: uppercase; }

/* --- FILIALS PAGE --- */
#FilialItem h4 { margin: 0; }
#FilialItem .section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
#FilialItem .section .mainInfo {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}
#FilialItem .section .mainInfo > div { padding: 20px 15px 15px; width: 60%;}
#FilialItem .section .mainInfo .workdays { 
	/* border-radius: 0 3px 3px 0;  */
	width: 40%;
}
#FilialItem .section .mainInfo .workdays .comment { margin: 15px 0 35px;}
/* #FilialItem .workdays h4 { margin: 22px 0 20px; } */
#FilialItem .workdays .shift { width: 35%; }
#FilialItem .workdays .head {
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 15px 0 5px;
}
#FilialItem .workdays .head h5 { -webkit-flex-grow: 1; flex-grow: 1; }
#FilialItem .workdays .head a { margin: 0 10px; font-size: 10px; }

/* #FilialItem .workdays label { z-index: 1; } */

#FilialItem .workdays .day {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: right;
	        justify-content: right;
	 margin-top: 5px;
}
#FilialItem .workdays .day .form-row { width: 60%;  margin: 0; }
#FilialItem .workdays .day .form-row:first-child { width: 40%; }
#FilialItem .workdays .day a { font-size: 10px; margin-bottom: 10px; }


/* #FilialItem.two-cols .section > .clearfix { min-height: auto; } */

#FilialItem .poses { border-top: 1px solid #dddddd; margin: 0; padding: 20px; }
#FilialItem .poses .pos { border-top: 1px solid #dddddd; padding: 10px 0; } 
#FilialItem .poses .pos:first-of-type { border: none; } 

#FilialItem .pos .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: 20px;
}
#FilialItem .pos .remove i { margin: 0; }

#FilialItem .add { margin-top: 25px; }



/* --- STOCKS PAGE --- */
#StockItem .section { padding: 20px; }
#StockItem .section .col { padding-right: 10px; }

#StockItem .section .toggle {
	background: none;
	box-shadow: none;
	color: #5e626d;
	display: block;
	float: right;
    text-transform: none;
    font-size: 12px;
}
#StockItem .section .toggle i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -218px -128px;
	display: inline-block;
	height: 24px;
	margin-left: 5px;
	vertical-align: middle;
	width: 24px;
}
#StockItem .section .toggle.close i { background-position: -240px -128px; }

#StockItem .section .title { background: #f5f6fb; margin: 5px -20px; padding: 5px 20px; }
#StockItem .section .title .remove {
	background: transparent;
	color: #5e626d;
	font-size: 12px;
	padding: 0;
	position: absolute;
	top: 25px;
	right: 20px;
}

#StockItem .section .item { padding-left: 20px; }
#StockItem .section .group .add { margin: 10px 0 10px 20px; }

#StockItem .semimanufactures .col { padding-right: 10px; }
#StockItem .semimanufactures .remove { right: 10px; }
#StockItem .semimanufactures .checkGroupLabel { width: auto;  margin-right: 20px; }

#StockItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: 20px;
}
#StockItem .remove i { margin: 0; }

#StockItem .section .add { margin-top: 20px; line-height: 32px; }

/* --- PROVIDERS PAGE --- */
#ProviderItem .backblock .suggest { margin-top: -2px; }
/* #ProviderItem .dadata .react-dadata { width: 90%; } */
#ProviderItem .dadata a.close {
	background: none;
	box-shadow: none;
	padding: 0;
	position: absolute;
	top: -4px;
	right: 0;
}
#ProviderItem .dadata a.close i { background: url(/static/media/icons.9c0007f3.svg) -168px -128px; }

#ProviderItem .requisites .dadata .react-dadata__suggestions {
	max-height: 250px;
}

#ProviderItem .day .form-row { float: left; margin-top: 5px; width: 70%; }
#ProviderItem .day .form-row:first-child { width: 30%; }

#ProviderItem .contacts .contact { margin-top: 40px; position: relative; }
#ProviderItem .contacts .contact:first-of-type { margin-top: 30px; }
#ProviderItem .contacts .contact .form-input { width: 90%; }

#ProviderItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: -5px;
	right: 0;
}
#ProviderItem .remove i { margin: 0; }

#ProviderItem .add { margin-top: 20px; line-height: 32px; }

#ProviderItem .products .form-row { padding-right: 10px; }
#ProviderItem .products .remove { right: -16px; top: 17px; }
#ProviderItem .products .select__menu-list { max-height: 100px; overflow: auto; }
#ProviderItem .products .product:last-of-type .select__menu-list { max-height: 70px; }

#ProviderItem .workdays .periodTime input { width: 70px; }

#ProviderItem .history .order { border-top: 1px solid #dddddd; padding: 5px 0; }
#ProviderItem .history .order:first-of-type { border: none; }
#ProviderItem .history .order .col { 
	overflow: hidden;
	padding-right: 10px; 
	text-overflow: ellipsis;
	white-space: nowrap;
}

#ProviderItem .history .order i {
	background: #dddddd;
	border-radius: 50%;
	display: inline-block;
	height: 8px;
	margin-right: 8px;
	width: 8px;
}

#ProviderItem .history .order i.success { background: #54b6a9; }
#ProviderItem .history .order i.ready { background: #f4bf3b; }

#ProviderItem .history .toggle { margin-top: 10px; display: inline-block; float: right; }





/* --- CHECK LIST MANAGE PAGE --- */
#CheckListManagePage > section > h2 { margin-top: 30px; }
#CheckListManagePage .head .buttons { display: -webkit-flex; display: flex; grid-gap: 20px; gap: 20px; }

#CheckListItem form {display: -webkit-flex;display: flex; -webkit-flex-direction: column; flex-direction: column; grid-gap: 20px; gap: 20px; }
#CheckListItem .section { padding: 15px 20px; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; margin-top: 0; }
@media (max-width: 1030px) {
	#CheckListItem .section { padding: 10px 15px; }
	#CheckListManagePage .head { -webkit-flex-direction: column; flex-direction: column; -webkit-align-items: start; align-items: start; }
}
@media (max-width: 700px) {
	#CheckListItem .section { padding: 5px 10px; }
}

#CheckListItem .section.main {
	-webkit-flex-direction: row;
	        flex-direction: row;
	grid-gap: 20px;
	gap: 20px;
	margin-top: 55px;
}
#CheckListItem .section.main > div { width: 40%;}
#CheckListItem .section.main > div:first-child { width: 60%;}

#CheckListItem .button.iconSvg { width: -webkit-max-content; width: -moz-max-content; width: max-content; }

#CheckListItem .grouptask { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; grid-gap: 10px; gap: 10px; padding: 0; }
#CheckListItem .grouptitle { 
	background: #f5f6fb; 
	background: var(--main-light-gray); 
	padding: 10px 20px; 
	display: -webkit-flex; 
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}
#CheckListItem .grouptitle > *:first-child { width: 65%; }

#CheckListItem .grouptitle input { background: #ffffff; }
#CheckListItem .grouptitle h4 { margin: 0; }

#CheckListItem .grouptask .button.iconSvg { margin: 0 20px 20px; }

#CheckListItem .tasks { 
	display: grid;
	grid-template-columns: 35% auto 20% 25px;
	grid-column-gap: 2%;
    grid-row-gap: 25px;
	padding: 0 20px;
}
#CheckListItem .tasks.simple { grid-template-columns: 95% 3%; }
#CheckListItem .tasks h5 { text-transform: uppercase; }
#CheckListItem .tasks textarea { height: 90px; }

#CheckListItem .tasks .periodSettins { display: -webkit-flex; display: flex; grid-gap: 20px; gap: 20px;}
#CheckListItem .tasks .periodSettins > div { width: 50%; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; grid-gap: 10px; gap: 10px;}

#CheckListItem .tasks .actions a { display: block; margin-bottom: 10px;}
#CheckListItem .tasks .week .checkGroupLabel { width: auto; margin-right: 5px; }

/* --- CHECK LIST EXECUTION PAGE --- */
#CheckListPage .taskStat > div { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: center; align-items: center; margin-bottom: 20px; }
#CheckListPage .taskStat .stat {
	/* margin : 20px 0; */
	height: 20px;
	border-radius: 10px;
	background: #b6b7c0;
	background: var(--dark-gray);
	width: 500px;
	display: -webkit-flex;
	display: flex;
	position: relative;
	overflow: hidden;
}
#CheckListPage .taskStat .stat > div { position: relative; }
#CheckListPage .taskStat .stat .done { background: #54b6a9; background: var(--green); }
#CheckListPage .taskStat .stat .expired { background: #ff2b65; background: var(--red); }

#CheckListPage .taskStat .stat span { font-size: 10px; position: absolute; right: 6px; line-height: 20px; color: #FFFFFF; color: var(--white); }

#CheckListPage.mobile .taskStat > div:not(:first-child) { -webkit-flex-direction: column; flex-direction: column; -webkit-align-items: flex-start; align-items: flex-start; }
#CheckListPage.mobile .taskStat .stat { width: 100%; margin-bottom: 5px; }

#CheckListPage .taskStat .filter {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	grid-gap: 10px;
	gap: 10px;
	width: 45%;
}
#CheckListPage .taskStat .filter > div { width: 45%; margin: 0; }

#CheckListPage .tasksList {
	margin-top: 20px;
	display: grid;
	grid-template-columns : 62% 18% 18%;
	grid-column-gap: 1%;
	grid-row-gap: 5px;
}
#CheckListPage .tasksList .title { display: inline-grid; grid-column-end: span 3; }
#CheckListPage .tasksList .name,
#CheckListPage .tasksList .time {
	background: #ffffff;
	border-radius: 3px;
	line-height: 35px;
	min-height: 35px;
	padding: 0 20px;
}

#CheckListPage .tasksList .name { 
	border-left: 3px solid #b6b7c0; 
	border-left: 3px solid var(--dark-gray); 
	display: -webkit-flex; 
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}
#CheckListPage .tasksList .name .iconSvg { height: 24px; margin-top: 5px; position: relative; }
#CheckListPage .tasksList .name.rejected .iconSvg::after {
	background: #ff2b65;
	background: var(--red);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	content: "";
	position: absolute;
	right: 0;
}
#CheckListPage .tasksList .name i { margin-right: 5px; }
#CheckListPage .tasksList .name i svg { width: 16px; height: 16px; }
#CheckListPage .tasksList .time { text-align: center; } 

#CheckListPage .tasksList .name.done { border-left: 3px solid #54b6a9; border-left: 3px solid var(--green); background: #54b6a91A; background: var(--green10); }
#CheckListPage .tasksList .name.done i svg * { fill: #54b6a9; fill: var(--green); }
#CheckListPage .tasksList .name.expired,
#CheckListPage .tasksList .name.rejected { border-left: 3px solid #ff2b65; border-left: 3px solid var(--red); background: #ff2b651A; background: var(--red10) }
#CheckListPage .tasksList .name.rejected i svg * { fill: #ff2b65; fill: var(--red); }
#CheckListPage .tasksList .name.disabled { background: #b6b7c01A; background: var(--dark-gray10); }
#CheckListPage .tasksList .name.oncheck { background: #f0cd051A; background: var(--yellow10); border-left: 3px solid #f0cd05; border-left: 3px solid var(--yellow); }
#CheckListPage .tasksList .name.oncheck i svg * { fill: #f0cd05; fill: var(--yellow); }

#CheckListPage .tasksList .history {
	grid-column-start: 1;
	grid-column-end: 4;
	display: grid;
	width: 62%;
	background: #FFFFFF;
	background: var(--white);
	padding: 10px 20px;
	box-sizing: border-box;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}
#CheckListPage .tasksList .history h5 {
	text-transform: uppercase;
	font-size: 11px;
}
#CheckListPage .tasksList .history > div { 
	width: 70%;
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}
#CheckListPage .tasksList .history .author { 
	font-size: 10px;
    color: #9799a2;
    color: var(--textGray);
    line-height: 14px;
}
#CheckListPage .tasksList .history .message {
	box-sizing: border-box;
	padding: 5px;
	background: #f5f6fb;
	background: var(--main-light-gray);
	border-radius: 10px;
}
#CheckListPage .tasksList .history .message.green { background: #54b6a91A; background: var(--green10);}
#CheckListPage .tasksList .history .message.red { background: #ff2b651A; background: var(--red10);}
#CheckListPage .tasksList .history .right { justify-self: right;}
#CheckListPage .tasksList .history .right .author { text-align: right;}
#CheckListPage .tasksList .history .message.photo {
	display: grid;
	grid-gap: 20px;
	gap: 20px;
}
#CheckListPage .tasksList .history .message.photo img { max-height: 40px;}

#CheckListPage #TaskListPhotoExecusion {
	width: 600px;
	max-height: 70vh;
	overflow: auto;
	position: relative;
}
#CheckListPage #TaskListPhotoExecusion form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}
#CheckListPage #TaskListPhotoExecusion p { margin: 0; }
#CheckListPage #TaskListPhotoExecusion .photos { display: -webkit-flex; display: flex; grid-gap: 20px; gap: 20px; }
#CheckListPage #TaskListPhotoExecusion .photos > div { width: 30%; }
#CheckListPage #TaskListPhotoExecusion .buttons { margin: 0;}

#TaskPhotoExecusion { width: -webkit-fit-content; width: -moz-fit-content; width: fit-content; max-width: 58%; padding: 10px; }
#TaskPhotoExecusion img { max-height: 70vh; max-width: 100%; }
#TaskPhotoExecusion a {
	color: #FFFFFF;
	color: var(--white);
	display: block;
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	width: 100px;
	right: -120px;
}


#CheckListPage.mobile .tasksList { grid-template-columns : 79% 20%; }
#CheckListPage.mobile .tasksList .title { grid-column-end: span 2; }
#CheckListPage.mobile .tasksList button svg { vertical-align: text-bottom; }

#CheckListPage .confirmBlock .checklists { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; }
#CheckListPage .confirmBlock .checklists button { float: none; margin: 10px 0; }

@media (max-width: 1030px) {
	#CheckListPage .tasksList .name,
	#CheckListPage .tasksList .time { padding: 0 10px;}
}

@media (max-width: 980px) {
	#CheckListPage .taskStat .stat { height: 30px; border-radius: 15px;}
	#CheckListPage .taskStat .stat span { line-height: 30px;}
}



/* --- CHECK LIST EXECUTE WIDGET --- */

#TaskListWidget .stat {
	margin : 10px 0;
	height: 12px;
	border-radius: 6px;
	background: #b6b7c0;
	background: var(--dark-gray);
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
}
#TaskListWidget .stat .done { background: #54b6a9; background: var(--green); }
#TaskListWidget .stat .expired { background: #ff2b65; background: var(--red); }

#TaskListWidget .list {
	display: grid;
	grid-template-columns: 77% 20%;
	grid-row-gap: 5px;
	row-gap: 5px;
	grid-column-gap: 3%;
	-webkit-column-gap: 3%;
	        column-gap: 3%;
}
#TaskListWidget .list > * { line-height: 18px; padding: 3px 0;}
#TaskListWidget .list button svg { vertical-align: text-bottom; }
#TaskListWidget .list button.red { background-color: #ff2b65; background-color: var(--red);}

#TaskListWidget .comment { margin-top: 20px; }

@media (max-width: 980px) {
	#TaskListWidget .stat { margin: 15px 0 30px; }
	#TaskListWidget .list { grid-row-gap: 10px; row-gap: 10px; }
	#TaskListWidget .list .name { line-height: 40px;}
}

#CheckListWidget {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}
#DashboardPage #CheckListWidget h4 { margin: 0; }
#CheckListWidget .button { text-align: center; }

#CheckListReportWidget { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; grid-gap: 15px; gap: 15px; }
#DashboardPage #CheckListReportWidget h4 { margin: 0; }
#CheckListReportWidget .tasksStat {
	height: 100px;
	overflow: hidden;
	position: relative;
}
#CheckListReportWidget .pie {
	border-radius: 50%;
	background: conic-gradient(#54b6a9 0.00% 13%, #FFFFFF 13% 13.4%, #f0cd05 13.4% 23%, #FFFFFF 23% 23.4%, #ff2b65 23.4% );
	background: conic-gradient(var(--green) 0.00% 13%, var(--white) 13% 13.4%, var(--yellow) 13.4% 23%, var(--white) 23% 23.4%, var(--red) 23.4% );
	height: 200px;
	margin: auto;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	position: relative;
	width: 200px;
}
#CheckListReportWidget .pie::after {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 50%;
	content: "";
	position: absolute;
	width: 90px;
	height: 90px;
	top: 55px;
	left: 55px;
}
#CheckListReportWidget .tasksStat > a { position: absolute; top: 0; left: 0; }
#CheckListReportWidget .tasksStat > a:hover + .legend { display: block;}
#CheckListReportWidget .tasksStat .legend {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 4px;
	box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.3);
	font-size: 11px;
	position: absolute;
	top: 0;
	left: 35px;
	padding: 5px 10px;
	display: none;
}
#CheckListReportWidget .tasksStat .legend::after {
	background: #FFFFFF;
	background: var(--white);
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	top: 7px;
	left: -5px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}

#CheckListReportWidget .userStat { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; grid-gap: 20px; gap: 20px; }
#CheckListReportWidget .userStat > * {
	display: -webkit-flex;
	display: flex;
	grid-gap: 5px;
	gap: 5px;
	width: 50%;
	overflow: hidden;
	font-size: 12px;
	line-height: 16px;
}

#CheckListReportWidget .userStat span.icon {
	background-color: #ff2b65;
	background-color: var(--red);
	background-size: cover;
	background-position: center;
	border: 2px solid #ff2b65;
	border: 2px solid var(--red);
	border-radius: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 31px;
	width: 31px;
	min-width: 31px;
}
#CheckListReportWidget .userStat > div > div > span { display: block; overflow: hidden; white-space: nowrap; }

#CheckListReportWidget .userStat .title {
	color: #9799a2;
	color: var(--textGray);
	font-size: 8px;
	line-height: 13px;
	text-transform: uppercase;
}
#CheckListReportWidget .userStat .count { font-weight: 900; font-size: 15px; }

#CheckListReportWidget .userStat .icon svg * { fill: #FFFFFF; fill: var(--white); }

#CheckListReportWidget .userStat .bestEmployee span.icon { background-color: #54b6a9; background-color: var(--green); border-color: #54b6a9; border-color: var(--green); }
#CheckListReportWidget .userStat .worthEmployee span.icon svg { -webkit-transform: rotate(180deg); transform: rotate(180deg);}

#CheckListReportWidget > img { height: 110px; }

/* --- CHECK LIST REPORT PAGE --- */
#CheckListReport { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; grid-gap: 10px; gap: 10px; }

#CheckListReport .head { -webkit-flex-direction: column; flex-direction: column; -webkit-align-items: normal; align-items: normal; margin: 0; }
#CheckListReport .head > div:first-child {display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between; width: 100%; }
#CheckListReport .head .filter { padding: 5px 10px; margin-right: 15px; border-radius: 5px 5px 0 0; }
#CheckListReport .head .filter.active { background: #FFFFFF; background: var(--white); }

#CheckListReport .head .filterBlock {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 10px;
	padding: 15px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;
}
#CheckListReport .head .filterBlock > div:first-child { -webkit-flex-grow: 1; flex-grow: 1; }
#CheckListReport .head .filterBlock .lists { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: flex-start; align-items: flex-start; grid-gap: 20px; gap: 20px; }
#CheckListReport .head .filterBlock .lists > * { width: 25%;}
#CheckListReport .head .filterBlock > button { float: none; margin-top: 16px; }

#CheckListReport .statBlock .statistic { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; grid-gap: 10px; gap: 10px; }
#CheckListReport .statBlock .statistic > * {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 10px;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	padding: 15px;
	width: 33%;
	position: relative;
}

#CheckListReport .statBlock .statistic span.icon {
	background-color: #b6b7c0;
	background-color: var(--dark-gray);
	border-radius: 5px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 40px;
	margin-right: 15px;
	width: 40px;
}
#CheckListReport .statBlock .statistic span.icon svg { width: 24px; }
#CheckListReport .statBlock .statistic span.icon.photo {
	background-size: cover;
	background-position: center;
	border: 2px solid #ff2b65;
	border: 2px solid var(--red);
	height: 48px;
	width: 48px;
}

#CheckListReport .statBlock .statistic .title {
	color: #9799a2;
	color: var(--textGray);
	font-size: 9px;
	line-height: 16px;
	text-transform: uppercase;
	display: block;
}
#CheckListReport .statBlock .statistic .count { display: block; font-weight: 900; font-size: 20px; }

#CheckListReport .statBlock .statistic .done .icon { background-color: #54b6a91A; background-color: var(--green10); }
#CheckListReport .statBlock .statistic .done .icon svg * { fill: #54b6a9; fill: var(--green); }
#CheckListReport .statBlock .statistic .expired .icon { background-color: #f0cd051A; background-color: var(--yellow10); }
#CheckListReport .statBlock .statistic .expired .icon svg * { fill: #f0cd05; fill: var(--yellow); }
#CheckListReport .statBlock .statistic .failed .icon { background-color: #ff2b651A; background-color: var(--red10); }
#CheckListReport .statBlock .statistic .failed .icon svg * { fill: #ff2b65; fill: var(--red); }

#CheckListReport .statBlock .statistic.rate {
	margin-top: 10px;
}
#CheckListReport .statBlock .statistic.rate > * {
	-webkit-align-items: center;
	        align-items: center;
	width: 25%;
} 
#CheckListReport .statBlock .statistic.rate span.icon {border-radius: 50%;}
#CheckListReport .statBlock .statistic.rate span.icon { background-color: #ff2b65; background-color: var(--red); }
#CheckListReport .statBlock .statistic.rate span.icon svg * { fill: #FFFFFF; fill: var(--white); }

#CheckListReport .statBlock .statistic .bestEmployee span.icon { background-color: #54b6a9; background-color: var(--green); border-color: #54b6a9; border-color: var(--green); }
#CheckListReport .statBlock .statistic .worthEmployee span.icon svg { -webkit-transform: rotate(180deg); transform: rotate(180deg);}

#CheckListReport .statBlock .statistic a.iconSvg {
	position: absolute;
	top: 5px;
	right: 10px;
}
#CheckListReport .statBlock .statistic a.iconSvg svg { width: 15px; }

@media (max-width: 1030px) {
	#CheckListReport .statBlock .statistic > * { padding: 10px; }
	#CheckListReport .statBlock .statistic .icon svg { width: 20px; } 
	#CheckListReport .statBlock .statistic .icon {
		height: 35px;
		margin-right: 5px;
		width: 35px;
	}
	#CheckListReport .statBlock .statistic .title { font-size: 8px; }
	#CheckListReport .statBlock .statistic .count { font-size: 16px; }
}

#CheckListReport .groups {
	display: grid;
	grid-template-columns: 9% 18% 18% 15% 13% 23% 4%;
	grid-row-gap: 2px;
}
#CheckListReport h5 { font-size: 12px; color: #9799a2; color: var(--textGray); padding: 0 7px; }
#CheckListReport .groups > h5:first-child { padding-left: 18px; }
#CheckListReport .groups > span { background: #FFFFFF; background: var(--white); padding: 8px 7px; }
#CheckListReport .groups > span.date { border-radius: 2px 0 0 2px; padding-left: 18px; }
#CheckListReport .groups > span.actions { border-radius: 0 2px 2px 0; }

#CheckListReport .groups > span.actions a { width: 20px; height: 20px; }
#CheckListReport .groups > span.actions a.active svg { -webkit-transform: rotate(90deg); transform: rotate(90deg); }

#CheckListReport .groups .stat {
	height: 20px;
	border-radius: 10px;
	background: #ff2b65;
	background: var(--red);
	display: -webkit-flex;
	display: flex;
	position: relative;
	overflow: hidden;
}
#CheckListReport .groups .stat > div { position: relative; }
#CheckListReport .groups .stat .done { background: #54b6a9; background: var(--green); }
#CheckListReport .groups .stat .expired { background: #f0cd05; background: var(--yellow); }
#CheckListReport .groups .stat span { font-size: 10px; position: absolute; right: 6px; line-height: 20px; color: #FFFFFF; color: var(--white); font-weight: bold; }

@media (max-width: 1030px) {
	#CheckListReport .groups { grid-template-columns: 21% 22% 22% 35% }
	#CheckListReport .groups > h5:first-child { padding-left: 8px; }
	#CheckListReport .groups > span { line-height: 18px; }
	#CheckListReport .groups > span.date { padding-left: 8px; }
}

#CheckListReport .groups.control {
	grid-template-columns: 110px 10% 18% 25% auto 11% 40px;
}
#CheckListReport .groups.control .comment {
	display: block;
	line-height: 12px;
	margin: 5px 0 0;
}
#CheckListReport .groups.control .photos {
	display: -webkit-flex;
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}
#CheckListReport .groups.control .photos img { max-height: 50px; cursor: pointer; }
#CheckListReport .groups.control .actions {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}
#CheckListReport .groups.control .actions a { width: auto; height: auto; }

#CheckListReport .window.large { position: fixed;}
#CheckListReport .window .stat {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 5% 39% 14% 14% 14% 14%;
	grid-row-gap: 4px;
}
#CheckListReport .window .stat > span { padding: 0 7px; }
#CheckListReport .window .stat .comment { display: block; margin: -2px 0 5px;}

#CheckListReport .groups .tasks {
	background: #FFFFFF;
	background: var(--white); 
	grid-column-end: span 7;
	padding: 5px 25px 10px 40px;
	display: grid;
	grid-template-columns: auto 14% 13% 15% 15% 25px;
}
#CheckListReport .groups .tasks > span,
#CheckListReport .groups .tasks > div { padding: 8px 7px; }
#CheckListReport .groups .tasks span.icon { text-align: center; }
#CheckListReport .groups .tasks span svg { width: 16px; height: 16px; }
#CheckListReport .groups .tasks .icon svg *,
#CheckListReport .groups .tasks .intime svg * { fill: #54b6a9; fill: var(--green); }
#CheckListReport .groups .tasks .expired svg * { fill: #f0cd05; fill: var(--yellow); }
#CheckListReport .groups .tasks .done { display: -webkit-flex; display: flex; -webkit-align-items: flex-start; align-items: flex-start; grid-gap: 10px; gap: 10px; }
#CheckListReport .groups .tasks .photo { display: -webkit-flex; display: flex; grid-gap: 20px; gap: 20px; }
#CheckListReport .groups .tasks .photo img { max-height: 50px;}

#CheckListReport .groups .tasks .history {
	grid-column-start: 1;
	grid-column-end: 7;
	display: grid;
	width: 75%;
	grid-gap: 10px;
	gap: 10px;
}
#CheckListReport .groups .tasks .history h5 { text-transform: uppercase; font-size: 11px; margin: 0; }
#CheckListReport .groups .tasks .history > div { 
	width: 70%;
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}
#CheckListReport .groups .tasks .history .author { 
	font-size: 10px;
    color: #9799a2;
    color: var(--textGray);
    line-height: 14px;
}
#CheckListReport .groups .tasks .history .message {
	box-sizing: border-box;
	padding: 5px;
	background: #f5f6fb;
	background: var(--main-light-gray);
	border-radius: 10px;
}
#CheckListReport .groups .tasks .history .message.green { background: #54b6a91A; background: var(--green10);}
#CheckListReport .groups .tasks .history .message.red { background: #ff2b651A; background: var(--red10);}
#CheckListReport .groups .tasks .history .right { justify-self: right;}
#CheckListReport .groups .tasks .history .right .author { text-align: right;}
/* #CheckListReport .groups .tasks .history .message.photo {
	display: flex;
	gap: 20px;
} */

#ReviewTaskWindow textarea { height: 120px; }

/* --- SHIFT REPORT PAGE --- */
#ShiftReport .head { margin: 0; }
#ShiftReport .listpage { margin-top: 10px; }

#ShiftReport .toggleFilter { left: 450px; }
#ShiftReport.mobile .toggleFilter { right: 10px; left: auto; }
#ShiftReport.mobile .filter .buttons { float: none; margin-top: 10px; }
/* #ShiftReport .filter .col { padding-right: 20px; } */

#ShiftReport .table span .comment { display: block; }

/* --- CASSA PAGE --- */
#CassaPage .head h2 { float: left; }
#CassaPage .head .openCassa { float: left; }
#CassaPage .head .openCassa button { float: left; margin-right: 20px; min-width: 100px; }
#CassaPage .head .openCassa form { float: left; }
#CassaPage .head .openCassa p { float: left; line-height: 34px; }
#CassaPage .head .openCassa p.alert { color: #ee1566; }

#CassaPage .head .cassaTransactions { float: right; }
#CassaPage .head .cassaTransactions button { float: none; margin-left: 20px; }

#CassaPage.mobile .head { margin: 0; }

#CassaPage .confirmBlock.transaction .col { padding-right: 10px; }
#CassaPage .confirmBlock.transaction .checkGroupLabel { width: 100%; }
#CassaPage .confirmBlock.transaction .checkGroupLabel span { display: inline; }

#CassaPage.mobile .confirmBlock { width: 98%; left: 1%; margin-left: 0; }
#CassaPage.mobile .confirmBlock h4 { margin-bottom: 20px; }
#CassaPage.mobile .confirmBlock .close {
	background: none;
	box-shadow: none;
	position: absolute;
	right: 10px;
	top: 10px;
}
#CassaPage.mobile .confirmBlock .close i {
	background: url(/static/media/icons.9c0007f3.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#CassaPage .filter .col { padding-right: 10px; }
#CassaPage .filter > button { margin-top: 18px; padding: 0 20px; }
#CassaPage .filter .checkGroupLabel { width: 100%; }
#CassaPage .filter .checkGroupLabel span { max-width: none; display: inline; }

#CassaPage.mobile .filter { margin-bottom: 10px; }
#CassaPage.mobile .filter button { margin-top: 10px; float: left; }
#CassaPage.mobile .filter .showOrders { margin-top: 10px; }

#CassaPage .stat {
	display: table;
	font-size: 12px;
	margin: 10px 0 5px;
	width: 100%;
	vertical-align: top;
}
#CassaPage .stat .row { display: table-row; }
#CassaPage .stat .row .td { 
	background: #e6e6ec; 
	border: 2px solid #f5f6fb; 
	border-radius: 5px;
	box-sizing: border-box;
	display: table-cell;
	padding: 2px 10px;
	vertical-align: middle;
}
#CassaPage .stat .row .td.dates { width: 15%; }
#CassaPage .stat .row .td.summary { width: 45%; }
#CassaPage .stat .row .td.couriers { width: 15%; position: relative; }
#CassaPage .stat .row .td h5 { color: #888888; font-size: 11px; line-height: 15px; }

#CassaPage .stat .row .td.summary p,
#CassaPage .stat .row .td.couriers p { font-size: 14px; font-weight: bold; } 
#CassaPage .stat .row .td.summary .col { border-left: 1px solid #c8cbe0; padding-left: 10px; }
#CassaPage .stat .row .td.summary .col:first-of-type { border-left: none; padding: 0; }
#CassaPage .stat .row.titles .td {
	background: transparent;
	border-top: none;
	border-bottom: none;
	color: #9699a7;
	font-size: 10px;
	line-height: 13px;
	text-transform: uppercase;
}
#CassaPage .stat .row .orders .titles { margin-top: 15px; }
#CassaPage .stat .row .orders .titles h5 { line-height: 21px; }
#CassaPage .stat .row .orders .col { padding-right: 0; }

#CassaPage .stat .row .td.couriers .details {
	background: #e6e6ec;
	border-radius: 5px;
	box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.2);
	display: none;
	padding: 10px;
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 2;
}
#CassaPage .stat .row .td.couriers:hover .details { display: block; }
#CassaPage .stat .row .td.couriers .details p { font-weight: normal; font-size: 12px; }

#CassaPage.mobile .stat { display: block; }
#CassaPage.mobile .stat .row { display: block; }
#CassaPage.mobile .stat .row .td { display: block; width: 100% !important; }
#CassaPage.mobile .stat .row .td.summary { padding: 7px 10px; }
#CassaPage.mobile .stat .row .td.summary .col {padding-left: 5px; }
#CassaPage.mobile .stat .row .td.summary p { font-size: 11px; font-weight: bold; }


#CassaPage .table .subcomponent { background: #fafbfe; padding: 0 5px 5px 35px; }
#CassaPage .table .days { background: #e4e5ea; }
#CassaPage .table .neg { border-left: 3px solid #e73535; color: #e73535 }
#CassaPage .table .pos { border-left: 3px solid #54b6a9; color: #54b6a9; }
#CassaPage .table .add { color: #999999; }

#CassaPage .table .no-transaction .rt-expandable { pointer-events: none; }
#CassaPage .table .no-transaction .rt-expander { display: none; }

#CassaPage .table .block span { display: block; line-height: 19px; }
#CassaPage .table .block span:last-child { color: #888888; font-size: 11px; line-height: 16px;}

/* --- CONTENT PAGE --- */
#ContentPage .head .searchBlock { float: right; }
#ContentPage .head .searchBlock input { padding-left: 35px; width: 100%; }
#ContentPage .head .searchBlock label { left: 35px; }
#ContentPage .head .searchBlock .search {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -96px -152px;
	display: block;
	height: 24px;
	position: absolute;
	top: 4px;
	left: 5px;
	width: 24px;
}

#ContentPage section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	overflow: auto;
	padding: 15px 20px 15px 0;
}

#ContentPage section .head { margin: 10px 0; }
#ContentPage section .head:first-of-type { margin-top: 0; }

#ContentPage section > .button.add { margin: 20px 35px; }
#ContentPage section > p { margin: 20px 35px; }

#ContentPage h3, #ContentPage h4, .SortableHelper h4 { text-transform: none; }

#ContentPage h3 { font-size: 15px; line-height: 28px; padding-right: 25px; }
#ContentPage h4 { font-size: 14px; }

#ContentPage h3 a { position: relative; }
#ContentPage h3 a:hover, #ContentPage h3 a:active, #ContentPage .open h3 a,
#ContentPage h4 a:hover, #ContentPage h4 a:active, #ContentPage .openContent h4 a { color: #54b6a9; }

#ContentPage .toggleIcon {
	background: url(/static/media/icons.9c0007f3.svg) -240px -128px;
	display: block;
	height: 24px;
	position: absolute;
	left: -24px;
	width: 24px;
}
#ContentPage h3 .toggleIcon { top: 4px; }
#ContentPage .openContent h4 .toggleIcon,
#ContentPage .open h3 .toggleIcon { background-position: -264px -128px; }

#ContentPage .article.edit { margin: 30px 0; }

#ContentPage .body { padding: 15px 0 25px; }
/* @media print { */
	/* #ContentPage .body > .clearfix { padding: 15px 25px 25px; } */
/* } */

#ContentPage ul { margin: 10px 30px; list-style: disc;}
#ContentPage ol { margin: 10px 30px; list-style: decimal; }

#ContentPage .button { line-height: 34px; margin: 20px 0; }

#ContentPage .group { margin-top: 10px; z-index: 1; padding-left: 35px; }
#ContentPage .group:first-child { margin-top: 0; }

#ContentPage .group > .head button { float: left; line-height: 29px; margin: 20px 0 0 20px; }
#ContentPage .group > .head .SortHandle { left: -27px; top: 2px; }
#ContentPage .group .button { margin: 0 0 0 20px; }

#ContentPage .News .group { padding-left: 10px; }

#ContentPage .icons { position: absolute; top: 0; right: 0; }
#ContentPage .icons .icon { display: inline-block; box-shadow: none; transition: none; cursor: pointer; }
/* #ContentPage .icons .icon i { display: block; height: 24px; width: 24px; } */
#ContentPage .icons .icon.close { background: none; }


#ContentPage .group .head .icons { top: 3px; }
#ContentPage .group .head .icons .close { margin-top: 20px; }
#ContentPage .article .icons .icon { margin-left: 10px; }


#ContentPage .article, .SortableHelper .article { margin-bottom: 0px; padding-left: 25px; width: 100%; }

#ContentPage .article.notactive, .SortableHelper .article.notactive, 
#ContentPage .article.notactive h4, .SortableHelper .article.notactive h4,
#ContentPage .article.notactive h5, .SortableHelper .article.notactive h5 { color: #adadad; }

#ContentPage .article h4, .SortableHelper .article h4 { margin-bottom: 8px; position: relative; }

#ContentPage .article.notactive .comment, .SortableHelper .article.notactive .comment { color: #bdbdbd; }
#ContentPage .article .head .icons .edit, .SortableHelper .article .head .icons .edit { top: 0; }

#ContentPage .article h4 .form-row {
	font-size: 13px;
	text-transform: none;
	padding-right: 30px;
}

#ContentPage .article .buttons { float: none; }
#ContentPage .article button { width: auto; float: left; }
#ContentPage .article .error { margin-left: 150px; line-height: 45px; }

#ContentPage.mobile .group { padding-left: 5px; }
#ContentPage.mobile .group > .head button {line-height: 35px; margin: 10px 0 0; }

#ContentPage.mobile .article h4 { padding-right: 25px; }
#ContentPage.mobile .article .icons .icon { display: block; margin-bottom: 5px; }

/* #ContentPage .article a:not(.button).icon.edit { right: 120px; } */



/* --- DIRECTORIES PAGE --- */
#Directory .addNew {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2) inset;
	padding: 12px 20px;
	margin-bottom: 10px;
}
#Directory .addNew form .form-row { padding-right: 20px; }

#Directory .addNew button { width: auto; float: left; }
#Directory .addNew a.cancel { background: none; box-shadow: none; }
#Directory .addNew a.cancel i { background: url(/static/media/icons.9c0007f3.svg) -168px -128px; }

#Directory .table i.icon {
	background: url(/static/media/icons.9c0007f3.svg);
	display: block;
	height: 24px;
	width: 24px;
}
#Directory .table i.icon.icon_1 { background-position: -144px -176px; }
#Directory .table i.icon.icon_2 { background-position: -168px -176px; }
#Directory .table i.icon.icon_3 { background-position: -192px -176px; }
#Directory .table i.icon.icon_4 { background-position: -216px -176px; }
#Directory .table i.icon.icon_5 { background-position: -240px -176px; }
#Directory .table i.icon.icon_6 { background-position: -264px -176px; }

#InventoriesItem.addNew form .buttons,
#InventoriesItem.addNew .sections .button { margin-top: 17px; }
#InventoriesItem.addNew .sections { margin-top: 50px; }

#InventoriesItem.addNew .error { margin-top: 15px; }

/* --- TRANSFER SHIFT REPORTS PAGE --- */
#UserReport:not(.mobile) .head h2 { float: left; margin: 0; }
#UserReport .head button { margin-left: 20px; }

#UserReport .error { line-height: 35px; margin: 0 360px 0 180px; }

#UserReport section {
	background: #FFFFFF;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	overflow: auto;
}
#UserReport:not(.mobile) section > .clearfix { overflow: hidden; padding-bottom: 20px; }
#UserReport section > .clearfix > .col { padding: 15px 20px 99999px; margin-bottom: -99999px; }
#UserReport section > .clearfix > .col.form { padding-right: 0; }

#UserReport.mobile .tabs { margin: 0; padding: 0; }
#UserReport .tabs a {
	line-height: 32px;
	color: #5E626D;
	float: left;
	text-align: center;
	width: 50%;
}
#UserReport .tabs a:first-child { border-top-left-radius: 5px; }
#UserReport .tabs a:last-child { border-top-right-radius: 5px; }

#UserReport.mobile .form, #UserReport.mobile .reports { padding: 5px 0 5px 5px; }


#UserReport form .form-row textarea { height: 100px; text-transform: capitalize; }
#UserReport form label span { color: #e73535; }

#UserReport form h4 { margin: 25px 20px 10px 0; border-top: 1px solid #d9dade; padding-top: 10px; }
#UserReport form .group:first-of-type h4 { margin-top: 0; border-top: none; padding-top: 0; }

#UserReport .reports { background: #f8f9fb; }
#UserReport .reports h4 { margin-bottom: 10px; }

#UserReport .reports .report { margin-bottom: 10px; padding-left: 30px; }

#UserReport .reports h6 { margin-top: 7px; }

@media (max-width: 1030px) {
	#UserReport .reports h4 { font-size: 12px; }
	#UserReport .reports h6 { font-size: 9px; line-height: 14px; }
}

#UserReport .reports .field p {
	font-size: 12px;
	line-height: 19px;
	color: #9699a7;
}
#UserReport .reports .field p.user { color: #535663; }

/* ----- SHIFT TRANSFERS PAGE ----- */
#ShiftTransferReport .head .toggleFilter { left: 320px; }

/* #ShiftTransferReport .filter .col { padding-right: 20px; } */
#ShiftTransferReport .filter .checkGroupLabel { width: 100%; max-width: 100%; }
#ShiftTransferReport.mobile .filter .buttons { float: none; margin-top: 10px; }

#ShiftTransferReport .table span .comment {display: block; }


#ShiftTransferReport .subRowTable { 
	background: #f9faff; 
	border-radius: 3px; 
	box-shadow: 0 1px 3px rgba(0,0,0,0.1) inset;
	padding : 10px 40px 10px 100px; 
}
#ShiftTransferReport.mobile .subRowTable { padding : 10px 20px;  }

#ShiftTransferReport .subRowTable h6 { text-transform: none; }
#ShiftTransferReport .subRowTable p { font-size: 12px; line-height: 19px; color: #9699a7; min-height: 19px; }

#ShiftTransferReport .subRowTable .row { border-top: 1px solid #dddddd; }
#ShiftTransferReport .subRowTable .row:first-of-type { border-top: none; }

#ShiftTransferReport .icon.check {
	border: 3px #adadad solid;
	border-radius: 2px;
	box-sizing: border-box;
	display: block;
	height: 16px;
	margin: 8px 0 0;
	width: 16px;
}
#ShiftTransferReport .icon.check:hover,
#ShiftTransferReport .icon.check:active { border-color: #35a289; }
#ShiftTransferReport .icon.check.active { border: #35a289 solid 8px; cursor: default; }
#ShiftTransferReport .icon i { background: none; }

/* --- REQUESTS PAGE --- */
#RequestItem.tablet .head { top: 0; }

#RequestItem .section { padding: 15px 20px 20px; }
#RequestItem .section .titles { width: 96%; }

#RequestItem .search { float: right; }

#RequestItem .templateProducts .product { padding-left: 20px; }
#RequestItem .templateProducts .product .checkGroupLabel { color: #787c86; }

#RequestItem h5 { text-transform: uppercase; font-size: 12px; color: #444444; margin: 20px 0 10px; }
#RequestItem .item { border-top: 1px solid #dddddd; padding: 5px 0; width: 96%; }
#RequestItem .item > span { line-height: 32px; min-height: 32px; }
#RequestItem .item span .date { display: inline-block; font-size: 10px; color: #888888; }

#RequestItem .remove { position: absolute; top: 10px; right: -20px; }

/* --- SHOPLISTS PAGE --- */
#ShopListItem .item { border-top: 1px solid #dddddd; padding: 10px 0; }
#ShopListItem .item:first-of-type { border: none;  }

#ShopListItem .amount .item { border: none; }
#ShopListItem .amount span { display: block; }
#ShopListItem .amount .min { color: #e83075; }

#ShopListItem .products .item,
#ShopListItem .products .titles { width: 99%; }
#ShopListItem .products .titles span { text-transform: uppercase; font-size: 11px; }
#ShopListItem .products .item .col { padding-right: 10px; }
#ShopListItem .products .item .form-row { margin: 0; }
#ShopListItem .products .min input { color: #e83075; }

#ShopListItem .contacts h4 { margin: 0; }
#ShopListItem .contacts h5 { text-transform: uppercase; color: #444444; margin-bottom: 5px; }

#ShopListItem .workdays .active { color: #54b6a9; }

#ShopListItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 9px;
	right: -20px;
}
#ShopListItem .remove i { margin: 0; }

/* --- STOCK PROCESS COMMON */
#page .nomargin { margin: 0; }

#page .item .processStockStat { display: table; width: 100%; }
#page .item .processStockStat .tr { display: table-row; width: 100%; }
#page .item .processStockStat .td { display: table-cell; float: none; }

#page .item .processStockStat .col { padding: 0 10px; border-left: 1px solid #d9dade; }
#page .item .processStockStat .col:first-child { border-left: none; padding-left: 0; }
#page .item .processStockStat h5 { margin-bottom: 10px; line-height: 24px; height: 24px; font-size: 12px; text-transform: uppercase; }
#page .item .processStockStat h5 i {
	background: url(/static/media/icons.9c0007f3.svg) -696px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 6px;
	vertical-align: middle;
	width: 24px;
}
#page .item .processStockStat .col.create h5 i { background-position: -696px -128px; }
#page .item .processStockStat .col.send h5 i   { background-position: -720px -128px; }
#page .item .processStockStat .col.accept h5 i { background-position: -744px -128px; }
#page .item .processStockStat .col.from h5 i { background-position: -768px -128px; }
#page .item .processStockStat .col.move h5 i   { background-position: -792px -128px; }
#page .item .processStockStat .col.to h5 i { background-position: -816px -128px; }
#page .item .processStockStat .col.payment h5 i { background-position: -552px -152px; }

#page .item .processStockStat .row { font-size: 12px; padding-left: 30px; line-height: 25px; }
#page .item .processStockStat .row .col { border: none; padding: 0 20px 0 0; }
#page .item .processStockStat .row .title { 
	color: #86878c; 
	font-size: 11px; 
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase; 
	white-space: nowrap; 

}

/* --- INVOICES PAGE --- */
#InvoicePage .toggleFilter { left: 150px; }
#InvoicePage span.icon {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	vertical-align: bottom;
}
#InvoicePage .icon.type_1 { background-position: 0 -176px; }
#InvoicePage .icon.type_2 { background-position: -48px -176px; }
#InvoicePage .icon.type_3 { background-position: -24px -176px; }

#InvoiceItem .head .button.green { text-align: center; width: 225px; }

#InvoiceItem.two-cols .section > .clearfix { min-height: auto; }

#InvoiceItem .backblock { margin-top: 50px; }
#InvoiceItem .backblock .form-row { margin: 0 !important; }
#InvoiceItem .main:not(.backblock) { padding: 15px 20px 0; }
#InvoiceItem .processStockStat .main.col { padding: 20px 0 20px 20px !important; }
#InvoiceItem .main .col { padding-right: 20px; }
#InvoiceItem .main .form-row:last-child { margin-bottom: 20px; }

#InvoiceItem .payment { padding: 15px 20px 20px;}
#InvoiceItem .main .form-row:not(.full_select) .select__menu-list { max-height: 85px; }
#InvoiceItem .main h5 { text-transform: uppercase; font-size: 11px; }

#InvoiceItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#InvoiceItem .item:first-of-type { border: none;  }

#InvoiceItem .titles { width: 99%; }

#InvoiceItem .invoiceList { padding: 20px; border-top: 1px solid #dddddd; }
#InvoiceItem .invoiceList .col { padding-right: 10px; margin: 0; }

#InvoiceItem .item span.col { line-height: 30px; min-height: 30px; }

#InvoiceItem .summary { border-top: 2px solid #dddddd; margin-top: 15px; padding-top: 25px; width: 99%; }
#InvoiceItem .summary h4 { text-align: right; margin-top: 23px; }

#InvoiceItem .pay { margin: 20px 0; }

#InvoiceItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#InvoiceItem .remove i { margin: 0; }

#InvoiceConfirm p.icon {
	font-size: 13px;
	line-height: 24px;
	margin-bottom: 10px;
	padding-left: 20px;
}
#InvoiceConfirm p.icon i { margin-right: 10px; }
#InvoiceConfirm .buttons { margin-top: 25px; }

#InvoiceConfirm p.icon.stock i { background-position: -648px -128px; }
#InvoiceConfirm p.icon.provider i { background-position: -792px -128px; }
#InvoiceConfirm p.icon.amount i { background-position: -552px -152px; }

/* --- PAYMENTS PAGE --- */
#PaymentPage .head h2 { float: left; }

#PaymentPage .head .monthsFilter { float: left; width: 60%; }
/* #PaymentPage .head .monthsFilter a { color: #54b6a9; line-height: 35px; display: inline-block; } */
/* #PaymentPage .head .monthsFilter span { font-size: 18px; display: inline-block; margin: 0 15px; line-height: 35px; } */

#PaymentPage .loader { text-align: center; padding-right: 130px; }

#PaymentPage .table { display: table; border-spacing: 2px; width: 100%; }
#PaymentPage .table .titles, #PaymentPage .summary { display: table-row; }
#PaymentPage .table .dates { display: table-row-group; }
#PaymentPage .table .titles .col, #PaymentPage .summary .sum { display: table-cell; float: none; }

#PaymentPage .weekday,
#PaymentPage .titles span {
	color: #9f9f9f;
	text-transform: uppercase; 
	font-size: 10px;     
	white-space: nowrap;
    text-overflow: ellipsis;
	overflow: hidden; 
	
}

#PaymentPage .titles span { padding-left: 10px; }
#PaymentPage .weekday { width: 25px; text-align: center;  }

#PaymentPage .line { display: table-row; }
#PaymentPage .day {
	background: #FFFFFF;
	box-sizing: border-box;
	border-radius: 4px;
	min-height: 50px;
	padding: 5px 10px;
	display: table-cell;
	float: none;
}
#PaymentPage .day:not(.disabled) { box-shadow: 0 1px 2px rgba(0,0,0,0.1); }
#PaymentPage .day.today { background: rgba(53, 162, 137, 0.15); }
#PaymentPage .day.disabled { background: transparent; }

#PaymentPage .day h5 { font-size: 11px; line-height: 21px; color: #888888; }

#PaymentPage .day .provider { font-size: 12px; border-top: 1px solid #dddddd;}
#PaymentPage .day .provider span { 
	line-height: 24px; 
	vertical-align: middle; 
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis;
	padding-right: 0px;
}

#PaymentPage i.icon {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	-webkit-transform: scale(0.7);
	        transform: scale(0.7);
	vertical-align: bottom;
}
#PaymentPage i.icon.type_1 { background-position: 0 -176px; }
#PaymentPage i.icon.type_2 { background-position: -48px -176px; }
#PaymentPage i.icon.type_3 { background-position: -24px -176px; }
#PaymentPage i.icon.type_1_3 { background-position: -72px -176px; }
#PaymentPage i.icon.type_1_2 { background-position: -96px -176px; }
#PaymentPage i.icon.type_2_3 { background-position: -120px -176px; }

#PaymentPage .pay { color: #54b6a9; }
#PaymentPage .notPay { color: #f4bf3b; }
#PaymentPage .alert { color: #e73535; }

#PaymentPage .summary .sum { min-height: 1px; margin-right: 2px; }
#PaymentPage .summary .icon { float: left; }
#PaymentPage .summary span { display: block; font-size: 11px; white-space: nowrap; }
#PaymentPage .summary .payment { color: #54b6a9; }

#PaymentPage .popup {
	background: #FFFFFF;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); 
	margin: 0 -180px;
	padding: 15px 20px;
	position: fixed;
	left: 50%;
	top: 90px;
	width: 360px;
	max-height: 350px;
	overflow: auto;
}
#PaymentPage .popup .close {
	background: none;
	box-shadow: none;
	position: absolute;
	right: 1px;
	top: 1px;
	-webkit-transform: scale(0.7);
	        transform: scale(0.7);
}
#PaymentPage .popup .close i {
	background: url(/static/media/icons.9c0007f3.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#PaymentPage .popup h5 { font-size: 11px; line-height: 21px; color: #888888; text-transform: uppercase;  }
#PaymentPage .popup h5 .date { text-align: right; }
#PaymentPage .popup h5 .deadline { color: #e83075 }

#PaymentPage .popup .type { border-top: 1px solid #dddddd; margin-top: 5px; font-size: 12px; }
#PaymentPage .popup .type h5 { line-height: 24px; margin: 5px 0; }

#PaymentPage .popup .type .payment { border-bottom: 1px solid #ececec; }
#PaymentPage .popup .type .payment span { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 25px; }
#PaymentPage .popup .type .payment span.comment { color: #888888; }
#PaymentPage .popup .type .payment .icon {
	background: none;
	box-shadow: none;
	line-height: 24px;
	padding: 0;
	position: absolute;;
}
#PaymentPage .popup .type .payment .icon i { margin: 0; }

#PaymentPage .popup .type .summary { padding: 0; margin-bottom: 10px; }

/* --- WRITE OFF REQUESTS PAGE --- */
#WriteOffRequestItem .section { padding: 15px 20px 20px; }

#WriteOffRequestItem h5 { text-transform: uppercase; font-size: 11px; }

#WriteOffRequestItem .titles { width: 99%; }
#WriteOffRequestItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#WriteOffRequestItem .item .form-row { margin: 0; }
#WriteOffRequestItem .item > span { line-height: 21px; min-height: 21px;}

#WriteOffRequestItem .add { margin-top: 20px; line-height: 32px; }
#WriteOffRequestItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#WriteOffRequestItem .remove i { margin: 0; }

#WriteOffRequestItem .new { line-height: 30px; color: #e73535; }

#WriteOffRequestItem .head .button.green { text-align: center; width: 225px; }



/* --- TRANSFERS PAGE --- */
#TransfersPage .toggleFilter { left: 165px;}
#TransferItem .section { padding: 20px; }
#TransferItem .col { padding-right: 10px; }
#TransferItem .backblock { margin-top: 60px; }

#TransferItem h5 { text-transform: uppercase; font-size: 11px; } 

#TransferItem .titles { width: 99%; }
#TransferItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#TransferItem .item .form-row { margin: 0; }

#TransferItem .item .alert input { color: #e83075; }
#TransferItem .item span.col { line-height: 32px; min-height: 32px; }

#TransferItem .add { margin-top: 20px; line-height: 32px; }
#TransferItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 6px;
	right: -20px;
}
#TransferItem .remove i { margin: 0; }

#TransferItem .head .button.accept {
	background: #54b6a9; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #54b6a9 0%,#35a289 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#54b6a9', endColorstr='#35a289',GradientType=0 ); /* IE6-9 */

	box-shadow: 1px 2px 10px rgba(40, 130, 109, 0.6);
	text-align: center;
	width: 225px;
}
#TransferItem .head .button.accept:hover,
#TransferItem .head .button.accept:active {
	background: #64cdbf; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #64cdbf 0%,#35a289 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64cdbf', endColorstr='#35a289',GradientType=0 ); /* IE6-9 */
}

#TransferItem .select__menu-list { max-height: 150px; overflow: auto; }

/* --- REVISION PAGE --- */
#AuditPage .toggleFilter { left: 100px; }
#AuditPage span.plus { color: #54b6a9; }
#AuditPage span.minus { color: #ad0f49; }

#AuditItem .section { padding: 20px; }
#AuditItem.tablet .head { top: 0; }

/* #AuditItem .head { float: left; } */
#AuditItem .productSearch { 
    position: fixed;
    right: 20px;
    top: 67px;
    z-index: 4;
}
#AuditItem.tablet .productSearch { top: 3px; }

#AuditItem .template .start { margin-top : 20px; }
#AuditItem .template .col { padding-right: 20px; }
#AuditItem .template .templateProducts { margin-top: 20px; }

#AuditItem .template .checkAll { margin: 0;  }
#AuditItem .template .checkAll + .form-row { margin: 0 0 0 20px; }

#AuditItem h5 { text-transform: uppercase; font-size: 11px; }
#AuditItem .block:not(.audit):not(.summary) h5 { color: #BBBBBB; margin-bottom: 5px;}
#AuditItem .titles span { color: #9f9f9f; }

#AuditItem .titles { width: 99%; }
#AuditItem .item { border-top: 1px solid #dddddd; padding: 7px 0; width: 99%; }
#AuditItem .item .form-row { margin: 0; }
#AuditItem .item span { line-height: 32px; min-height: 32px; }
#AuditItem .plus { color: #54b6a9; }
#AuditItem .minus { color: #ad0f49; }
#AuditItem .item span.date { color: #888888; font-size: 12px; font-style: italic; }

#AuditItem .head .button.green { text-align: center; width: 225px; }
#AuditItem .summary h5 { text-align: right; }

/* --- LEFTOVERS PAGE --- */
#ActualStockPage h2 { float: left; } 
#ActualStockPage form .form-row { margin: 0; } 
#ActualStockPage form .col { padding-right: 20px;}

#ActualStockPage h2 a, #ActualStockPage h2 span {
	color: inherit;
	font-size: 12px;
	line-height: 18px;
	text-transform: none;
}
#ActualStockPage h2 span { margin-left: 20px; }
#ActualStockPage .head .sort { border-bottom: 1px dotted #5e626d; margin-left: 10px; }
#ActualStockPage h2 .sort:hover, #ActualStockPage h2 .sort:active, #ActualStockPage h2 .sort.active { border-color: #35a289; color: #35a289; }

#ActualStockPage .section {
	background: #FFFFFF;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	margin-top: 15px;
	padding: 15px 20px;
	overflow: auto;
}

#ActualStockPage h5 { text-transform: uppercase; font-size: 11px; color: #BBBBBB; margin-bottom: 5px;}
#ActualStockPage h5 .pos { color: #35a289; }
#ActualStockPage h5 .neg { color: #ad0f49; }

#ActualStockPage .item { border-top: 1px solid #dddddd; padding: 7px 0; }
#ActualStockPage .item span { line-height: 22px; min-height: 22px; }
#ActualStockPage .item span.date { color: #888888; font-size: 12px; font-style: italic; }

/* --- RELEASE PAGE --- */
#ReleasePage .item { margin: 5px 0; }
#ReleasePage .item .col { margin-left: 5px; text-align: center; }
#ReleasePage .item .col:first-child { margin: 0; }

#ReleasePage .item span {
	background: #ffffff;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
	color: #71747e;
	line-height: 35px;
	padding: 0 20px;
}

#ReleasePage .item .name { border-left: 3px solid transparent; text-align: left; }
#ReleasePage .item.done .name { border-left: 3px solid #79b853; }
#ReleasePage .item.expired .name { border-left: 3px solid #e73535; background: #f8f1e7 }

#ReleasePage .item .form-row { margin-top: 0; }
#ReleasePage .item input { line-height: 33px; }
#ReleasePage .item button { float: left; }

#ReleasePage .item.done .action { background: #79b853; color: #ffffff; }

#ReleasePage .item .error { padding: 40px 20px 10px; }

/* --- INVENTARIZATION PAGE --- */
#InventorizationPage .toggleFilter { left: 190px; }
#InventorizationPage span.plus { color: #54b6a9; }
#InventorizationPage span.minus { color: #ad0f49; }

#InventarizationItem .backblock { margin-top: 25px; }
#InventarizationItem .section { padding: 20px; }

#InventarizationItem .item { border-top: 1px solid #dddddd; padding: 7px 0; }

#InventarizationItem .item,
#InventarizationItem .titles { width: 99%; }
#InventarizationItem .item .form-row { margin: 0; }
#InventarizationItem .item span.col { line-height: 32px; min-height: 32px; }

#InventarizationItem .add { margin-top: 20px; line-height: 32px; }
#InventarizationItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 4px;
	right: -15px;
}
#InventarizationItem .remove i { margin: 0; }

/* --- STATISTIC PAGE --- */
#StatPage .head { margin: 0; }
#StatPage .head h2 { float: left; }
#StatPage .head .toggleFilter { float: left; position: static; margin-top: 5px; }
#StatPage .head .button { float: right; }
#StatPage .head .error { display: inline-block; }

/* #StatPage form { margin-top : -10px; } */
#StatPage form .filter .col { padding-right: 10px; }
#StatPage form .filter .preset a {
	padding: 0;
	margin: 20px 0 0;
	background: #c8cad4;
	color: #FFFFFF;
	text-align: center;
	line-height: 30px;
}
#StatPage form .filter .preset a:first-child { border-radius: 4px 0 0 4px; }
#StatPage form .filter .preset a:last-child { border-radius: 0 4px 4px 0; }
#StatPage form .filter .preset a.active { background: #54b6a9; }
#StatPage form .filter .checkGroupLabel { width: 100%; }
#StatPage form .filter .nomargin { margin: 0; }
#StatPage form .buttons { margin-top: 17px; }
#StatPage form .buttonDropdown .dropdown-menu.show { -webkit-transform: translate3d(0, 35px, 0px) !important; transform: translate3d(0, 35px, 0px) !important; }

#StatPage form.PurchaseSummary .filter .buttonGroup { text-transform: capitalize; }

#StatPage form .filter .select__value-container { max-height: 32px; overflow: auto; }

#StatPage form.EmployeesStat .buttons { margin-top: 72px;}

#StatPage .loader { text-align: center; margin: 70px 0;}

#StatPage .table .block span { display: block; line-height: 19px; }
#StatPage .table .block span:last-child { color: #888888; font-size: 11px; line-height: 16px;}

#StatPage .table span.plus { color: #54b6a9; }
#StatPage .table span.minus { color: #ad0f49; }

#StatPage .table .section .rt-expandable { pointer-events: none; }
#StatPage .table .section .rt-expander { display: none; }
#StatPage .subRowTable { 
	background: #f9faff; 
	border-radius: 3px; 
	box-shadow: 0 1px 3px rgba(0,0,0,0.1) inset;
	padding : 10px 40px 10px 100px; 
}
#StatPage .subRowTable .col { padding-right: 20px; }

#StatPage .subRowTable .row { border-top: 1px solid #dddddd; }
#StatPage .subRowTable .row:first-of-type { border-top: none; }

#ImportItem .head .button { float: none; }
#ImportItem .comment { margin: 0 0 5px; }
#ImportItem .main .col { padding-right: 20px; }
#ImportItem .errors { color: #c01d1d; }

#ImportItem .item { border-top: 1px solid #dddddd; padding: 7px 0; }
#ImportItem .item .form-row { margin: 0; }
#ImportItem .item span.col { line-height: 32px; min-height: 32px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

#ImportItem .add { margin-top: 20px; line-height: 32px; }
#ImportItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 4px;
	right: -15px;
}
#ImportItem .remove i { margin: 0; }

#ImportItem form .fileContainer.file .buttonContainer { top: 2%; left: 30%; }

/* --- MERCHANT SETTINGS --- */
#MerchantPage .tabs {
	margin-top: 55px;
	display: -webkit-flex;
	display: flex;
}
#MerchantPage .tabs a {
	background: #FFFFFFA1;
	display: inline-block;
	color: #5e626d;
	line-height: 30px;
	padding: 0 15px;
	margin-left: 5px;
	border-radius: 5px 5px 0 0;
	text-transform: uppercase;
	font-size: 12px;
}
#MerchantPage .tabs a:first-child { margin-left: 15px; }
#MerchantPage .tabs a:hover { background: #FFFFFF; z-index: 1;}
#MerchantPage .tabs a.active {
	background: #FFFFFF;
	color: #54b6a9;
	z-index: 1;
}

#MerchantPage .flexSection {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 15px;
}
/* #MerchantPage .flexSection > div { padding: 15px; width: 35%; } */
#MerchantPage .flexSection .main { display: -webkit-flex; display: flex; grid-gap: 20px; gap: 20px; margin-bottom: 20px; }
#MerchantPage .flexSection .main .commonSettings { -webkit-flex-grow: 1; flex-grow: 1; }

#MerchantPage .type { margin-top: 10px; }

#MerchantPage .flexSection .main .paymentInfo {
	border: 1px solid #54b6a9;
	border: 1px solid var(--green);
    border-radius: 10px;
    margin-top: 20px;
    padding: 15px;
    position: relative;
	width: 40%;
	box-sizing: border-box;
}
#MerchantPage .flexSection .main .paymentInfo h5 {
	position: absolute;
    top: -11px;
    background: #fff;
    padding: 0 10px;
	color: #54b6a9;
}
#MerchantPage .flexSection .main .paymentInfo .support { margin-top: 15px;}


@media (max-width: 980px) {
	#MerchantPage .flexSection .main { -webkit-flex-direction: column; flex-direction: column; }
	#MerchantPage .flexSection .main .paymentInfo { width: 100%; }
}



#MerchantPage .flexSection .entitiesList {
	display: grid;
	grid-row-gap: 20px;
	row-gap: 20px;
	grid-column-gap: 2%;
	-webkit-column-gap: 2%;
	        column-gap: 2%;
	grid-template-columns: 22% 22% 22% 22% 5%;
	margin-bottom: 20px;
}
#MerchantPage .flexSection .entitiesList .iconSvg { margin-top: 20px;}


#MerchantPage.mobile .section { padding: 10px; }


#MerchantPage .checkGroupLabel { width: 100%; max-width: 100%; }

#MerchantPage .ip .comment { margin-top: 5px; }
#MerchantPage .ip textarea { height: 70px; }

#MerchantPage .list .add { margin-top: 15px; }

#MerchantPage .list .item { position: relative; }
#MerchantPage.mobile .list .item { padding: 0; margin-top: 15px; }

#MerchantPage .list .remove { position: absolute; top: 22px; right: 16px; }

#MerchantPage .list .fileContainer { height: 125px; }
#MerchantPage .list .small .fileContainer { height: 85px; }

#MerchantPage .blockedMerchant {
	/* background: #FFFFFF; */
	/* border-radius: 3px; */
	/* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding: 15px 20px;
	margin-top: 0;
}

#MerchantPage .blockedMerchant img { max-width: 320px; }

/* --- ORDER STATUS PAGE --- */
#OrderStatusPage .table .titles {
    color: #71747e;
	font: 12px/26px "Roboto", Arial, sans-serif;
	padding-left: 33px;
}

#OrderStatusPage .SortHandle, .orderSortItem .SortHandle { left: 0;  top: 10px; }

#OrderStatusPage .table .titles span { padding: 0 5px; }
#OrderStatusPage .table .tableBody { overflow: auto; }
.orderSortItem {
	background: #FFFFFF;
	border-left: 3px solid transparent;
	border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.13);
	margin: 2px;
    padding-left: 30px;
}
.orderSortItem span { line-height: 45px !important; padding: 0 5px; height: 45px; }
.orderSortItem.notactive { color: #868c9c; }

.orderSortItem span i.icon {
	border-radius: 50%;
	display: inline-block;
	height: 18px;
	width: 18px;
}
.orderSortItem .icon { display: inline-block;  height: 24px; position: static; vertical-align: middle; }


#StatusItem .flexible { display: inline-block; margin-top: 20px; }

#StatusItem .main .comment { margin: 0; }

#StatusItem h5 { text-transform: uppercase; }
#StatusItem .flextime .time { width: calc(85%/7 - 1px); }
#StatusItem .flextime .titles { margin-top: 20px; }
#StatusItem .flextime .item { border-top: 1px solid #dddddd; padding: 3px 0; }
#StatusItem .flextime .item span { line-height: 30px; min-height: 30px; }

/* --- BILLTEMPLATES PAGE --- */
#BillTemplateItem .template,
#BillTemplateItem.mobile .sample { padding: 10px; }
#BillTemplateItem .fields .item { position: relative; }
#BillTemplateItem .fields .add { margin: 20px 0; }
#BillTemplateItem .fields .item .remove { position: absolute; top: 12px; right: 10px; }

#BillTemplateItem.mobile .printFormat .checkGroupLabel { width: 49%; }

#BillTemplateItem .billItem { box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); margin: 20px 0; }

.billItem { background: #FFFFFF; box-sizing: border-box; }

#BillTapeItem { width: 270px; padding: 3%; }
#BillPaperItem { width: 100%; padding: 20px 4%; }

#BillTapeItem .head .brand { text-align: center; border-bottom: 1px solid #adadad; margin-bottom: 10px; }
#BillPaperItem .head .brand { text-align: left; }

.billItem .head .brand img { max-width: 100%; margin-bottom: 15px; }

#BillTapeItem .head .orderNumber { margin-top: 15px; }
#BillPaperItem .head .orderNumber { text-align: center; }
#BillPaperItem .head .order { text-align: right; }

.billItem .head .fields p { margin-top: 15px; }

.billItem .info { margin: 15px 0; }
.billItem .info .item { border-top: 1px solid #dddddd; }
.billItem .info .item:first-child { border: none; }
.billItem .info .item b, .billItem .info .item span { min-height: 21px; }

.billItem .products { margin: 15px 0; display: table; width: 100%; border-collapse: collapse; }
.billItem .products .trGroup { display: table-row-group; }
.billItem .products .tr { display: table-row; }
.billItem .products .td { display: table-cell; }

#BillTapeItem .products .td { padding: 1px 5px; }
#BillPaperItem .products .td { border: 1px solid #adadad; padding: 1px 10px; }

.billItem .products .titles { font-weight: bold; }

#BillTapeItem .products .product .td { border-top: 1px solid #eeeeee; }
#BillTapeItem .products .titles + .product .td { border-top: 1px solid #adadad; }

.billItem .products .count, .billItem .products .price { text-align: center; }
.billItem .products .amount { text-align: right; width: 40%; }

#BillTapeItem .products .amounts { border-top: 1px solid #adadad; border-bottom: 1px solid #adadad;	}
#BillPaperItem .products .amounts { font-weight: bold;}
.billItem .products .summary { font-weight: bold; }

.billItem .comments { margin: 15px 0; text-align: center; }

.billItem .customer .description { margin-top: 10px; }

.billItem .composition h5 { 
	border-top: 1px solid #adadad; 
	border-bottom: 1px solid #adadad; 
	font-size: 12px;
	font-weight: bold;
	margin: 20px 0 10px;
}
.billItem .composition .item {
	line-height: 120%;
	border-bottom: 1px solid #dddddd;
	margin-top: 5px;
	padding-bottom: 5px;
}

/* --- PAYOUT PAGE --- */
#PayoutPage .head .filter { display: inline-block; vertical-align: middle; width: 60%; }
#PayoutPage .head .filter .col { padding-right: 20px; }

#PayoutItem .head { left: 0 !important; }
#PayoutItem .section { margin-top: 0; }

#PayoutItem .stat .item { border-top: 1px solid #dddddd; }
#PayoutItem .stat .item span { min-height: 21px; }
#PayoutItem .stat form { margin-bottom: 10px; }

#PayoutItem .payments h4 { float: left; line-height: 35px; margin: 0; }
#PayoutItem .payments button { margin-left: 10px; }

#PayoutItem span.icon {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	width: 24px;
	vertical-align: bottom;
}
#PayoutItem .icon.type_1 { background-position: 0 -176px; }
#PayoutItem .icon.type_2 { background-position: -48px -176px; }
#PayoutItem .icon.type_3 { background-position: -24px -176px; }

#PayoutPage .confirmBlock.payout { width: 480px; }
#PayoutPage .confirmBlock.payout .col { padding-right: 20px; }
#PayoutPage .confirmBlock.payout .buttons { float: none; }
#PayoutPage .confirmBlock.payout .buttons button { float: left; width: 140px; }

#PayoutPage .confirmBlock.payout .checkGroupLabel { width: 100%; max-width: 100%; }
#PayoutPage .confirmBlock.payout .checkGroupLabel span { display: inline; max-width: none; } 

#PayoutPage .confirmBlock.payout.payment { margin-top: 0; top: 100px; }
/** ----- STOP LIST PAGE ----- */
#StopListPage h5 { color: #71747e; text-transform: uppercase; }

#StopListPage .addBlock { margin-top: 20px; }
#StopListPage button { float: left; }
#StopListPage .addBlock form button, #StopListPage .addBlock form .button { margin: 18px 20px 0 0; }

#StopListPage .item { margin: 5px 0; }
#StopListPage .item .col { margin-right: 5px; }

#StopListPage .item span {
	background: #ffffff;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
	line-height: 35px;
	padding: 0 20px;
}
#StopListPage .item .name { border-left: 3px solid #e73535; }
#StopListPage .item span i { color: #9699a7; font-size: 12px; }
#StopListPage .item .remove { padding: 5px; height: 24px; }



/* --- BONUS PAGE --- */
#BonusRulePage .table .titles {
    color: #71747e;
	font: 12px/26px "Roboto", Arial, sans-serif;
	padding-left: 33px;
}
#BonusRulePage .table .titles span { padding: 0 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }

#BonusRulePage .SortHandle, .orderSortItem .SortHandle { left: 0;  top: 10px; }

#BonusRulePage .table .tableBody { overflow: auto; }
.orderSortItem {
	background: #FFFFFF;
	border-left: 3px solid transparent;
	border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.13);
	margin: 2px;
    padding-left: 30px;
}
.orderSortItem span { line-height: 45px !important; padding: 0 5px; height: 45px; }
.orderSortItem .col.edit { min-width: 35px; }
.orderSortItem.notactive { color: #868c9c; }

.orderSortItem span i.icon {
	border-radius: 50%;
	display: inline-block;
	height: 18px;
	width: 18px;
	vertical-align: middle;
}

#RuleItem .section > .clearfix > .col.col70 { padding-top: 25px; }

#RuleItem h4 { margin-bottom: 5px; }
#RuleItem textarea { height: 100px; }
#RuleItem .date label { left: 45px; }
#RuleItem .date .form-input { padding-left: 30px; }
#RuleItem .date .form-input i { left: 0; }
#RuleItem .item .comment { margin-top: 20px; padding: 0; }
#RuleItem .item .week .checkGroupLabel { width: 33%; }

#RuleItem .item .product { position: relative; }
#RuleItem .item .add { margin-top: 20px; }
#RuleItem .item .remove { background: none; padding: 0; position: absolute; top: 17px; right: 10px; }
#RuleItem .item .remove i { margin: 0; }

/* --- PROMOCODES PAGE --- */
#PromocodePage .upper { text-transform: uppercase;}

#PromocodeItem h4 { margin-bottom: 5px; }
#PromocodeItem textarea { height: 100px; }

#PromocodeItem .generatePromo { padding: 0; }
#PromocodeItem .generatePromo .dotted { margin-top: 22px; display: inline-block; }
#PromocodeItem .generatePromo .col { padding-right: 10px; }
#PromocodeItem .generatePromo .button { margin-top: 17px; float: right; width: 50%; }

#PromocodeItem .promocode input { text-transform: uppercase; }

#PromocodeItem .date label { left: 45px; }
#PromocodeItem .date .form-input { padding-left: 30px; }
#PromocodeItem .date .form-input i { left: 0; }
#PromocodeItem .item .comment { margin-top: 20px; padding: 0; }
#PromocodeItem .item .week .checkGroupLabel { width: 33%; }

#PromocodeItem .item .product { position: relative; }
#PromocodeItem .item .add { margin-top: 20px; }
#PromocodeItem .item .remove { background: none; padding: 0; position: absolute; top: 17px; right: 10px; }
#PromocodeItem .item .remove i { margin: 0; }

#CustomerItem .orderStat .titles { border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd; margin-top: 20px;  }

/** --- ACCOUNT PAGE --- */
#AccountPage section {
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

@media (max-width: 1280px) {
	#AccountPage section { grid-gap: 15px; gap: 15px; }
}

#AccountPage h2 {width: 100%; }
#AccountPage h4 { margin: 0; }
#AccountPage h5 { text-transform: uppercase; }

#AccountPage .section { padding: 20px; display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; margin: 0; position: relative; }

#AccountPage .userinfo { -webkit-align-items: center; align-items: center; grid-gap: 15px; gap: 15px; width: 40%; }
#AccountPage .userinfo .photo {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background-color: #b6b7c0;
	background-color: var(--dark-gray);
	background-size: cover;
	background-position: center;
	text-align: center;
    line-height: 200px;
    font-weight: bold;
    color: #f5f6fb;
    color: var(--main-light-gray);
    font-size: 18px;
}
#AccountPage .userinfo .edit { position: absolute; top: 10px; right: 10px; }
#AccountPage .userinfo h5 { margin-top: 10px; }

#AccountPage .checklist { width: 50%; grid-gap: 20px; gap: 20px; }
#AccountPage .checklist .rate {
	background: #b6b7c0;
	background: var(--dark-gray);
	background: linear-gradient(90deg, #ff2b65 0%, #f0cd05 50%, #54b6a9 100%);
	background: linear-gradient(90deg, var(--red) 0%, var(--yellow) 50%, var(--green) 100%);
	height: 50px;
	border-radius: 25px;
	position: relative;
}
#AccountPage .checklist .rate > div {
	background-color: #b6b7c0;
	background-color: var(--dark-gray);
	height: 44px;
	width: 44px;
	border-radius: 25px;
	border: 3px solid #5e626d;
	border: 3px solid var(--text);
	text-align: center;
	line-height: 44px;
	position: absolute;
	top: 0;
	color: #FFFFFF;
	color: var(--white);
    font-weight: bold;
    font-size: 18px;
	text-transform: uppercase;
	background-position: center;
	background-size: cover;
}
#AccountPage .checklist .rate > div.last { left: 0; border-color: #ff2b65; border-color: var(--red); }
#AccountPage .checklist .rate > div.middle { border-color: #f0cd05; border-color: var(--yellow); }
#AccountPage .checklist .rate > div.first { right: 0; border-color: #54b6a9; border-color: var(--green); }

#AccountPage .checklist > p { font-size: 16px; padding: 0 10%; text-align: center; margin-bottom: 30px; }
#AccountPage .checklist > img { max-width: 350px; -webkit-align-self: center; align-self: center;}

#AccountPage .checklist .table { display: grid; grid-template-columns: 30% 30% 20% 20%; }
#AccountPage .checklist .table span { border-top: 1px solid #DDDDDD; border-top: 1px solid var(--border-gray); line-height: 32px; }


#AccountPage .tips { padding: 0; border-radius: 10px; width: 30%;}
#AccountPage .tips > div { padding: 15px 20px; position: relative; }

#AccountPage .tips .code { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; }
#AccountPage .tips .code a { display: -webkit-inline-flex; display: inline-flex; margin-top: 20px; }

#AccountPage .tips .card a svg *,
#AccountPage .tips .status a svg * { fill: #f5f6fb; fill: var(--main-light-gray); }
#AccountPage .tips .card a:hover svg *,
#AccountPage .tips .status a:hover svg * { fill: #FFFFFF; fill: var(--white); }

#AccountPage .tips .card {
	background: #54b6a9;
	background: var(--green);
	border-radius: 10px 10px 0 0;
	padding-bottom: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

#AccountPage .tips .card .amount { font-size: 25px; color: #FFFFFF; color: var(--white); display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; }

#AccountPage .tips .card .amountLimit {
	background: #2c7982;
	background: var(--dark-green);
	height: 5px;
	position: relative;
	margin-top: 10px;
	border-radius: 3px;
}
#AccountPage .tips .card .amountLimit p {
	position: absolute;
	top: -20px;
	color: #f5f6fb;
	color: var(--main-light-gray);
	font-size: 10px;
	text-transform: uppercase;
}
#AccountPage .card .amountLimit p:last-child { text-align: right; right: 0; }
#AccountPage .card .amountLimit .balance { background: #FFFFFF; background: var(--white); border-radius: 5px; height: 5px; }

#AccountPage .card .cardNumber { display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; position: relative; width: 90%; -webkit-justify-content: space-between; justify-content: space-between; grid-gap: 20px; gap: 20px; }
#AccountPage .card .cardNumber > div { width: 45%; margin: 0; }
#AccountPage .card .cardNumber > div:first-child { width: 100%; }
#AccountPage .card .cardNumber a { position: absolute; top: 0; right: -30px; }
#AccountPage .card .cardNumber input:-moz-read-only { opacity: 1; background: rgba(215,225,255,0.1); }
#AccountPage .card .cardNumber input:read-only { opacity: 1; background: rgba(215,225,255,0.1); }


#AccountPage .tips .status { 
	background: #2c7982; 
	background: var(--dark-green);
	color: #f5f6fb;
	color: var(--main-light-gray);
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding-top: 25px;
	display: -webkit-flex;
	display: flex;
}
#AccountPage .tips .status::after {
	content: "";
	height: 10px;
	width: 100%;
	background: #54b6a9;
	background: var(--green);
	border-radius: 0 0 10px 10px;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#AccountPage .tips .info button { float: none; width: 100%; margin-top: 20px; }

#AccountPage .transactions { width: 64%;}
#AccountPage .transactions .head { display: -webkit-flex; display: flex; -webkit-justify-content: center; justify-content: center; grid-gap: 10px; gap: 10px; -webkit-align-items: stretch; align-items: stretch; }
#AccountPage .transactions .head a:first-child svg { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
#AccountPage .transactions .table { display: grid; grid-template-columns: 30% 30% 40%; }
#AccountPage .transactions .table span { border-top: 1px solid #DDDDDD; border-top: 1px solid var(--border-gray); line-height: 32px; }
#AccountPage .transactions .pos { color: #54b6a9; color: var(--green); }
#AccountPage .transactions .neg { color: #ff2b65; color: var(--red); }

@media (max-width: 980px) {
	#AccountPage .section { width: 100%; }
	#AccountPage .userinfo .button { width: 100%; text-align: center;}
}

/* #AccountPage.mobile .section { width: 100%; } */

#AccountPage .window h5 { text-align: left; margin-bottom: 50px; }
#AccountPage .window iframe { width: 100%; min-height: 450px; }

#AccountPage #ConfirmPhone .comment { margin-bottom: 0; }
.mobile#AccountPage #ConfirmPhone { bottom: 10px; }
.mobile#AccountPage #ConfirmPhone iframe { min-height: 380px; }

#AccountPage #PayOut .form-row { width: 50%; margin-bottom: 15px; }
#AccountPage #PayOut button { float: none; margin-top: 30px; }

.mobile#AccountPage #PayOut { bottom: 10px; }
.mobile#AccountPage #PayOut iframe { min-height: 380px; }

/** --- TIPS PRINT PAGE --- */
#TipsPrintPage a { cursor: pointer; }
#TipsPrintPage .filter { margin-bottom: 20px; }
#TipsPrintPage .filter a { color: #54b6a9; margin-top: 20px; line-height: 30px; display: inline-block; }
#TipsPrintPage .filter a:hover { text-decoration: underline; }

#TipsPrintPage .item {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	height: 266px;
	margin-bottom: 20px;
	padding: 10px 15px;
	position: relative;
}

#TipsPrintPage .item img { max-width: 100%; max-height: 200px; }

#TipsPrintPage .item h5 { text-transform: uppercase; margin-bottom: 10px; }
#TipsPrintPage .item p { margin: 5px 0 10px; }
#TipsPrintPage .item a { margin-top: 10px; padding: 0 5px; }

/** --- DASHBOARD PAGE --- */
/* #DashboardPage { margin-right: -20px; } */
/* @media (max-width: 1280px) {
	#DashboardPage { margin-right: -15px; }
}
@media (max-width: 1030px) {
	#DashboardPage { margin-right: -10px; }
} */
#DashboardPage section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
}



#DashboardPage .widgetBlock { margin-bottom: 20px; }
#DashboardPage .widget {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	/* height: 230px; */
	/* min-height: 170px; */
	padding: 10px 15px 15px;
	position: relative;
	box-sizing: border-box;
	width: 31.5%;
}
#DashboardPage .widget h4 { margin-bottom: 10px; }
#DashboardPage .widget .openPage { position: absolute; top: 10px; right: 10px; }
#DashboardPage .widget .openPage i {
	background: url(/static/media/icons.9c0007f3.svg) -336px -128px;
	display: block;
	-webkit-filter: brightness(0) invert(0.5);
	        filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
}
#DashboardPage .widget .openPage:hover i,
#DashboardPage .widget .openPage:active i { -webkit-filter: none; filter: none; }

@media (max-width: 980px) {
	#DashboardPage { margin-right: 0; }
	#DashboardPage .widget { height: auto; }
}

/* -- DASHBOARD TASKS -- */
#DashboardPage .widget .task { margin-bottom: 5px; line-height: 28px; }
#DashboardPage .widget .item { border-top: 1px solid #dddddd; line-height: 28px; }
#DashboardPage .widget .task .name i,
#DashboardPage .widget .item i {
	background: #dddddd;
	border-radius: 50%;
	display: inline-block;
	height: 8px;
	margin-right: 10px;
	width: 8px;
}
#DashboardPage .widget .task:first-of-type,
#DashboardPage .widget .item:first-of-type { border: none; }

#DashboardPage .widget .task.expired,
#DashboardPage .widget .item.expired { color: #ee1566; }
#DashboardPage .widget .task.expired .name i,
#DashboardPage .widget .titemask.expired i { background: #ee1566; }

#DashboardPage .widget .task > .col:not(.button),
#DashboardPage .widget .item > .col { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

#DashboardPage .widget .task button { line-height: 28px; padding: 0 5px; }
#DashboardPage .widget .task button i { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }

/* -- DASHBOARD NEWS -- */
#DashboardPage #NewsWidget .news a { color: #54b6a9; display: block; text-transform: uppercase; }
#DashboardPage #NewsWidget .news a:hover,
#DashboardPage #NewsWidget .news a:active { color: #35a289; }
#DashboardPage #NewsWidget .news p { margin: 0 0 10px; }


.back {
	position: fixed;
    background: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	z-index: 200;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.window {
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.5);
	padding: 20px;
	/* position: fixed; */
	/* top: 50%; */
	/* left: 50%; */
}

.window .close { 
	background: none;
	box-shadow: none;
	padding: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 2;
}
.window .close:hover, .window .close:active { background: none; box-shadow: none; opacity: 0.7; }
.window .close i {
	background: url(/static/media/icons.9c0007f3.svg) -120px -128px;
	-webkit-filter: brightness(0) invert(0.4);
	        filter: brightness(0) invert(0.4);
	display: block;
	height: 24px;
	margin: 0;
	width: 24px;
}

.window.large { 
	/* top: 80px;  */
	/* bottom: 30px;  */
	overflow: auto; 
	top: 90px;
    height: 80vh;
}
.window.large.customerItem {
	/* max-height: 80vh;
	margin-top: 80px;
	width: 76%; */
	top: 90px;
    height: 80vh;
}
.window.medium { 
	/* top: 80px;  */
	/* width: 500px;  */
	margin: 0 -250px; 
}




/* .mobile .window, .mobile .window.large, .mobile .window.medium { width: 94%; left: 3%; margin: 0; } */

.tablet.window.large, .tablet.window.medium { top: 30px; }

.window .windowHeader {
	background: #FFFFFF;
	padding: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.window .windowContainer { padding-top: 40px; }

.window .breadcrumbs { margin-bottom: 10px; color: #adadad; }
.window .breadcrumbs a { margin-right: 20px; color: #787c86; position: relative; font-size: 14px; font-weight: bold; }
.window .breadcrumbs a:hover { text-decoration: underline; }
.window .breadcrumbs a::after { content: ">"; color: #999; position: absolute; right: -14px; }



.confirmBlock { width: 400px; }
@media (max-width: 980px) {
	.confirmBlock { width: 96%; }
}


.confirmBlock p { font-size: 15px; margin-bottom: 25px; }
.confirmBlock p p { margin-bottom: 5px; }
.confirmBlock p.comment { font-size: 11px; }
.confirmBlock .buttons { float: none; }
.confirmBlock .buttons button { width: 140px; float: left;}
.confirmBlock .buttons .gray { float: right; }

.confirmBlock.checkShift ul { margin : 20px 0; }
.confirmBlock.checkShift ul li { margin: 5px 0; }
.confirmBlock.checkShift ul li.alert { color: #ee1566; }
.confirmBlock.checkShift ul li i {
	background: url(/static/media/icons.9c0007f3.svg) -120px -128px;
	display: inline-block;
	height: 24px;
	margin-right: 7px;
	vertical-align: middle;
	width: 24px;
}
.confirmBlock.checkShift ul li.complete i { background-position: -48px -152px; }

.confirmBlock.checkShift .buttons { float: none; }
.confirmBlock.checkShift .buttons button { float: left; width: 140px; }

.galleryBlock {
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
	height: 200px;
	margin: -100px 0;
	overflow: hidden;
	position: fixed;
    top: 50%;
    left: 20%;
    width: 60%;
}
.galleryBlock .head, .galleryBlock .buttons {
	background: #FFFFFF;
	box-sizing: border-box;
	padding: 10px 20px;
	position: absolute !important;
	left: 0;
	width: 100%;
	z-index: 1;
}
.galleryBlock .head { border-bottom: 1px solid #dddddd; top: 0; }
.galleryBlock .head h4 { margin: 0 !important; }
.galleryBlock .buttons { border-top: 1px solid #dddddd; bottom: 0; }
.galleryBlock .buttons .close { float: right; }

.galleryBlock .body { height: 200px; overflow: auto; }

.galleryBlock .files { padding: 60px 20px 65px; }
.galleryBlock .fileItem {
	box-sizing: border-box;
	height: 100px;
	float: left;
	padding: 10px;
	width: 25%;
}
.galleryBlock .fileItem img {
    cursor: pointer;
    background: #eee;
	min-width: 100%;
    min-height: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.galleryBlock .fileItem img.active { box-shadow: 0 0 5px #3b96e3; }

/* --- SORTABLE --- */
.SortableList {
	height: auto;
	list-style: none;
	outline: none;
	overflow: auto;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 0;
	
	-webkit-overflow-scrolling: touch;
}
.SortableItem {
	background-color: #FFF;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.SortableHelper {
	box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
	background-color: rgba(255, 255, 255, 0.8);
	cursor: row-resize;
	z-index: 20;
}
.SortHandle {
	background: url(/static/media/icons.9c0007f3.svg) 0 -128px !important;
	display: block;
	height: 24px;
	position: absolute;
	top: 0;
	left: -24px;
	width: 24px;
}

.pagination { margin-top: 20px; display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: center; align-items: center; }
.pagination a { line-height: 30px; display: -webkit-inline-flex; display: inline-flex; color: #5e626d; color: var(--text); -webkit-align-items: center; align-items: center; }
.pagination a:hover { color: #54b6a9; color: var(--green); }
.pagination .prev svg { -webkit-transform: rotate(180deg); transform: rotate(180deg); }

.pagination.numbers { text-align: center; vertical-align: top; }
.pagination.numbers .icon {
	display: inline-block;
	background: #ffffff;
	color: #757575;
	border-radius: 4px;
	font-weight: bold;
	height: 24px;
	line-height: 24px;
	margin: 0 5px; 
	min-width: 24px;
	vertical-align: top;
}
.pagination.numbers .icon i {
	display: block;
	font-style: normal;
	height: 24px;
	min-width: 24px;
}
.pagination.numbers .icon:not(.number) i { background: url(/static/media/icons.9c0007f3.svg) 0 0; -webkit-filter: brightness(0) invert(0.6); filter: brightness(0) invert(0.6); }
.pagination.numbers .icon.start i { background-position: -864px -152px; }
.pagination.numbers .icon.prev i { background-position: -888px -152px; }
.pagination.numbers .icon.next i { background-position: -912px -152px; }
.pagination.numbers .icon.end i { background-position: -936px -152px; }
.pagination.numbers .icon:hover i { -webkit-filter: none; filter: none; }

.pagination.numbers .icon:hover { color: #54b6a9; }
.pagination.numbers .icon.active,
.pagination.numbers .icon.hover { background: #54b6a9; color: #ffffff; cursor: default; }

/* --- ERROR MESSAGES --- */
#errorMessage {
	background: rgba(0, 0, 0, 0.45);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	cursor: pointer;
}

#errorMessage .message {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 5px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	padding: 20px;
	width: 360px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
	position: relative;
	cursor: default;
}
#errorMessage .message a {
	color: #FFFFFF;
	color: var(--white);
	display: block;
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	width: 100px;
	right: -120px;
}

#errorMessage .message.error, #errorMessage .message.error h4 { color: #ff2b65; color: var(--red);}
#errorMessage .message.success, #errorMessage .message.success h4 { color: #54b6a9; color: var(--green);}

/* --- PUSH MESSAGES --- */
#pushMessage {
	position: fixed;
	top: 20px;
	right: 10px;
	width: 300px;
	z-index: 10;
}
#pushMessage .message {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	box-sizing: border-box;
	color: #d9dade;
	padding: 12px 20px 15px;
	position: relative;
	width: 100%;
	margin-bottom: 15px;
}
#pushMessage .message h5 { color: #FFFFFF; }
#pushMessage .message .close {
	background: none;
	box-shadow: none;
	position: absolute;
	top: 7px;
	right: 7px;
}
#pushMessage .message .close i {
	background: url(/static/media/icons.9c0007f3.svg) -168px -128px;
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
	display: block;
	height: 24px;
	width: 24px;
}
#pushMessage .message .close:hover,
#pushMessage .message .close:active { opacity: 0.7; }
@media (max-width: 750px) {
	main { padding: 60px 5px 5px;}
}
/* ----- ASIDE & HEADER ----- */
aside.tablet { width: 50px; top: 0; bottom: 0; }
aside.mobile { width: 100%; top: 0; left: 0; height: 50px; }

aside.tablet .logo,
aside.mobile .logo {
	background: #6bc9aa; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #6bc9aa 0%,#257782 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bc9aa', endColorstr='#257782',GradientType=0 ); /* IE6-9 */

	padding: 20px 10px;
}
aside.tablet .logo img, aside.mobile .logo img { width: 100%; }
aside.mobile .logo { width: 26px; padding: 12px; float: left; }

aside.tablet .shift p:first-child,
aside.mobile .shift p:first-child { margin-top: 0; text-transform: uppercase; text-align: center; }

aside a.menu { display: block; padding: 14px; text-align: center; }
aside.mobile a.menu { width: 50px; float: left; height: 50px; box-sizing: border-box; padding: 13px; }
aside a.menu i {
	background: url(/static/media/icons.9c0007f3.svg) 0 -200px;
	display: block;
	height: 24px;
	width: 24px;
}
aside a.menu.navigation i { background-position: 0 -200px; }
aside a.menu.navigation.active i { -webkit-filter: brightness(0) invert(0.9); filter: brightness(0) invert(0.9); }

aside a.menu.shiftsettings i { background-position: -24px -200px; }
aside a.menu.shiftsettings.active i { -webkit-filter: brightness(0) invert(0.9); filter: brightness(0) invert(0.9); }

/* aside a.menu.shiftsettings.active i { background-position: -24px -224px; } */

aside a.menu.orders i { background-position: -48px -200px; }
aside a.menu.orders.active i { -webkit-filter: brightness(0) invert(0.9); filter: brightness(0) invert(0.9); }

aside.tablet a.menu.logout { position: absolute; bottom: 0; }
aside.mobile a.menu.logout { position: absolute; right: 0; top: 0; }
aside a.menu.logout i { background-position: -312px -200px; }
aside a.menu.logout:active i { background-position: -312px -224px; }

aside.tablet a.menu.account { position: absolute; bottom: 50px; }
aside.mobile a.menu.account { position: absolute; right: 50px; top: 0; }
aside a.menu.account i { background-position: -336px -200px; }
aside a.menu.account:active i { background-position: -336px -224px; }

aside .tabletMenu {
	background: #343844;
	border: 1px solid #474c5a;
	position: absolute;
	bottom: 0;
	z-index: 6;
}
aside.tablet .tabletMenu {
	box-shadow: 3px 0 4px rgba(0, 0, 0, 0.5); 
	top: 0;
	left: 50px;
	width: 230px;
}
aside.mobile .tabletMenu {
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5); 
	height: 370px;
	top: 50px;
	left: 0;
	width: 100%;
}

aside.tablet .shift .dropdown { margin-bottom: 15px; line-height: 40px; }
aside.tablet .shift .dropdown:last-of-type { margin: 0; }
aside.tablet .shift button { line-height: 40px; }
aside.tablet .shift .dropdown .dropdown-toggle { top: 7px; right: 14px; -webkit-transform: scale(1.2); transform: scale(1.2); }
aside.tablet .shiftToggle .shiftb { width: 100%; }
aside.tablet .shiftToggle .date { display: block; margin-top: 10px; text-align: center; }

aside.mobile .shift button { font-size: 15px; }
aside.mobile .shift .dropdown { margin-bottom: 15px; line-height: 50px; }
aside.mobile .shift .dropdown .dropdown-toggle { top: 10px; right: 20px; -webkit-transform: scale(1.5); transform: scale(1.5); }
aside.mobile .shift .dropdown .dropdown-menu { top: 48px !important }
aside.mobile .shift .dropdown:last-of-type { margin: 0; }
aside.mobile .shiftToggle .shiftb { width: 100%; line-height: 50px; font-size: 15px; }
aside.mobile .shiftToggle .date { display: block; margin-top: 10px; text-align: center; }

aside.tablet .menu a i,
aside.mobile .menu a i { background: url(/static/media/icons.9c0007f3.svg); }
aside.tablet .menu a.Orders i,
aside.mobile .menu a.Orders i 		{ background-position:   -48px -200px; }
aside.tablet .menu a.CallCentre i,
aside.mobile .menu a.CallCentre i 	{ background-position:   -72px -200px; }
aside.tablet .menu a.Production i,
aside.mobile .menu a.Production i 	{ background-position:   -96px -200px; }
aside.tablet .menu a.Staff i,
aside.mobile .menu a.Staff i 		{ background-position:   -120px -200px; }
aside.tablet .menu a.Directory i,
aside.mobile .menu a.Directory i 	{ background-position:   -144px -200px; }
aside.tablet .menu a.Store i,
aside.mobile .menu a.Store i 		{ background-position:   -168px -200px; }
aside.tablet .menu a.Money i,
aside.mobile .menu a.Money i 		{ background-position:   -192px -200px; }
aside.tablet .menu a.Management i,
aside.mobile .menu a.Management i 	{ background-position:   -216px -200px; }
aside.tablet .menu a.Statistics i,
aside.mobile .menu a.Statistics i 	{ background-position:   -240px -200px; }
aside.tablet .menu a.Settings i,
aside.mobile .menu a.Settings i 	{ background-position:   -264px -200px; }

aside.tablet .menu a.Orders:active i, 		aside.tablet .menu a.Orders.active i,
aside.mobile .menu a.Orders:active i, 		aside.mobile .menu a.Orders.active i 	 { background-position:   -48px -224px; }
aside.tablet .menu a.CallCentre:active i, 	aside.tablet .menu a.CallCentre.active i,
aside.mobile .menu a.CallCentre:active i, 	aside.mobile .menu a.CallCentre.active i { background-position:   -72px -224px; }
aside.tablet .menu a.Production:active i, 	aside.tablet .menu a.Production.active i,
aside.mobile .menu a.Production:active i, 	aside.mobile .menu a.Production.active i { background-position:   -96px -224px; }
aside.tablet .menu a.Staff:active i, 		aside.tablet .menu a.Staff.active i,
aside.mobile .menu a.Staff:active i, 		aside.mobile .menu a.Staff.active i 	 { background-position:   -120px -224px; }
aside.tablet .menu a.Directory:active i, 	aside.tablet .menu a.Directory.active i,
aside.mobile .menu a.Directory:active i, 	aside.mobile .menu a.Directory.active i  { background-position:   -144px -224px; }
aside.tablet .menu a.Store:active i, 		aside.tablet .menu a.Store.active i,
aside.mobile .menu a.Store:active i, 		aside.mobile .menu a.Store.active i 	 { background-position:   -168px -224px; }
aside.tablet .menu a.Money:active i, 		aside.tablet .menu a.Money.active i,
aside.mobile .menu a.Money:active i, 		aside.mobile .menu a.Money.active i 	 { background-position:   -192px -224px; }
aside.tablet .menu a.Management:active i, 	aside.tablet .menu a.Management.active i,
aside.mobile .menu a.Management:active i, 	aside.mobile .menu a.Management.active i { background-position:   -216px -224px; }
aside.tablet .menu a.Statistics:active i, 	aside.tablet .menu a.Statistics.active i,
aside.mobile .menu a.Statistics:active i, 	aside.mobile .menu a.Statistics.active i { background-position:   -240px -224px; }
aside.tablet .menu a.Settings:active i, 	aside.tablet .menu a.Settings.active i,
aside.mobile .menu a.Settings:active i, 	aside.mobile .menu a.Settings.active i 	 { background-position:   -264px -224px; }

/* --- COMMON PAGES --- */
main .page .head.mobile h2 { margin-right: 20px;}
main .page .head.mobile .toggleActive, main .page .head.mobile .toggleTask { display: block; margin: 5px 0 0; }
main .page .item form > .head.mobile { position: relative; top: 0; padding: 0; left: 0 !important; }

main .page .item form > .head.mobile + .section { margin-top: 15px; }
main .page .item form > .head.mobile + .backblock { margin-top: 25px; }

/* --- ROLE PAGE --- */
#RoleItem .departmentBlock.mobile { margin: 0 }

/* --- FILIALS PAGE --- */
#FilialItem .pos.mobile > .col { padding-right: 0;} 
#FilialItem .pos.mobile .remove { top: 27px; right: -10px; }

/* --- RELEASE PAGE --- */x
#ReleasePage.tablet .item { margin: 10px 0; }
#ReleasePage.tablet .item span,
#ReleasePage.tablet .item input,
#ReleasePage.tablet .item button { line-height: 42px; }

/* --- CHECK LIST EXECUTION PAGE --- */
#CheckListPage.tablet .task { margin: 10px 0; }
#CheckListPage.tablet .task .col,
#CheckListPage.tablet .task .col button { line-height: 42px; }

/**
 * Inputs and Validation
 */

:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--text: #5e626d;
	--green10: #54b6a91A;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
}

input, select, textarea {
	background: transparent;
	/* border: 1px solid #DDDDDD; */
	border: 1px solid #DDDDDD;
	border: 1px solid var(--border-gray);
	border-radius: 5px;
	box-sizing: border-box;
	color: #5e626d;
	color: var(--text);
	font: 14px/28px "Roboto_Light", Arial, Helvetica, sans-serif;
	padding: 0 13px;
	vertical-align: middle;
	transition: all 0.2s ease;
}



.dark input {
	background: rgba(215,225,255,0.1); color: #FFFFFF; border-color: transparent;
}
.dark .selectize .select__control, 
.dark .selectize .select__control:hover, 
.dark .selectize .select__control--is-focused {
	background: rgba(215,225,255,0.1); color: #FFFFFF; border-color: transparent;
}
.dark .selectize.single .select__value-container > div {
	color: #FFFFFF;
}

input:not([type="radio"]), input:not([type="checkbox"]) { width: 100%; }
textarea {
	font: 13px/18px "Roboto_Light", Arial, Helvetica, sans-serif;
	padding: 5px 13px;
}

input:focus, select:focus, textarea:focus { outline: none; }

input:disabled, select:disabled, textarea:disabled { opacity: 0.5; background-color: #EEEEEE; }
input:-moz-read-only, select:-moz-read-only, textarea:-moz-read-only { opacity: 0.5; background-color: #EEEEEE; }
input:read-only, select:read-only, textarea:read-only { opacity: 0.5; background-color: #EEEEEE; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] { -moz-appearance: textfield; }

textarea {
	overflow-y: auto;
	resize: none;
	width: 100%;
}

@media (max-width: 980px) {
	input, select, textarea { 
		line-height: 40px;
	}
}

form .form-row, .form .form-row { margin-top: 20px; }
form .form-row.hidden { margin: 0; }

form .form-row.hidden .form-input { min-height: auto; }

form .buttons { margin-top: 20px; float: right; }

form .buttons button { width: 100%; }

form .period .form-row { float: left; margin: 0 1% 0 0; width: 49%; }

form .period .checkGroupLabel { margin-right: 5px; width: auto !important; }

form .form-row.invalid label { color: #db004f; }

form .form-row.invalid .form-input input { border-color: #db004f; }

form .form-input input { width: 100%; display: block; }

form .form-input input[type="radio"], form .form-input input[type="checkbox"] {
	display: inline-block;
	margin: 0 7px 0 1px;
	width: auto;
}

form .form-input select, 
.form .form-input select { 
	background: #FFFFFF; 
	height: 30px; 
	opacity: 1; 
	width: 100%; 
	font-size: 13px; 
	padding-left: 7px;
}
form .form-input select:disabled,
.form .form-input select:disabled { opacity: 0.5;  }

form .form-input input.upper { text-transform: uppercase; }

form label, .form label {
	color: #9699a7;
	display: inline-block;
	line-height: 28px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: absolute;
	left: 13px;
	font-size: 14px;
	transition: all 0.2s ease;
	max-width: 80%;
	z-index: 0;
}



form label.focused, .form label.focused {
	font-size: 11px;
	margin-left: -10px;
	-webkit-transform: translateY(-24px);
	        transform: translateY(-24px);
	max-width: 95%;
}

@media (max-width: 980px) {
	form label, .form label { 
		line-height: 40px;
	}
	form label.focused, .form label.focused {
		-webkit-transform: translateY(-30px);
		        transform: translateY(-30px);
	}
}

form label.checkGroupLabel {
	display: inline-block;
	vertical-align: middle;
	color: #5e626d;
	font-size: 12px;
	position: relative;
	left: 0;
	width: 49%;
}

form label.checkGroupLabel span {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	max-width: 78%;
}

form label.checkGroupLabel input:disabled+span { color: #9fa6af; }

form label.checkGroupLabel .help {
	border: 1px solid #d2d4d7;
	border-radius: 50%;
	color: #888;
	font-size: 13px;
	font-style: normal;
	font-weight: 900;
	height: 20px;
	line-height: 20px;
	text-align: center;
	top: 5px;
	right: 0;
	width: 20px;
	z-index: 1;
}

form .form-input { display: block; min-height: 32px; position: relative; }

form .form-input i, .form .form-input i {
	display: block;
	height: 24px;
	position: absolute;
	top: 2px;
	right: 5px;
	width: 24px;
}

form .form-input i.date, .form .form-input i.date {
	background: url(/static/media/icons.9c0007f3.svg) -288px -128px;
	z-index: 0;
}
/* 
form .form-input i.login {
	background: url(../images/icons.svg) 0 0;
}

form .form-input i.pass {
	background: url(../images/icons.svg) -40px 0;
} */

form .selectize, .form .selectize { white-space: nowrap; }

form .selectize .select__control, form .selectize .select__control:hover, form .selectize .select__control--is-focused, .form .selectize .select__control, .form .selectize .select__control:hover, .form .selectize .select__control--is-focused {
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	box-shadow: none;
	min-height: 32px;
}

form .selectize .select__value-container, .form .selectize .select__value-container { padding: 0 8px; }
form .selectize .select__indicators .select__indicator, .form .selectize .select__indicators .select__indicator { padding: 5px 6px; }
form .selectize.single .select__control, .form .selectize.single .select__control { height: 30px; }
form .selectize.single .select__value-container, .form .selectize.single .select__value-container { height: 30px; }

@media (max-width: 980px) {
	form .selectize.single .select__control, .form .selectize.single .select__control { height: 40px; }
	form .selectize.single .select__value-container, .form .selectize.single .select__value-container { height: 40px; }
}

form .selectize .select__menu, 
.form .selectize .select__menu {
	top: 100%;
	background-color: hsl(0, 0%, 100%);
	border-radius: 4px;
	box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
	margin-bottom: 8px;
	margin-top: 0px;
	position: absolute;
	/* width: 110%; */
	min-width: 100%;
	max-width: 170%;
	width: auto;
	z-index: 2;
	box-sizing: border-box;
	font-size: 12px;
}

form .selectize .select__menu-list, .form .selectize .select__menu-list {
	max-height: 300px;
	overflow-y: auto;
	padding-bottom: 4px;
	padding-top: 4px;
	position: relative;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
}
@media (max-width: 980px) {
	form .selectize .select__menu-list, .form .selectize .select__menu-list { max-height: 180px;}
}

form .selectize .select__option, .form .selectize .select__option {
	background-color: transparent;
	color: inherit;
	cursor: default;
	display: block;
	font-size: inherit;
	padding: 0 55px 0 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
	position: relative;
}

form .selectize .select__option:active, form .selectize .select__option:hover, .form .selectize .select__option:active, .form .selectize .select__option:hover {
	background-color: #B2D4FF;
}

form .selectize .select__option span, .form .selectize .select__option span {
	width: 100%;
	display: inline-block;
	line-height: 32px;
}

form .selectize .select__option a {
	display: none;
	color: #db004f;
	font-size: 12px;
	position: absolute;
	top: 8px;
	right: 5px;
}

form .selectize .select__option:hover a { display: inline-block; }

form .selectize input { border: none; border-radius: 0; padding: 0; }

form .selectize .icon.status {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	top: 6px;
}


/* form .form-input.periodTime span { display: inline-block; margin: 0 10px; vertical-align: middle; } */

/* form .form-input.periodTime span:first-child { margin-left: 0; } */
form .form-input.periodTime input { display: inline-block; width: 60px; padding: 0 10px; }

form .form-input.periodTime .type { display: -webkit-flex; display: flex; -webkit-justify-content: left; justify-content: left; margin-bottom: 5px; grid-gap: 10px; gap: 10px; }
form .form-input.periodTime .type a { font-size: 10px; line-height: 17px; color: #5e626d; }
form .form-input.periodTime .type a.active { color:#54b6a9; cursor: default; }
form .form-input.periodTime .type span {
	background: #b6b7c0;
	border: 2px solid #b6b7c0;
	border-radius:11px;
	display: -webkit-flex;
	display: flex;
	width: 30px;
	-webkit-justify-content: left;
	        justify-content: left;
}
form .form-input.periodTime .type span.exact { -webkit-justify-content: right ; justify-content: right ;}
form .form-input.periodTime .type span b {
	display: -webkit-inline-flex;
	display: inline-flex;
	width: 14px;
	height: 14px;
	background: #FFFFFF;
	border-radius: 50%;
}

form .form-input.periodTime .set {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

/* --- DatePicker --- */
.page .react-datepicker {
	border: none;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	font-family: "Roboto", Arial, sans-serif;
}

.page .react-datepicker.withTime { width: 282px; }

.page .react-datepicker-popper { z-index: 3; }

.page .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .page .react-datepicker__year-read-view--down-arrow::before, .page .react-datepicker__month-read-view--down-arrow::before, .page .react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: transparent;
}

.page .react-datepicker-wrapper, .react-datepicker__input-container { width: 100%; }

.page .react-datepicker-wrapper input {
	background: #FFF;
	position: relative;
	z-index: 1;
}

.page .react-datepicker-wrapper input:disabled { opacity: 0.5; background-color: #EEEEEE; }

.page .react-datepicker__navigation {
	background: none;
	width: 10px;
	box-shadow: none;
	border-radius: 0;
}

.page .react-datepicker__header { background: #f5f6fb; border: none; }

.page .react-datepicker__current-month, 
.page .react-datepicker-time__header { font-weight: normal; text-transform: uppercase; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item { height: 20px !important; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled { display: none; }
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) { left: 175px; }

.page .react-datepicker__close-icon {
	background: transparent url(/static/media/icons.9c0007f3.svg) -168px -128px;
	box-shadow: none;
	height: 24px;
	opacity: 0.4;
	top: 4px;
	width: 24px;
	z-index: 1;
}

.page .react-datepicker__close-icon:hover { opacity: 0.6; }
.page .react-datepicker__close-icon:after { display: none; }
.page input:disabled+.react-datepicker__close-icon { display: none; }

.page .react-datepicker__year-dropdown, 
.page .react-datepicker__month-dropdown, 
.page .react-datepicker__month-year-dropdown {
	background: #f5f6fb;
	border: none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.page .react-datepicker__month-dropdown { left: 5%; }
.page .react-datepicker__year-dropdown { left: 50%; }

.page .react-datepicker__year-option:hover, 
.page .react-datepicker__month-option:hover, 
.page .react-datepicker__month-year-option:hover {
	background: #e6e7ec;
}

.page .react-datepicker__month-dropdown .react-datepicker__navigation,
.page .react-datepicker__year-dropdown .react-datepicker__navigation { width: 0; }


form .slider { margin-top: 6px; }

form .slider .rc-slider-rail {
	height: 6px;
	background: #b6b7c0;
	background: var(--dark-gray);
}

form .slider .rc-slider-track {
	height: 6px;
}

form .slider .rc-slider-handle, form .slider .rc-slider-handle:active {
	background: #54b6a9;
	background: var(--green);
	border: none;
	box-shadow: none;
	margin-top: -4px;
	outline: none;
}

form .colorPreset span {
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	height: 20px;
	margin: 7px 7px 0 0;
	overflow: hidden;
	width: 20px;
	transition: all 0.2s ease;
}

form .colorPreset span:hover {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}

form .colorPreset span.active {
	border: 1px solid #5e626d;
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}

form .colorPreset span.disabled {
	cursor: default;
	opacity: 0.5;
}

form .colorPreset span.disabled:hover {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

form .iconPreset span {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: left;
	height: 30px;
	margin: 0 5px 5px 0;
	overflow: hidden;
	padding: 3px;
	width: 30px;
	transition: all 0.2s ease;
}

form .iconPreset span:hover {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
}

form .iconPreset span.active {
	border: 1px solid #5e626d;
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
	padding: 2px;
}

form .iconPreset span.disabled {
	cursor: default;
	opacity: 0.5;
}

form .iconPreset span.disabled:hover {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

form .iconPreset span i {
	background: url(/static/media/icons.9c0007f3.svg);
	display: block;
	height: 24px;
	width: 24px;
	position: static;
}

form .iconPreset span.icon_1 i {
	background-position: -144px -176px;
}

form .iconPreset span.icon_2 i {
	background-position: -168px -176px;
}

form .iconPreset span.icon_3 i {
	background-position: -192px -176px;
}

form .iconPreset span.icon_4 i {
	background-position: -216px -176px;
}

form .iconPreset span.icon_5 i {
	background-position: -240px -176px;
}

form .iconPreset span.icon_6 i {
	background-position: -264px -176px;
}

.textEditor button {
	box-shadow: none;
}

.textEditor .DraftEditor-editorContainer {
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	font-size: 13px;
}

.textEditor .DraftEditor-editorContainer h5 {
	font-size: 15px;
	text-transform: uppercase;
}

.textEditor .DraftEditor-editorContainer h6 {
	font-size: 13px;
	text-transform: uppercase;
	font-weight: normal;
}

.gallery {
	margin-bottom: 15px;
}

.gallery .buttons .button {
	margin: 0 !important;
}

/* .buttonDropdown { float: right; width: 22%; } */

.buttonDropdown .btn-group { position: relative; }

.buttonDropdown .btn {
	/* background: #0cd6e5;  */
	border-radius: 5px 0 0 5px;
	float: left;
	padding: 0 10px;
	text-align: left;
	width: 86%;
}

/* #page .buttonDropdown .btn:hover { background: #1de7f6; } */

.buttonDropdown .dropdown-toggle {
	border-radius: 0 5px 5px 0;
	display: block;
	float: right;
	/* height: 30px; */
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: -1000px;
	width: 13%;
}

.buttonDropdown .dropdown-toggle:after {
	border-style: solid;
	border-width: 5px 5px;
	border-color: transparent;
	border-top-color: #ffffff;
	content: '';
	margin: -2px -5px;
	position: absolute;
	top: 50%;
	right: 50%;
}

.buttonDropdown .dropdown-menu {
	background: #FFFFFF;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	position: absolute;
	width: 100%;
	z-index: 2;
	-webkit-transform: translate3d(500%, 0px, 0);
	        transform: translate3d(500%, 0px, 0);
}

.buttonDropdown .dropdown-menu .dropdown-item {
	background: transparent;
	box-shadow: none;
	color: #5e626d;
	display: block;
	line-height: 28px;
	text-align: left;
	padding: 0 10px;
	text-transform: none;
	width: 100%;
}

.buttonDropdown .dropdown-menu .dropdown-item:hover {
	padding-left: 15px;
}

form .fileUploader {
	margin-top: 20px;
}

form .fileContainer {
	background: #d2d4d7;
	border-radius: 10px;
	box-shadow: none;
	display: block;
	height: 150px;
	margin: 0 auto 0;
	overflow: hidden;
	padding: 0;
}

form .fileContainer .buttonContainer {
	position: absolute;
	margin: -55px 0 0;
	top: 50%;
	left: 0;
	text-align: center;
	width: 100%;
	z-index: 2;
}

form .fileContainer.file .buttonContainer {
	display: inline-block;
	margin: 0 4% 0 0;
	position: relative;
	width: 30%;
	vertical-align: middle;
}

form .fileContainer button.chooseFileButton {
	background: none;
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 5px;
	float: none;
	padding: 0 25px;
	position: static;
	text-transform: uppercase;
	transition: none;
}

form .fileContainer button i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -48px -128px;
	display: block;
	height: 24px;
	margin: 0 auto;
	width: 24px;
	-webkit-transform: scale(1.5);
	        transform: scale(1.5);
}

form .fileContainer button.chooseFileButton:hover {
	background: none;
	color: #54b6a9;
}

form .fileContainer button.chooseFileButton:hover i {
	background-position: -480px -128px;
}

form .fileContainer .deleteImage {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -120px -128px;
	border-radius: 0;
	height: 24px;
	overflow: hidden;
	text-indent: -100px;
	top: 5px;
	right: 5px;
	width: 24px;
	z-index: 3;
}

form .fileContainer .deleteImage:hover {
	background-position: -144px -128px;
}

form .fileContainer .uploadPictureContainer {
	background: none;
	border: none;
	box-shadow: none;
	color: #555555;
	margin: 0;
	padding: 0;
	width: 100%;
}

form .fileContainer .uploadPictureContainer img.uploadPicture {
	-webkit-transform: scale(1.4);
	        transform: scale(1.4);
	transition: all linear 0.2s;
}

form .fileContainer .uploadPictureContainer video {
	width: 120%;
}

form .fileContainer:hover .uploadPictureContainer img, form .fileContainer:hover .uploadPictureContainer video {
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);
}

form .fileContainer.file .uploadPicturesWrapper {
	display: inline-block;
	width: 65%;
	vertical-align: middle;
}

form .fileContainer .errorsContainer {
	color: #e13e3e;
	font-size: 11px;
	line-height: 130%;
	margin: 10px 0;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
}

.form .tags span, form .tags span {
	background: #f5f6fb;
	border-radius: 4px;
	;
	color: #5e626d;
	cursor: pointer;
	display: inline-block;
	line-height: 25px;
	margin: 0 0 7px 3px;
	padding: 0 7px;
	white-space: nowrap;
	transition: 0.2s all linear;
}

.form .tags span:hover, form .tags span:hover {
	background: #54b6a9;
}

.form .tags span.active, form .tags span.active {
	background: #54b6a9;
	color: #FFFFFF;
}

.form .tags span.disabled, form .tags span.disabled, .form .tags span.disabled:hover, form .tags span.disabled:hover {
	color: #888888;
	background: #f5f6fb;
	cursor: default;
}
/* --- EDITABLE TAGS GROUP --- */
.form .edittags span, form .edittags span {
	display: inline-block;
	margin: 0 3% 7px 0;
	position: relative;
	width: 22%;
}
.form .edittags span i, form .edittags span i { right: 2px; }

/* --- BUTTONS RADIO GROUP --- */
form .buttonGroup, .form .buttonGroup { margin-bottom: 5px; }

form .buttonGroup span, .form .buttonGroup span {
	background: #f5f6fb;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	color: #5e626d;
	cursor: pointer;
	display: block;
	float: left;
	line-height: 32px;
	margin: 0 1px;
	padding: 0 12px;
	white-space: nowrap;
	transition: 0.2s all linear;
}
form .buttonGroup span.icon, .form .buttonGroup span.icon { padding-top: 4px; padding-bottom: 4px; }
form .buttonGroup span.icon i, .form .buttonGroup span.icon i { position: static; }

form .buttonGroup span:first-child, .form .buttonGroup span:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

form .buttonGroup span:last-child, .form .buttonGroup span:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

form .buttonGroup span:hover, .form .buttonGroup span:hover,
form .buttonGroup span:active, .form .buttonGroup span:active { background: #54b6a9; }

/* .mobile form .buttonGroup span:hover, .mobile .form .buttonGroup span:hover { background: #f5f6fb; } */

form .buttonGroup span.active, .form .buttonGroup span.active {
	background: #54b6a9;
	color: #FFFFFF;
}

form .buttonGroup span.disabled, .form .buttonGroup span.disabled {
	background: #e5e6eb;
	box-shadow: none;
	color: #7e828d;
	cursor: default;
	text-shadow: 0 1px 0 #FFFFFF;
}

form .buttonGroup span.disabled.active, .form .buttonGroup span.disabled.active {
	background: #9ac1bc;
	color: #f5f6fb;
	text-shadow: none;
}

/* --- NUMBER SPINNER INPUT --- */
form .spinner > * {
	border: 1px solid #DDDDDD;
	border-left: none;
	color: #7e828d;
	float: left;
	line-height: 30px;
	padding: 0 8px;
	text-align: center;
}

form .spinner a { font-weight: bold; width: 10px; }

form .spinner a:hover { background: #f5f6fb; }

form .spinner a:first-child { border-radius: 4px 0 0 4px; border-left: 1px solid #DDDDDD; }
form .spinner a:last-child { border-radius: 0 4px 4px 0; }
form .spinner a.disabled { cursor: default; background: #f7f7f7; }

form .spinner .form-row { margin: 0; padding: 0; width: 40%; }
form .spinner .form-row .form-input { min-height: 30px; }
form .spinner .form-row input {
	border: none;
	text-align: center;
	padding: 0 8px;
	border-radius: 0;
	line-height: 30px;
}

/* --- DADATA --- */
form .dadata .react-dadata__input { font-size: 14px; height: 30px; border-color: #DDDDDD; }
form .dadata .react-dadata__input:focus { box-shadow: none; }
form .dadata .react-dadata__suggestions button {
	background: #ffffff;
	box-shadow: none;
	color: #5e626d;
	font-size: 13px;
	line-height: 22px;
}
form .dadata  .react-dadata__suggestions button:hover { background: #f5f6fb; }
form .dadata .react-dadata--highlighted { color: #54b6a9; }

/* --- AUTOCOMPLETE --- */
form .form-input.autocomplete { position: relative; }
form .form-input.autocomplete .menu {
	background: #FFFFFF;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	position: absolute;
	padding: 5px 0;
	overflow: auto;
	max-height: 150px;
	width: 100%;
	z-index: 3;
}
form .form-input.autocomplete .menu a { color: #555555; display: block; padding: 0 5px; line-height: 20px }
form .form-input.autocomplete .menu a:hover { background: #f5f6fb; }

/* --- DATEPRESET --- */
form .dates { display: -webkit-flex; display: flex; -webkit-justify-content: space-between; justify-content: space-between; grid-gap: 20px; gap: 20px; }

form .datepreset { 
	margin-top: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	border-radius: 15px;
	overflow: hidden;
	
}
form .datepreset a {
	background: #b6b7c0;
	background: var(--dark-gray);
	color: #FFFFFF;
	color: var(--white);
	text-align: center;
	line-height: 30px;
	width: 25%;
}
form .datepreset a.active { background: #54b6a9; background: var(--green); }

/* --- MONTH FILTER --- */
.monthsFilter { text-align: center; text-transform: capitalize; }
.monthsFilter a { color: #54b6a9; line-height: 35px; display: inline-block; }
.monthsFilter span { font-size: 18px; display: inline-block; margin: 0 15px; line-height: 35px; }

/* --- CHECK FILTER --- */
.form-input .checkFilter { display: -webkit-flex; display: flex; -webkit-justify-content: left; justify-content: left; margin-bottom: 5px; }
.form-input .checkFilter a { font-size: 11px; line-height: 18px; color: #5e626d; color: var(--text); }

.form-input .checkFilter span {
	background: #b6b7c0;
	background: var(--dark-gray);
	border: 2px solid #b6b7c0;
	border: 2px solid var(--dark-gray);
	border-radius:11px;
	display: -webkit-flex;
	display: flex;
	width: 30px;
	-webkit-justify-content: left;
	        justify-content: left;
	margin-right: 5px;
}
.form-input .checkFilter span b {
	display: -webkit-inline-flex;
	display: inline-flex;
	width: 14px;
	height: 14px;
	background: #FFFFFF;
	border-radius: 50%;
}
.form-input .checkFilter.active span { -webkit-justify-content: right; justify-content: right; background: #54b6a9; background: var(--green); border-color: #54b6a9; border-color: var(--green);}
.form-input .checkFilter.active a { color:#54b6a9; color:var(--green); }

@media (max-width: 980px) {
	.form-input .checkFilter a { font-size: 12px; line-height: 26px; }
	.form-input .checkFilter span { border-radius: 13px; width: 50px;}
	.form-input .checkFilter span b { height: 24px; width: 24px; }
}
.fileUploader {
	width: 100%;
}

.fileContainer {
	background: #fff;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
	position: relative;
	border-radius: 10px;
	padding: 20px 0;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
}

.fileContainer input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fileContainer p {
	font-size: 12px;
	margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
	max-width: 300px;
	font-size: 12px;
	color: red;
	text-align: left;
}

.fileContainer .chooseFileButton {
	padding: 6px 23px;
	background: #3f4257;
	border-radius: 30px;
	color: white;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
}

.fileContainer .chooseFileButton:hover {
	background: #545972;
}

.fileContainer .uploadFilesButton {
	padding: 5px 43px;
	background: transparent;
	border-radius: 30px;
	color: #3f4257;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
	background: #3f4257;
	color: #fff;
}

.fileContainer .uploadIcon {
	width: 50px;
	height: 50px;
}

.fileContainer .uploadPicturesWrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
}

.fileContainer .uploadPictureContainer {
	width: 25%;
	margin: 5%;
	padding: 10px;
	background: #edf2f6;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 100%;
}

.fileContainer .deleteImage {
	position: absolute;
	top: -9px;
	right: -9px;
	color: #fff;
	background: #ff4081;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;
}

.flipMove {
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

:root {
	--border-gray : #DDDDDD;
	--green: #54b6a9;
	--green10: #54b6a91A;
	--text: #5e626d;
	--textGray: #9799a2;
	--dark-gray: #b6b7c0;
	--dark-gray10: #b6b7c01A;
	--red : #ff2b65;
	--red10 : #ff2b651A;
	--white: #FFFFFF;
	--main-light-gray: #f5f6fb;
	--yellow: #f0cd05;
	--yellow10: #f0cd051A;
}

.page .ReactTable  { border: none; }

.page .ReactTable .rt-thead.-header {
    box-shadow: none;
    font: 12px/20px "Roboto", Arial, sans-serif;
    color: #9799a2;
    color: var(--textGray);
}
.page .ReactTable .rt-thead .rt-tr {
    background: none;
    /* box-shadow: none; */
    padding-left: 10px;
    text-align: left;
}
.page .ReactTable .rt-tbody .rt-tr-group { border: none; } 
.page .ReactTable .rt-tr {
    background: #FFFFFF;
    background: var(--white);
    border-radius: 2px;
    /* box-shadow: 0 1px 5px rgba(0,0,0,0.13); */
    margin: 1px 2px 1px 2px;
}

.page .ReactTable .rt-thead .rt-th, 
.page .ReactTable .rt-thead .rt-td,
.page .ReactTable .rt-tbody .rt-td {
    border: none;
    padding: 5px 7px;
}
.page .ReactTable .rt-tbody .rt-tr { border-left: 3px solid transparent; padding-left: 10px; }

.page .ReactTable .rt-tbody .rt-td { line-height: 30px; }

.page .ReactTable .rt-thead .rt-th.-sort-desc, 
.page .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(121,195,179,0.6);
}

.page .ReactTable .rt-thead .rt-th.-sort-asc, 
.page .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(121,195,179,0.6);
}

.page .ReactTable.listTable .rt-tbody .rt-tr:hover { border-left: 3px solid #54b6a9; border-left: 3px solid var(--green); }
.page .ReactTable.reportTable .rt-tbody .rt-tr.expired { border-left: 3px solid #ff2b65; border-left: 3px solid var(--red); background: #ff2b651A; background: var(--red10) }
.page .ReactTable.reportTable .rt-tbody .rt-tr.notSend { border-left: 3px solid #f4bf3b; background: #fffbe9 }
.page .ReactTable .rt-tbody .rt-tr.alert { border-left: 3px solid #ff2b65; border-left: 3px solid var(--red); background: #ff2b651A; background: var(--red10) }

.page .ReactTable.reportTable .rt-tbody .rt-tr.payment { border-left: 3px solid #54b6a9; border-left: 3px solid var(--green); color: #aaaaaa; }
.page .ReactTable.reportTable .rt-tbody .rt-tr.notPayment { border-left: 3px solid #f4bf3b; background: #fffbe9; }

.page .ReactTable .rt-tbody .rt-tr.section { border-left: 3px solid #afb1bb; background: #e4e5ea; }

.page .ReactTable .rt-thead.-filters .rt-th { border: none; padding: 2px;}
.page .ReactTable .rt-thead.-filters input, .page .ReactTable .rt-thead.-filters select { 
    background: transparent;
    line-height: 18px; 
}

/* ----- PRODUCTS NAVIGATION ----- */
.productsNav { position: relative; }
.productsNav .container { 
    background: #f8f9fb;
	border-radius: 3px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}
.productsNav a, .sectionItem {
	border-top: 1px solid #d9dade;
	color: #5e626d;
	display: block;
	line-height: 30px;
	padding: 0 15px 0 30px;
	position: relative;
	text-transform: none;
}
.productsNav a:first-of-type { border: none; padding-top: 5px; border-radius: 3px 3px 0 0; }
.productsNav a:hover, .productsNav a:active { color: #54b6a9; }
.productsNav a.active { background: #54b6a9; color: #FFFFFF; border: none; }
.productsNav a.active + a { border: none; }

.productsNav a:not(.button) i { position: absolute; top: 3px; right: 3px; }
.productsNav a:first-of-type i { top: 8px; }

.productsNav a i.hidden { 
	background-position: -168px -152px; 
	-webkit-filter: brightness(0) invert(0.5); 
	        filter: brightness(0) invert(0.5);
	cursor: default; 
	right: 15px; 
}
.productsNav a.active i.hidden { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }

.productsNav a i.edit,
.productsNav a i.remove,
.productsNav a i.add { cursor: pointer; display: none; right: 0; transition: background-color linear 0.2s; }

.productsNav a i.edit { background-position: -96px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); right: 27px; }
.productsNav a i.edit:hover, .productsNav a i.edit:active { -webkit-filter: none; filter: none; }
.productsNav a.active i.edit { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }
.productsNav a.active i.edit:hover, .productsNav a.active i.edit:active { opacity: 0.7; }
.productsNav a i.add { background-position: -480px -128px; -webkit-filter: brightness(0) invert(0.5); filter: brightness(0) invert(0.5); right: 27px; }
.productsNav a i.add:hover, .productsNav a i.add:active { -webkit-filter: none; filter: none; }
.productsNav a.active i.add { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }
.productsNav a.active i.add:hover, .productsNav a.active i.add:active { opacity: 0.7; }

.productsNav a i.remove { background-position: -120px -128px; }
.productsNav a i.remove:hover, .productsNav a i.remove:active { opacity: 0.6; }

.productsNav a:hover i.edit, .productsNav a:active i.edit, 
.productsNav a:hover i.remove, .productsNav a:active i.remove,
.productsNav a:hover i.add, .productsNav a:active i.add { display: block; }
.productsNav a:hover i.hidden, .productsNav a:active i.hidden { display: none; }

.productsNav a.add { padding-left: 5px; }
.productsNav a.removed {
	border: none; 
	padding: 15px; 
	position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}
.productsNav a.add i,
.productsNav a.removed i {
	background: url(/static/media/icons.9c0007f3.svg) -384px -152px;
	display: inline-block;
	height: 24px;
	margin-right: 5px;
	vertical-align: middle;
	position: static;
	width: 24px;
}
.productsNav a.removed:hover i,
.productsNav a.removed:active i { background-position: -408px -152px; }
.productsNav a.removed.active i { background-position: -432px -152px; }

.productsNav a.add i { background: url(/static/media/icons.9c0007f3.svg) -456px -128px; }
.productsNav a.add:hover i,
.productsNav a.add:active i { background: url(/static/media/icons.9c0007f3.svg) -480px -128px; }

/** ----- PRODUCTS PAGE ----- */
#ProductPage .section { margin-top: 15px; }
/* #ProductPage .section { margin-top: 90px; } */

#ProductPage .productsNav .container { padding-bottom: 60px; }

#ProductPage .productsNav form { border-top: 1px solid #d9dade; margin: 0; padding: 15px; }

#ProductPage .productsNav form input { background: #FFFFFF; }
#ProductPage .productsNav form label { z-index: 1;}
#ProductPage .productsNav form .checkGroupLabel { width: 100%; }
/* #ProductPage .productsNav form .nomargin { margin: 0; } */
#ProductPage .productsNav form .error { margin-top: 10px; }

#ProductPage .productsNav form button { 
	line-height: 32px; 
	/* display: inline-block;  */
	float: left; 
	margin: 10px 0 0 0; 
}
#ProductPage .productsNav form .close { 
	background: none;
	box-shadow: none;
	line-height: 32px; 
	float: right; 
	padding: 2px;
	margin-top: 7px;
}
#ProductPage .productsNav form .close i { position: static; }

#ProductPage .SortHandle, .sectionItem .SortHandle { left: 5px; opacity: 0.5; -webkit-transform: scale(0.7); transform: scale(0.7); }

#ProductPage .products { padding-left: 20px; }

#ProductPage .products .table .color {
	border-radius: 50%;
	display: inline-block;
	height: 15px;
	vertical-align: middle;
	width: 15px;
}

#ProductPage .products .table .ws {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	margin-left: 3px;
	width: 24px;
}
#ProductPage .products .table .ws:first-child { margin-left: 0; }
#ProductPage .products .table .ws.ws_1 { background-position: -144px -176px; }
#ProductPage .products .table .ws.ws_2 { background-position: -168px -176px; }
#ProductPage .products .table .ws.ws_3 { background-position: -192px -176px; }
#ProductPage .products .table .ws.ws_4 { background-position: -216px -176px; }
#ProductPage .products .table .ws.ws_5 { background-position: -240px -176px; }
#ProductPage .products .table .ws.ws_6 { background-position: -264px -176px; }

/* -- RAW ITEM --*/
#RawItem .section { margin-top: 110px; }

#RawItem textarea { height: 120px; }

#RawItem .block h4 { margin-bottom: 0; }
#RawItem .gray h4 { margin-bottom: 10px; }
#RawItem .gray h5 { text-transform: uppercase; margin-bottom: 5px; }

#RawItem .gray .item { border-top: 1px solid #dddddd; }
#RawItem .gray .item span { 
	line-height: 25px; 
	min-height: 25px;
	white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis; 
}

#RawItem .costs .item { padding: 5px 0;}
#RawItem .costs .item .form-row { margin: 0; }
#RawItem .costs .item span { line-height: 32px; min-height: 32px; }

#RawItem .gray .toggle { display: block; float: right; background: transparent; box-shadow: none; }
#RawItem .gray .toggle i {
    background: url(/static/media/icons.9c0007f3.svg) no-repeat -218px -128px;
    display: block;
    height: 24px;
    width: 24px;
}
#RawItem .gray .toggle.open i { background-position: -218px -128px; }
#RawItem .gray .toggle.close i { background-position: -242px -128px; }

#RawItem .gray .provider { 
	background: transparent; 
	box-shadow: none; 
	display: block; 
	position: absolute; 
	top : 7px;
	right: 0;
}
#RawItem .gray .provider i {
    background: url(/static/media/icons.9c0007f3.svg) no-repeat -648px -128px;
    display: block;
    height: 24px;
    width: 24px;
}

/* -- PRODUCT ITEM --*/
#ProductItem .section { margin-top: 90px; }

#ProductItem .remove {
	background: none;
	padding: 0;
	position: absolute;
	top: 17px;
	right: -15px;
}
#ProductItem .remove i { margin: 0; }
#ProductItem .add { line-height: 32px; margin-top: 15px; }
#ProductPage .nomargin { margin: 0; }

#ProductItem .form-row .icon.hidden {
	background: url(/static/media/icons.9c0007f3.svg) -168px -152px;
	-webkit-filter: brightness(0) invert(0.6);
	        filter: brightness(0) invert(0.6);
	position: absolute;
	top: 4px;
	right: 15px;
}


#ProductItem .row { border-top: 1px solid #dddddd; }
#ProductItem .row span { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
#ProductItem .row.titles span,
#ProductItem .row span.title { text-transform: uppercase; font-size: 11px; }
#ProductItem .row:first-of-type { border: none; }

#ProductItem .description textarea { height: 150px; }

#ProductItem .expdate { padding-bottom: 0; }


#ProductItem .finance .prices span { line-height: 32px; height: 32px; }
#ProductItem .finance .titles { margin-top: 10px; }
#ProductItem .finance .city { padding: 5px 0; }
#ProductItem .finance .city .col { padding-right: 10px; }
#ProductItem .finance .city .form-row { margin: 0; }
#ProductItem .finance .alert input { color: #e83075; }

#ProductItem .technique h4 { margin: 0; }
#ProductItem .technique .tech { padding-bottom: 15px; }
#ProductItem .technique textarea { height: 98px; }

#ProductItem .composition .group { margin-bottom: 15px; }
#ProductItem .composition .group .product .col { padding-right: 10px; }
#ProductItem .composition .group .product > span { line-height: 30px; margin-top: 20px; }
#ProductItem .composition .buttonDropdown { width: 47%; }
#ProductItem .composition .buttonDropdown button { line-height: 32px; }

#ProductItem .CPFC .titles { margin-top: 20px; }
#ProductItem .CPFC span { line-height: 21px; height: 21px;}

#ProductItem .video .col { position: relative; }
#ProductItem .video .item { margin-bottom: 20px; }
#ProductItem .video .item .form-row { margin: 0; }
#ProductItem .video .fileUploader { margin: 0; width: 90%; }
#ProductItem .video .remove { top: -5px; right: 15px;}
#ProductItem .video .add { margin: 0; }
#ProductItem .video .deleteImage { display: none; }

/** ------- MENU PAGE ------- */
#MenuPage .head .searchProducts { float: right; }

#MenuPage > .col { padding-right: 20px; }
#MenuPage > .col .container { box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); border-radius: 5px; }

#MenuPage .productsNav.sec { width: 17%; }
#MenuPage .productsNav.prod { width: 23%; }
#MenuPage .productsNav .container { overflow: auto; }
#MenuPage .productsNav a { font-size: 12px; line-height: 22px; padding: 4px 15px; }
#MenuPage .productsNav .type_2 + .type_3 { margin-top: 20px; border: none; }

#MenuPage .MenuProductItem { padding: 0; }
#MenuPage .MenuProductItem .container { background: #FFFFFF; overflow: auto; }

.MenuProductItem .container h4 { margin-bottom: 10px; }
.MenuProductItem .container h5 { text-transform: uppercase; font-weight: bold; margin-bottom: 5px; }
.MenuProductItem .container .row { border-top: 1px solid #d9dade; }
	
.MenuProductItem .main > .col { border-left: 1px solid #d9dade; padding: 15px; }
.MenuProductItem .main .col:first-child { border: none; }

.MenuProductItem .container .photo {
	background-position: center;
	background-size: cover; 
	height: 190px;
	margin: -10px -15px -10px 0;
	position: relative;
}
@media (max-width: 1030px) {
	.MenuProductItem .container .photo { height: 110px; }
}
.MenuProductItem .container .photo .zoom { position: absolute; top: 5px; right: 5px; }
.MenuProductItem .container .photo .zoom i { -webkit-filter: invert(1); filter: invert(1);}
.MenuProductItem .container .main .photo {
	background-image: url(/static/media/menu_dummy.64a527ef.svg);
	background-color: #535663;
	margin: -15px -15px 10px;
}

.MenuProductItem .container form .form-row { margin-top: 5px;}

.MenuProductItem .container .tech .row { padding: 10px 15px; }

.MenuProductItem .container .composition .row .col,
.MenuProductItem .container .composition .titles span { line-height: 24px; padding: 3px 10px 3px 0; }
.MenuProductItem .container .composition .titles { border: none; }
.MenuProductItem .container .composition .titles span { 
    color: #888888;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.MenuProductItem .container .composition .row .count { font-weight: bold; }
.MenuProductItem .container .composition .row a { color: #54b6a9; }
.MenuProductItem .container .composition .row a:hover { text-decoration: underline; }

.MenuProductItem .container .video { border-top: 1px solid #d9dade; margin-top: 15px; padding-top: 15px; }
.MenuProductItem .container .video video { max-width: 100%; }

.window.menuPhoto .photo {
	background-position: center;
	background-size: cover; 
	height: 100%;
	/* margin: -10px -15px -10px 0; */
	position: relative;
}

/* --- tablet --- */
.tablet#MenuPage .productsNav a { font-size: 15px; }
.tablet .MenuProductItem .container h4 { font-size: 20px; }
.tablet .MenuProductItem .container h5 { font-size: 16px; }
.tablet .MenuProductItem .container .composition .row span,
.tablet .MenuProductItem .container .composition .row a,
.tablet .MenuProductItem .container p { font-size: 16px; font-weight: bold; }

/* --- CUSTOMER ITEM --- */
.customerItem .tabsNav a { 
	background: #F1F2FA; 
	border-radius: 5px;
	color: #5e626d; 
	display: inline-block; 
	margin-right: 5px;
	padding: 5px 20px; 
}
.customerItem .tabsNav a:hover { color: #54b6a9; }
.customerItem .tabsNav a.active { background: #54b6a9; color: #ffffff; }

.customerItem .tabsContent .tab { padding-top: 10px; }

.customerItem .addressBlock .add { margin-top: 20px; }
.customerItem .address { 
	border-top: 1px solid #e9eaee;
	margin-top: 10px;
	padding-top: 20px;
	padding-right: 30px; 
}
.customerItem .address:first-of-type { border: none; }
.customerItem .address .dadata { padding-right: 20px; }
.customerItem .address .comment { margin: 10px 0 5px; }
.customerItem .address .toggle { display: block; position: absolute; top: 2px; right: -8px; }
.customerItem .address .toggle i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	width: 24px;
}
.customerItem .address .toggle.up i { background-position: -216px -128px; }
.customerItem .address .toggle.down i { background-position: -240px -128px; }
.customerItem .address .remove { position: absolute; top: 5px; right: -15px; }

.customerItem .stat .item { margin-bottom: 5px; }
.customerItem .stat h4 { margin: 0; font-size: 12px;  }
.customerItem .stat .number { font-size: 18px; font-weight: 700; color: #54b6a9; line-height: 25px; }
.customerItem .stat .number span { font-size: 13px; }


.customerItem .orderStat .graph .col { width: calc(100%/6 - 1px); }
.customerItem .orderStat .col .bar { min-height: 200px; margin: 0 25%; position: relative; }
.customerItem .orderStat .col .bar .fill { 
	border: 1px solid #ffffff; 
	position: absolute;
	bottom: 0;
	width: 100%;
}
.customerItem .orderStat .col .bar .fill .amount { 
	color: #FFFFFF;
	display: block;
	font-weight: 700;
	text-align: center;
	width: 100%;
}
.customerItem .orderStat .col .date {
	border-top: 1px solid #dddddd;
	color: #86878c;
	font-size: 11px;
	line-height: 30px;
	text-align: center;
	text-transform: capitalize;
}

.customerItem .brands { margin: 10px 0 20px; }
.customerItem .brands .item { border-top: 1px solid #dddddd; }
.customerItem .brands .chart > div { margin: auto; }
.customerItem .brands .chart text { color: #535663; }

.customerItem .orderHistory .table { display: table; width: 100%; font-size: 12px; }
.customerItem .orderHistory .order { display: table-row-group; box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); border-radius: 2px; }
.customerItem .orderHistory .order .tr { display: table-row; }
.customerItem .orderHistory .order .tr .td { display: table-cell; }
.customerItem .orderHistory .order .tr.head { background: #f5f6fb; }
.customerItem .orderHistory .order .tr.head .td { border-color: transparent; padding: 5px; line-height: 30px; display: table-cell; }
#OrdersPage .customerItem .orderHistory .order .td { display: table-cell; border-left: 1px solid #dddddd; padding: 2px 5px; vertical-align: top; border-bottom: none; }
.customerItem .orderHistory .order .td:first-child { border: none; }
.customerItem .orderHistory .order .td.products { width: 50%; }
.customerItem .orderHistory .order .td.status { width: 20%; }
.customerItem .orderHistory .order img { max-width: 90%; max-height: 40px; }
.customerItem .orderHistory .order h5 { text-transform: uppercase; color: #888888; font-size: 8px; line-height: 11px; }

.customerItem .orderHistory .order button {
	background: #3b96e3; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b96e3', endColorstr='#2e86d0',GradientType=0 ); /* IE6-9 */
	
	box-shadow: 1px 2px 10px rgba(110, 198, 247, 0.75);
	font: 14px/30px "Roboto", Arial, sans-serif;
}
.customerItem .orderHistory button:hover {
	background: #4da8f5; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #4da8f5 0%,#2e86d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4da8f5', endColorstr='#2e86d0',GradientType=0 ); /* IE6-9 */
}

.customerItem .orderHistory button:disabled {
    background: #c9cace;
    cursor: default;
    box-shadow: none;
}

/* --- CUSTOMERS PAGE --- */
#CustomersPage .filter {margin-bottom: 10px; }
#CustomersPage .filter .col { padding-right: 20px; }
#CustomersPage .filter button { margin-top: 16px; width: 20%; }

#CustomersPage .table i.icon {
	background: url(/static/media/icons.9c0007f3.svg) -672px -152px;
	display: block;
	height: 24px;
	margin-top: 5px;
	width: 24px;
}
#CustomersPage .table .icon.blacklist { background-position: -672px -152px; }
#CustomersPage .table .icon.common { background-position: -840px -152px; }

#CustomersPage .confirmBlock .buttons { float: none; }

#CustomerItem .section > .clearfix { min-height: auto; }
#CustomerItem .section .main .col { padding-right: 20px; }
#CustomerItem .section .main .col:last-child { margin-bottom: 20px; }

#CustomerItem .icon.common { background-position: -840px -152px; }



/* --- ORDERS PAGE --- */
/* #OrdersPage .listpage { padding-top: 90px; } */
#OrdersPage .head { margin: 0; }
#OrdersPage section > .head { display: block; }

#OrdersPage .head.fixed { 
	background: #f5f6fb;
	padding: 15px 20px 5px;
	position: fixed;
	top: 70px;
	right: 0;
	z-index: 2;
}

#OrdersPage .stat { font-size: 14px; margin: 0; }
#OrdersPage .stat span { display: inline-block; padding: 0 10px; border-left: 1px solid #d9dade; }
#OrdersPage .stat span span { font-size: 12px; color: #9a9a9a; padding: 0; }
#OrdersPage .stat span:first-of-type { border: none; padding-left: 0; }

#OrdersPage section > .head h2 { float: left; }
#OrdersPage section > .head button { float: right; } 


#OrdersPage .noSearch { margin-top: 20px; }

#OrdersPage .head .filter { float: right; margin: 2px 0; }
#OrdersPage .head .filter .nomargin {float: left; margin-right: 20px; }
#OrdersPage .head .filter .buttonGroup span,
#OrdersPage .head .filter .tags span { background: #FFFFFF; }
#OrdersPage .head .filter .buttonGroup span:hover,
#OrdersPage .head .filter .tags span:hover { background: #54b6a9; }
#OrdersPage .head .filter .buttonGroup span.active,
#OrdersPage .head .filter .tags span.active { background: #54b6a9; color: #FFFFFF; }


#OrdersPage .filter.fixed { background: #c1c3cc; margin: 5px 0; padding: 5px; }
#OrdersPage .filter .toggleFilter { top : 8px; right: 10px; left: auto; }
#OrdersPage .filter.fixed > div > .col { min-height: 32px; padding-right: 5px; }
#OrdersPage .filter .tags span { margin: 1px 0 0 5px; font-size: 12px; line-height: 30px; padding: 0 5px; box-sizing: border-box; }
#OrdersPage .filter .tags span:first-child { margin-left: 0; }

#OrdersPage .filter .col.payment { width: 10%; }
#OrdersPage .filter .col.filial { width: 13% ; }
#OrdersPage .filter .col.courier { width: 16%; }
#OrdersPage .filter .col.status { width: 13%; }

@media (max-width: 1280px) {
}
@media (max-width: 1030px) {
	#OrdersPage .stat { font-size: 12px; }
	#OrdersPage .head .filter .buttonGroup span { padding: 0 7px;}
}

#OrdersPage .filter .datePeriod {
	background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 5px 0 5px 5px;
	position: absolute;
	top: 42px;
    left: 0;
    z-index: 1;
}
#OrdersPage .filter .datePeriod .col { padding-right: 5px; }
#OrdersPage .filter .datePeriod .button { margin-top: 17px; text-align: center; padding: 0 10px;  }
#OrdersPage .filter .datePeriod .close { 
	background: none;
	box-shadow: none;
	position: absolute;
	top: 5px;
    right: 5px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
#OrdersPage .filter .datePeriod .close i {
	background: url(/static/media/icons.9c0007f3.svg) -168px -128px;
	display: block;
	height: 24px;
	width: 24px;
}

#OrdersPage .filter .addDesktop input { background: #FFFFFF; }
#OrdersPage .filter .addDesktop label { color: #535353; }

#OrdersPage .SortHandle { top: 10px; left: 5px; }

/** --- ORDERS TABLE CSS --- */
.ordersTable.table { display: table; width: 100%; }
.ordersTable .tableBody { overflow: auto; }
.ordersTable .tableBody .SortableList { display: table; }
.ordersTable.table .tr h5 { text-transform: uppercase; font-size: 8px; color: #888888; line-height: 10px; }

.ordersTable .tag .time {
	border-radius: 50%;
	display: inline-block;
	height: 14px;
	margin-right: 5px;
	vertical-align: middle;
	width: 14px;
}
.ordersTable .tag .time.red { background: rgb(239, 139, 139); }
.ordersTable .tag .time.yellow { background: rgb(244, 233, 137); }
.ordersTable .tag .time.green { background: rgb(145, 249, 154); }
.ordersTable .tag .time.orange { background: rgb(242, 188, 139); }

.ordersTable .tr {
	border-radius: 2px;
	display: table-row-group;
	font-size: 12px;
	line-height: 19px;
	margin: 2px;
}
.ordersTable .tr .head { background: #ffffff; margin: 0; display: table-row; height: 43px; }
.ordersTable .tr .body { display: table-row; background: #FFFFFF;}
.ordersTable .tr .td {
	border-left: 1px solid #dddddd; 
	border-bottom: 6px solid #f5f6fb; 
	display: table-cell; 
	float: none; 
	padding: 3px 10px; 
	position: relative;
	vertical-align: top;
}
.ordersTable .tr .head .td {
	border-bottom: 1px solid #d9dade;
	border-left-color: transparent; 
	vertical-align: middle; 
	padding: 1px 10px 0;  
}
.ordersTable .tr .td:first-of-type { border-left: none; }

.ordersTable .tr .head .orderNumber b { border-bottom: 1px dotted #5e626d; cursor: pointer; }
.ordersTable .tr .head .orderNumber { min-width: calc(100% - 100px); text-overflow: clip; }

.ordersTable .tr .head .td > span:not(.tag) {
	line-height: 32px; 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
}

.ordersTable .tr .head .tag { white-space: nowrap; overflow: hidden; margin-bottom: 0; vertical-align: middle; }
.ordersTable .tr .head .tag.alert { color: #ad0f49; font-weight: bold; }

.ordersTable.table .tr button {
	background: #3b96e3;
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%);
	margin: 0;
	padding: 0 10px;
}
.ordersTable.table .tr button.red {
	background: #e73535;
	background: linear-gradient(to bottom, #e73535 0%,#e13131 100%);
	font-size: 10px !important;
}
#OrdersPage:not(.mobile) .table .tr button { width: auto; line-height: 25px; font-size: 12px; }
.ordersTable .tr button:disabled { background: #c9cace; cursor: default; box-shadow: none; }

.ordersTable .tr .td .col { padding-right: 0; }

.ordersTable .tr button.icon i { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }

.ordersTable .tr .head span + button.button { float: right; margin-top: 4px; margin-right: 0; }
.ordersTable .tr .head .form-row { margin: 0; }

.ordersTable .tr .head h5 { color: #535663; line-height: 12px; font-size: 8px; }

.ordersTable .tr .products { width: 23%; }
.ordersTable .tr .products .ready { font-weight: 700; color: #54b6a9; }
.ordersTable .tr .head .products .col { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.ordersTable .tr .body .products .col { line-height: 17px; margin-bottom: 2px; }
.ordersTable .tr .products .toggle { color: #5e626d; border-bottom: 1px dotted #5e626d; }
.ordersTable .tr .products .price span { font-size: 10px; opacity: 0.7; }
.ordersTable .tr .products .mod > .col:first-child { padding-left: 20px; color: #888888; }
.ordersTable .tr .products .mod > .col:first-child::before { content: "+" ; position: absolute; left: 9px; }

.ordersTable .tr .amount { width: 5%; min-width: 75px; }
.ordersTable .tr .amount p span { font-size: 10px; opacity: 0.7; }

.ordersTable .tr .filial { width: 8%; white-space: nowrap; max-width: 150px; min-width: 150px; }
.ordersTable .tr .filial .reciving { float: left; }
.ordersTable .tr .filial .reference { float: right;}
.ordersTable .tr .filial .reference .tag { margin-right: 0; }
.ordersTable .tr .filial p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 15px;
	margin-bottom: 2px;
	max-width: 120px;
}
.ordersTable .tr .filial img { max-width: 90px; max-height: 30px; }

.ordersTable .tr:last-child .payment .form-row .select__menu-list { max-height : 80px; }
.ordersTable .tr .payment { min-width: 110px; }
.ordersTable .tr .payment button { float: none; margin-top: 5px; }

.ordersTable .tr .payment .tag { padding: 1px 4px; vertical-align: top; }
.ordersTable .tr .payment button.qr { margin-top: 0; padding: 0 2px; }
.ordersTable .tr .payment button.qr i { background: url(/static/media/icons.9c0007f3.svg) -984px -128px; }

.ordersTable .tr .time.td { min-width: 310px; }

.ordersTable .tr .head .time .couriers { float: right; padding-top: 4px; text-align: right; }
.ordersTable .tr .head .time .couriers .icon { margin: 0 0 0 5px; }
.ordersTable .tr .head .time .couriers .icon.info {
	background: #f2f3f9;
	border-radius: 50%;
	display: inline-block;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}
.ordersTable .tr .head .time .couriers .icon.info i { 
	background-position: -984px -152px; 
	-webkit-filter: brightness(0) invert(0.6); 
	        filter: brightness(0) invert(0.6);
}
.ordersTable .tr .head .time .couriers .icon.info:hover,
.ordersTable .tr .head .time .couriers .icon.info:active,
.ordersTable .tr .head .time .couriers .icon.info.active {
	background: #54B6A9;
}
.ordersTable .tr .head .time .couriers .icon.info:hover i,
.ordersTable .tr .head .time .couriers .icon.info:active i,
.ordersTable .tr .head .time .couriers .icon.info.active i {
	-webkit-filter: brightness(0) invert(1);
	        filter: brightness(0) invert(1);
}

.ordersTable .tr .head .time .couriers .form-row,
.ordersTable .tr .head .time .couriers .tag { display: inline-block; margin-right: 0; }
.ordersTable .tr .head .time .couriers .form-row { width: calc(100% - 35px); }
.ordersTable .tr .head .time .couriers .tag { max-width: calc(100% - 35px); text-overflow: ellipsis; }

.ordersTable .tr .customer { text-align: center; vertical-align: middle; width: 13%; min-width: 150px; max-width: 150px; }
.ordersTable .tr .customer .form-row {text-align: left; }

.ordersTable .tr:last-child .customer .form-row .select__menu-list { max-height : 120px; }
.ordersTable .tr .customer h4 {
	font-size: 13px;
	margin-bottom: 0; 
	text-transform: none; 
	white-space: nowrap;
	overflow: hidden;
	max-width: 150px;
}
.ordersTable .tr .customer h4 a { text-overflow: ellipsis; overflow: hidden; max-width: 100%; }
.ordersTable .tr .customer h5 { font-size: 11px; margin-bottom: 5px; }
.ordersTable .tr .customer .tag { margin: 0; }
.ordersTable .tr .customer i {
	background: url(/static/media/icons.9c0007f3.svg) -576px -152px;
	display: inline-block;
	height: 24px;
	margin-right: 7px;
	width: 24px;
	vertical-align: middle;
}
.ordersTable .tr .customer a i { background-position: -624px -152px; }
.ordersTable .tr .customer a:hover i { background-position: -648px -152px; }
.ordersTable .tr .td.remove { vertical-align: middle; width: 40px; position: relative; }

.ordersTable .tr .td.remove .priority {
	display: block;
	height: 12px;
	margin: 0 -10px;
	padding: 3px;
	position: absolute;
	left: 50%;
	top: 50%;
	text-align: center;
}
.ordersTable .tr .td.remove .priority i {
	border-color: transparent;
	border-width: 8px 6px;
	border-style: solid;
	display: block;
	height: 0;
}
.ordersTable .tr .td.remove .priority.up { margin-top: -14px; }
.ordersTable .tr .td.remove .priority.up i { border-bottom-color: #535663; border-top: none; }
.ordersTable .tr .td.remove .priority.up:hover i { border-bottom-color: #54b6a9; }

.ordersTable .tr .td.remove .priority.down i { border-top-color: #535663; border-bottom: none; }
.ordersTable .tr .td.remove .priority.down:hover i { border-top-color: #54b6a9; }



.ordersTable .tr a.remove { background: transparent; padding: 0; }
.ordersTable .tr a.remove i { margin: 0; }

.ordersTable .orderActionsList {
	background : #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
	padding: 2px 5px 5px;
	position: absolute;
	top: 45px;
	z-index: 2;
}
.ordersTable .orderActionsList::after {
	border: 8px transparent solid;
	border-bottom-color: #FFFFFF;
	content: "";
	position: absolute;
	top: -16px;
	left: 14px;
}
.ordersTable .orderActionsList.phones { right: 0; }
.ordersTable .orderActionsList.phones::after { left: auto; right: 16px; }
.ordersTable .orderActionsList span {
	background : #f2f3f9;
	border-radius: 2px;
	color: #535663;
	display: block;
	line-height: 25px;
	margin-top: 3px; 
	padding: 0 10px;
	white-space: nowrap;
	min-width: 70px;
}
.ordersTable .orderActionsList.printFormats span { cursor: pointer; }
.ordersTable .orderActionsList.printFormats span:hover { color: #54b6a9; }

.ordersTable .orderActionsList.phones span {
	display: inline-block;
	line-height: 24px;
	width: auto;
	vertical-align: middle;
}
.ordersTable .orderActionsList.phones .icon { margin: 0; vertical-align: middle; }

@media (max-width: 1030px) {
	.ordersTable .tr .td { padding: 3px; }
	.ordersTable .tr select { font-size: 12px; }
	.ordersTable .tr .payment { min-width: 80px; }
	.ordersTable .tr .head .td { padding: 1px 5px 0; }
	.ordersTable .tr .head .tag { padding: 0 5px; }
	.ordersTable .tr .products { max-width: 240px; }
	.ordersTable .tr .filial .reference { margin: 0; }
	.ordersTable .tr .filial { max-width: 115px; min-width: 115px; }
	.ordersTable .tr .description { max-width: 230px; }
	.ordersTable .tr .customer { min-width: 120px; }
}

/* --- ORDERS PAGE – MOBILE ---*/
#OrdersPage.mobile .listpage { padding: 0; }

#OrdersPage.mobile .stat { font-size: 11px; margin: 0 0 10px; width: 100%; float: none; line-height: 25px; }

#OrdersPage.mobile .addFilter button {
	float: left;
	font-size: 14px;
	padding: 0 10px;
}
#OrdersPage.mobile .addFilter .time { margin: 0 40px 0 110px; }
#OrdersPage.mobile .addFilter .tags span { background: #FFFFFF; line-height: 35px; margin-right: 5px; font-size: 12px; }

#OrdersPage.mobile .addFilter .date {
	background: #FFFFFF;
	box-shadow: none;
	float: right;
	padding: 5px;
	height: 35px;
}
#OrdersPage.mobile .addFilter .date i { display: block; margin: 0; }
#OrdersPage.mobile .addFilter .dates {
	background: #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
	padding: 10px;
	position: absolute;
	left: 1%;
	width: 98%;
	z-index: 5;
}
#OrdersPage.mobile .addFilter .dates::before {
	content: "";
	border: 5px solid #FFFFFF;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: absolute;
	top: -5px;
	right: 10px;
}
#OrdersPage.mobile .addFilter .dates .buttonGroup span { padding: 0 10px;}
#OrdersPage.mobile .addFilter .dates .datePeriod { margin-top: 30px; }
#OrdersPage.mobile .addFilter .dates .datePeriod .button { float: right; margin-top: -83px; }

#OrdersPage.mobile .filter { background: #c1c3cc; border-radius: 5px; padding: 5px 40px 5px 10px; margin-bottom: 5px; }
#OrdersPage.mobile .filter .nomargin,
#OrdersPage.mobile .filter .addDesktop .col
{ width: 100%; float:none; }
#OrdersPage.mobile .filter .nomargin { padding: 0; margin: 5px 0; }

#OrdersPage.mobile .filter .col.payment { width: 100%; }

#OrdersPage.mobile .filter .tags span { margin: 0 1% 0 0; text-align: center; width: 49%; }
#OrdersPage.mobile .filter .datePeriod { box-shadow: none; position: relative; top: 0; }


#OrdersPage.mobile .table { display: block; }
#OrdersPage.mobile .tableBody .SortableList { display: block; }

#OrdersPage.mobile .table .tr { box-shadow: 0 1px 5px rgba(0,0,0,0.13); display: block; margin: 0 0 10px; }
#OrdersPage.mobile .table .tr .head { border-radius: 2px 2px 0 0; display: block;  height: auto; }
#OrdersPage.mobile .table .tr .body { display: block; background: #FFFFFF;}

#OrdersPage.mobile .table .tr .td { 
	display: block; 
	float: left; 
	padding: 3px 10px; 
	border-bottom: none;
}

#OrdersPage.mobile .table .tr .head .td { border: none; }

#OrdersPage.mobile .table .tr .head .products { width: 100%;  }
#OrdersPage.mobile .table .tr .head .orderNumber { width: 60%; vertical-align: middle; min-width: auto; }
#OrdersPage.mobile .table .tr .head .status { width: 40%; float: right; }
#OrdersPage.mobile .table .tr .head .time { min-width: auto; padding-top: 0; }

#OrdersPage.mobile .table .tr .head .option {
    float: right;
    margin-top: 10px;
	width: auto;
    padding: 0 15px;
}
#OrdersPage.mobile .table .tr .head .optionMenu {
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,0.5);
	border-radius: 4px;
	position: absolute;
	top: 45px;
	left: 5%;
	width: 90%;
	z-index: 2;
}
#OrdersPage.mobile .table .tr .head .optionMenu:before {
	content: "";
	border: 5px solid #ffffff;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: absolute;
	top: -5px;
	right: 18px;

}
#OrdersPage.mobile .table .tr .head .optionMenu .form-row { margin-top: 20px; }
#OrdersPage.mobile .table .tr .head .optionMenu .row { padding: 5px 15px; } 
#OrdersPage.mobile .table .tr .optionMenu button,
#OrdersPage.mobile .table .tr .optionMenu .button { float: left; line-height: 31px; }

#OrdersPage.mobile .table .tr .row { border-bottom: 1px solid #dddddd; }

#OrdersPage.mobile .table .tr .products { width: 65%; }
#OrdersPage.mobile .table .tr .amount { width: 35%; }
#OrdersPage.mobile .table .tr .amount .pay .tag { 
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 85%;
}
#OrdersPage.mobile .table .tr .filial { width: 35%; }
#OrdersPage.mobile .table .tr .customer { width: 100%; text-align: left; }
#OrdersPage.mobile .table .tr .customer > * { display: inline-block; vertical-align: middle; }
#OrdersPage.mobile .table .tr .customer h4 { height: 26px; max-width: 40%; }
#OrdersPage.mobile .table .tr .customer h5 { margin: 0 10px; line-height: 26px; }
#OrdersPage.mobile .table .tr .customer p { float: right; }

#OrdersPage.mobile .table .tr .payment { padding: 0; }
#OrdersPage.mobile .table .tr .payment button,
#OrdersPage.mobile .table .tr .payment p { width: 35%; margin: 20px 0 0 5%; line-height: 31px; float: left; }

#OrdersPage.mobile .table .buttons { padding: 5px 10px; }
#OrdersPage.mobile .table .buttons button { line-height: 40px; float: left; }
#OrdersPage.mobile .table .buttons .remove { height: 40px; text-align: center; }
#OrdersPage.mobile .table .buttons .remove i { -webkit-transform: scale(1.5); transform: scale(1.5); margin-top: 3px; }

/* #OrdersPage .table .tr .head .history {
	overflow: visible;
	padding: 10px;
	position: absolute;
	width: 340px;
} */
/* #OrdersPage .table .tr .head .history::before {
	border: 5px solid transparent;
	border-bottom-color: #ffffff;
	content: "";
	position: absolute;
	top: -10px;
	left: 30px;
} */
/* #OrdersPage .table .tr .head .history p { vertical-align: middle; margin-bottom: 5px; font-size: 12px; }
#OrdersPage .table .tr .head .history i {
	display: inline-block;
	border-radius: 50%;
	height: 15px;
	margin-right: 10px;
	width: 15px;
	vertical-align: middle; 
}
#OrdersPage .table .tr .head .history .user { margin-top: 10px; }
#OrdersPage .table .tr .head .history .user p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .table .tr .head .history .user p:first-child { padding-left: 25px; } */


/* #OrdersPage .table .tr .head .td > span:not(.tag) { 
	line-height: 32px; 
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
} */

/* #OrdersPage .table .tr .head .tag { white-space: nowrap; overflow: hidden; margin-bottom: 0; } */
/* #OrdersPage .table .tr button {
	background: #3b96e3;
	background: linear-gradient(to bottom, #3b96e3 0%,#2e86d0 100%);
	line-height: 30px;
	margin: 0;
	width: 90px;
}
#OrdersPage .table .tr button:disabled { background: #c9cace; cursor: default; box-shadow: none; }
#OrdersPage .table .tr .head span + button { margin-left: 10px; }
#OrdersPage .table .tr .head .form-row { margin: 0; }

#OrdersPage .table .tr .head h5 { color: #535663; line-height: 12px; font-size: 8px; } */

/* #OrdersPage.mobile .table .tr .products { width: 100%; } */
/* #OrdersPage .table .tr .products .ready { font-weight: 700; color: #54b6a9; } */
/* #OrdersPage .table .tr .products .col { text-overflow: ellipsis; white-space: nowrap; overflow: hidden; } */
/* #OrdersPage .table .tr .products .toggle { color: #5e626d; border-bottom: 1px dotted #5e626d; } */

/* #OrdersPage .table .tr .filial { width: 8%; }
#OrdersPage .table .tr .filial p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .table .tr .filial img { max-width: 90px; max-height: 40px; }

#OrdersPage .table .tr:last-child .payment .form-row .select__menu-list { max-height : 80px; }
#OrdersPage .table .tr .payment { min-width: 110px; }
#OrdersPage .table .tr .payment button { float: none; margin-top: 5px; }

#OrdersPage .table .tr .time.td { min-width: 310px; }
#OrdersPage .table .tr .head .time .select { float: right; margin-top: 4px; }




*/


/* --- ORDERS PAGE - CUSTOMER --- */

#OrdersPage .customerItem  > .clearfix:first-of-type > h4 { padding-bottom: 10px; }

#OrdersPage .customerItem .mainInfo > .col:first-child {padding-right: 20px;}
#OrdersPage .customerItem .main .title,
#OrdersPage .customerItem .main h5 { font-size: 11px; color: #888888; text-transform: uppercase; }
#OrdersPage .customerItem .main p { line-height: 25px; }

#OrdersPage .customerItem .stat { background: #f5f6fb; border-radius: 5px; padding: 10px 10px 5px; }
#OrdersPage .customerItem .stat h4 { margin: 0; line-height: 20px; }
#OrdersPage .customerItem .stat .number span { border: none; padding: 0; }

#OrdersPage .customerItem .orderHistory .order .tr { display: table-row; }

#OrdersPage .window.qrcode { min-height: 500px; }
#OrdersPage .window.qrcode img { margin-top: 20px; max-width: 100%; }

/* --- ORDERS PAGE - CONTENT --- */
#OrdersPage .window.content h3, #OrdersPage .window.content h4 { text-transform: none; }
#OrdersPage .window.content h3 { font-size: 15px; line-height: 35px; }
#OrdersPage .window.content h4 { font-size: 14px; margin-bottom: 8px; position: relative; }

#OrdersPage .window.content .toggleContent:hover,
#OrdersPage .window.content .toggleContent:active,
#OrdersPage .window.content .open .toggleContent { color: #54b6a9; }
#OrdersPage .window.content .toggleContent i {
	background: url(/static/media/icons.9c0007f3.svg) -240px -128px;
	position: absolute;
	left: -24px;
}
#OrdersPage .window.content .open > h4 > .toggleContent i { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }

#OrdersPage .window.content .article { padding-left: 25px; }
#OrdersPage .window.content .body { padding: 15px 0; }
#OrdersPage .window.content ul { margin: 10px 30px; list-style: disc;}
#OrdersPage .window.content ol { margin: 10px 30px; list-style: decimal; }

/* --- ORDERS PAGE - BILL --- */
#OrdersPage .bill { padding: 0; }

/* #OrdersPage .bill .close { top: 10px; right: 10px; } */

#OrdersPage .bill .print { padding: 20px; }
#OrdersPage .bill .print .checkGroupLabel { width: 100%; }
#OrdersPage .bill .print .button { width: 100%; text-align: center; margin-top: 20px; }

#OrdersPage .bill .billTemplate { background: #f5f6fb; padding: 20px;}

/* --- ORDERS PAGE - PROCESSING --- */
#OrdersCookPage .head { vertical-align: middle; -webkit-justify-content: start; justify-content: start; grid-gap: 30px; gap: 30px; }
#OrdersCookPage .head h2 { vertical-align: middle; }

#OrdersCookPage .filter { display: inline-block; vertical-align: middle; margin-top: -15px; width: 50%; }
#OrdersCookPage .filter .col { padding-right: 20px; }

#OrdersCookPage .list { margin-top: 10px; overflow: auto; }
#OrdersCookPage .list .column { padding-right: 20px; }
#OrdersCookPage .list.tablet .column { padding-right: 10px; }

#OrdersCookPage .list .item {
	background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
	overflow: hidden;
    position: relative;
}
#OrdersCookPage .list .item h5 { text-transform: uppercase; font-size: 10px; }
#OrdersCookPage .list .item button { float: none; width: 100%; padding: 0; }
#OrdersCookPage .list .item .head .tag { margin: 0; }

#OrdersCookPage .list .item .head {
	background: #e8e9eb;
	border-radius: 4px 4px 0 0;
	color: #5e626d;
	margin: 0;
}
#OrdersCookPage .list .item .head.red { background: rgb(239, 139, 139); }
#OrdersCookPage .list .item .head.yellow { background: rgb(244, 233, 137); }
#OrdersCookPage .list .item .head.green { background: rgb(145, 249, 154); }
#OrdersCookPage .list .item .head.orange { background: rgb(242, 188, 139); }

#OrdersCookPage .list .item .head .orderInfo { padding: 5px 10px; }
#OrdersCookPage .list .item .head .orderInfo > div:first-child { margin-bottom: 3px; }
#OrdersCookPage .list .item .head .orderInfo .orderNumber,
#OrdersCookPage .list .item .head .orderInfo .orderType { display: inline-block; width: 45px; line-height: 24px; }
#OrdersCookPage .list .item .head .orderInfo .orderType i { background: url(/static/media/icons.9c0007f3.svg); }
#OrdersCookPage .list .item .head .orderInfo .orderType.restaurant i { background-position: -864px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.pickup i { background-position: -888px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.delivery i { background-position: -912px -128px;}
#OrdersCookPage .list .item .head .orderInfo .orderType.home i { background-position: -24px -200px; -webkit-filter: brightness(0) invert(0.45); filter: brightness(0) invert(0.45);}

#OrdersCookPage .list .item .head .time {
	font-size: 22px;
	line-height: 63px;
	text-align: right;
	padding: 0 10px;
}

#OrdersCookPage .list .item .head .reference { border-left: 3px solid #f2f3f9; padding: 9px 10px; }
#OrdersCookPage .list.tablet .item .head .reference { padding: 9px 5px; }

#OrdersCookPage .list .item .decription { padding: 5px 10px; } 
#OrdersCookPage .list .item .decription > .col { min-height: 1px; }
#OrdersCookPage .list .item .decription img { max-width: 100%; max-height: 30px; }
#OrdersCookPage .list .item .decription .brand { float: right; }

#OrdersCookPage .list .item .body { border-left: 10px solid #d5d9f2; }

#OrdersCookPage .list .item .products { display: table; width: 100%; }
#OrdersCookPage .list .item .product { display: table-row-group; }
#OrdersCookPage .list .item .product .row { display: table-row; }
#OrdersCookPage .list .item .product .td { 
	display: table-cell; 
	border-bottom: 1px solid #d9dade;
	font-size: 14px;
	line-height: 30px;
	vertical-align: top;
}
#OrdersCookPage .list .item .product .td.count { text-align: center; width: 35px; }
#OrdersCookPage .list .item .product .td.name { padding: 0 5px; }
#OrdersCookPage .list .item .product .td.name .mods { color: #888888; }
#OrdersCookPage .list .item .product .td.workshops {
	text-align: center; width: 35px; vertical-align: text-top;
}
#OrdersCookPage .list .item .product .td.workshops i {
	background: url(/static/media/icons.9c0007f3.svg);
	display: inline-block;
	height: 24px;
	vertical-align: text-top;
	width: 24px;
}
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_1 { background-position: -144px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_2 { background-position: -168px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_3 { background-position: -192px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_4 { background-position: -216px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_5 { background-position: -240px -176px; }
#OrdersCookPage .list .item .product .td.workshops i.icon.icon_6 { background-position: -264px -176px; }

#OrdersCookPage .list .item .product .td.buttons { width: 80px; text-align: center; }
#OrdersCookPage .list .item .product .td.cancel { width: 30px; }
#OrdersCookPage .list .item .product .td.cancel i { margin: 0; }

#OrdersCookPage .list .item > button { border-radius: 0 0 5px 5px; }

#OrdersCookPage .list .item .product.inProcess { font-weight: bold; }
#OrdersCookPage .list .item .product.ready { font-weight: bold; color: #54B6A9; }

#OrdersCookPage .list .item.active .product .td { border: 3px solid #FFFFFF; line-height: 35px; }
#OrdersCookPage .list .item.active .product .td.count { 
	background: #3A95E2; 
	border-radius: 5px 0 0 5px;
	color: #FFFFFF; 
	font-size: 24px;
}
#OrdersCookPage .list .item.active .product .td.count.more { background: #1962A0; }
#OrdersCookPage .list .item.active .product.ready .td.count { background: #54B6A9;}
#OrdersCookPage .list .item.active .product .td.name { 
	background-color: #f5f6fb; 
	color: #454A5A;
	font-weight: bold;
	line-height: 21px;
	padding: 7px 5px;
}

#OrdersCookPage .list .item.active .product .td.name a { display: block; color: #454A5A; }
#OrdersCookPage .list .item.active .product .td.workshops { background-color: #f5f6fb; }

#OrdersCookPage .list .item.active .product .mods .td { border-top-width: 0; }
#OrdersCookPage .list .item.active .product .mods .count { background: none; }
#OrdersCookPage .list .item.active .product .mods .td.name { padding-top: 0; border-right-color: #f5f6fb; }
#OrdersCookPage .list .item.active .product .mods .td.name p { color: #757575; }
#OrdersCookPage .list .item.active .product .mods .td.workshops { padding-top: 21px; border-left-color: #f5f6fb; }
#OrdersCookPage .list .item.active .product .mods .td.workshops p {
	color: #757575;
	line-height: 21px;
	font-weight: bold;
}

#OrdersCookPage .window.large { position: absolute;}

/* --- CHANGE FROM BLOCK --- */
.changeBlock h4 { margin: 0; }
.changeBlock .main p { line-height: 30px; }
.changeBlock .calc .col { padding-right: 10px; }
.changeBlock .calc .col.preset { padding: 0; }
.changeBlock .calc a {
	background: #f5f6fb;
	border-radius: 4px;
	color: #535663;
	display: block;
	line-height: 28px;
	text-align: center;
	margin: 2px 0;
}
.changeBlock .calc .col.preset a { background: #54b6a9; color: #ffffff; }
.changeBlock .calc a.clear { background: #e83075; color: #ffffff; }

/* --- NEW ORDER PAGE --- */
#OrderItem .section .order { display: table; padding: 0; }
#OrderItem .section .order > div { display: table-row; }
#OrderItem .section .order > div > .col { display: table-cell; float: none; background: #f8f9fb; padding: 0; }
#OrderItem .section .order > div > .col.active { background: #FFFFFF; }

#OrderItem h5 { text-transform: uppercase; font-size: 11px; white-space: nowrap; }
#OrderItem .comment { margin-top: 0; }

#OrderItem .order .tab { color: #5e626d; line-height: 35px; text-align: center; }
#OrderItem .order .tab.active { background: #54b6a9; color: #FFFFFF; }

#OrderItem .order .tabBlock { border-top: 1px solid #e8e9eb; padding: 15px 20px 0; }
#OrderItem .order .active .tabBlock { border-top-color: #FFFFFF; }

@media (max-width: 1280px) {
	#OrderItem .order .tabBlock { padding: 10px 15px 0; }
}

#OrderItem .orderBlock { padding-left: 15px; border-right: 1px solid #dddddd; padding-right: 0; }
#OrderItem .orderBlock:first-of-type { padding: 0 20px 0 0; position: relative; }
#OrderItem .orderBlock:last-of-type { border: none; }

#OrderItem .orderBlock .brands .checkGroupLabel { width: 50%; }
#OrderItem .orderBlock .toggleBrands { font-size: 11px; line-height: 17px; position: absolute; top: -20px; right: 10px; }

#OrderItem .customerDescription textarea { height: 210px; }
#OrderItem .customerDescription p { margin: 5px 0; }
#OrderItem .customerDescription p i.icon {
	background: url(/static/media/icons.9c0007f3.svg) -624px -152px;
	display: inline-block;
	height: 24px;
	width: 24px;
	margin-right: 5px;
	position: static;
	vertical-align: middle;
}
#OrderItem .customerDescription p i.icon.birth { background-position: -960px -128px; }
#OrderItem .customerDescription p i.icon.bl { background-position: -672px -152px; }

#OrderItem .orderBlock .customerInfo { position: absolute; top: 22px; right: -4px; }
#OrderItem .orderBlock .customerInfo i {
	background: url(/static/media/icons.9c0007f3.svg) -624px -152px;
	display: block;
	height: 24px;
	width: 24px;
}
#OrderItem .orderBlock .customer .icon { position: absolute; top: 22px; }
#OrderItem .orderBlock .icon i {
	background: url(/static/media/icons.9c0007f3.svg) -624px -152px;
	display: block;
	height: 24px;
	width: 24px;
}
#OrderItem .orderBlock .customerInfo { right: -8px; }
#OrderItem .orderBlock .customerInfo i { background-position: -624px -152px; }
#OrderItem .orderBlock .customerInfo:hover i { background-position: -648px -152px; }

#OrderItem .orderBlock .changeCustomerIcon { right: 18px; }
#OrderItem .orderBlock .changeCustomerIcon i {
	background: url(/static/media/icons.9c0007f3.svg);
	background-position: -1010px -127px;
}
#OrderItem .orderBlock .changeCustomerIcon:hover i { background-position: -1010px -151px; }

#OrderItem .orderBlock .customerStat { right: 30px;}
#OrderItem .orderBlock .customerStat i {
	background-color: #757575;
	background-position: -960px -152px;
	border-radius: 50%;
}
#OrderItem .orderBlock .customerStat.active i { background-color: #54b6a9; }

#OrderItem .orderBlock .addressBlock {
	background: #f5f6fb;
    margin: 10px -20px 30px;
    padding: 10px 20px 5px;
}
#OrderItem .orderBlock .location { margin-bottom: 30px; }

#OrderItem .orderBlock .location .orderType span { padding-left: 10px; padding-right: 10px; }
#OrderItem .orderBlock .location .orderType span.home i { background-position: -24px -200px; }
#OrderItem .orderBlock .location .orderType span.home.active i { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }

#OrderItem .address { margin: 20px 0 10px; }
#OrderItem .address .col { padding-right: 10px;}
#OrderItem .address .dadata { padding-right: 20px; }
#OrderItem .address .comment { margin: 10px 0 5px; }
#OrderItem .address .toggle { display: block; position: absolute; top: 2px; right: -8px; }
#OrderItem .address .toggle i {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -216px -128px;
	display: inline-block;
	height: 24px;
	width: 24px;
}
#OrderItem .address .toggle.up i { background-position: -216px -128px; }
#OrderItem .address .toggle.down i { background-position: -240px -128px; }

#OrderItem .orderBlock .toDate { padding-left: 30px; }
#OrderItem .orderBlock .toDate i.date { left: -30px; }
#OrderItem .orderBlock .toDate label { left: 43px; }

#OrderItem .orderBlock .aggregators { margin-bottom: 10px; }
#OrderItem .orderBlock .aggregators .checkGroupLabel { width: 50%; }
#OrderItem .orderBlock .aggregators .toggleBrands { top: -20px; right: 0; }

#OrderItem .orderBlock .homeorder { margin: 10px 0 30px; }

#OrderItem .order .products .col { padding-right: 10px; }
#OrderItem .order .products .item { border-top: 1px solid #dddddd; padding: 5px 0; }
#OrderItem .order .products .item .form-row { margin: 0; }
#OrderItem .order .products .item .col { padding-right: 10px; }
#OrderItem .order .products .item > span { line-height: 22px; min-height: 32px; padding: 4px 0; }
#OrderItem .order .products .item .started { color : #54b6a9; font-weight: 700; }
#OrderItem .order .products .item .alert { color : #ad0f49; font-weight: 700; }
#OrderItem .order .products .item .mod { color: #888888; padding-left: 20px; }
#OrderItem .order .products .item .mod::before { content: "+"; position: absolute; left: 6px; }

#OrderItem .order .products .item .mods {
	color: #888888;
	display: block;
	font-size: 11px;
	line-height: 12px;
	margin-top: 5px;
}
#OrderItem .order .products .item .mods i {
	background: #888888;
	border-radius: 2px;
	color: #FFFFFF;
	display: inline-block;
	font-weight: bold;
	font-style: normal;
	height: 12px;
	margin-right: 5px;
	text-align: center;
	width: 12px;
}
#OrderItem .order .products .item .mods:hover { color: #54B6A9; }
#OrderItem .order .products .item .mods:hover i { background: #54B6A9; }

#OrderItem .order .total { padding-right: 0; }
#OrderItem .order .total h5 { line-height: 32px; text-align: right; }
#OrderItem .order .total span { line-height: 32px; padding-left: 13px; padding-right: 0; }
#OrderItem .order .total .form-row { margin: 0; padding-right: 0; }

#OrderItem .order .payment .paymentMethod .checkGroupLabel { margin: 5px 0; }

#OrderItem .menu { border-left: 1px solid #e8e9eb; overflow: auto; padding: 0; }

#OrderItem .section .menu .table { display: table; height: 100%; width: 100%; }
#OrderItem .section .menu .table .row { display: table-row; height: 100%; }
#OrderItem .section .menu .table .row > .col { display: table-cell; float: none; padding: 0;}

#OrderItem .menu .searchBlock.clearfix { padding: 5px; }
/* #OrderItem .menu .searchBlock .form-row { margin-top: 10px; } */
#OrderItem .menu .searchBlock .searchInput .form-input { padding-left: 35px; }

#OrderItem .menu .searchBlock .search {
	background: url(/static/media/icons.9c0007f3.svg) no-repeat -96px -152px;
	top: 4px;
	left: 5px;
}
#OrderItem .menu .searchBlock .icon i {
    background: url(/static/media/icons.9c0007f3.svg) no-repeat -360px -128px;
    display: block;
    height: 24px;
    width: 24px;
}
#OrderItem .menu .searchBlock .toggleFilter { left: auto; right: 20px; background: none; }
#OrderItem .menu .searchBlock .toggleFilter i { background-position: -360px -128px; }
#OrderItem .menu .searchBlock .toggleFilter.active i { background-position: -385px -128px; }

#OrderItem .menu .searchBlock .toggleProducts { position: absolute; right: 0; top: 5px; }
#OrderItem .menu .searchBlock .toggleProducts i { 
	background-position: -216px -152px; 
	-webkit-filter: brightness(0) invert(0.4); 
	        filter: brightness(0) invert(0.4);
}
#OrderItem .menu .searchBlock .toggleProducts { background: none; }
#OrderItem .menu .searchBlock .toggleProducts.active i { -webkit-filter: none; filter: none; }

#OrderItem .menu .item {
	background: #f8f9fb;
	color: #5e626d;
	padding: 0 10px;
	position: relative;
	z-index: 0;
}
#OrderItem .menu .item.active { background: #54b6a9 !important; color: #FFFFFF; }
#OrderItem .menu .item.disabled { cursor: default; color: #9a9a9a; }
#OrderItem .menu .item.disabled.active { color: #dddddd; }

#OrderItem .menu .sections { background: #f8f9fb; vertical-align: top; width: 130px; }
#OrderItem .menu .sections .item {
	border-top: 1px solid #e9eaee;
	box-sizing: border-box;
	display: block;
	line-height: 22px;
	padding: 8px 10px;
}

#OrderItem .menu .sections .item:first-of-type { border-top: none; }
#OrderItem .menu .sections .item.active { border: none; }


#OrderItem .menu .products { padding: 5px; }
#OrderItem .menu .products .item { margin: 5px; padding: 0; border-radius: 5px; width: 110px; height: 72px; border: 0; }
#OrderItem .menu .products .item a { 
	border-radius: 5px; 
	box-sizing: border-box;
	color: #5e626d;
	display: block; 
	padding: 3px 10px 5px; 
	width: 100%;
	height: 100%;
}
#OrderItem .menu .products .item a.active { background: #54b6a9 !important; color: #FFFFFF; }
#OrderItem .menu .products .item a.disabled { cursor: default; color: #9a9a9a; }
#OrderItem .menu .products .item a.disabled.active { color: #dddddd; }

#OrderItem .menu .products .item span { display: block; text-align: center; overflow: hidden; text-overflow: ellipsis; }
#OrderItem .menu .products .item .name { line-height: 17px; font-size: 12px; height: 34px; }
#OrderItem .menu .products .item .price,
#OrderItem .menu .products .item .title { font-weight: bold; line-height: 24px; }

#OrderItem .menu .products .productList { min-height: 613px; padding-bottom: 0; }

/* ----- MODIFICATORS BLOCK ----- */
#OrderItem .window.modificators {
	padding: 5px;
	min-width: 250px;
	max-width: 350px;
	max-height: 400px;
	overflow: auto;
}
#OrderItem .window.modificators::before {
	border: 5px solid transparent;
	border-bottom-color: #FFFFFF;
	content: "";
	position: absolute;
	top: -10px;
	left: 10px;
}
#OrderItem .window.modificators .item {
	background: #F5F6FB;
	border-radius: 2px;
	color: #5E626D;
	display: block;
	margin-top: 5px;
	padding: 2px 10px;
}
#OrderItem .window.modificators .item:first-child { margin: 0; }
#OrderItem .window.modificators .item:hover { color: #54b6a9; }
#OrderItem .window.modificators .item .price { text-align: right;}

/** ----- NEW ORDER MOBILE ----- */
.mobile #OrderItem .head {
	position: fixed;
	padding: 10px 5px;
	top: 50px;
	width: 100%;
}
.mobile #OrderItem .section { margin-top: 77px; }

.mobile #OrderItem .order .tabBlock > h4 { padding: 5px 10px; }
.mobile #OrderItem .orderBlock { padding: 0 10px;}

.mobile #OrderItem .customer .customerDescription {
	background: #f5f6fb;
	margin: 10px -10px;
    padding: 5px 10px 10px;
}

.mobile #OrderItem .menu .search .toggleFilter { right: 35px; }

.mobile #OrderItem .section .menu .table,
.mobile #OrderItem .section .menu .table .row { display: block; }

.mobile #OrderItem .menu .products { border-bottom: 1px solid #dddddd; margin-top: 10px; }
.mobile #OrderItem .menu .products .productList { height: 270px; min-height: auto; overflow: auto; width: 100%; }

.mobile #OrderItem .menu .products .productContainer { display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-flex-direction: column; flex-direction: column; height: 250px; }
.mobile #OrderItem .menu .products .item { width: 147px; height: 72px; }

.mobile #OrderItem .clearfix .titles { padding-left: 10px; padding-top: 10px; }
.mobile #OrderItem h5 { padding-left: 10px; }

.mobile #OrderItem .clearfix.form-row.col.col50 { padding-left: 10px; }
.mobile #OrderItem .clearfix.form-row.col.col50>label { padding-left: 10px; }

.mobile #OrderItem .clearfix.form-row.paymentMethod { padding-left: 10px; }
.mobile #OrderItem .clearfix.form-row.paymentMethod>label { padding-left: 10px; }

.mobile #OrderItem .clearfix .block p { padding-left: 10px; }

.mobile #OrderItem .order .products .item > span:nth-child(1) { line-height: 22px; min-height: 32px; padding: 4px 10px; }
.mobile #OrderItem .order div.clearfix.block.products { background-color: #F5F6FB; padding-bottom: 10px; }

/* OrderMobileTabs */

.orderMobileTabs {
	position: fixed;
	top: 100px;
	left: 0px;
	z-index: 9999;
	width: 100vw;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.orderMobileTabs a {
	width: 49%;
	height: 32px;
	border-radius: 0px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: #5E626D;
	background-color: white;
}

.orderMobileTabs a:nth-child(1) {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.orderMobileTabs a:nth-child(2) {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.activeLink {
	color: white !important;
	background-color: #54B6A9 !important;
}

/** ------ CONFIRM BLOCK ------ */
#OrdersPage .confirmBlock .buttons { float: none; }
#OrdersPage .confirmBlock button { width: 140px; float: none; }
#OrdersPage .confirmBlock .checkGroupLabel { width: 100%; max-width: none; }

/* ----- PAYMENT BLOCK ----- */
#OrdersPage .confirmBlock.paymentconfirm .close { right: 0; top: 0; }

#OrdersPage .confirmBlock.paymentconfirm .button { width: auto; }
#OrdersPage .confirmBlock.paymentconfirm .button.icon i { background: url(/static/media/icons.9c0007f3.svg); -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }
#OrdersPage .confirmBlock.paymentconfirm .button.icon_1 i { background-position: 0 -176px; }
#OrdersPage .confirmBlock.paymentconfirm .button.icon_2 i { background-position: -24px -176px; }
#OrdersPage .confirmBlock.paymentconfirm .buttons .right { float: right; }

#OrdersPage .confirmBlock.paymentconfirm .buttons .separated {
	border-top: 1px solid #dddddd;
    margin-top: 10px;
	padding-top: 30px;
}

#OrdersPage .confirmBlock.paymentconfirm .loading {
	background: rgba(255, 255, 255, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 5;
}
#OrdersPage .confirmBlock.paymentconfirm .loading img { margin: -10px -80px; position: absolute; top: 50%; left: 50%; }

#OrdersPage.mobile .confirmBlock { margin-left: 0; width: 98%; left: 1%; top: 190px; }

/* ----- ORDER HISTORY BLOCK ----- */
#OrdersPage .window.history {
	overflow: visible;
	padding: 10px;
	position: absolute;
	width: 340px;
}
#OrdersPage .window.history::before {
	border: 5px solid transparent;
	border-bottom-color: #ffffff;
	content: "";
	position: absolute;
	top: -10px;
	left: 20px;
}
#OrdersPage .window.history p {
	font-size: 12px; 
	margin-bottom: 5px; 
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle; 
	white-space: nowrap;
}
#OrdersPage .window.history i {
	display: inline-block;
	border-radius: 50%;
	height: 15px;
	margin-right: 10px;
	width: 15px;
	vertical-align: middle; 
}
#OrdersPage .window.history .user { margin-top: 10px; }
#OrdersPage .window.history .user p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#OrdersPage .window.history .user p:first-child { padding-left: 25px; }

/* ----- PAYMENT BLOCK ----- */

/** ------- DELIVERY PAGE ------- */
#DeliveryOrdersPage .head h2 { float: left; }
#DeliveryOrdersPage .head form { float: left; }
#DeliveryOrdersPage.mobile .head form { float: right; }

#DeliveryOrdersPage .deliveryList .item {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}
#DeliveryOrdersPage .item .head {
	border-bottom: 1px solid #d5d9f2;
	font-size: 15px;
	margin: 0;
	padding: 7px 15px;
}
#DeliveryOrdersPage .item .head .amount { text-align: right; }
#DeliveryOrdersPage .item .head .tag { margin: 22px 5px 0 0; font-size: 12px;}
#DeliveryOrdersPage .item .head .times {float: right; }
#DeliveryOrdersPage .item .head .times .tag { margin-top: 0;}

#DeliveryOrdersPage .item .head .times .tag i {
	border-radius: 50%;
	display: inline-block;
	height: 16px;
	margin-right: 5px;
	vertical-align: middle;
	width: 16px;
}
#DeliveryOrdersPage .item .head .times .tag i.red { background: rgb(239, 139, 139); }
#DeliveryOrdersPage .item .head .times .tag i.yellow { background: rgb(244, 233, 137); }
#DeliveryOrdersPage .item .head .times .tag i.green { background: rgb(145, 249, 154); }
#DeliveryOrdersPage .item .head .times .tag i.orange { background: rgb(242, 188, 139); }

#DeliveryOrdersPage .item .body { padding: 5px 15px 5px; }
#DeliveryOrdersPage .item .body p { margin-top: 7px; }

#DeliveryOrdersPage .item .body .filial { color: #86878c; }
#DeliveryOrdersPage .item .body .address { font-size: 15px; line-height: 24px; }

#DeliveryOrdersPage.mobile .item .block { margin-top: 10px; }
#DeliveryOrdersPage .item .block:last-child { padding-bottom: 0;}

#DeliveryOrdersPage .address { position: relative; width: 85% }
#DeliveryOrdersPage .geo {
	background: none;
	box-shadow: none;
	height: 24px;
	line-height: 24px;
	padding: 0;
	position: absolute;
	top: 0;
	right: -40px;
}

#DeliveryItem .head .phone { text-align: center; }
#DeliveryItem .head .close { float: right; text-align: center; }

#DeliveryItem .order {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;
}
#DeliveryItem .order .products {
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
	padding: 10px 15px;
}
#DeliveryItem .order .products .product { border-top: 1px solid #dddddd; }
#DeliveryItem .order .products .product:first-of-type { border-top: none; }

#DeliveryItem .paymentInfo .tag { margin-top: 5px; }


#DeliveryItem .buttons .button { text-align: center; }
#DeliveryItem .buttons .button.main { line-height: 50px; }

#DeliveryItem .button.icon:not(.geo) i { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }
#DeliveryItem .button.icon.qr i { margin: 0; }

#DeliveryItem .buttons .button.pay.icon i { background: url(/static/media/icons.9c0007f3.svg); }
#DeliveryItem .buttons .button.pay.icon_1 i { background-position: 0 -176px; }
#DeliveryItem .buttons .button.pay.icon_2 i { background-position: -24px -176px; }

#DeliveryItem .buttons .adds {
	border-top: 1px solid #c1c3cc;
	border-bottom: 1px solid #dddddd;
	margin: 25px 0 0;
	padding: 20px 0 10px;
}
#DeliveryItem .buttons .adds p { line-height: 35px; margin-left: 3%; font-size: 12px; }
#DeliveryItem .buttons .payment { font-weight: bold; padding: 0 0 10px; }

#DeliveryItem .buttons .button.gray { float: right; }

#DeliveryItem .loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: rgba(255,255,255,0.5);
}
#DeliveryItem .loading img { position: absolute; top: 50%; left: 50%; margin: -10px -80px; }

#DeliveryItem .confirmBlock.calc p { margin-bottom: 0; }

#DeliveryOrdersPage.mobile .confirmBlock.calc {
	width: 98%;
	left: 1%;
	margin: -180px 0 0;
	min-width: auto;
}

#DeliveryOrdersPage.mobile .changeBlock .calc a { line-height: 45px; }

#DeliveryItem .window.qrcode { min-height: 500px; }
#DeliveryItem .window.qrcode img { margin-top: 20px; max-width: 100%; }

#DeliveryOrdersPage.mobile .window.qrcode {
	width: 90%;
	left: 5%;
	margin: 0;
	top: 60px;
	min-height: 450px;
}


/*-----------ChangeCustomerBlock--------------*/
#OrdersPage .confirmBlock.changeCustomer .button.gray { float: right; }
#CatalogPage .productsNav > button { width: 100%; }

#CatalogPage .productsNav .brand { margin-bottom: 20px; padding-bottom: 5px; }
#CatalogPage .productsNav .brand > .add { margin-top: 20px; }

#CatalogPage .productsNav h5 {
	font-weight: bold;
	color: #54B6A9;
	font-size: 13px;
	line-height: 30px;
	padding: 0 15px;
	text-transform: uppercase;
}

#CatalogPage .productsNav .sectionItem { border-top: 1px solid #dddddd;}
#CatalogPage .productsNav .SortableList .SortableList .sectionItem { padding-left: 50px; }
#CatalogPage .productsNav .SortableList .SortableList .sectionItem .SortHandle { left: 25px; }
#CatalogPage .productsNav .SortableList .SortableList .sectionItem .edit { right: 27px; }

#CatalogPage .productsNav a:first-of-type { padding-top: 0; border-radius: 0; }
#CatalogPage .productsNav a:first-of-type i { top: 3px; }
#CatalogPage .productsNav a i.edit { right : 51px; }

#CatalogPage .productsNav form { padding: 10px; }
#CatalogPage .productsNav form .checkGroupLabel { width: 100%; }
#CatalogPage .productsNav form .buttons { float: none; }
#CatalogPage .productsNav form .buttons button { float: left; width: 70%; }

#CatalogPage .table .titles { margin: 0 2px; padding-left: 33px; }
#CatalogPage .table .titles span { padding: 0 5px; }
#CatalogPage .table i.hidden {
	background: url(/static/media/icons.9c0007f3.svg) -168px -152px; 
	display: inline-block;
	-webkit-filter: brightness(0) invert(0.5);
	        filter: brightness(0) invert(0.5);
	height: 24px;
	width: 24px;
	vertical-align: middle;
}
#CatalogPage .table .col { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
#CatalogPage .table .photo {
	background-position: center;
	background-size: cover; 
	border-radius: 2px;
	height: 40px;
	margin: 3px 0;
}


#CatalogItem .section { margin-top: 115px; }
#CatalogItem .fileContainer { height: 110px; }
#CatalogItem textarea { height: 110px; }
#CatalogItem h5 { color: #9f9f9f; text-transform: uppercase; font-size: 10px; }
#CatalogItem .productInfo { margin: 10px; }

#CatalogItem .optionsBlock .remove { position: absolute; right: -10px; top: 23px; }
#CatalogItem .optionsBlock .group { position: relative; }
#CatalogItem .optionsBlock .group .remove { right: 30px; }
#CatalogItem .optionsBlock .productInfo { margin: 0; }
